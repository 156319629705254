import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DriverDetailFlags from "../../../PolicyLifecycleManager/RedFlags/DriverDetailFlags";
import useGeneralState from "../../../../Context/actions/useGeneralState";

const DriverCard = ({ driver, itemIndex, showDriver }) => {

  const { controlToast } = useGeneralState()
  const handleCopy = () => {
    navigator.clipboard.writeText(`${driver.driverFirst} ${driver.driverLast}`);
    controlToast(true, "Driver name copied", "success");
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Card
      sx={{
        mb: 2,
        borderRadius: 3,
        backgroundColor: "#f9f9f9",
      }}
    >
      <CardContent>
        <Box>
          <Typography onClick={(e) => {
            showDriver(e, driver?.id, itemIndex)
          }} variant="h6">
            {driver.driverFirst} {driver.driverLast}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip title="Copy Driver Name">
            <IconButton onClick={handleCopy} color="primary">
              <CopyIcon />
            </IconButton>
          </Tooltip>
          {!driver.licensePhoto && (
            <Tooltip title="No Driver's License">
              <IconButton
                color="error"
                onClick={(e) => showDriver(e, driver.id, itemIndex)}
              >
                <PersonOffIcon />
              </IconButton>
            </Tooltip>
          )}
          <DriverDetailFlags singleIcon={true} driver={driver} />
        </Stack>
        <Stack mt={2}>
          <Typography
            variant="body2"
            color={
              driver.driverLookupStatus !== "Incomplete"
                ? "green"
                : "textSecondary"
            }
          >
            Country: {driver.licenseCountry}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            License State: {driver.licenseState || "N/A"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            License Number: {driver.licenseNumber}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            MXNMP: {driver.mxNMP}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Years of Experience: {driver.yoe}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Status: {driver.status}
          </Typography>
        </Stack>
      </CardContent>
      {/* {(formData.documentType === "Application" ||
        formData.documentType === "Policy") && (
        <CardActions>
          <Button
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={(e) => {
              if (isApplicationOrPolicy) {
                deleteThisDriver(e, driver);
              }
            }}
          >
            Delete Driver
          </Button>
        </CardActions>
      )} */}
    </Card>
  );
};

export default DriverCard;
