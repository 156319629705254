import React, { useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatePicker from "react-datepicker";
import ApiService from "../../services/southern-api/apiService";
import "react-datepicker/dist/react-datepicker.css";
import useTranslations from "../../Context/actions/useTranslations";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import ApprovalButtons from "../assets/forms/ApprovalButtons";

export default function InsuredInfo(props) {
  const { t } = useTranslations();

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();

  const agencyID = props.agencyID;
  const enableEditing = props.enableEditing;
  const setEnableEditing = props.setEnableEditing;
  const success = props.success;
  const setSuccess = props.setSuccess;
  const insuredsID = props.insuredsID;
  const policyID = props.policyID;
  const [formData, setFormData] = useState({});
  const [dateEstablished, setDateEstablished] = useState(new Date());

  const apiService = new ApiService();

  const saveForm = async (e) => {
    e.preventDefault();
    const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredsID);
    const newFormData = { ...formData };
    if (dateEstablished) {
      newFormData["dateEstablished"] = dateEstablished;
    }
    if (Boolean(moduleValue)) {
      apiService.setInsuredInfo({
        agencyID,
        insuredID: insuredsID,
        payload: newFormData,
      });
    } else {
      setDoc(insuredRef, newFormData, { merge: true });
    }
    setEnableEditing(false);
    setSuccess(true);
  };

  const convertToDate = (timestamp) => {
    if (timestamp && typeof timestamp === "object" && "_seconds" in timestamp) {
      return new Date(timestamp._seconds * 1000);
    }
    return null;
  };

  const handleApiPolicyData = async () => {
    const data = await apiService.renewalProcessPolicyData({ policyID });
    setFormData(data?.insuredData);
    const establishedDate = data?.insuredData?.dateEstablished;
    const establishedDateValue = convertToDate(establishedDate);
    if (establishedDateValue) {
      setDateEstablished(establishedDateValue);
    }
  };

  const getCompanyInfo = (agencyID) => {
    if (!agencyID) {
      return () => {};
    }
    if (Boolean(moduleValue)) {
      handleApiPolicyData();
      return () => {};
    }
    const docRef = doc(db, "agencies", agencyID, "insureds", insuredsID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setFormData({ ...docSnap.data(), id: docSnap.id });
        if (docSnap.data()?.dateEstablished) {
          setDateEstablished(docSnap.data().dateEstablished.toDate());
        }
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = getCompanyInfo(agencyID);
    return () => unsubscribe();
  }, [agencyID]);

  const calculateYearsInBusiness = (date) => {
    const today = new Date();
    let yearsDifference = today.getFullYear() - date.getFullYear();
    const monthDifference = today.getMonth() - date.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < date.getDate())
    ) {
      yearsDifference--;
    }
    return yearsDifference;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Box
      component="form"
      onSubmit={saveForm}
      noValidate
      autoComplete="off"
      sx={{ mt: 2 }}
    >
      <fieldset disabled={!enableEditing} style={{ border: "none" }}>
        <Grid container spacing={2}>
          {/* Company Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!enableEditing}
              fullWidth
              label={t("plm.coverage-side-panel.insured-table.company-name")}
              name="company"
              value={formData.company}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Company Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t(
                "plm.coverage-side-panel.insured-table.company-email-address"
              )}
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Primary Contact */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t(
                "plm.coverage-side-panel.insured-table.primary-contact-full-name"
              )}
              name="primaryContact"
              value={formData.primaryContact}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Contact Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t(
                "plm.coverage-side-panel.insured-table.primary-contact-email"
              )}
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Mailing Address */}
          <Grid item xs={12}>
            <TextField
              disabled={!enableEditing}
              fullWidth
              label={t("plm.coverage-side-panel.insured-table.mailing-address")}
              name="mailingAddress"
              value={formData.mailingAddress}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Mailing City and State */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("plm.coverage-side-panel.insured-table.ma-city")}
              name="mailingCity"
              value={formData.mailingCity}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>
                {t("plm.coverage-side-panel.insured-table.ma-state")}
              </InputLabel>
              <Select
                disabled={!enableEditing}
                name="mailingState"
                value={formData.mailingState}
                onChange={handleChange}
                label={t("plm.coverage-side-panel.insured-table.ma-state")}
              >
                {/* Add options dynamically if needed */}
                <MenuItem value="AL">Alabama</MenuItem>
                <MenuItem value="AK">Alaska</MenuItem>
                <MenuItem value="AZ">Arizona</MenuItem>
                <MenuItem value="AR">Arkansas</MenuItem>
                <MenuItem value="CA">California</MenuItem>
                <MenuItem value="CO">Colorado</MenuItem>
                <MenuItem value="CT">Connecticut</MenuItem>
                <MenuItem value="DE">Delaware</MenuItem>
                <MenuItem value="FL">Florida</MenuItem>
                <MenuItem value="GA">Georgia</MenuItem>
                <MenuItem value="HI">Hawaii</MenuItem>
                <MenuItem value="ID">Idaho</MenuItem>
                <MenuItem value="IL">Illinois</MenuItem>
                <MenuItem value="IN">Indiana</MenuItem>
                <MenuItem value="IA">Iowa</MenuItem>
                <MenuItem value="KS">Kansas</MenuItem>
                <MenuItem value="KY">Kentucky</MenuItem>
                <MenuItem value="LA">Louisiana</MenuItem>
                <MenuItem value="ME">Maine</MenuItem>
                <MenuItem value="MD">Maryland</MenuItem>
                <MenuItem value="MA">Massachusetts</MenuItem>
                <MenuItem value="MI">Michigan</MenuItem>
                <MenuItem value="MN">Minnesota</MenuItem>
                <MenuItem value="MS">Mississippi</MenuItem>
                <MenuItem value="MO">Missouri</MenuItem>
                <MenuItem value="MT">Montana</MenuItem>
                <MenuItem value="NE">Nebraska</MenuItem>
                <MenuItem value="NV">Nevada</MenuItem>
                <MenuItem value="NH">New Hampshire</MenuItem>
                <MenuItem value="NJ">New Jersey</MenuItem>
                <MenuItem value="NM">New Mexico</MenuItem>
                <MenuItem value="NY">New York</MenuItem>
                <MenuItem value="NC">North Carolina</MenuItem>
                <MenuItem value="ND">North Dakota</MenuItem>
                <MenuItem value="OH">Ohio</MenuItem>
                <MenuItem value="OK">Oklahoma</MenuItem>
                <MenuItem value="OR">Oregon</MenuItem>
                <MenuItem value="PA">Pennsylvania</MenuItem>
                <MenuItem value="RI">Rhode Island</MenuItem>
                <MenuItem value="SC">South Carolina</MenuItem>
                <MenuItem value="SD">South Dakota</MenuItem>
                <MenuItem value="TN">Tennessee</MenuItem>
                <MenuItem value="TX">Texas</MenuItem>
                <MenuItem value="UT">Utah</MenuItem>
                <MenuItem value="VT">Vermont</MenuItem>
                <MenuItem value="VA">Virginia</MenuItem>
                <MenuItem value="WA">Washington</MenuItem>
                <MenuItem value="WV">West Virginia</MenuItem>
                <MenuItem value="WI">Wisconsin</MenuItem>
                <MenuItem value="WY">Wyoming</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Mailing Zip and Country Code */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={t("plm.coverage-side-panel.insured-table.ma-zip")}
              name="mailingZip"
              value={formData.mailingZip}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>
                {t(
                  "plm.coverage-side-panel.insured-table.company-country-code"
                )}
              </InputLabel>
              <Select
                disabled={!enableEditing}
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                label={t(
                  "plm.coverage-side-panel.insured-table.company-country-code"
                )}
              >
                <MenuItem value="+1">USA (+1)</MenuItem>
                <MenuItem value="+52">Mexico (+52)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={t(
                "plm.coverage-side-panel.insured-table.company-phone-number"
              )}
              name="phone"
              type="number"
              value={formData.phone}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          {/* Physical Address */}
          <Grid item xs={12}>
            <TextField
              label={t(
                "plm.coverage-side-panel.insured-table.physical-address"
              )}
              name="physicalAddress"
              value={formData.physicalAddress}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              fullWidth
              variant="outlined"
            />
          </Grid>

          {/* Physical City and State */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={t("plm.coverage-side-panel.insured-table.pa-city")}
                name="physicalCity"
                value={formData.physicalCity}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  {t("plm.coverage-side-panel.insured-table.pa-state")}
                </InputLabel>
                <Select
                  disabled={!enableEditing}
                  name="physicalState"
                  value={formData.physicalState}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  label={t("plm.coverage-side-panel.insured-table.pa-state")}
                >
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Physical Zip */}
          <Grid item xs={12}>
            <TextField
              label={t("plm.coverage-side-panel.insured-table.pa-zip")}
              name="physicalZip"
              value={formData.physicalZip}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              fullWidth
              variant="outlined"
            />
          </Grid>

          {/* Business Structure */}
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>
                {t("plm.coverage-side-panel.insured-table.business-structure")}
              </InputLabel>
              <Select
                disabled={!enableEditing}
                name="businessStructure"
                value={formData.businessStructure}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                label={t(
                  "plm.coverage-side-panel.insured-table.business-structure"
                )}
              >
                <MenuItem value="">Choose One</MenuItem>
                <MenuItem value="Sole Proprietorship">
                  Sole Proprietorship
                </MenuItem>
                <MenuItem value="Partnership">Partnership</MenuItem>
                <MenuItem value="LLC">LLC</MenuItem>
                <MenuItem value="Corporation">Corporation</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Federal Tax ID and Year Established */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={t(
                  "plm.coverage-side-panel.insured-table.federal-tax-id"
                )}
                name="federalTaxID"
                value={formData.federalTaxID}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // TODO: Translate this label
                label={t("Date Established")}
                name="dateEstablished"
                value={dateEstablished}
                onChange={(date) => {
                  setFormData({ ...formData, dateEstablished: date });
                  setDateEstablished(date);
                  const yearsInBusiness = calculateYearsInBusiness(date);
                  const yearFromDate = date.getFullYear();
                  setFormData({
                    ...formData,
                    yearsInBusiness: yearsInBusiness,
                    yearEstablished: yearFromDate,
                  });
                }}
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          {/* Date Established and Years in Business */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled
                label={t(
                  "plm.coverage-side-panel.insured-table.year-established"
                )}
                name="yearEstablished"
                type="number"
                value={formData.yearEstablished}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t(
                  "plm.coverage-side-panel.insured-table.year-in-business"
                )}
                name="yearsInBusiness"
                type="number"
                value={formData.yearsInBusiness}
                disabled
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <ApprovalButtons
              onApprove={null}
              onDecline={(e) => {
                e.preventDefault();
                setFormData({});
                setEnableEditing(false);
              }}
              handleAsSubmit={true}
              disabledDecline={false}
              dissapearDecline={false}
              titleAccept={t("plm.coverage-side-panel.insured-table.submit")}
              titleDecline={t("plm.coverage-side-panel.insured-table.cancel")}
              disabledAccept={false}
            />
          </Grid>
        </Grid>
      </fieldset>
    </Box>
  );
}
