const handleAddCommodity = (insuredInfo, setInsuredInfo) => {
  const commodityFields = insuredInfo?.commodityFields || [];
  const lastID = commodityFields.length > 0 ? commodityFields[commodityFields.length - 1].id : 0;

  setInsuredInfo({
    ...insuredInfo,
    commodityFields: [
      ...commodityFields,
      { id: lastID + 1, commodityDescription: "", commodityLoad: "0" },
    ],
  });
};

export default handleAddCommodity;
