import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  collection,
  doc,
  getDoc,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { setPolicyDetailUpdate, setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import Popup from "../../generals/Popup";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";

export default function AddTrailer(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false)

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreateTrailer = async (
      endorsementId,
      status,
      trailerData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      const subcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "trailers",
        trailerData?.id
      );

      const payload = setPolicyInfo(
        {
          ...trailerData,
          dateCreated: new Date(),
        },
        {
          policyEffectiveDate: endorsement.endoEffectiveDate,
          glPolicyNo: endorsement?.glPolicyNo,
          alPolicyNo: endorsement?.alPolicyNo,
          policyID: paramPolicyID,
          status: "Active",
        },
        'endo-addition'
      );

      if (trailerData?.mirroredIds && trailerData?.mirroredPolicyId) {
        payload["otherSideOfMirrorId"] = trailerData?.mirroredIds[0];
      }

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date())
          const endoPayload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, endoPayload);

          const entities = await getDoc(subcollectionRef)
          const entityData = entities.data()
          const updatedPayload = setPolicyDetailUpdate(payload, entityData, payload?.['policiesDetails']?.[0])

          await setDoc(subcollectionRef, updatedPayload, { merge: true });

          const thereIsMirrorDataHere = trailerData?.mirroredIds && trailerData?.mirroredPolicyId
          if (thereIsMirrorDataHere) {
            for (const id of trailerData.mirroredIds) {
              const mirroredSubcollectionRef = doc(
                db,
                "agencies",
                agencyID,
                "insureds",
                insuredID,
                "trailers",
                id
              );

              const mirroredPayload = setPolicyInfo(
                {
                  ...updatedPayload,
                  dateCreated: new Date(),
                },
                {
                  policyEffectiveDate: endorsement.endoEffectiveDate,
                  glPolicyNo: endorsement?.glPolicyNo,
                  alPolicyNo: endorsement?.alPolicyNo,
                  policyID: trailerData?.mirroredPolicyId,
                  status: "Active",
                },
                'endo-addition'
              );

              mirroredPayload["otherSideOfMirrorId"] = trailerData?.id

              await setDoc(mirroredSubcollectionRef, mirroredPayload, { merge: true });
            }
          }
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating trailer:",
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true)
      return
    }

    updateEndorsementStatusAndCreateTrailer(
      paramRefID,
      "Approved",
      endorsement.data
    );
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false)

  const declineAUEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const trailerRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons
    }

    await updateDoc(trailerRef, payload);

    const { agencyID, insuredID, data } = endorsement;
    const subcollectionRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "trailers",
      data?.id
    );

    const subCollectionPayload = setPolicyInfo(
      {
        ...data,
        dateCreated: new Date(),
      },
      {
        policyEffectiveDate: endorsement.endoEffectiveDate,
        glPolicyNo: endorsement?.glPolicyNo,
        alPolicyNo: endorsement?.alPolicyNo,
        policyID: paramPolicyID,
        status: "Declined",
      },
      'endo-addition'
    );

    const entities = await getDoc(subcollectionRef)
    const entityData = entities.data()
    const updatedPayload = setPolicyDetailUpdate(subCollectionPayload, entityData, subCollectionPayload?.['policiesDetails']?.[0])

    await setDoc(subcollectionRef, updatedPayload, { merge: true });

    const thereIsMirrorDataHere = data?.mirroredIds && data?.mirroredPolicyId
    if (thereIsMirrorDataHere) {
      for (const id of data.mirroredIds) {
        const mirroredSubcollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "trailers",
          id
        );
        
        const mirroredPayload = setPolicyInfo(
          {
            ...payload,
            dateCreated: new Date(),
          },
          {
            policyEffectiveDate: endorsement.endoEffectiveDate,
            glPolicyNo: endorsement?.glPolicyNo,
            alPolicyNo: endorsement?.alPolicyNo,
            policyID: data?.mirroredPolicyId,
            status: "Declined",
          },
          'endo-addition'
        );

        const updatedPayload = setPolicyDetailUpdate(mirroredPayload, entityData, mirroredPayload?.['policiesDetails']?.[0])

        await setDoc(mirroredSubcollectionRef, updatedPayload, { merge: true });
      }
    }

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false)
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  return (
    <div>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth='50%'
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved
          because the policy is In Cancellation
        </p>
      </Popup>
      <ReasonsModal
        title='Rejection Reasons'
        text='Please enter the reason(s) why the endorsement is being rejected.'
        addButtonText='Add A Reason'
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
        <Container className="p-0">
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">VIN:</span>{" "}
              {endorsement.data.trailerVIN}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Year:</span>{" "}
              {endorsement.data.trailerYear}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Make:</span>{" "}
              {endorsement.data.trailerMake}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">ACV:</span>{" "}
              {endorsement.data.trailerACV}
            </Col>
          </Row>
        </Container>
      </div>

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(
        endorsement.status === "Submitted" ||
        isRequestException
      ) &&
      user.role === "Underwriter" ? (
        <>
          <article className="mt-3">
            <p className={isRequestException ? 'text-red-700 text-center !max-w-[345px] mx-auto' : ''}>
              {isRequestException ?
                `This endorsement has already been declined. 
                You are accepting/decling an exception request` :
                `Approving this endorsement will add the above trailer to the
                policy and notify the insured of the change.`
              }
            </p>
          </article>
          {isRequestException ?
            <RequestExceptions endorsement={endorsement} color='red' center={true} /> : null
          }
          <ApprovalButtons
            onApprove={approveAUEndorsement}
            onDecline={() => setRejectionModal(true)}
          />
        </>
      ) : endorsement.status === "Declined" &&
      !isRequestException &&
      !wasARequestException &&
      user.role === "Agent" &&
      !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </div>
  );
}
