import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  Delete as DeleteIcon,
  PersonOff as PersonOffIcon,
  ModeEdit as PencilIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
  Check as CheckIcon
} from "@mui/icons-material";
import Pagination from "./Pagination";
import useDynamicTable from "../../../../hooks/generals/useDynamicTable";
import SingleCheckbox from "../../forms/SingleCheckbox";
import TableSkeleton from "../../../generals/TableSkeleton";
import DriverDetailFlags from "../../../PolicyLifecycleManager/RedFlags/DriverDetailFlags";
import PowerUnitDetailFlags from "../../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";
import DropdownMultiUpload from "../../forms/DynamicPropertiesInputs/DropdownMultiUpload";
import ModalLicenseUploadForm from "../../../PolicyLifecycleManager/Drivers/fileUpload/ModalLicenseUploadForm";
import ModalTruckUploadForm from "../../../PolicyLifecycleManager/Drivers/fileUpload/ModalTruckUploadForm";
import { handleDropdown, handleDropdownPowerUnit } from "./utils";
import useTranslations from "../../../../Context/actions/useTranslations";
import DropdownPowerUnitMultiUpload from "../../forms/DynamicPropertiesInputs/DropdownPowerUnitMultiUpload";
import { CircularProgress, Grid, Link, useMediaQuery } from "@mui/material";
import FilingsFlags from "../../../PolicyLifecycleManager/RedFlags/LetterFlags";
import './index.css'

function DynamicTable(props) {
  const {
    columns,
    onClickItem,
    deleteThisItem,
    deletionMode,
    deleteMode,
    formData,
    handleOpenEditMode,
    skeletonLoader = false,
    tableData,
    superEndoSearcher,
  } = props;

  const {
    searchTerm,
    handleSearch,
    handleSort,
    currentItems,
    pageCount,
    currentPage,
    handlePageChange,
    sortConfig,
    handleSingleCheckbox,
    handleDeletionBehavior,
    loadingSearch
  } = useDynamicTable(props);

  const pageCountState = pageCount
  const currentPageState = currentPage

  const isApplicationOrPolicy =
    formData?.documentType === "Application" ||
    formData?.documentType === "Policy" ||
    typeof formData === "undefined";

  const { t } = useTranslations();

  return (
    <>
      {!deletionMode || (deleteMode && deletionMode) ?
        <div className="flex relative">
          <input
            type="text"
            placeholder={`${t('endorsements.search')}${superEndoSearcher ? t('endorsements.search-2'): ''}`}
            value={searchTerm}
            onChange={handleSearch}
            className="my-2 p-2 border rounded w-full"
          />
          {loadingSearch ?
            <div className="ml-1 flex items-center absolute right-1.5 top-2.5">
              <CircularProgress className="w-full h-full" />
            </div> : null
          }
        </div>
        : null
      }
      {!skeletonLoader && tableData.length === 0 && !deletionMode ?
      (
        <p>{t('plm.drivers.no-item')}</p>
      ) :
      <div>
        {skeletonLoader && loadingSearch ? <TableSkeleton
          rows={1}
          columns={parseInt(columns?.length)}
        /> :
          <>
            <Table>
              <Thead>
                <Tr>
                  {columns.length &&
                    columns.map((row, rowIndex) => {
                      const { name, label } = row;
                      const isFirstIndex = rowIndex === 0;
                      const isLastIndex = rowIndex + 1 === columns.length;
                      return (
                        <Td
                          key={`row${rowIndex}`}
                          className={classNames(
                            `bg-[#072a48] text-white
                            ${
                              isFirstIndex
                                ? "rounded-tl-md"
                                : isLastIndex
                                ? "rounded-tr-md"
                                : ""
                            }
                            cursor-pointer`,
                            {
                              "hover:bg-[#072a48]": sortConfig.key !== name,
                              "bg-blue-500": sortConfig.key === name,
                            }
                          )}
                          onClick={() => handleSort(name)}
                        >
                          {label}
                        </Td>
                      );
                    })}
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map((item, itemIndex) => {
                  const info = item?.tableInfo;
                  const iconLeft = item?.iconLeft;
                  const dataIndex = item?.dataIndex;
                  if (info) {
                    const keys = Object.keys(info);
                    const values = Object.values(info);
                    return (
                      <Tr key={`currentItems${itemIndex}`}>
                        {values.map((value, valueIndex) => {
                          const currentKeyName = keys[valueIndex];
                          return (
                            <Td key={`values${valueIndex}`}>
                              <div className="flex drivers-center flex-wrap">
                                {iconLeft?.map((icon, i) => {
                                  if (icon.toField === currentKeyName) {
                                    const onClick = icon?.onClick
                                      ? (e) => icon.onClick(e, item)
                                      : (e) => onClickItem(e, item?.id, itemIndex);
                                    return (
                                      <button
                                        key={`iconButton${i}`}
                                        onClick={onClick}
                                        className={`${
                                          icon.className
                                            ? icon.className
                                            : "text-sky-900 hover:text-[#072a48] mr-2"
                                        }`}
                                      >
                                        {icon?.name === "copyToClipboard" ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-copy d-inline"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                            />
                                          </svg>
                                        ) : icon?.name === "noDriverLicense" ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-person-fill-x d-inline"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                                          </svg>
                                        ) : icon?.name === "redFlag-filings" ? (
                                          <FilingsFlags item={item} formData={formData} />
                                        ) : icon?.name === "redFlag-powerUnit" ? (
                                          <PowerUnitDetailFlags powerUnit={item} />
                                        ) : icon?.name === "redFlag" ? (
                                          <DriverDetailFlags singleIcon={true} driver={item} />
                                        ) :
                                        icon?.name === "goodToGo" ? (
                                          <CheckIcon color="success" />
                                        ) :
                                        (
                                          ""
                                        )}
                                      </button>
                                    );
                                  }
                                })}
                                {value?.actions &&
                                value?.actions?.length > 0 ? (
                                  <>
                                    {value.actions.map((action) => (
                                      <>
                                        {action === "download" && handleOpenEditMode ?
                                          <button
                                            key={`downloadButton${dataIndex}`}
                                            onClick={(e) =>
                                              handleOpenEditMode(e, item)
                                            }
                                            className="text-[#8B0000] hover:text-[#8B0000] d-inline mx-1"
                                          >
                                            <DownloadIcon />
                                          </button> : null
                                        }
                                        {action === "download-disabled" && handleOpenEditMode ?
                                          <button
                                            key={`downloadDisabledButton${dataIndex}`}
                                            disabled={true}
                                            onClick={() => {}}
                                            className="text-[#928a8a] hover:text-[#928a8a] d-inline mx-1"
                                          >
                                            <DownloadIcon />
                                          </button> : null
                                        }
                                        {action === "deletion-behavior" ?
                                          <button
                                            key={`deletionBehaviorButton${dataIndex}`}
                                            onClick={(e) =>
                                              handleDeletionBehavior(e, dataIndex)
                                            }
                                            className="text-[#8B0000] hover:text-[#8B0000] d-inline mx-1"
                                          >
                                            <PersonOffIcon />
                                          </button> : null
                                        }
                                        {action === "view" && handleOpenEditMode ?
                                          <button
                                            key={`viewButton${dataIndex}`}
                                            onClick={(e) => {
                                              if (isApplicationOrPolicy) {
                                                handleOpenEditMode(e, item);
                                              }
                                            }}
                                            className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
                                              ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
                                            `}
                                          >
                                            <VisibilityIcon
                                              className={!isApplicationOrPolicy ? 'opacity-20' : ''}
                                            />
                                          </button> : null
                                        }
                                        {action === "edit" && handleOpenEditMode ?
                                          <button
                                            key={`editButton${dataIndex}`}
                                            onClick={(e) => {
                                              if (isApplicationOrPolicy) {
                                                handleOpenEditMode(e, item);
                                              }
                                            }}
                                            className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
                                              ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
                                            `}
                                          >
                                            <PencilIcon
                                              className={!isApplicationOrPolicy ? 'opacity-20' : ''}
                                            />
                                          </button> : null
                                        }
                                        {action === "dropdown-driver-imgs" && handleOpenEditMode ?
                                          <DropdownMultiUpload
                                            key={`dropdown${dataIndex}`}
                                            containerStyle="bg-transparent border-none p-0"
                                            wrapperStyle={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
                                              ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
                                            `}
                                            iconStyle={!isApplicationOrPolicy ? 'opacity-20' : ''}
                                            handleDropdown={() => handleDropdown(
                                              item,
                                              null,
                                              "drivers",
                                              null,
                                              null,
                                              null,
                                              t
                                            )}
                                            formData={formData}
                                            item={item}
                                          /> : null
                                        }
                                        {action === "dropdown-power-unit-imgs" && handleOpenEditMode ?
                                          <DropdownPowerUnitMultiUpload
                                            key={`dropdown${dataIndex}`}
                                            containerStyle="bg-transparent border-none p-0"
                                            wrapperStyle={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
                                              ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
                                            `}
                                            iconStyle={!isApplicationOrPolicy ? 'opacity-20' : ''}
                                            handleDropdown={() => handleDropdownPowerUnit(
                                              item,
                                              null,
                                              "power-units",
                                              null,
                                              null,
                                              null,
                                              t
                                            )}
                                            formData={formData}
                                            item={item}
                                          /> : null
                                        }
                                        {action === "dropdown-driver-view-result" &&
                                        (
                                          item?.driverLicenseFront?.length > 0 ||
                                          item?.driverLicenseBack?.length > 0 ||
                                          item?.MVR?.length > 0 ||
                                          item?.caDriverAbstract?.length > 0 ||
                                          item?.medicalLongForm?.length > 0 ||
                                          item?.additionalDocument?.length > 0 ||
                                          item?.otherDocuments?.length > 0
                                        ) ?
                                          <ModalLicenseUploadForm
                                            key={`modalLicenseUploadForm${dataIndex}`}
                                            isApplicationOrPolicy={isApplicationOrPolicy}
                                            item={item}
                                          />
                                          : null
                                        }
                                        {action === "dropdown-power-units-view-result" &&
                                        (
                                          item?.truckDocuments?.length > 0
                                        ) ?
                                          <ModalTruckUploadForm
                                            key={`modaltruckUploadForm${dataIndex}`}
                                            isApplicationOrPolicy={isApplicationOrPolicy}
                                            item={item}
                                          />
                                          : null
                                        }
                                        {action === "delete" ?
                                          <button
                                            key={`deleteButton${dataIndex}`}
                                            onClick={(e) => {
                                              if (isApplicationOrPolicy) {
                                                deleteThisItem(e, item);
                                              }
                                            }}
                                            className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
                                              ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
                                            `}
                                          >
                                            <DeleteIcon
                                              className={!isApplicationOrPolicy ? 'opacity-20' : ''}
                                            />
                                          </button> : null
                                        }
                                        {typeof action?.checkbox !== "undefined" ?
                                          <SingleCheckbox
                                            key={`checkbox${dataIndex}`}
                                            checked={Boolean(action?.checkbox)}
                                            onChange={() => handleSingleCheckbox(dataIndex, action)}
                                          /> : null
                                        }
                                      </>
                                    ))}
                                  </>
                                ) :
                                value?.text && value?.href ? (
                                  <Link
                                    key={`textAnchor${valueIndex}`}
                                    href={value?.href}
                                    className={`
                                      ${value.className ? value.className : ''}
                                      !text-sky-900 hover:text-[#072a48] text-left !no-underline
                                    `}
                                  >
                                    {value?.text}
                                  </Link>
                                ) :
                                value?.text && value?.isButton ? (
                                  <button
                                    key={`textButton${valueIndex}`}
                                    className={`
                                      ${value.className ? value.className : ''}
                                      text-sky-900 hover:text-[#072a48] text-left
                                    `}
                                    onClick={(e) =>
                                      onClickItem(e, item?.id, itemIndex)
                                    }
                                  >
                                    {value?.text}
                                  </button>
                                ) : value?.text ? (
                                  <p
                                    key={`textParagraph${valueIndex}`}
                                    className={
                                      value?.className ? value?.className : ""
                                    }
                                  >
                                    {value?.text}
                                  </p>
                                ) : null}
                              </div>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  }
                  return null;
                })}
              </Tbody>
            </Table>
            {!deletionMode || (deleteMode && deletionMode) ? (
              <Pagination
                pageCount={pageCountState}
                currentPage={currentPageState}
                handlePageChange={handlePageChange}
              />
            ) : null}
          </>
        }
      </div>
      }
    </>
  );
}

export default DynamicTable;