import React, { useEffect, useState } from "react";
import CreatePolicyPanel from "../../Components/agent/policies/CreatePolicyPanel";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import useTranslations from "../../Context/actions/useTranslations";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

export default function QuickEndo({ userInfo, loading, setLoading }) {
  const { t } = useTranslations();

  const breadcrumbs = [{ label: "Quick Endo" }, { label: "New Endorsement" }];

  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [showPolicySelection, setShowPolicySelection] = useState(false);
  const [showEndorsementManager, setShowEndorsementManager] = useState(false);
  const [selectionType, setSelectionType] = useState("policy");
  const [loadingPolicies, setLoadingPolicies] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedInsured, setSelectedInsured] = useState("");
  const [policyGroups, setPolicyGroups] = useState([]);

  useEffect(() => {
    // Define the collection reference
    const policyGroupsCollection = collection(db, "policyGroups");

    // Set up the real-time listener using onSnapshot
    const unsubscribe = onSnapshot(policyGroupsCollection, (snapshot) => {
      const groupsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPolicyGroups(groupsData); // Update state with the new data
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Define the collection reference
    const policyApplicationsCollection = collection(db, "policy-applications");

    // Create a query to filter where the status is "Active" or "In-Renewal"
    const activePoliciesQuery = query(
      policyApplicationsCollection,
      where("status", "in", ["In-Force", "In-Renewal"])
    );

    // Real-time listener using onSnapshot
    const unsubscribe = onSnapshot(activePoliciesQuery, async (snapshot) => {
      const policiesData = await Promise.all(
        snapshot.docs.map(async (document) => {
          const policy = {
            id: document.id,
            ...document.data(),
          };

          // Get the insuredID and agencyID from the policy document
          const { insuredID, agencyID } = policy;

          // Fetch the corresponding insured document from the insureds collection
          if (insuredID && agencyID) {
            const insuredRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID
            );
            const insuredSnap = await getDoc(insuredRef);

            if (insuredSnap.exists()) {
              // Add the namedInsured (company field) to the policy
              policy.namedInsured =
                insuredSnap.data().company || "Unknown Insured";
            } else {
              policy.namedInsured = "Unknown Insured"; // Fallback if no insured document found
            }
          } else {
            policy.namedInsured = "Unknown Insured"; // Fallback if insuredID or agencyID is missing
          }

          return policy;
        })
      );

      setPolicies(policiesData);
      setLoadingPolicies(false); // Set loading to false after fetching
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleSelectPolicy = (policy) => {
    setSelectedPolicy(policy);
    setShowPolicySelection(false);
    setShowEndorsementManager(true);
  };

  const handleEndorsementManagerClose = () => {
    setSelectedPolicy(null);
    setShowEndorsementManager(false);
  };

  const handleSelectionTypeChange = (event) => {
    setSelectionType(event.target.value);
    setSelectedGroup(""); // Reset group selection
    setSelectedInsured(""); // Reset insured selection
  };

  return (
    <div className="my-4 max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t("Quick Endorsement")}
          {selectedPolicy}
        </span>
      </div>

      <p className="mb-4">
        Quickly create a new endorsement for an insured or group.
      </p>
      {/* Selection Type: Insured or Group */}
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Select Target</FormLabel>
        <RadioGroup
          row
          value={selectionType}
          onChange={handleSelectionTypeChange}
        >
          <FormControlLabel value="policy" control={<Radio />} label="Policy" />
          <FormControlLabel value="group" control={<Radio />} label="Group" />
        </RadioGroup>
      </FormControl>

      {/* Policy Selection Dropdown */}
      {selectionType === "policy" && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="policy-select-label">Select Policy</InputLabel>
          <Select
            labelId="policy-select-label"
            value={
              selectionType === "insured" ? selectedInsured : selectedGroup
            }
            onChange={(event) => {
              if (selectionType === "insured") {
                setSelectedInsured(event.target.value);
              } else {
                setSelectedGroup(event.target.value);
              }
            }}
            disabled={loadingPolicies} // Disable if loading policies
          >
            {loadingPolicies ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              policies.map((policy) => (
                <MenuItem key={policy.id} value={policy.id}>
                  {policy.namedInsured} - {policy.alPolicyNo}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      )}
      {selectionType === "group" && (
        <>
          {/* Group Selection */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="policy-select-label">Select Group</InputLabel>
            <Select
              labelId="policy-group-select-label"
              value={selectedGroup}
              onChange={(event) => setSelectedGroup(event.target.value)}
            >
              {policyGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.groupName} {/* Display group name */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      {/* Button to proceed */}
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectionType === "insured") {
              const selected = policies.find((p) => p.id === selectedInsured);
              handleSelectPolicy(selected);
            } else {
              // Handle group selection logic here
            }
          }}
          disabled={!selectedInsured && !selectedGroup} // Disable if nothing selected
        >
          Proceed
        </Button>
      </Box>

      {/* Conditional rendering of Endorsement Manager */}
      {showEndorsementManager && (
        <CreatePolicyPanel
          policy={selectedPolicy}
          onClose={handleEndorsementManagerClose}
        />
      )}
    </div>
  );
}
