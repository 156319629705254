import LoadingScreen from "react-loading-screen";
import CoveragesSidePanel from "../../Components/PolicyLifecycleManager/CoveragesSidePanel";
import History from "../../Components/PolicyLifecycleManager/History";
import AppOverview from "../../Components/PolicyLifecycleManager/AppOverview";
import EndoManager from "../../Components/PolicyLifecycleManager/EndoManager";
import ActionButtons from "../../Components/PolicyLifecycleManager/ActionButtons";
import CustomTabs from "../../Components/PolicyLifecycleManager/CustomTabs";
import InsuredProfile from "../../Components/insured/InsuredProfile";
import useManagerTabs from "../../hooks/policy/useManagerTabs";
import { Row, Col, Container } from "../../Components/generals/GridSystem";
import UnderwriterPanel from "../../Components/PolicyLifecycleManager/UnderwriterPanel";
import BottomNavbar from "../../Components/PolicyLifecycleManager/BottomNavBar";
import ReturnToAgentMessage from "../../Components/PolicyLifecycleManager/ReturnToAgentModal/ReturnToAgentMessage";
import { useMediaQuery } from "@mui/material";
import MiniHistory from "../../Components/PolicyLifecycleManager/History/MiniHistory";
import DisplayCancellationReasons from "../../Components/CancelPolicyPanel/DisplayCancellationReasons";
import RenewalMessage from "../../Components/PolicyLifecycleManager/RenewPolicyModal/RenewalMessage";
import { useLocation } from "react-router-dom";
import BindPanel from "../../Components/bindPolicyDetails/BindPanel";
import ReferralMessage from "../../Components/PolicyLifecycleManager/Referral/ReferralMessage";
import { USER_INFO_ROLE } from "../../utils/constants";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { handleOfacAndSafer } from "../../utils/helpers";
import updatePolicyField from "../../Components/utils/updatePolicyField";

export default function ApplicationForm() {
  const {
    loading,
    loadingText,
    coverageProps,
    displayEndorsementManager,
    setDisplayEndorsementManager,
    setDisplayUnderwritingPanel,
    displayUnderwritingPanel,
    gotEndorsements,
    endorsements,
    displayCompanyDetails,
    actionButtonProps,
    appOverviewProps,
    showOverviewAndHistory,
    setShowOverviewAndHistory,
    policyID,
    value,
    handleChange,
    filteredTabs,
    userInfo,
    insuredInfo,
    setDisplayCompanyDetails,
    tabProps,
    getEndorsements,
    getUniqueIdentifierAutoLiabilityNAFTA,
    formData,
    skeletonEndoTableLoader,
    currentEndoFilters,
    setCurrentEndoFilters,
    displayBindDetailsInfo,
    setDisplayBindDetailsInfo,
    agencyInfo,
    nextAL,
    nextGL,
  } = useManagerTabs();

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const newTasks = (type) => {
    const existingTaskList = formData?.underwritingTasks || {};
    const newTaskList = {
      ...existingTaskList,
      [type]: {
        completed: true,
      },
    };
    return newTaskList;
  };

  const handleOfacAndSaferCompletion = async () => {
    const { ofac, saferReport } = await handleOfacAndSafer(insuredInfo);
    let payload = {};
    if (ofac || saferReport) {
      payload = {};
      payload["ofac"] = ofac ? ofac : [];
      if (ofac && ofac?.length > 0) {
        const newTaskList = newTasks("OFAC Review");
        await updatePolicyField(policyID, "underwritingTasks", newTaskList);
      }
      if (saferReport) {
        payload["saferReport"] = saferReport;
        const newTaskList = newTasks("Safer Report");
        await updatePolicyField(policyID, "underwritingTasks", newTaskList);
      }
      if (payload) {
        await setDoc(doc(db, "policy-applications", policyID), payload, {
          merge: true,
        });
      }
    }
  };

  useEffect(() => {
    if (
      formData &&
      !formData?.ofac &&
      !formData?.saferReport &&
      insuredInfo &&
      Boolean(insuredInfo?.company)
    ) {
      handleOfacAndSaferCompletion();
    }
  }, [formData, insuredInfo, policyID]);

  const [letterType, setLetterType] = useState("");

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={loadingText}
    >
      {!formData?.id && !loading ? (
        <div className="bg-white shadow-md rounded-lg p-6 m-6 border border-gray-200">
          <h1 className="text-center text-lg font-semibold text-red-500">
            Policy Not Found
          </h1>
        </div>
      ) : null}
      {formData?.id && !loading && (
        <Container className="p-0 px-4 mt-1 mb-4 w-full" fluid>
          <BottomNavbar {...actionButtonProps} />
          <Row>
            <Col lg={3} className="mt-2">
              <CoveragesSidePanel {...coverageProps} />
            </Col>
            {!displayEndorsementManager &&
              !displayCompanyDetails &&
              !displayUnderwritingPanel &&
              !displayBindDetailsInfo && (
                <Col lg={9}>
                  <Row>
                    <Col spacing={12} className="mt-2">
                      <ActionButtons {...actionButtonProps} />
                      {formData?.status === "Returned" && (
                        <ReturnToAgentMessage
                          userRole={formData?.returnToAgentDetails.userRole}
                          user={formData?.returnToAgentDetails.user}
                          className={""}
                          reasons={formData?.returnToAgentReasons}
                          returnedDate={formData?.returnToAgentDetails.date}
                        />
                      )}
                      {formData?.nonRenewal && (
                        <RenewalMessage
                          className={""}
                          reasons={formData?.nonRenewalReasons}
                          returnedDate={formData?.nonRenewalDate}
                        />
                      )}
                      {formData?.isReferral && (
                        <ReferralMessage
                          policyID={policyID}
                          userRole={USER_INFO_ROLE}
                          className={""}
                        />
                      )}
                      {formData.status === "In-Cancellation" && (
                        <DisplayCancellationReasons
                          formData={formData}
                          setLetterType={setLetterType}
                          setDisplayUnderwritingPanel={
                            setDisplayUnderwritingPanel
                          }
                        />
                      )}
                    </Col>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      lg={8}
                      className="mt-2"
                    >
                      <AppOverview {...appOverviewProps} />
                    </Col>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      lg={4}
                      className="mt-2"
                    >
                      <MiniHistory
                        formData={formData}
                        showOverviewAndHistory={showOverviewAndHistory}
                        setShowOverviewAndHistory={setShowOverviewAndHistory}
                        policyID={policyID}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      className={isMobileScreen ? "mt-2" : ""}
                    >
                      <CustomTabs
                        value={value}
                        handleChange={handleChange}
                        filteredTabs={filteredTabs}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            {displayEndorsementManager && (
              <Col lg={9}>
                <Row>
                  <EndoManager
                    userInfo={userInfo}
                    formData={formData}
                    setDisplayEndorsementManager={setDisplayEndorsementManager}
                    gotEndorsements={gotEndorsements}
                    endorsements={endorsements}
                    tabProps={tabProps}
                    getEndorsements={getEndorsements}
                    policyID={policyID}
                    skeletonEndoTableLoader={skeletonEndoTableLoader}
                    currentEndoFilters={currentEndoFilters}
                    setCurrentEndoFilters={setCurrentEndoFilters}
                    insuredInfo={insuredInfo}
                    agencyInfo={agencyInfo}
                  />
                </Row>
              </Col>
            )}
            {displayCompanyDetails && (
              <InsuredProfile
                agencyID={userInfo.agencyID}
                insuredsID={insuredInfo.id}
                userInfo={userInfo}
                setDisplayCompanyDetails={setDisplayCompanyDetails}
              />
            )}
            {displayBindDetailsInfo && (
              <BindPanel
                agencyID={userInfo.agencyID}
                insuredsID={insuredInfo.id}
                userInfo={userInfo}
                setDisplayCompanyDetails={setDisplayBindDetailsInfo}
                formData={formData}
              />
            )}
            {displayUnderwritingPanel && (
              <Col lg={9}>
                <Row>
                  <UnderwriterPanel
                    tabProps={tabProps}
                    getEndorsements={getEndorsements}
                    setDisplayUnderwritingPanel={setDisplayUnderwritingPanel}
                    policyID={policyID}
                    initialLetterType={letterType}
                    activateListener={true}
                  />
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </LoadingScreen>
  );
}
