import updateCommodityTotal from "./updateCommodityTotal";

const handleChangeCommodityPercentage = async (
  i,
  e,
  formData,
  policyID,
  insuredInfo,
  setInsuredInfo
) => {
  const values = [...insuredInfo.commodityFields];
  if (!values[i]) values[i] = {};
  values[i] = { ...values[i], [e.target.name]: e.target.value };
  const total = await updateCommodityTotal(policyID, formData, insuredInfo, setInsuredInfo);
  setInsuredInfo({
    ...insuredInfo,
    commodityTotal: total,
    commodityFields: values,
  });
};

export default handleChangeCommodityPercentage;