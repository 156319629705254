import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  IconButton,
  Table,
  TableContainer,
  TablePagination,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTableHead from "./CustomTableHead";
import CustomTableBody from "./CustomTableBody";
import handleSearch from "./utils/handleSearch";
import LoadingScreen from "../../../assets/LoadingScreen";

function EndoSearch({ userInfo }) {
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [page, setPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [results, setResults] = useState([]);
  const [driverNameFilter, setDriverNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Submitted");
  const [searchFunction, setSearchFunction] = useState("status");
  const [outstandingEndorsements, setOutstandingEndorsements] = useState(0);

  const userAgencyID = userInfo.agencyID;

  const [sortField, setSortField] = useState("endoNumber"); // Default sort field
  const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // get default search when page loads
  useEffect(() => {
    searchFunction === "status" &&
      selectedStatus === "Submitted" &&
      handleSearch(
        true,
        queryText,
        searchFunction,
        userAgencyID,
        selectedStatus,
        setOutstandingEndorsements,
        setResults,
        setLoading
      );
  }, []);

  const handleFilter = (text) => {
    setPage(0);
    setFilterText(text);
    const filtered = results.filter((result) =>
      result.namedInsured.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredResults(filtered);
  };

  const handleStatusFilter = (status) => {
    setPage(0);
    if (status === "") {
      setFilteredResults(results);
      return;
    }
    setStatusFilter(status);
    const filtered = results.filter((result) => result.status === status);
    setFilteredResults(filtered);
  };

  const handleDriverNameFilter = (text) => {
    setPage(0);
    setDriverNameFilter(text);
    const filtered = results.filter(
      (result) =>
        Array.isArray(result.searchData) &&
        result.searchData.some(
          (driverName) =>
            typeof driverName === "string" &&
            driverName.toLowerCase().includes(text.toLowerCase())
        )
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    if (statusFilter === "") {
      setFilteredResults(results);
      return;
    }
    handleStatusFilter(statusFilter);
  }, [results, statusFilter]);

  useEffect(() => {
    handleFilter(filterText);
  }, [results, filterText]);

  return (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <LoadingScreen text={"Searching, please wait..."} />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h7">
              Outstanding Endorsements: {outstandingEndorsements}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                disabled // TODO: turn off disabled after abdiel connects button
                variant="outlined"
                size="small"
                sx={{ width: 160 }} // Adjust width as needed for ~12 characters
                placeholder="Jump To Endo No."
              />
              <Button
                disabled // TODO: turn off disabled after abdiel connects button
                variant="contained"
                size="small"
                sx={{ textTransform: "none" }} // Keeps button text normal case
              >
                Go
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Select
              value={searchFunction}
              onChange={(e) => setSearchFunction(e.target.value)}
              sx={{ mr: 1 }}
            >
              <MenuItem value="status">Search By Status</MenuItem>
              <MenuItem value="company">Search By Company Name</MenuItem>
              <MenuItem value="driver">Search By Driver Name</MenuItem>
              <MenuItem value="license">
                Search By Driver License Number
              </MenuItem>
              <MenuItem value="VIN">
                Search By VIN
              </MenuItem>
            </Select>
            {searchFunction !== "status" ? (
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={queryText}
                onChange={(e) => setQueryText(e.target.value)}
              />
            ) : (
              <Select
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
                sx={{ mr: 1, width: "100%" }}
              >
                <MenuItem value="Submitted">Submitted</MenuItem>
                <MenuItem disabled value="Approved">Approved</MenuItem>
                <MenuItem value="Declined">Declined</MenuItem>
                <MenuItem value="Exception Request">Exception Request</MenuItem>
              </Select>
            )}
            <IconButton
              onClick={(e) =>
                handleSearch(
                  false,
                  queryText,
                  searchFunction,
                  userAgencyID,
                  selectedStatus,
                  setOutstandingEndorsements,
                  setResults,
                  setLoading
                )
              }
              sx={{ ml: 1 }}
            >
              <SearchIcon /> <span className="text-sm">{" "}Search</span>
            </IconButton>
          </Box>
          {results.length > 0 && searchFunction !== "company" && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <TextField
                label="Filter By Company Name"
                variant="outlined"
                fullWidth
                value={filterText}
                onChange={(e) => handleFilter(e.target.value)}
              />
            </Box>
          )}
          {results.length > 0 &&
            searchFunction !== "driver" &&
            searchFunction !== "license" && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  label="Filter By Driver Name or License"
                  variant="outlined"
                  fullWidth
                  value={driverNameFilter}
                  onChange={(e) => handleDriverNameFilter(e.target.value)}
                />
              </Box>
            )}
          {results.length > 0 && searchFunction !== "status" && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <FormControl fullWidth sx={{ mr: 1 }}>
                <InputLabel id="statusFilterLabel">Filter Status</InputLabel>
                <Select
                  labelId="statusFilterLabel" // Associate label with Select
                  id="statusFilter"
                  value={statusFilter}
                  onChange={(e) => handleStatusFilter(e.target.value)}
                  label="Filter Status" // This will show as the floating label
                >
                  <MenuItem value="Submitted">Show Submitted</MenuItem>
                  <MenuItem value="Approved">Show Approved</MenuItem>
                  <MenuItem value="Declined">Show Declined</MenuItem>
                  <MenuItem value="Exception Request">
                    Show Exception Request
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
          <TableContainer>
            <Table>
              <CustomTableHead
                sortDirection={sortDirection}
                sortField={sortField}
                setSortDirection={setSortDirection}
                setSortField={setSortField}
              />
              <CustomTableBody
                sortDirection={sortDirection}
                sortField={sortField}
                results={filteredResults}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
}

export default EndoSearch;
