export const RESET_STATE = "RESET_STATE"
export const SET_THEME = "SET_THEME";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_TOAST = "SET_TOAST"
export const SET_PERSISTENCE = "SET_PERSISTENCE";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const SET_COMPANY = "SET_COMPANY";
export const GET_LOSS_RUNS = "GET_LOSS_RUNS"
export const SET_ENDORSEMENTS = "SET_ENDORSEMENTS"
export const SET_WAIVERS_OF_SUBROGATION = "SET_WAIVERS_OF_SUBROGATION"
export const SET_POLICIES_PDF = "SET_POLICIES_PDF"
export const SET_CALENDAR_TOKEN = "SET_CALENDAR_TOKEN"
export const SET_CALENDAR_SELECTED_DATA = "SET_CALENDAR_SELECTED_DATA"
export const SET_FORM_DATA = "SET_FORM_DATA"
export const SET_FORM_DATA_GL = "SET_FORM_DATA_GL"
export const SET_FORM_DATA_MTC = "SET_FORM_DATA_MTC"
export const SET_FORM_DATA_APD = "SET_FORM_DATA_APD"
export const SET_QUOTE_SELECTED = "SET_QUOTE_SELECTED"
export const SET_PAGINATIONS = "SET_PAGINATIONS"
export const SET_MANAGING_ALLOW_APPROVAL_RED_FLAG = "SET_MANAGING_ALLOW_APPROVAL_RED_FLAG"
export const SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG = "SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG"
export const SET_CLIENT_ROUTE = "SET_CLIENT_ROUTE";
