import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

export default function InsuredsPoliciesList({ policiesDetails }) {
  console.log(policiesDetails);
  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        Insured Policies
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Policy No.</strong>
              </TableCell>
              <TableCell>
                <strong>Effective Date</strong>
              </TableCell>
              <TableCell>
                <strong>Coverage</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policiesDetails.map((policy, index) => (
              <TableRow key={index}>
                <TableCell>{policy.policyNumber}</TableCell>
                <TableCell>
                  {policy.effectiveDate.toDate().toString().slice(4, 15)}
                </TableCell>
                <TableCell>{policy.coverage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
