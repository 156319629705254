import React, { useState } from "react";
import InsuredInfo from "./InsuredInfo";
import Breadcrumbs from "../assets/Breadcrumbs";
import GoBack from "../generals/GoBack";
import useTranslations from "../../Context/actions/useTranslations";

export default function InsuredProfile(props) {
  const agencyID = props.agencyID;
  const insuredsID = props.insuredsID;
  const [enableEditing, setEnableEditing] = useState(false);
  const userInfo = props.userInfo;
  const [success, setSuccess] = useState(false);

  const { t } = useTranslations();

  const breadcrumbs = [
    { label: t("plm.coverage-side-panel.insured-table.breadcrumb-insured") },
    { label: t("plm.coverage-side-panel.insured-table.breadcrumb-profile") },
  ];

  return (
    <div
      id="insuredProfile"
      className="fixed inset-0 z-50 bg-white p-6 overflow-y-auto"
    >
      <Breadcrumbs items={breadcrumbs} />
      <GoBack
        handleFn={() => {
          props.setDisplayCompanyDetails(false);
          // scroll to top
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t("plm.coverage-side-panel.insured-table.insured-profile")}
        </span>
        {!enableEditing && (
          <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={() => {
              setEnableEditing(true);
              setSuccess(false);
            }}
          >
            {t("plm.coverage-side-panel.insured-table.edit-button")}
          </button>
        )}
      </div>
      <p className="mb-4 max-w-[700px]">
        {t("plm.coverage-side-panel.insured-table.description")}
      </p>
      <fieldset>
        <InsuredInfo
          insuredsID={insuredsID}
          agencyID={agencyID}
          success={success}
          setSuccess={setSuccess}
          enableEditing={enableEditing}
          setEnableEditing={setEnableEditing}
          userInfo={userInfo}
        />
      </fieldset>
    </div>
  );
}
