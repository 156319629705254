const rateTruck = (
  product,
  limitLiability,
  hazmatCargo,
  state,
  truckClass,
  radius,
  program
) => {
  if (program === "NAFTA") {
    if (state === "TX") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 904.62;
            } else if (radius === "0-100") {
              return 1066.77;
            } else if (radius === "0-250") {
              return 1261.36;
            } else if (radius === "0-500") {
              return 1494.86;
            } else if (radius === "0-800") {
              return 1869;
            } else if (radius === "0-1000") {
              return 2230.56;
            } else if (radius === "0-1200") {
              return 2777.16;
            } else if (radius === "0-1500") {
              return 3464.25;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1185.01;
            } else if (radius === "0-100") {
              return 1403.25;
            } else if (radius === "0-250") {
              return 1665.13;
            } else if (radius === "0-500") {
              return 1979.38;
            } else if (radius === "0-800") {
              return 2478.04;
            } else if (radius === "0-1000") {
              return 2966.11;
            } else if (radius === "0-1200") {
              return 3701.74;
            } else if (radius === "0-1500") {
              return 4626.45;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1634.32;
            } else if (radius === "0-100") {
              return 1942.42;
            } else if (radius === "0-250") {
              return 2312.12;
            } else if (radius === "0-500") {
              return 2755.79;
            } else if (radius === "0-800") {
              return 2755.79;
            } else if (radius === "0-1000") {
              return 4144.75;
            } else if (radius === "0-1200") {
              return 5183.3;
            } else if (radius === "0-1500") {
              return 6488.76;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1877.55;
            } else if (radius === "0-100") {
              return 2234.3;
            } else if (radius === "0-250") {
              return 2662.38;
            } else if (radius === "0-500") {
              return 3176.1;
            } else if (radius === "0-800") {
              return 3982.31;
            } else if (radius === "0-1000") {
              return 4782.81;
            } else if (radius === "0-1200") {
              return 5985.34;
            } else if (radius === "0-1500") {
              return 7496.93;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 769.49;
            } else if (radius === "0-100") {
              return 904.62;
            } else if (radius === "0-250") {
              return 1066.77;
            } else if (radius === "0-500") {
              return 1261.36;
            } else if (radius === "0-800") {
              return 1575.49;
            } else if (radius === "0-1000") {
              return 1876.08;
            } else if (radius === "0-1200") {
              return 2331.58;
            } else if (radius === "0-1500") {
              return 2904.16;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1002.59;
            } else if (radius === "0-100") {
              return 1184.33;
            } else if (radius === "0-250") {
              return 1402.44;
            } else if (radius === "0-500") {
              return 1664.16;
            } else if (radius === "0-800") {
              return 2081.81;
            } else if (radius === "0-1000") {
              return 2487.56;
            } else if (radius === "0-1200") {
              return 3100.21;
            } else if (radius === "0-1500") {
              return 3870.32;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1377.57;
            } else if (radius === "0-100") {
              return 1634.32;
            } else if (radius === "0-250") {
              return 1942.42;
            } else if (radius === "0-500") {
              return 2312.12;
            } else if (radius === "0-800") {
              return 2896.3;
            } else if (radius === "0-1000") {
              return 3471.24;
            } else if (radius === "0-1200") {
              return 4336.7;
            } else if (radius === "0-1500") {
              return 5424.58;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1580.27;
            } else if (radius === "0-100") {
              return 1877.55;
            } else if (radius === "0-250") {
              return 2234.3;
            } else if (radius === "0-500") {
              return 2662.38;
            } else if (radius === "0-800") {
              return 3336.58;
            } else if (radius === "0-1000") {
              return 4002.96;
            } else if (radius === "0-1200") {
              return 5005.06;
            } else if (radius === "0-1500") {
              return 6264.72;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 757.76;
            } else if (radius === "0-100") {
              return 892.89;
            } else if (radius === "0-250") {
              return 1055.04;
            } else if (radius === "0-500") {
              return 1249.63;
            } else if (radius === "0-800") {
              return 1575.49;
            } else if (radius === "0-1000") {
              return 1862.56;
            } else if (radius === "0-1200") {
              return 2318.06;
            } else if (radius === "0-1500") {
              return 2890.63;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 991.42;
            } else if (radius === "0-100") {
              return 1173.28;
            } else if (radius === "0-250") {
              return 1391.52;
            } else if (radius === "0-500") {
              return 1653.4;
            } else if (radius === "0-800") {
              return 2083.03;
            } else if (radius === "0-1000") {
              return 2475.51;
            } else if (radius === "0-1200") {
              return 3088.54;
            } else if (radius === "0-1500") {
              return 3859.13;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1365.84;
            } else if (radius === "0-100") {
              return 1622.59;
            } else if (radius === "0-250") {
              return 1930.69;
            } else if (radius === "0-500") {
              return 2300.39;
            } else if (radius === "0-800") {
              return 2882.23;
            } else if (radius === "0-1000") {
              return 3457.71;
            } else if (radius === "0-1200") {
              return 4323.17;
            } else if (radius === "0-1500") {
              return 5411.05;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1568.54;
            } else if (radius === "0-100") {
              return 1865.82;
            } else if (radius === "0-250") {
              return 2222.57;
            } else if (radius === "0-500") {
              return 2650.65;
            } else if (radius === "0-800") {
              return 3322.5;
            } else if (radius === "0-1000") {
              return 3989.43;
            } else if (radius === "0-1200") {
              return 4991.54;
            } else if (radius === "0-1500") {
              return 6251.2;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.76;
            } else if (radius === "0-250") {
              return 892.89;
            } else if (radius === "0-500") {
              return 1055.04;
            } else if (radius === "0-800") {
              return 1316.82;
            } else if (radius === "0-1000") {
              return 1567.16;
            } else if (radius === "0-1200") {
              return 1946.75;
            } else if (radius === "0-1500") {
              return 2423.88;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.4;
            } else if (radius === "0-100") {
              return 990.86;
            } else if (radius === "0-250") {
              return 1172.6;
            } else if (radius === "0-500") {
              return 1390.71;
            } else if (radius === "0-800") {
              return 1738.75;
            } else if (radius === "0-1000") {
              return 2076.72;
            } else if (radius === "0-1200") {
              return 2587.27;
            } else if (radius === "0-1500") {
              return 3229.02;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.89;
            } else if (radius === "0-100") {
              return 1365.84;
            } else if (radius === "0-250") {
              return 1622.59;
            } else if (radius === "0-500") {
              return 1930.69;
            } else if (radius === "0-800") {
              return 2417.49;
            } else if (radius === "0-1000") {
              return 2896.46;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.24;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.8;
            } else if (radius === "0-100") {
              return 1568.54;
            } else if (radius === "0-250") {
              return 1865.82;
            } else if (radius === "0-500") {
              return 2222.57;
            } else if (radius === "0-800") {
              return 2784.39;
            } else if (radius === "0-1000") {
              return 3339.55;
            } else if (radius === "0-1200") {
              return 4174.64;
            } else if (radius === "0-1500") {
              return 5224.36;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.76;
            } else if (radius === "0-250") {
              return 892.89;
            } else if (radius === "0-500") {
              return 1055.04;
            } else if (radius === "0-800") {
              return 1316.82;
            } else if (radius === "0-1000") {
              return 1567.16;
            } else if (radius === "0-1200") {
              return 1946.75;
            } else if (radius === "0-1500") {
              return 2423.88;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.87;
            } else if (radius === "0-100") {
              return 991.42;
            } else if (radius === "0-250") {
              return 1173.28;
            } else if (radius === "0-500") {
              return 1391.52;
            } else if (radius === "0-800") {
              return 1739.76;
            } else if (radius === "0-1000") {
              return 2077.95;
            } else if (radius === "0-1200") {
              return 2588.81;
            } else if (radius === "0-1500") {
              return 3230.96;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.89;
            } else if (radius === "0-100") {
              return 1365.84;
            } else if (radius === "0-250") {
              return 1622.59;
            } else if (radius === "0-500") {
              return 1930.69;
            } else if (radius === "0-800") {
              return 2417.49;
            } else if (radius === "0-1000") {
              return 2896.46;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.24;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.8;
            } else if (radius === "0-100") {
              return 1568.54;
            } else if (radius === "0-250") {
              return 1865.82;
            } else if (radius === "0-500") {
              return 2222.57;
            } else if (radius === "0-800") {
              return 2784.39;
            } else if (radius === "0-1000") {
              return 3339.55;
            } else if (radius === "0-1200") {
              return 4174.64;
            } else if (radius === "0-1500") {
              return 5224.36;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 551.31;
            } else if (radius === "0-100") {
              return 645.15;
            } else if (radius === "0-250") {
              return 757.76;
            } else if (radius === "0-500") {
              return 892.89;
            } else if (radius === "0-800") {
              return 1112.99;
            } else if (radius === "0-1000") {
              return 1320.99;
            } else if (radius === "0-1200") {
              return 1637.32;
            } else if (radius === "0-1500") {
              return 2034.93;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 713.18;
            } else if (radius === "0-100") {
              return 839.4;
            } else if (radius === "0-250") {
              return 990.86;
            } else if (radius === "0-500") {
              return 1172.6;
            } else if (radius === "0-800") {
              return 1464.6;
            } else if (radius === "0-1000") {
              return 1745.62;
            } else if (radius === "0-1200") {
              return 2171.09;
            } else if (radius === "0-1500") {
              return 2705.88;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 973.59;
            } else if (radius === "0-100") {
              return 1151.89;
            } else if (radius === "0-250") {
              return 1365.84;
            } else if (radius === "0-500") {
              return 1622.59;
            } else if (radius === "0-800") {
              return 2030.22;
            } else if (radius === "0-1000") {
              return 2428.74;
            } else if (radius === "0-1200") {
              return 3029.75;
            } else if (radius === "0-1500") {
              return 3785.23;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1114.35;
            } else if (radius === "0-100") {
              return 1320.8;
            } else if (radius === "0-250") {
              return 1568.54;
            } else if (radius === "0-500") {
              return 1865.82;
            } else if (radius === "0-800") {
              return 2335.96;
            } else if (radius === "0-1000") {
              return 2797.99;
            } else if (radius === "0-1200") {
              return 3493.9;
            } else if (radius === "0-1500") {
              return 4368.66;
            }
          }
        }
      }
    } else if (state === "NM") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 904.6176;
            } else if (radius === "0-100") {
              return 1066.7731;
            } else if (radius === "0-250") {
              return 1261.3621;
            } else if (radius === "0-500") {
              return 1494.8595;
            } else if (radius === "0-800") {
              return 1868.9996;
            } else if (radius === "0-1000") {
              return 2230.5584;
            } else if (radius === "0-1200") {
              return 2777.1647;
            } else if (radius === "0-1500") {
              return 3464.2515;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1185.0115;
            } else if (radius === "0-100") {
              return 1403.2482;
            } else if (radius === "0-250") {
              return 1665.1322;
            } else if (radius === "0-500") {
              return 1979.3789;
            } else if (radius === "0-800") {
              return 2478.0446;
            } else if (radius === "0-1000") {
              return 2966.1062;
            } else if (radius === "0-1200") {
              return 3701.7442;
            } else if (radius === "0-1500") {
              return 4626.4477;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1634.3174;
            } else if (radius === "0-100") {
              return 1942.4176;
            } else if (radius === "0-250") {
              return 2312.1238;
            } else if (radius === "0-500") {
              return 2755.7876;
            } else if (radius === "0-800") {
              return 3439.9046;
            } else if (radius === "0-1000") {
              return 4144.7454;
            } else if (radius === "0-1200") {
              return 5183.2984;
            } else if (radius === "0-1500") {
              return 6488.7589;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1877.5507;
            } else if (radius === "0-100") {
              return 2234.2952;
            } else if (radius === "0-250") {
              return 2662.3816;
            } else if (radius === "0-500") {
              return 3176.0969;
            } else if (radius === "0-800") {
              return 3982.3115;
            } else if (radius === "0-1000") {
              return 4782.8077;
            } else if (radius === "0-1200") {
              return 5985.3392;
            } else if (radius === "0-1500") {
              return 7496.928;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 769.488;
            } else if (radius === "0-100") {
              return 904.6176;
            } else if (radius === "0-250") {
              return 1066.7731;
            } else if (radius === "0-500") {
              return 1261.3621;
            } else if (radius === "0-800") {
              return 1575.4915;
            } else if (radius === "0-1000") {
              return 1876.0831;
            } else if (radius === "0-1200") {
              return 2331.5827;
            } else if (radius === "0-1500") {
              return 2904.155;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1002.5866;
            } else if (radius === "0-100") {
              return 1184.3312;
            } else if (radius === "0-250") {
              return 1402.4388;
            } else if (radius === "0-500") {
              return 1664.1586;
            } else if (radius === "0-800") {
              return 2081.8052;
            } else if (radius === "0-1000") {
              return 2487.5595;
            } else if (radius === "0-1200") {
              return 3100.208;
            } else if (radius === "0-1500") {
              return 3870.3209;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1377.5712;
            } else if (radius === "0-100") {
              return 1634.3174;
            } else if (radius === "0-250") {
              return 1942.4176;
            } else if (radius === "0-500") {
              return 2312.1238;
            } else if (radius === "0-800") {
              return 2896.3012;
            } else if (radius === "0-1000") {
              return 3471.2389;
            } else if (radius === "0-1200") {
              return 4336.696;
            } else if (radius === "0-1500") {
              return 5424.5778;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1580.2656;
            } else if (radius === "0-100") {
              return 1877.5507;
            } else if (radius === "0-250") {
              return 2234.2952;
            } else if (radius === "0-500") {
              return 2662.3816;
            } else if (radius === "0-800") {
              return 3336.575;
            } else if (radius === "0-1000") {
              return 4002.9575;
            } else if (radius === "0-1200") {
              return 5005.0634;
            } else if (radius === "0-1500") {
              return 6264.7225;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 757.758;
            } else if (radius === "0-100") {
              return 892.8876;
            } else if (radius === "0-250") {
              return 1055.0431;
            } else if (radius === "0-500") {
              return 1249.6321;
            } else if (radius === "0-800") {
              return 1575.4915;
            } else if (radius === "0-1000") {
              return 1862.5591;
            } else if (radius === "0-1200") {
              return 2318.0587;
            } else if (radius === "0-1500") {
              return 2890.631;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 991.4196;
            } else if (radius === "0-100") {
              return 1173.2815;
            } else if (radius === "0-250") {
              return 1391.5182;
            } else if (radius === "0-500") {
              return 1653.4022;
            } else if (radius === "0-800") {
              return 2083.0251;
            } else if (radius === "0-1000") {
              return 2475.5119;
            } else if (radius === "0-1200") {
              return 3088.5435;
            } else if (radius === "0-1500") {
              return 3859.1298;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1365.8412;
            } else if (radius === "0-100") {
              return 1622.5874;
            } else if (radius === "0-250") {
              return 1930.6876;
            } else if (radius === "0-500") {
              return 2300.3938;
            } else if (radius === "0-800") {
              return 2882.2252;
            } else if (radius === "0-1000") {
              return 3457.7149;
            } else if (radius === "0-1200") {
              return 4323.172;
            } else if (radius === "0-1500") {
              return 5411.0538;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1568.5356;
            } else if (radius === "0-100") {
              return 1865.8207;
            } else if (radius === "0-250") {
              return 2222.5652;
            } else if (radius === "0-500") {
              return 2650.6516;
            } else if (radius === "0-800") {
              return 3322.499;
            } else if (radius === "0-1000") {
              return 3989.4335;
            } else if (radius === "0-1200") {
              return 4991.5394;
            } else if (radius === "0-1500") {
              return 6251.1985;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.758;
            } else if (radius === "0-250") {
              return 892.8876;
            } else if (radius === "0-500") {
              return 1055.0431;
            } else if (radius === "0-800") {
              return 1316.8215;
            } else if (radius === "0-1000") {
              return 1567.1611;
            } else if (radius === "0-1200") {
              return 1946.746;
            } else if (radius === "0-1500") {
              return 2423.884;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.3988;
            } else if (radius === "0-100") {
              return 990.8566;
            } else if (radius === "0-250") {
              return 1172.6012;
            } else if (radius === "0-500") {
              return 1390.7088;
            } else if (radius === "0-800") {
              return 1738.7496;
            } else if (radius === "0-1000") {
              return 2076.7229;
            } else if (radius === "0-1200") {
              return 2587.2652;
            } else if (radius === "0-1500") {
              return 3229.0241;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.886;
            } else if (radius === "0-100") {
              return 1365.8412;
            } else if (radius === "0-250") {
              return 1622.5874;
            } else if (radius === "0-500") {
              return 1930.6876;
            } else if (radius === "0-800") {
              return 2417.4944;
            } else if (radius === "0-1000") {
              return 2896.4576;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.2401;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.798;
            } else if (radius === "0-100") {
              return 1568.5356;
            } else if (radius === "0-250") {
              return 1865.8207;
            } else if (radius === "0-500") {
              return 2222.5652;
            } else if (radius === "0-800") {
              return 2784.3853;
            } else if (radius === "0-1000") {
              return 3339.5489;
            } else if (radius === "0-1200") {
              return 4174.6447;
            } else if (radius === "0-1500") {
              return 5224.355;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.758;
            } else if (radius === "0-250") {
              return 892.8876;
            } else if (radius === "0-500") {
              return 1055.0431;
            } else if (radius === "0-800") {
              return 1316.8215;
            } else if (radius === "0-1000") {
              return 1567.1611;
            } else if (radius === "0-1200") {
              return 1946.746;
            } else if (radius === "0-1500") {
              return 2423.884;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.868;
            } else if (radius === "0-100") {
              return 991.4196;
            } else if (radius === "0-250") {
              return 1173.2815;
            } else if (radius === "0-500") {
              return 1391.5182;
            } else if (radius === "0-800") {
              return 1739.7584;
            } else if (radius === "0-1000") {
              return 2077.9513;
            } else if (radius === "0-1200") {
              return 2588.8092;
            } else if (radius === "0-1500") {
              return 3230.9625;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.886;
            } else if (radius === "0-100") {
              return 1365.8412;
            } else if (radius === "0-250") {
              return 1622.5874;
            } else if (radius === "0-500") {
              return 1930.6876;
            } else if (radius === "0-800") {
              return 2417.4944;
            } else if (radius === "0-1000") {
              return 2896.4576;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.2401;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.798;
            } else if (radius === "0-100") {
              return 1568.5356;
            } else if (radius === "0-250") {
              return 1865.8207;
            } else if (radius === "0-500") {
              return 2222.5652;
            } else if (radius === "0-800") {
              return 2784.3853;
            } else if (radius === "0-1000") {
              return 3339.5489;
            } else if (radius === "0-1200") {
              return 4174.6447;
            } else if (radius === "0-1500") {
              return 5224.355;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 551.31;
            } else if (radius === "0-100") {
              return 645.15;
            } else if (radius === "0-250") {
              return 757.758;
            } else if (radius === "0-500") {
              return 892.8876;
            } else if (radius === "0-800") {
              return 1112.9893;
            } else if (radius === "0-1000") {
              return 1320.9905;
            } else if (radius === "0-1200") {
              return 1637.3169;
            } else if (radius === "0-1500") {
              return 2034.9337;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 713.184;
            } else if (radius === "0-100") {
              return 839.3988;
            } else if (radius === "0-250") {
              return 990.8566;
            } else if (radius === "0-500") {
              return 1172.6012;
            } else if (radius === "0-800") {
              return 1464.5961;
            } else if (radius === "0-1000") {
              return 1745.6216;
            } else if (radius === "0-1200") {
              return 2171.0866;
            } else if (radius === "0-1500") {
              return 2705.8819;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 973.59;
            } else if (radius === "0-100") {
              return 1151.886;
            } else if (radius === "0-250") {
              return 1365.8412;
            } else if (radius === "0-500") {
              return 1622.5874;
            } else if (radius === "0-800") {
              return 2030.2167;
            } else if (radius === "0-1000") {
              return 2428.7414;
            } else if (radius === "0-1200") {
              return 3029.7479;
            } else if (radius === "0-1500") {
              return 3785.2324;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1114.35;
            } else if (radius === "0-100") {
              return 1320.798;
            } else if (radius === "0-250") {
              return 1568.5356;
            } else if (radius === "0-500") {
              return 1865.8207;
            } else if (radius === "0-800") {
              return 2335.9591;
            } else if (radius === "0-1000") {
              return 2797.9916;
            } else if (radius === "0-1200") {
              return 3493.9029;
            } else if (radius === "0-1500") {
              return 4368.6577;
            }
          }
        }
      }
    } else if (state === "AZ") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 904.6176;
            } else if (radius === "0-100") {
              return 1066.7731;
            } else if (radius === "0-250") {
              return 1261.3621;
            } else if (radius === "0-500") {
              return 1494.8595;
            } else if (radius === "0-800") {
              return 1868.9996;
            } else if (radius === "0-1000") {
              return 2230.5584;
            } else if (radius === "0-1200") {
              return 2777.1647;
            } else if (radius === "0-1500") {
              return 3464.2515;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1185.0115;
            } else if (radius === "0-100") {
              return 1403.2482;
            } else if (radius === "0-250") {
              return 1665.1322;
            } else if (radius === "0-500") {
              return 1979.3789;
            } else if (radius === "0-800") {
              return 2478.0446;
            } else if (radius === "0-1000") {
              return 2966.1062;
            } else if (radius === "0-1200") {
              return 3701.7442;
            } else if (radius === "0-1500") {
              return 4626.4477;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1634.3174;
            } else if (radius === "0-100") {
              return 1942.4176;
            } else if (radius === "0-250") {
              return 2312.1238;
            } else if (radius === "0-500") {
              return 2755.7876;
            } else if (radius === "0-800") {
              return 3439.9046;
            } else if (radius === "0-1000") {
              return 4144.7454;
            } else if (radius === "0-1200") {
              return 5183.2984;
            } else if (radius === "0-1500") {
              return 6488.7589;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1877.5507;
            } else if (radius === "0-100") {
              return 2234.2952;
            } else if (radius === "0-250") {
              return 2662.3816;
            } else if (radius === "0-500") {
              return 3176.0969;
            } else if (radius === "0-800") {
              return 3982.3115;
            } else if (radius === "0-1000") {
              return 4782.8077;
            } else if (radius === "0-1200") {
              return 5985.3392;
            } else if (radius === "0-1500") {
              return 7496.928;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 769.488;
            } else if (radius === "0-100") {
              return 904.6176;
            } else if (radius === "0-250") {
              return 1066.7731;
            } else if (radius === "0-500") {
              return 1261.3621;
            } else if (radius === "0-800") {
              return 1575.4915;
            } else if (radius === "0-1000") {
              return 1876.0831;
            } else if (radius === "0-1200") {
              return 2331.5827;
            } else if (radius === "0-1500") {
              return 2904.155;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1002.5866;
            } else if (radius === "0-100") {
              return 1184.3312;
            } else if (radius === "0-250") {
              return 1402.4388;
            } else if (radius === "0-500") {
              return 1664.1586;
            } else if (radius === "0-800") {
              return 2081.8052;
            } else if (radius === "0-1000") {
              return 2487.5595;
            } else if (radius === "0-1200") {
              return 3100.208;
            } else if (radius === "0-1500") {
              return 3870.3209;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1377.5712;
            } else if (radius === "0-100") {
              return 1634.3174;
            } else if (radius === "0-250") {
              return 1942.4176;
            } else if (radius === "0-500") {
              return 2312.1238;
            } else if (radius === "0-800") {
              return 2896.3012;
            } else if (radius === "0-1000") {
              return 3471.2389;
            } else if (radius === "0-1200") {
              return 4336.696;
            } else if (radius === "0-1500") {
              return 5424.5778;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1580.2656;
            } else if (radius === "0-100") {
              return 1877.5507;
            } else if (radius === "0-250") {
              return 2234.2952;
            } else if (radius === "0-500") {
              return 2662.3816;
            } else if (radius === "0-800") {
              return 3336.575;
            } else if (radius === "0-1000") {
              return 4002.9575;
            } else if (radius === "0-1200") {
              return 5005.0634;
            } else if (radius === "0-1500") {
              return 6264.7225;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 757.758;
            } else if (radius === "0-100") {
              return 892.8876;
            } else if (radius === "0-250") {
              return 1055.0431;
            } else if (radius === "0-500") {
              return 1249.6321;
            } else if (radius === "0-800") {
              return 1575.4915;
            } else if (radius === "0-1000") {
              return 1862.5591;
            } else if (radius === "0-1200") {
              return 2318.0587;
            } else if (radius === "0-1500") {
              return 2890.631;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 991.4196;
            } else if (radius === "0-100") {
              return 1173.2815;
            } else if (radius === "0-250") {
              return 1391.5182;
            } else if (radius === "0-500") {
              return 1653.4022;
            } else if (radius === "0-800") {
              return 2083.0251;
            } else if (radius === "0-1000") {
              return 2475.5119;
            } else if (radius === "0-1200") {
              return 3088.5435;
            } else if (radius === "0-1500") {
              return 3859.1298;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1365.8412;
            } else if (radius === "0-100") {
              return 1622.5874;
            } else if (radius === "0-250") {
              return 1930.6876;
            } else if (radius === "0-500") {
              return 2300.3938;
            } else if (radius === "0-800") {
              return 2882.2252;
            } else if (radius === "0-1000") {
              return 3457.7149;
            } else if (radius === "0-1200") {
              return 4323.172;
            } else if (radius === "0-1500") {
              return 5411.0538;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1568.5356;
            } else if (radius === "0-100") {
              return 1865.8207;
            } else if (radius === "0-250") {
              return 2222.5652;
            } else if (radius === "0-500") {
              return 2650.6516;
            } else if (radius === "0-800") {
              return 3322.499;
            } else if (radius === "0-1000") {
              return 3989.4335;
            } else if (radius === "0-1200") {
              return 4991.5394;
            } else if (radius === "0-1500") {
              return 6251.1985;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.758;
            } else if (radius === "0-250") {
              return 892.8876;
            } else if (radius === "0-500") {
              return 1055.0431;
            } else if (radius === "0-800") {
              return 1316.8215;
            } else if (radius === "0-1000") {
              return 1567.1611;
            } else if (radius === "0-1200") {
              return 1946.746;
            } else if (radius === "0-1500") {
              return 2423.884;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.3988;
            } else if (radius === "0-100") {
              return 990.8566;
            } else if (radius === "0-250") {
              return 1172.6012;
            } else if (radius === "0-500") {
              return 1390.7088;
            } else if (radius === "0-800") {
              return 1738.7496;
            } else if (radius === "0-1000") {
              return 2076.7229;
            } else if (radius === "0-1200") {
              return 2587.2652;
            } else if (radius === "0-1500") {
              return 3229.0241;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.886;
            } else if (radius === "0-100") {
              return 1365.8412;
            } else if (radius === "0-250") {
              return 1622.5874;
            } else if (radius === "0-500") {
              return 1930.6876;
            } else if (radius === "0-800") {
              return 2417.4944;
            } else if (radius === "0-1000") {
              return 2896.4576;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.2401;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.798;
            } else if (radius === "0-100") {
              return 1568.5356;
            } else if (radius === "0-250") {
              return 1865.8207;
            } else if (radius === "0-500") {
              return 2222.5652;
            } else if (radius === "0-800") {
              return 2784.3853;
            } else if (radius === "0-1000") {
              return 3339.5489;
            } else if (radius === "0-1200") {
              return 4174.6447;
            } else if (radius === "0-1500") {
              return 5224.355;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 645.15;
            } else if (radius === "0-100") {
              return 757.758;
            } else if (radius === "0-250") {
              return 892.8876;
            } else if (radius === "0-500") {
              return 1055.0431;
            } else if (radius === "0-800") {
              return 1316.8215;
            } else if (radius === "0-1000") {
              return 1567.1611;
            } else if (radius === "0-1200") {
              return 1946.746;
            } else if (radius === "0-1500") {
              return 2423.884;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 839.868;
            } else if (radius === "0-100") {
              return 991.4196;
            } else if (radius === "0-250") {
              return 1173.2815;
            } else if (radius === "0-500") {
              return 1391.5182;
            } else if (radius === "0-800") {
              return 1739.7584;
            } else if (radius === "0-1000") {
              return 2077.9513;
            } else if (radius === "0-1200") {
              return 2588.8092;
            } else if (radius === "0-1500") {
              return 3230.9625;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1151.886;
            } else if (radius === "0-100") {
              return 1365.8412;
            } else if (radius === "0-250") {
              return 1622.5874;
            } else if (radius === "0-500") {
              return 1930.6876;
            } else if (radius === "0-800") {
              return 2417.4944;
            } else if (radius === "0-1000") {
              return 2896.4576;
            } else if (radius === "0-1200") {
              return 3617.67;
            } else if (radius === "0-1500") {
              return 4524.2401;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1320.798;
            } else if (radius === "0-100") {
              return 1568.5356;
            } else if (radius === "0-250") {
              return 1865.8207;
            } else if (radius === "0-500") {
              return 2222.5652;
            } else if (radius === "0-800") {
              return 2784.3853;
            } else if (radius === "0-1000") {
              return 3339.5489;
            } else if (radius === "0-1200") {
              return 4174.6447;
            } else if (radius === "0-1500") {
              return 5224.355;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 551.31;
            } else if (radius === "0-100") {
              return 645.15;
            } else if (radius === "0-250") {
              return 757.758;
            } else if (radius === "0-500") {
              return 892.8876;
            } else if (radius === "0-800") {
              return 1112.9893;
            } else if (radius === "0-1000") {
              return 1320.9905;
            } else if (radius === "0-1200") {
              return 1637.3169;
            } else if (radius === "0-1500") {
              return 2034.9337;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 713.184;
            } else if (radius === "0-100") {
              return 839.3988;
            } else if (radius === "0-250") {
              return 990.8566;
            } else if (radius === "0-500") {
              return 1172.6012;
            } else if (radius === "0-800") {
              return 1464.5961;
            } else if (radius === "0-1000") {
              return 1745.6216;
            } else if (radius === "0-1200") {
              return 2171.0866;
            } else if (radius === "0-1500") {
              return 2705.8819;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 973.59;
            } else if (radius === "0-100") {
              return 1151.886;
            } else if (radius === "0-250") {
              return 1365.8412;
            } else if (radius === "0-500") {
              return 1622.5874;
            } else if (radius === "0-800") {
              return 2030.2167;
            } else if (radius === "0-1000") {
              return 2428.7414;
            } else if (radius === "0-1200") {
              return 3029.7479;
            } else if (radius === "0-1500") {
              return 3785.2324;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1114.35;
            } else if (radius === "0-100") {
              return 1320.798;
            } else if (radius === "0-250") {
              return 1568.5356;
            } else if (radius === "0-500") {
              return 1865.8207;
            } else if (radius === "0-800") {
              return 2335.9591;
            } else if (radius === "0-1000") {
              return 2797.9916;
            } else if (radius === "0-1200") {
              return 3493.9029;
            } else if (radius === "0-1500") {
              return 4368.6577;
            }
          }
        }
      }
    } else if (state === "CA") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 985.6954;
            } else if (radius === "0-100") {
              return 1164.0617;
            } else if (radius === "0-250") {
              return 1378.1108;
            } else if (radius === "0-500") {
              return 1634.9626;
            } else if (radius === "0-800") {
              return 2045.1138;
            } else if (radius === "0-1000") {
              return 2443.2458;
            } else if (radius === "0-1200") {
              return 3044.5116;
            } else if (radius === "0-1500") {
              return 3800.3116;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1294.124;
            } else if (radius === "0-100") {
              return 1534.1902;
            } else if (radius === "0-250") {
              return 1822.2555;
            } else if (radius === "0-500") {
              return 2167.9386;
            } else if (radius === "0-800") {
              return 2715.061;
            } else if (radius === "0-1000") {
              return 3252.3417;
            } else if (radius === "0-1200") {
              return 4061.5502;
            } else if (radius === "0-1500") {
              return 5078.7241;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1788.3675;
            } else if (radius === "0-100") {
              return 2127.2707;
            } else if (radius === "0-250") {
              return 2533.9615;
            } else if (radius === "0-500") {
              return 3021.9764;
            } else if (radius === "0-800") {
              return 3774.5146;
            } else if (radius === "0-1000") {
              return 4548.8538;
            } else if (radius === "0-1200") {
              return 5691.2598;
            } else if (radius === "0-1500") {
              return 7127.272;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 2055.9171;
            } else if (radius === "0-100") {
              return 2448.3325;
            } else if (radius === "0-250") {
              return 2919.2334;
            } else if (radius === "0-500") {
              return 3484.3144;
            } else if (radius === "0-800") {
              return 4369.7417;
            } else if (radius === "0-1000") {
              return 5250.7268;
            } else if (radius === "0-1200") {
              return 6573.5093;
            } else if (radius === "0-1500") {
              return 8236.2512;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 837.0528;
            } else if (radius === "0-100") {
              return 985.6954;
            } else if (radius === "0-250") {
              return 1164.0617;
            } else if (radius === "0-500") {
              return 1378.1108;
            } else if (radius === "0-800") {
              return 1722.2455;
            } else if (radius === "0-1000") {
              return 2053.3151;
            } else if (radius === "0-1200") {
              return 2554.3793;
            } else if (radius === "0-1500") {
              return 3184.2033;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1093.4589;
            } else if (radius === "0-100") {
              return 1293.385;
            } else if (radius === "0-250") {
              return 1533.2987;
            } else if (radius === "0-500") {
              return 1821.1881;
            } else if (radius === "0-800") {
              return 2279.1859;
            } else if (radius === "0-1000") {
              return 2725.9425;
            } else if (radius === "0-1200") {
              return 3399.866;
            } else if (radius === "0-1500") {
              return 4246.9868;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1505.9443;
            } else if (radius === "0-100") {
              return 1788.3675;
            } else if (radius === "0-250") {
              return 2127.2707;
            } else if (radius === "0-500") {
              return 2533.9615;
            } else if (radius === "0-800") {
              return 3175.1468;
            } else if (radius === "0-1000") {
              return 3807.9865;
            } else if (radius === "0-1200") {
              return 4759.9972;
            } else if (radius === "0-1500") {
              return 5956.6683;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1728.9082;
            } else if (radius === "0-100") {
              return 2055.9171;
            } else if (radius === "0-250") {
              return 2448.3325;
            } else if (radius === "0-500") {
              return 2919.2334;
            } else if (radius === "0-800") {
              return 3659.4433;
            } else if (radius === "0-1000") {
              return 4392.877;
            } else if (radius === "0-1200") {
              return 5495.2069;
            } else if (radius === "0-1500") {
              return 6880.8309;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 825.3228;
            } else if (radius === "0-100") {
              return 973.9654;
            } else if (radius === "0-250") {
              return 1152.3317;
            } else if (radius === "0-500") {
              return 1366.3808;
            } else if (radius === "0-800") {
              return 1722.2455;
            } else if (radius === "0-1000") {
              return 2039.7911;
            } else if (radius === "0-1200") {
              return 2540.8553;
            } else if (radius === "0-1500") {
              return 3170.6793;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 1082.3506;
            } else if (radius === "0-100") {
              return 1282.394;
            } else if (radius === "0-250") {
              return 1522.4602;
            } else if (radius === "0-500") {
              return 1810.5255;
            } else if (radius === "0-800") {
              return 2280.5349;
            } else if (radius === "0-1000") {
              return 2714.0414;
            } else if (radius === "0-1200") {
              return 3388.3819;
            } else if (radius === "0-1500") {
              return 4236.0211;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1494.2143;
            } else if (radius === "0-100") {
              return 1776.6375;
            } else if (radius === "0-250") {
              return 2115.5407;
            } else if (radius === "0-500") {
              return 2522.2315;
            } else if (radius === "0-800") {
              return 3161.0708;
            } else if (radius === "0-1000") {
              return 3794.4625;
            } else if (radius === "0-1200") {
              return 4746.4732;
            } else if (radius === "0-1500") {
              return 5943.1443;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1717.1782;
            } else if (radius === "0-100") {
              return 2044.1871;
            } else if (radius === "0-250") {
              return 2436.6025;
            } else if (radius === "0-500") {
              return 2907.5034;
            } else if (radius === "0-800") {
              return 3645.3673;
            } else if (radius === "0-1000") {
              return 4379.353;
            } else if (radius === "0-1200") {
              return 5481.6829;
            } else if (radius === "0-1500") {
              return 6867.3069;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 701.454;
            } else if (radius === "0-100") {
              return 825.3228;
            } else if (radius === "0-250") {
              return 973.9654;
            } else if (radius === "0-500") {
              return 1152.3317;
            } else if (radius === "0-800") {
              return 1439.1185;
            } else if (radius === "0-1000") {
              return 1714.8545;
            } else if (radius === "0-1200") {
              return 2132.408;
            } else if (radius === "0-1500") {
              return 2657.2631;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 915.1277;
            } else if (radius === "0-100") {
              return 1081.7289;
            } else if (radius === "0-250") {
              return 1281.655;
            } else if (radius === "0-500") {
              return 1521.5687;
            } else if (radius === "0-800") {
              return 1903.2394;
            } else if (radius === "0-1000") {
              return 2275.3792;
            } else if (radius === "0-1200") {
              return 2836.9746;
            } else if (radius === "0-1500") {
              return 3542.9048;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1258.8636;
            } else if (radius === "0-100") {
              return 1494.2143;
            } else if (radius === "0-250") {
              return 1776.6375;
            } else if (radius === "0-500") {
              return 2115.5407;
            } else if (radius === "0-800") {
              return 2649.8657;
            } else if (radius === "0-1000") {
              return 3177.0806;
            } else if (radius === "0-1200") {
              return 3970.421;
            } else if (radius === "0-1500") {
              return 4967.647;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1444.6668;
            } else if (radius === "0-100") {
              return 1717.1782;
            } else if (radius === "0-250") {
              return 2044.1871;
            } else if (radius === "0-500") {
              return 2436.6025;
            } else if (radius === "0-800") {
              return 3053.448;
            } else if (radius === "0-1000") {
              return 3664.4969;
            } else if (radius === "0-1200") {
              return 4583.092;
            } else if (radius === "0-1500") {
              return 5737.7824;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 701.454;
            } else if (radius === "0-100") {
              return 825.3228;
            } else if (radius === "0-250") {
              return 973.9654;
            } else if (radius === "0-500") {
              return 1152.3317;
            } else if (radius === "0-800") {
              return 1439.1185;
            } else if (radius === "0-1000") {
              return 1714.8545;
            } else if (radius === "0-1200") {
              return 2132.408;
            } else if (radius === "0-1500") {
              return 2657.2631;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 915.6438;
            } else if (radius === "0-100") {
              return 1082.3506;
            } else if (radius === "0-250") {
              return 1282.394;
            } else if (radius === "0-500") {
              return 1522.4602;
            } else if (radius === "0-800") {
              return 1904.3538;
            } else if (radius === "0-1000") {
              return 2276.7316;
            } else if (radius === "0-1200") {
              return 2838.6763;
            } else if (radius === "0-1500") {
              return 3545.0461;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 1258.8636;
            } else if (radius === "0-100") {
              return 1494.2143;
            } else if (radius === "0-250") {
              return 1776.6375;
            } else if (radius === "0-500") {
              return 2115.5407;
            } else if (radius === "0-800") {
              return 2649.8657;
            } else if (radius === "0-1000") {
              return 3177.0806;
            } else if (radius === "0-1200") {
              return 3970.421;
            } else if (radius === "0-1500") {
              return 4967.647;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 1444.6668;
            } else if (radius === "0-100") {
              return 1717.1782;
            } else if (radius === "0-250") {
              return 2044.1871;
            } else if (radius === "0-500") {
              return 2436.6025;
            } else if (radius === "0-800") {
              return 3053.448;
            } else if (radius === "0-1000") {
              return 3664.4969;
            } else if (radius === "0-1200") {
              return 4583.092;
            } else if (radius === "0-1500") {
              return 5737.7824;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 520;
            } else if (radius === "0-100") {
              return;
            } else if (radius === "0-250") {
              return;
            } else if (radius === "0-500") {
              return;
            } else if (radius === "0-800") {
              return;
            } else if (radius === "0-1000") {
              return;
            } else if (radius === "0-1200") {
              return;
            } else if (radius === "0-1500") {
              return;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return;
            } else if (radius === "0-100") {
              return;
            } else if (radius === "0-250") {
              return;
            } else if (radius === "0-500") {
              return;
            } else if (radius === "0-800") {
              return;
            } else if (radius === "0-1000") {
              return;
            } else if (radius === "0-1200") {
              return;
            } else if (radius === "0-1500") {
              return;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return;
            } else if (radius === "0-100") {
              return;
            } else if (radius === "0-250") {
              return;
            } else if (radius === "0-500") {
              return;
            } else if (radius === "0-800") {
              return;
            } else if (radius === "0-1000") {
              return;
            } else if (radius === "0-1200") {
              return;
            } else if (radius === "0-1500") {
              return;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return;
            } else if (radius === "0-100") {
              return;
            } else if (radius === "0-250") {
              return;
            } else if (radius === "0-500") {
              return;
            } else if (radius === "0-800") {
              return;
            } else if (radius === "0-1000") {
              return;
            } else if (radius === "0-1200") {
              return;
            } else if (radius === "0-1500") {
              return;
            }
          }
        }
      }
    }
  } else if (program === "Domestic") {
    if (state === "TX") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
    } else if (state === "NM") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
    } else if (state === "AZ") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 47913000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
    } else if (state === "CA") {
      if (product === "MCA") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 72813000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 150313000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MCE") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
      if (product === "MX") {
        if (limitLiability === "$1,000,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
        if (limitLiability === "$750,000") {
          if (truckClass === "Class I") {
            if (radius === "0-50") {
              return 52013000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class II") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class III") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          } else if (truckClass === "Class IV") {
            if (radius === "0-50") {
              return 13000;
            } else if (radius === "0-100") {
              return 13000;
            } else if (radius === "0-250") {
              return 13000;
            } else if (radius === "0-500") {
              return 13000;
            } else if (radius === "0-800") {
              return 13000;
            } else if (radius === "0-1000") {
              return 13000;
            } else if (radius === "0-1200") {
              return 13000;
            } else if (radius === "0-1500") {
              return 13000;
            }
          }
        }
      }
    }
  }
};

export default rateTruck;
