import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const getCustomerData = async () => {
  try {
    const customerRef = collection(
      db,
      "agencies",
      "JiAfXAkc0Bjr4OXfqSv9",
      "insureds"
    );
    const customerSnapshot = await getDocs(customerRef);
    return customerSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (error) {
    console.error("Error fetching customer data:", error);
    return [];
  }
};

export default getCustomerData;
