const getDailyPremium = (
  endoData,
  totalPremiumAfterFleetCredit,
  leapDays,
  truck,
  effectiveDate,
  addedDate,
  endoEffectiveDate
) => {
  // Initialize flags for different endorsement types
  let isAddPowerUnitsEndorsement = false;
  let isRemovePowerUnitsEndorsement = false;
  let isCancellation = false;

  // Check for endorsement type
  if (endoData) {
    if (endoData.type === "Add Power Units") {
      isAddPowerUnitsEndorsement = true;
    } else if (endoData.type === "Remove Power Units") {
      isRemovePowerUnitsEndorsement = true;
    }
  } else {
    // Handle case when endoData is null (e.g., cancellation)
    isCancellation = true;
  }

  // Utility function to reset date to midnight
  function resetTimeToMidnight(date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  const resetEndoEffectiveDate = resetTimeToMidnight(endoEffectiveDate);
  const resetEffectiveDate = resetTimeToMidnight(effectiveDate);

  let dailyPremium;

  if (isAddPowerUnitsEndorsement) {
    // For "Add Power Units," prorate based on 365 days
    const rawDailyPremium = totalPremiumAfterFleetCredit / (365 + leapDays);
    dailyPremium = parseFloat(rawDailyPremium.toFixed(2));
  } else if (isRemovePowerUnitsEndorsement) {
    // For "Remove Power Units," adjust the active days correctly
    const daysBetweenEndoEffectiveAndEffective = Math.max(
      0,
      Math.floor(
        (resetEffectiveDate - resetEndoEffectiveDate) / (1000 * 60 * 60 * 24)
      )
    );

    const totalDays = 365 - daysBetweenEndoEffectiveAndEffective + leapDays;
    if (totalDays <= 0) {
      throw new Error(
        "Invalid calculation: Total days for premium calculation must be greater than 0."
      );
    }
    const rawDailyPremium =
      totalPremiumAfterFleetCredit / totalDays;
    dailyPremium = parseFloat(rawDailyPremium.toFixed(2));
  } else if (isCancellation) {
    // For cancellations, calculate daily premium based on total policy duration
    const rawDailyPremium = totalPremiumAfterFleetCredit / (365 + leapDays);
    dailyPremium = parseFloat(rawDailyPremium.toFixed(2));
  }

  return dailyPremium;
};

export default getDailyPremium;
