import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import IconButton from "@mui/material/IconButton";
import {
  Delete as DeleteIcon,
  ModeEdit as PencilIcon,
} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import classNames from "classnames";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import BlueButton from "../../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import ImportDriversTrainings from "./ImportDriversTrainings";
import TableSkeleton from "../../../generals/TableSkeleton";
import useTranslations from "../../../../Context/actions/useTranslations";
import AddDriverTrainingModal from "../../../LossControl/DriverTraining/AddDriverTrainingModal";
import DatePicker from "react-datepicker";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import ApiService from "../../../../services/southern-api/apiService";
import useUsersState from "../../../../Context/actions/useUsersState";

export default function DriverTrainingPanel({
  driver,
  driversLicenseNumber,
  formData,
  isTemporalUrl,
  insuredInfo,
}) {
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user: userInfo } = useUsersState();

  const { t } = useTranslations();
  const apiService = new ApiService("southern");

  useEffect(() => {
    const fetchTrainingHistory = () => {
      setLoading(true);
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const q = query(
        trainingsCollectionRef,
        where("driversLicenseNumber", "==", driversLicenseNumber)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const history = [];
        querySnapshot.forEach((doc) => {
          history.push({ ...doc.data(), id: doc.id });
          if (!doc.data().completionDate) {
            setCompletionDates({
              ...completionDates,
              [doc.id]: new Date(),
            });
            setCompletionTrackers({
              ...completionTrackers,
              [doc.id]: false,
            });
          }
        });
        setLoading(false);
        setTrainingHistory(history);
      });
      return unsubscribe;
    };

    const unsubscribe = fetchTrainingHistory();

    return () => unsubscribe();
  }, []);

  const handleDeleteTraining = async (id) => {
    const confirmation = prompt(
      "To confirm deletion, confirm the action typing DELETE"
    );
    if (confirmation === "DELETE") {
      const trainingRef = doc(db, "driver-trainings", id);
      await deleteDoc(trainingRef);
    }
  };

  const [editData, setEditData] = useState(null);

  const handleEditTraining = async (id) => {
    setEditMode(true);
    const selectedTraining = trainingHistory.find(
      (training) => training.id === id
    );
    setEditData({ ...selectedTraining, id });
    setOpenModalDriverTrainingModal(true);
  };

  const [openModalDriverTrainingModal, setOpenModalDriverTrainingModal] =
    useState(false);

  const handleAddTraining = () => {
    setEditData(null);
    setOpenModalDriverTrainingModal(true);
  };

  const handleCloseDriverTrainingModal = () => {
    setEditMode(false);
    setEditData(null);
    setOpenModalDriverTrainingModal(false);
  };

  const addDriverModal = [
    {
      label: t("plm.drivers.details.training-name"),
      type: "text",
      name: "trainingName",
      required: true,
      defaultValue: editData?.trainingName || "",
    },
    {
      label: t("plm.drivers.details.notes"),
      type: "text",
      name: "notes",
      required: true,
      defaultValue: editData?.notes || "",
    },
    {
      label: t("plm.drivers.details.institution"),
      type: "text",
      name: "institution",
      required: true,
      disabled: true,
      defaultValue: "JJ Keller",
    },
    {
      label: t("plm.drivers.details.completion-date"),
      type: "date",
      name: "completionDate",
      required: true,
      defaultValue: editData?.completionDate
        ? editData?.completionDate.toDate()
        : null,
    },
    {
      label: `${t("plm.upload")} ${t("plm.drivers.details.certificate-file")}`,
      type: "file",
      storageFile: `files/drivers/${driversLicenseNumber}`,
      name: "certificateFileURL",
      required: true,
      multiple: false,
      dissapearWhenImgExists: true,
      showPastingOption: true,
      defaultValue:
        editData?.certificateFileURL?.length > 0
          ? editData?.certificateFileURL
          : [],
    },
  ];

  const handleSaveTraining = async (e, value) => {
    e.preventDefault();
    const newPayload = {
      ...value,
      driversLicenseNumber,
    };
    if (!editData) {
      const trainingsCollectionRef = collection(db, "driver-trainings");
      await addDoc(trainingsCollectionRef, newPayload);
    } else {
      const trainingsCollectionRef = doc(db, "driver-trainings", editData.id);
      await setDoc(trainingsCollectionRef, newPayload, { merge: true });
    }
    handleCloseDriverTrainingModal();
  };

  const formSettingsDriverTraining = {
    onSubmit: handleSaveTraining,
    onDecline: handleCloseDriverTrainingModal,
    inputs: addDriverModal,
    buttonLabel: t("plm.drivers.submit"),
    buttonLabelDecline: t("plm.cancel"),
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [importDriversTrainingSection, setImportDriversTrainingSection] =
    useState(false);

  const [completionDates, setCompletionDates] = useState(new Date());
  const [completionTrackers, setCompletionTrackers] = useState({});
  const [editMode, setEditMode] = useState(false);

  const { controlToast } = useGeneralState();
  const handlePrintTrainingCertificate = async (trainingId, trainingInfo) => {
    if (!trainingInfo.courseComplete)
      return alert(
        "You cannot print a certificate for a course that is not marked as complete."
      );
    try {
      controlToast(true, "Preparing Driver Certificate", "info");
      setLoading(true);
      const formattedCompletionDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(trainingInfo.completionDate.toDate());      

      // get current year
      const currentYear = new Date().getFullYear();
      let payload = {
        formattedCompletionDate,
        currentYear,
        trainingInfo,
        driver,
        insuredInfo,
      };

      const result = await apiService.printDriverTrainingCertificate({
        payload,
        driverId: trainingId,
      });
      const url = result.url;
      window.open(url, "_blank");
      setLoading(false);
    } catch (error) {
      controlToast(
        true,
        "There was an error trying to print the driver certificate. Please contact support if the problem persists.",
        "error"
      );
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="mb-3">
          <TableSkeleton rows={2} columns={6} />
        </div>
      ) : (
        <>
          {importDriversTrainingSection ? (
            <>
              <ImportDriversTrainings
                setShowImport={setImportDriversTrainingSection}
                driversLicenseNumber={driversLicenseNumber}
              />
            </>
          ) : (
            <>
              <BlueButton
                text={t("plm.drivers.details.add-training")}
                onClickFunction={handleAddTraining}
                hasContentToBottom={true}
                hasContentToRight={!isMobileScreen}
                className={isMobileScreen ? "w-[100%]" : ""}
              />

              {/* <BlueButton
                text={t("plm.drivers.details.import-training")}
                onClickFunction={() => setImportDriversTrainingSection(true)}
                hasContentToBottom={true}
                hasContentToRight={!isMobileScreen}
                className={isMobileScreen ? "w-[100%]" : ""}
              /> */}
              <Table>
                <Thead>
                  <Tr>
                    <Td
                      className={classNames(
                        "bg-[#072a48] min-w-[80px] text-white rounded-tl-md cursor-pointer"
                      )}
                    >
                      {t("plm.drivers.details.actions")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer max-w-[275px] min-w-[150px]"
                      )}
                    >
                      {t("plm.drivers.details.training-name")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer"
                      )}
                    >
                      {t("plm.drivers.details.completion-date")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer"
                      )}
                    >
                      {t("plm.drivers.details.category")}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer"
                      )}
                    >
                      {t("plm.drivers.details.institution")}
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {trainingHistory.map((training, index) => (
                    <Tr key={index}>
                      <Td>
                        <div className="flex">
                          {/* TODO: Protect these with permissions */}
                          <IconButton
                            className="mr-1"
                            onClick={() => handleDeleteTraining(training.id)}
                          >
                            <DeleteIcon sx={{ color: "red" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleEditTraining(training.id);
                            }}
                          >
                            <PencilIcon sx={{ color: "#072a48" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handlePrintTrainingCertificate(
                                training.id,
                                training
                              );
                            }}
                          >
                            <PrintIcon sx={{ color: "#072a48" }} />
                          </IconButton>
                        </div>
                      </Td>
                      <Td>{training.courseTitle}</Td>
                      <Td>
                        {training?.completionDate ? (
                          training.completionDate
                            .toDate()
                            .toString()
                            .slice(4, 15)
                        ) : (
                          <>
                            {!completionTrackers[training.id] ? (
                              <button
                                className="text-[#072a48]"
                                onClick={() => {
                                  setCompletionTrackers({
                                    ...completionTrackers,
                                    [training.id]: true,
                                  });
                                }}
                              >
                                Mark Complete
                              </button>
                            ) : (
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={completionDates[training.id]}
                                onChange={(date) => {
                                  setCompletionDates({
                                    ...completionDates,
                                    [training.id]: date,
                                  });
                                  const shouldProceed = window.confirm(
                                    "Your selected completion date is: " +
                                      date.toString().slice(4, 15) +
                                      ". Are you sure you want to save this date?"
                                  );
                                  if (shouldProceed) {
                                    const trainingsCollectionRef = doc(
                                      db,
                                      "driver-trainings",
                                      training.id
                                    );
                                    setDoc(
                                      trainingsCollectionRef,
                                      {
                                        courseComplete: true,
                                        completionDate: date,
                                      },
                                      { merge: true }
                                    );
                                  }
                                }}
                                className={
                                  "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                }
                              />
                            )}
                          </>
                        )}
                      </Td>
                      <Td>{training.courseCategory}</Td>
                      <Td>{training.trainingProvider}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {/* TODO: Remove the logic supporting this commented out popup, 
                  sorry I made a new one not knowing it existed */}
              {/* <FormPopup
                isOpen={openModalDriverTrainingModal}
                formSettings={formSettingsDriverTraining}
                onRequestClose={handleCloseDriverTrainingModal}
                title={t('plm.drivers.details.add-new-training')}
              /> */}
              <AddDriverTrainingModal
                open={openModalDriverTrainingModal}
                setOpen={setOpenModalDriverTrainingModal}
                onClose={(e) => {
                  setEditData(null);
                  setEditMode(false);
                  setOpenModalDriverTrainingModal(false);
                }}
                driver={driver}
                editMode={editMode}
                editData={editData}
                userInfo={userInfo}
                formData={formData}
                isTemporalUrl={isTemporalUrl}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
