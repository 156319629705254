import { doc, updateDoc } from "firebase/firestore";
import qs from "qs";
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { db } from "../../../firebase";
import DriverDetails from "./DriverDetails";
import DriversList from "./DriversList";
import updateDriverField from "../../utils/updateDriverField";
import isAlphaNumeric from "../../utils/isAlphaNumeric";
import RedButton from "../../assets/forms/RedButton";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import useDriversList from "./useDriversList";
import FormPopup from "../../generals/Popup/FormPopup";
import { isExpired } from "../../../utils/helpers";
import "../../styling/styles.css";
import { useMediaQuery } from "@mui/material";
import config from "../../../config/env";

export default function DriverExclusionsPanel(props) {
  const {
    formData,
    policyID,
    insuredInfo,
    userInfo,
    driverList,
    isEndoManager,
    getEndorsements,
    setShowDriverExclusions,
    showDriverExclusions
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [showDriverDetails, setShowDriverDetails] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const { t } = useTranslations();
  const driverHook = useDriversList({
    ...props,
    setSelectedDriver,
    setShowTable,
    setShowDriverDetails,
    setShowImport,
    setShowAddDriver,
    setShowDriverExclusions,
    isExclusionPanel: true,
  });
  const { setDeletionMode, setDeletionType, deletionMode } = driverHook;

  useEffect(() => {
    setDeletionType("exclusion");
  }, []);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const { REACT_APP_SOUTHERN_BACKEND } = config;

  const [dataUpdated, setDataUpdated] = useState(0);
  const [auditing, setAuditing] = useState(false);
  const agencyID = formData.agencyID;
  const insuredID = formData.insuredID;
  const validateDriver = (i, license, sct, driverID) => {
    setAuditing(true);
    if (isAlphaNumeric(driverList[i].licenseNumber) === false) {
      driverList[i].driverLookupStatus = "Invalid Driver Data";
      updateDriverField(
        policyID,
        driverID,
        "driverLookupStatus",
        "Invalid Driver Data",
        agencyID,
        insuredID
      );
      return;
    } else {
      axios
        .post(`${REACT_APP_SOUTHERN_BACKEND}/sct`, {
          driverLicenseNumber: license,
          driverSCTNumber: sct,
        })
        .then(async function (response) {
          const responseData = qs.parse(response.data);
          const values = [...driverList];

          if (responseData.error === "No se encontro información") {
            values[i].driverLookupStatus = "No information found";
            updateDriverField(
              policyID,
              driverID,
              "driverLookupStatus",
              "No information found",
              agencyID,
              insuredID
            );
          } else {
            values[i].driverLookupStatus = "Complete";
            values[i].driverLookupData = responseData;
            const policyRef = doc(db, "drivers", driverID);
            await updateDoc(policyRef, {
              driverLookupStatus: "Complete",
              driverLookupData: responseData,
            });
          }
          props.setDriverList(values);
          return values;
        })
        .then((values) => {
          setDataUpdated(dataUpdated + 1);
          setAuditing(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const [validationComplete, setValidationComplete] = useState(false);
  const validateAllDrivers = () => {
    setAuditing(true);
    driverList.forEach(async (driver, i) => {
      if (driver.licenseCountry !== "MX") return;
      if (driver.mxNMP === 0 || driver.mxNMP === "") {
        driverList[i].driverLookupStatus = "No SCT Provided";
        updateDriverField(
          policyID,
          driverList[i].id,
          "driverLookupStatus",
          "No SCT Provided",
          agencyID,
          insuredID
        );
        return console.log("no sct");
      } else if (
        i !== 0 &&
        driver.driverLookupStatus === "Incomplete" &&
        driverList[i - 1].driverLookupStatus !== "Incomplete"
      ) {
        validateDriver(
          [i],
          driverList[i].licenseNumber,
          driverList[i].mxNMP,
          driver.id
        );
      } else if (i === 0 && driver.driverLookupStatus === "Incomplete") {
        validateDriver(
          [i],
          driverList[i].licenseNumber,
          driverList[i].mxNMP,
          driver.id
        );
      } else {
        setValidationComplete(true);
      }
    });
  };

  const breadcrumbs = [{ label: formData.documentType }, { label: "Drivers" }];

  const breadcrumbsDetails = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Driver Details" },
  ];

  useEffect(() => {
    if (
      driverList.length > 0 &&
      driverList.filter((e) => e.driverLookupStatus === "Incomplete").length !==
        0
    )
      validateAllDrivers();
  }, [userInfo.role, dataUpdated, auditing]);

  const count = useMemo(() => {
    const effectiveElementsList = driverList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [driverList]);

  const titleDocumentType = formData?.documentType || ''

  return (
    <>
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {showTable && !showImport && !showAddDriver && (
            <>
              {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
              <h1
                className={`${
                  isEndoManager
                    ? "text-2xl mt-2.5 font-bold md:mb-0"
                    : "text-3xl mt-2 font-semibold md:mb-0"
                }`}
              >
                {showDriverExclusions ? 'Exclude Drivers' :
                !isEndoManager
                  ? `${titleDocumentType} Drivers (${count})`
                  : `${t("Endorse Drivers")}`}
              </h1>
              <p className="mt-2 mb-3">{t("plm.drivers.explanation")}</p>
              <div className="my-3">
                {!Boolean(deletionMode) ? (
                  <RedButton
                    text={"Exclude Specific Drivers"}
                    onClickFunction={() => {
                      setDeletionMode(true);
                    }}
                    hasContentToBottom={true}
                    className={isMobileScreen ? "w-[100%]" : ""}
                  />
                ) : null}
              </div>
            </>
          )}
          {showTable && (
            <>
              <p className="mb-2">{t("plm.drivers.see-driver-details")}</p>
              <DriversList
                formData={formData}
                auditing={auditing}
                validateAllDrivers={validateAllDrivers}
                policyID={policyID}
                userInfo={userInfo}
                insuredInfo={insuredInfo}
                driverList={driverList}
                setDriverList={props.setDriverList}
                setShowTable={setShowTable}
                setSelectedDriver={setSelectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                isEndoManager={isEndoManager}
                getEndorsements={getEndorsements}
                {...driverHook}
              />
              <FormPopup
                isOpen={driverHook.openModal}
                onRequestClose={driverHook.handleCloseModal}
                title={`Select the expiration date of the drivers
                ${driverHook.deletionMode ? " and the deletion reason" : ""}`}
                formSettings={driverHook.formSettings}
                loading={driverHook.modalSubmitting}
              />
            </>
          )}
          {showDriverDetails && (
            <>
              {!isEndoManager ? (
                <Breadcrumbs items={breadcrumbsDetails} />
              ) : null}
              <DriverDetails
                formData={formData}
                driverList={driverList}
                selectedDriver={selectedDriver}
                setShowDriverDetails={setShowDriverDetails}
                setShowTable={setShowTable}
                policyID={policyID}
                userInfo={userInfo}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
