import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import miniValidation from "./premiumCalculation/miniValidation";
import getTruckList from "./premiumCalculation/getTruckList";
import updateSystemLog from "./updateSystemLog";

const requestQuote = async (
  e,
  formData,
  policyID,
  userAuth,
  userState,
  insuredInfo,
  formDataGL,
  formDataAPD,
  formDataMTC
) => {
  e.preventDefault();

  const truckList = await getTruckList(policyID, formData);

  const isValid = miniValidation(
    truckList,
    formData,
    insuredInfo,
    formDataGL,
    formDataAPD,
    formDataMTC
  );

  let totalInsuredValue = 0;
  truckList.forEach((truck) => {
    const thisPolicyObject = truck.policiesDetails.filter(
      (policy) => policy.id === policyID && policy.status === "Active"
    )[0];
    const thisPolicyACV = thisPolicyObject.truckACV;
    totalInsuredValue += thisPolicyACV || 0;
    updateDoc(doc(db, "policy-applications", policyID), {
      TIV: totalInsuredValue,
    });
  });

  const sourceDocRef = doc(db, "policy-applications", policyID);
  const sourceDocSnapshot = await getDoc(sourceDocRef);
  const documentType = "Quote Request";
  const status = "Submitted";
  const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
  const agencyID = sourceDocSnapshot.data().agencyID;

  isValid &&
    (await updateDoc(doc(db, "policy-applications", policyID), {
      status,
      documentType,
      returnToAgentData: null,
    }));

  isValid &&
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
  isValid &&
    updateSystemLog(
      auth,
      policyID,
      "Quote Request Submitted for " + insuredInfo.company + ".",
      "Quote Request Submitted",
      { agencyID, policyID }
    );
};

export default requestQuote;
