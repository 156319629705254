import { doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import rateAllUnits from "./rateUnits/rateAllUnits";
import getSurplusLinesRate from "./premiumCalculation/getSurplusLinesRate";
import getStampFee from "./premiumCalculation/getStampFee";
import getTruckList from "./premiumCalculation/getTruckList";
import getTaxSchedule from "./getTaxSchedule";
import calculateFeesAndTaxes from "./calculateFeesAndTaxes";

const updatePolicy = async (
  policyRef,
  selectedQuote,
  adjustmentPercentage,
  doNotRerate
) => {
  if (!doNotRerate) {
    await updateDoc(policyRef, {
      selectedQuote,
      alPremiums: {
        adjustment: adjustmentPercentage || 0,
      },
    });
  }
};

const calculatePremiums = async (
  policyID,
  formData,
  e,
  selectedQuote,
  adjustmentPercentage,
  doNotRerate,
  isEndo,
  endoID,
  formDataGL
) => {
  const policyRef = doc(db, "policy-applications", policyID);

  // Asegúrate de que glID no es undefined o vacío
  const glID = formData?.glPolicyID || null;
  let glPolicyRef = null;

  // Solo intenta crear el glPolicyRef si glID es válido
  if (glID) {
    glPolicyRef = doc(db, "gl-policies", glID);
  }

  await updatePolicy(
    policyRef,
    selectedQuote,
    adjustmentPercentage,
    doNotRerate
  );

  const truckList = await getTruckList(policyID, formData);

  const [alAmount, ,] = await rateAllUnits(
    e,
    policyID,
    formData,
    truckList,
    adjustmentPercentage,
    isEndo,
    doNotRerate,
    endoID,
    isEndo
  );

  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };

  const debitOrCredit =
    formData?.alPremiums?.adjustmentDebitOrCredit || "Debit";
  const hasGLCoverage = formData?.hasGLCoverage;

  // TODO: Make this amount dynamic and able to be edited in the admin panel
  const glAmount = hasGLCoverage ? 2400 : 0;
  const alTaxInfo = calculateFeesAndTaxes(
    formData,
    alAmount,
    taxesAndFees,
    false,
    isEndo
  );
  const alTaxes = alTaxInfo.taxes;
  const alPremiums = {
    GWP: alAmount,
    otherFees: isEndo ? 0 : taxesAndFees.otherFees,
    processingFee: taxesAndFees.processingFee,
    stampFee: alTaxInfo.stampFee,
    surplusLinesTax: alTaxInfo.surplusLinesTax,
    adjustment: adjustmentPercentage || 0,
    debitOrCredit,
    ...alTaxes,
  };
  
  const glTaxInfo = calculateFeesAndTaxes(
    formData,
    glAmount,
    taxesAndFees,
    true,
    isEndo
  );
  const glTaxes = glTaxInfo.taxes;
  const glPremiums = {
    GWP: glAmount,
    processingFee: taxesAndFees.processingFee,
    stampFee: glTaxInfo.stampFee,
    surplusLinesTax: glTaxInfo.surplusLinesTax,
    ...glTaxes,
  };

  const totalALPremium =
    alAmount +
    (isEndo ? 0 : alPremiums.otherFees) +
    alPremiums.processingFeeAmount +
    alPremiums.stampTaxAmount +
    alPremiums.surplusLinesTaxAmount;
  const totalGLPremium =
    glAmount +
    glPremiums.processingFeeAmount +
    glPremiums.stampTaxAmount +
    glPremiums.surplusLinesTaxAmount;

  const updatedALData = {
    rerateRequired: false,
    isReferral: totalALPremium > 500000 ? true : false,
    referralApproved: null,
    alPremiums: { ...alPremiums, totalPremium: totalALPremium },
    selectedQuote,
  };

  const updatedGLData = {
    glPremiums: glAmount === 0 ? {} : { ...glPremiums, totalPremium: totalGLPremium },      
  };

  if (!doNotRerate) {
    await updateDoc(policyRef, updatedALData);
  }

  // Solo intenta actualizar el GL policy si hasGLCoverage es true y glPolicyRef existe
  if (!doNotRerate && hasGLCoverage && glPolicyRef) {
    await updateDoc(glPolicyRef, updatedGLData);
  }

  const grandTotal = totalALPremium + totalGLPremium;

  const returnData = [
    { ...alPremiums, totalPremium: alAmount },
    { ...glPremiums, totalPremium: glAmount },
    grandTotal,
  ];
  return returnData;
};

export default calculatePremiums;