const expiresWithin60Days = (date) => {
  const expirationDate = date.toDate(); // Assuming toDate() returns a Date object
  const currentDate = new Date();
  const sixtyDaysInMilliseconds = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds

  // Ensure the expiration date is in the future and within 60 days from the current date
  return (
    expirationDate.getTime() > currentDate.getTime() && // Expiration date is in the future
    (expirationDate.getTime() - currentDate.getTime()) <= sixtyDaysInMilliseconds
  );
};

export default expiresWithin60Days;
