import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateDoc } from "firebase/firestore";
import ApprovalButtons from "../../../../assets/forms/ApprovalButtons";
import ApiService from "../../../../../services/southern-api/apiService";
import useUsersState from "../../../../../Context/actions/useUsersState";
import updateSystemLog from "../../../../utils/updateSystemLog";
import { auth } from "../../../../../firebase";
import useGeneralState from "../../../../../Context/actions/useGeneralState";

const DriverFlagReasonsModal = ({
  open,
  onClose,
  title,
  text,
  addButtonText,
  driverDocRef,
  policyID,
  formData,
  insuredInfo,
}) => {
  const { user } = useUsersState();
  const { controlToast } = useGeneralState();

  const [reasons, setReasons] = useState([""]);

  const handleAddReason = () => {
    setReasons([...reasons, ""]);
  };

  const [otherReason, setOtherReason] = useState("");
  const handleChangeOtherReason = (value, reasons, setReasons) => {
    setOtherReason(value);
  };

  const handleChangeReason = (index, value, otherReason) => {
    let updatedReasons = [...reasons];
    updatedReasons[index] = value;
    setReasons(updatedReasons);
  };

  const handleRemoveReason = (index) => {
    const updatedReasons = [...reasons];
    updatedReasons.splice(index, 1);
    setReasons(updatedReasons);
  };

  const apiService = new ApiService("southern");

  function camelCaseToHumanFormat(camelCaseStr) {
    const humanFormat = camelCaseStr
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
    return humanFormat;
  }

  const [loadingFlag, setLoadingFlag] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoadingFlag(true);
      const adjustedReasons = reasons.includes("other")
        ? reasons.map((reason) => (reason === "other" ? otherReason : reason))
        : reasons;
      await updateDoc(driverDocRef, {
        flags: {
          flaggedByUnderwriter: true,
          flaggedByUnderwriterDate: new Date(),
          flaggedByUnderwriterReasons: adjustedReasons,
          flaggedPolicy: policyID,
        },
      });
      const reasonsFormatted = reasons.map((reason) =>
        camelCaseToHumanFormat(reason)
      );
      await apiService.sendEmailWithAddedFlag({
        createdAt: new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        description: reasonsFormatted.join(", "),
        user: user.first + " " + user.last,
        urlToRedirect: window.location.href,
      });
      updateSystemLog(
        auth,
        policyID,
        "Red Flag was created by" + (insuredInfo?.company || ""),
        "Red Flag was entered manually",
        { ...formData, policyID }
      );
      setLoadingFlag(false);
      controlToast(true, "Flag added successfully", "success");
      setReasons([""]);
      setOtherReason("");
      onClose();
    } catch (error) {
      setLoadingFlag(false);
      controlToast(true, "There was an error in the service", "error");
      console.error(error);
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const enabled = useMemo(() => {
    if (Boolean(reasons?.length === 0)) return false;
    const reasonsToVerify = reasons.every((reason) => Boolean(reason));
    return reasonsToVerify;
  }, [reasons]);

  return (
    <Modal open={open} onClose={null}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 400,
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Typography className="mt-2" variant="h6" gutterBottom>
            {title}
          </Typography>
          <p className="my-3">{text}</p>
          {reasons.map((reason, index) => (
            <Box
              key={index}
              display={reason === "other" ? "block" : "flex"}
              alignItems="center"
              mb={2}
            >
              <Select
                required
                label={`Reason ${index + 1}`}
                value={reason}
                onChange={(e) =>
                  handleChangeReason(index, e.target.value, otherReason)
                }
                fullWidth
              >
                <MenuItem value="yearsOfExperience">
                  Years of Experience
                </MenuItem>
                <MenuItem value="driversAge">Age</MenuItem>
                <MenuItem value="missingDocuments">Missing Documents</MenuItem>
                <MenuItem value="drivingRecord">Driving Record</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {reason === "other" && (
                <div className="flex mt-4">
                  <TextField
                    required
                    label="Other Reason"
                    value={otherReason}
                    onChange={(e) =>
                      handleChangeOtherReason(
                        e.target.value,
                        reasons,
                        setReasons
                      )
                    }
                    fullWidth
                    multiline
                    rows={4}
                  />
                  <IconButton onClick={() => handleRemoveReason(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
              {/* {reason !== "other" && (
                <IconButton onClick={() => handleRemoveReason(index)}>
                  <DeleteIcon />
                </IconButton>
              )} */}
            </Box>
          ))}
          {/* <Button
            variant="outlined"
            onClick={handleAddReason}
            sx={{ mb: 2, color: "#072a48", borderColor: "#072a48" }}
          >
            {addButtonText}
          </Button> */}
          <ApprovalButtons
            onApprove={null}
            onDecline={(e) => {
              onClose();
              setOtherReason("");
              setReasons([""]);
            }}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={!enabled || loadingFlag}
            disabledDecline={loadingFlag}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default DriverFlagReasonsModal;
