import { useState } from "react";
import ApiService from "../../services/southern-api/apiService";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import SingleCheckbox from "../assets/forms/SingleCheckbox";
import Popup from "../generals/Popup";
import useGeneralState from "../../Context/actions/useGeneralState";
import useTranslations from "../../Context/actions/useTranslations";

const ShareModal = ({
    newInsuredInfo,
    formData,
    openShareModal,
    setOpenShareModal,
    module
}) => {

  const { t } = useTranslations();
  const apiService = new ApiService();
  const { controlToast } = useGeneralState();
  const [shareOptions, setShareOptions] = useState({
    email: false,
    contactEmail: false,
    other: false,
    customEmail: "",
  });

  const handleShareChange = (name, value) => {
    const updatedOptions = {
      email: false,
      contactEmail: false,
      other: false,
      customEmail: "",
    };
    if (typeof value === "boolean") {
      Object.keys(shareOptions).forEach((key) => {
        if (key === "customEmail") {
          updatedOptions[key] = "";
        } else {
          updatedOptions[key] = false;
        }
      });
      updatedOptions[name] = value;
    } else {
      Object.keys(shareOptions).forEach((key) => {
        if (key === "customEmail") {
          updatedOptions[key] = value;
          updatedOptions["other"] = true;
        } else {
          updatedOptions[key] = false;
        }
      });
    }
    setShareOptions(updatedOptions);
  };

  const handleInsuredEditDrivers = async () => {
    try {
      const emails = [];

      if (
        !shareOptions["email"] &&
        !shareOptions["contactEmail"] &&
        !shareOptions["other"]
      ) {
        alert(t("plm.share-module.alert1"));
        return;
      }

      if (shareOptions["email"] && newInsuredInfo?.email) {
        emails.push(newInsuredInfo?.email);
      } else if (shareOptions["email"] && !newInsuredInfo?.email) {
        alert(t("plm.share-module.alert2"));
        return;
      }

      if (shareOptions["contactEmail"] && newInsuredInfo?.contactEmail) {
        emails.push(newInsuredInfo?.contactEmail);
      } else if (shareOptions["contactEmail"] && !newInsuredInfo?.contactEmail) {
        alert(t("plm.share-module.alert3"));
        return;
      }

      if (shareOptions["other"] && shareOptions["customEmail"]) {
        emails.push(shareOptions["customEmail"]);
      } else if (shareOptions["other"] && !shareOptions["customEmail"]) {
        alert(t("plm.share-module.alert4"));
        return;
      }

      const linkToRedirect = window?.location?.href
        ?.replace("policy", "temporarily")
        ?.replace("application", "temporarily");

      await apiService.renewalProcessModuleUpdate({
        link: linkToRedirect,
        policyData: formData,
        emails,
        module
      });
      controlToast(
        true,
        alert(t("plm.share-module.alert5")),
        "success"
      );
      setOpenShareModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup
      maxWidth="50%"
      isOpen={openShareModal}
      onRequestClose={() => setOpenShareModal(false)}
      zIndex={10000}
    >
      <p className="text-center">{t("plm.share-module.title")}</p>
      <SingleCheckbox
        name="email"
        label={`${t("plm.share-module.company-email-address")} ${
          newInsuredInfo?.email ? `(${newInsuredInfo?.email})` : `(${t("plm.share-module.not-provided")})`
        }`}
        checked={shareOptions["email"]}
        onChange={(e) => handleShareChange("email", e.target.checked)}
      />
      <SingleCheckbox
        name="contactEmail"
        label={`${t("plm.share-module.primary-contact-email")} ${
          newInsuredInfo?.contactEmail
            ? `(${newInsuredInfo?.contactEmail})`
            : "(not provided)"
        }`}
        checked={shareOptions["contactEmail"]}
        onChange={(e) => handleShareChange("contactEmail", e.target.checked)}
      />
      <SingleCheckbox
        name="other"
        label={t("plm.share-module.other")}
        checked={shareOptions["other"]}
        onChange={(e) => handleShareChange("other", e.target.checked)}
      />
      {shareOptions["other"] === true ? (
        <div className="mb-2">
          <label
            htmlFor="customEmail"
            className="block text-sm font-medium text-gray-600"
          >
            {t("plm.share-module.custom-email-address")}
          </label>
          <input
            name="customEmail"
            type="email"
            value={shareOptions["customEmail"]}
            onChange={(e) => handleShareChange("customEmail", e.target.value)}
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
      ) : null}

      <ApprovalButtons
        onApprove={handleInsuredEditDrivers}
        onDecline={() => setOpenShareModal(false)}
        titleAccept={t("plm.share-module.send")}
        titleDecline={t("plm.share-module.cancel")}
      />
    </Popup>
  );
};

export default ShareModal;
