import React from "react";
import calculateAge from "../../../utils/calculateAge";
import DocumentsTable from "../../../DocumentsTable";
import updateSystemLog from "../../../utils/updateSystemLog";
import { auth } from "../../../../firebase";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import {
  selectIfNewOrExistingSubcollection,
  validateIfBlacklisted,
  validateIfExists,
} from "../../../utils/validateIfBlacklisted";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";
import { setPolicyInfoForEndorsement } from "../setPolicyInfo";
import { multipleToEndorsements } from "../multipleToEndorsements";
// import ApiService from "../../../../services/southern-api/apiService";

export default function Step3({
  parentProps,
  driverData,
  setDriverData,
  fileObjectsMedicalForm,
  fileObjectsLicenseBack,
  fileObjectsLicenseFront,
  fileObjectsMvr,
  fileObjectsCaDriverAbstract,
  fileObjectsAdditionalForm,
  fileObjectsOtherDocuments,
  setFileObjectsMedicalForm,
  setFileObjectsLicenseBack,
  setFileObjectsLicenseFront,
  setFileObjectsMvr,
  setFileObjectsCaDriverAbstract,
  setFileObjectsAdditionalForm,
  setFileObjectsOtherDocuments,
  endoEffectiveDate,
  setSuccess,
  setPolicyInfo,
  goBack,
  setShowStep0,
  setShowStep1,
  setShowStep2,
  setShowStep3,
  appearATutorInput,
  formData,
  setEndoEffectiveDate,
  setLicenseExpirationDate,
  setDobDate,
  setProgresspercent,
}) {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations();

  const documentUploadInfo = [
    {
      name: "Drivers License (Front)",
      key: "driverLicenseFront",
      files: fileObjectsLicenseFront,
      setFiles: setFileObjectsLicenseFront,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    {
      name: "Drivers License (Back)",
      key: "driverLicenseBack",
      files: fileObjectsLicenseBack,
      setFiles: setFileObjectsLicenseBack,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    driverData.licenseCountry === "USA" && {
      name: "MVR",
      key: "MVR",
      files: fileObjectsMvr,
      setFiles: setFileObjectsMvr,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    driverData.licenseCountry === "CA" && {
      name: "CA Driver Abstract",
      // name: t("plm.drivers.ca-driver-abstract"),
      key: "caDriverAbstract",
      files: fileObjectsCaDriverAbstract,
      setFiles: setFileObjectsCaDriverAbstract,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    calculateAge(driverData?.dob) > 64 && {
      name: "Medical Long Form",
      key: "medicalLongForm",
      files: fileObjectsMedicalForm,
      setFiles: setFileObjectsMedicalForm,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    appearATutorInput && {
      name: "Driver Additional Documents",
      key: "additionalDocument",
      files: fileObjectsAdditionalForm,
      setFiles: setFileObjectsAdditionalForm,
      storageFile: `files/drivers/${driverData.licenseNumber}/additional-docs`,
      multiple: true,
    },
    {
      name: "Other Documents",
      key: "otherDocuments",
      files: fileObjectsOtherDocuments,
      setFiles: setFileObjectsOtherDocuments,
      storageFile: `files/drivers/${driverData.licenseNumber}/other-docs`,
      multiple: true,
    },
  ];

  const submitAddDriverStep3 = async (
    e,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess,
  ) => {
    e.preventDefault();
    const { agencyID, insuredID } = parentProps.formData;
    const verifiedInBlacklistDrivers = await validateIfBlacklisted(
      [driverData],
      true,
      auth?.currentUser
    );
    const someDriverIsBlacklisted = verifiedInBlacklistDrivers.some(
      (possibleBlacklistedDriver) =>
        Boolean(possibleBlacklistedDriver.blacklist)
    );
    if (someDriverIsBlacklisted) {
      controlToast(true, "This user is blacklisted", "error");
      return;
    }
    const verifiedInExistingData = await validateIfExists(
      verifiedInBlacklistDrivers,
      agencyID,
      insuredID,
      "drivers"
    );

    const newPayload = verifiedInExistingData[0]

    if (fileObjectsLicenseFront[0])
      newPayload["driverLicenseFront"] = fileObjectsLicenseFront[0];
    if (fileObjectsLicenseBack[0])
      newPayload["driverLicenseBack"] = fileObjectsLicenseBack[0];
    if (fileObjectsMvr[0]) newPayload["MVR"] = fileObjectsMvr[0];
    if (fileObjectsCaDriverAbstract[0])
      newPayload["caDriverAbstract"] = fileObjectsCaDriverAbstract[0];
    if (fileObjectsMedicalForm[0])
      newPayload["medicalLongForm"] = fileObjectsMedicalForm[0];
    if (fileObjectsAdditionalForm[0])
      newPayload["additionalDocument"] = fileObjectsAdditionalForm;
    if (fileObjectsOtherDocuments[0])
      newPayload["otherDocuments"] = fileObjectsOtherDocuments;

    const payloadResult = setPolicyInfo(
      {
        ...newPayload,
        dateCreated: new Date(),
      },
      {
        ...parentProps.formData,
        policyEffectiveDate: endoEffectiveDate,
        glPolicyNo: parentProps?.formData?.glPolicyNo,
        alPolicyNo: parentProps?.formData?.alPolicyNo,
        policyID: policyIDToUse,
        documentType: documentTypeToUse,
        documentSource:
          documentTypeToUse === "Policy" ?
          "Endorsement" : "Policy",
        policyStatus: parentProps?.formData?.status,
        status:
          !renewalProcess && documentTypeToUse === "Application"
            ? "Active"
            : "Pending",
        agencyID,
        insuredID,
      },
      !renewalProcess && documentTypeToUse === "Application"
        ? "direct-addition"
        : "endo-addition",
      false,
      endoEffectiveDate
    );

    newPayload["driverFirst"] = newPayload.driverFirst.toUpperCase();
    newPayload["driverLast"] = newPayload.driverLast.toUpperCase();

    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "drivers"
    );

    if (documentTypeToUse === "Policy") {
      const payload = {
        ...payloadResult,
        id: response.id,
      };

      const payloadForEndo = setPolicyInfoForEndorsement(payload, policyIDToUse)

      const endoPolicyID =
        !renewalProcess ?
        parentProps.formData?.id :
          (
            parentProps.formData.documentType === "Policy" ?
            parentProps.formData?.id :
            parentProps.formData.renewalPolicyID
          )

      await multipleToEndorsements(
        endoPolicyID,
        endoEffectiveDate,
        "Add Drivers",
        [payloadForEndo],
        parentProps.insuredInfo,
        parentProps.formData,
        parentProps.userInfo,
        parentProps.getEndorsements,
        [],
        null,
        null,
        null
      );
    }

    const isTemporalUrl =
      typeof window !== "undefined"
        ? window?.location?.pathname?.includes("temporarily")
        : false;

    if (!isTemporalUrl) {
      updateSystemLog(
        auth,
        policyIDToUse,
        "Driver (" +
          newPayload.driverFirst +
          " " +
          newPayload.driverLast +
          ") Added To Application For " +
          parentProps.insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Driver Added",
        newPayload
      );
    }
  };

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      parentProps.formData.status === "In-Renewal" &&
      parentProps.formData.documentType === "Application";
    const isRenewalPolicy =
      parentProps.formData.status === "In-Renewal" &&
      parentProps.formData.documentType === "Policy";
    const notARenewalProcess = parentProps.formData.status !== "In-Renewal";
    
    try {
      if (notARenewalProcess) {
        await submitAddDriverStep3(
          e,
          parentProps.policyID,
          parentProps.formData.documentType,
          false
        );
        const isPolicy = parentProps.formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Driver endorsement successfully!"
          : "You created a Driver successfully!";
        console.log('message', message)
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await submitAddDriverStep3(
          e,
          parentProps.policyID,
          "Application",
          true
        );
        await submitAddDriverStep3(
          e,
          parentProps.formData.renewalPolicyID,
          "Policy",
          true,
        );
        const message = "You created an Add Driver endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await submitAddDriverStep3(
          e,
          parentProps.formData.renewalApplicationID,
          "Application",
          true
        );
        await submitAddDriverStep3(
          e,
          parentProps.policyID,
          "Policy",
          true,
        );
        const message = "You created an Add Driver endorsement successfully!";
        controlToast(true, message, "success");
      }

      // eslint-disable-next-line no-restricted-globals
      const userResponse = confirm("Would you like to add another driver?")
      if (userResponse) {
        setShowStep0(true);
        setShowStep1(false);
        setShowStep2(false);
        setShowStep3(false);
        setDriverData({});
        setEndoEffectiveDate(new Date())
        setLicenseExpirationDate(new Date())
        setDobDate(new Date())
        setProgresspercent(0)
      } else {
        setDriverData({});
        setSuccess(true);
        goBack(e);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <section>
      <DocumentsTable documentUploadInfo={documentUploadInfo} formData={formData} />
      <ApprovalButtons
        onApprove={mirrorLogic}
        handleAsSubmit={false}
        onDecline={(e) => {
          setShowStep3(false);
          setShowStep2(true);
        }}
        titleAccept="Submit"
        titleDecline="Go Back"
      />
    </section>
  );
}
