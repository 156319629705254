import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_INFO_STATUS } from "../../utils/constants";
import { SIGN_IN_ROUTE } from "../../utils/routes";
import { db } from "../../firebase";
import { setDoc, doc, getDoc, addDoc, collection } from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import { UserAuth } from "../../Context/AuthContent";
import ApiService from "../../services/southern-api/apiService";

const useEditProfile = () => {

  const { user } = UserAuth();
  const userID = user.uid;
  const auth = getAuth();
  const [loading, setLoading] = useState(false)
  const { UNAPPOINTED_STATUS } = USER_INFO_STATUS;
  const [status, setStatus] = useState(UNAPPOINTED_STATUS);
  const [initialData, setInitialData] = useState({})

  const navigate = useNavigate();

  // const apiService = new ApiService()

  // const sendEmail = async (data) => {
  //   const {
  //     first,
  //     last,
  //     phone,
  //     company,
  //     title
  //   } = data
  //   try {
  //     await apiService.sendEmailToNewCreatedUser({
  //       to: "colt@southernstarmga.com",
  //       cc: "amarino@southernstarmga.com",
  //       name: first + " " + last,
  //       title: title,
  //       company: company,
  //       phone: phone,
  //       email: auth.currentUser.email,
  //     })
  //   } catch (error) {
  //     throw error
  //   }
  // };

  const [gotExistingData, setGotExistingData] = useState(false);
  const getExistingData = async (e) => {
    try {
      setGotExistingData(false);
      const docRef = doc(db, "users", userID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const {
          first,
          last,
          title,
          company,
          phone,
          profilePhoto,
          status,
          agencyID
        } = docSnap?.data() || {}

        const initialData = {
          first,
          last,
          title,
          company,
          phone
        }

        const agencyDocRef = doc(db, "agencies", agencyID);
        const agencyDocSnap = await getDoc(agencyDocRef);

        if (agencyDocSnap.exists()) {
          const { name } = agencyDocSnap?.data() || {}
          initialData['company'] = name
        }
        
        if (profilePhoto) initialData['profilePhoto'] = [profilePhoto]
        setInitialData(initialData)
        if (status) setStatus(status);
      } else {
        console.log("No such document!");
      }
      setGotExistingData(true);
    } catch (error) {
      console.log("Error getting document:", error);
      setGotExistingData(true);
    }
  };

  const setProfile = async (e, data) => {
    e.preventDefault();
    try {
      setLoading(true)
      const {
        first,
        last,
        company,
        title,
        profilePhoto
      } = data
      const payload = { ...data }
      if (!profilePhoto?.length > 0) {
        payload['profilePhoto'] = ''
      } else {
        payload['profilePhoto'] = profilePhoto[0]
      }
      payload['email'] = auth.currentUser.email
      payload['status'] = status
      payload['role'] = 'Agent'
      payload['nextStep'] = 'Review Application'
      await setDoc(
        doc(db, "users", userID),
        payload,
        { merge: true }
      );
      // await sendEmail(data);
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "New User Created: " +
          first +
          " " +
          last +
          ", " +
          title +
          " of " +
          company,
      });
      await makeChange(first + " " + last);
      setLoading(false)
      navigate(SIGN_IN_ROUTE);
    } catch (error) {
      throw error
    }
  };

  const inputs = useMemo(() => {
    const {
      first,
      last,
      title,
      company,
      phone,
      profilePhoto
    } = initialData
    return [{
      label: "First Name",
      type: "text",
      name: "first",
      defaultValue: first ? first : ''
    },
    {
      label: "Last Name",
      type: "text",
      name: "last",
      defaultValue: last ? last : ''
    },
    {
      label: "Title",
      type: "text",
      name: "title",
      defaultValue: title ? title : ''
    },
    {
      label: "Company",
      type: "text",
      name: "company",
      defaultValue: company ? company : '',
      disabled: true
    },
    {
      label: "Phone Number",
      type: "number",
      name: "phone",
      defaultValue: phone ? phone : ''
    },
    {
      label: "Profile Photo",
      type: "file",
      storageFile: `files/users/${userID}`,
      name: "profilePhoto",
      defaultValue: profilePhoto ? profilePhoto : [],
      dissapearWhenImgExists: true,
      showPastingOption: true
    }]
  }, [initialData])

  const formSettings = {
    title: "Edit Your Account Details",
    onSubmit: setProfile,
    buttonLabel: "Save",
    inputs,
  };

  const makeChange = async (newName) => {
    try {
      await updateProfile(auth.currentUser, {
        displayName: newName,
      })
      console.log(auth.currentUser?.displayName + `'s Profile Updated`);
    } catch (error) {
      alert("Error");
    }
  };

  useEffect(() => {
    if (userID) {
      !gotExistingData && getExistingData();
    }
  }, [gotExistingData, userID]);

  return {
    setProfile,
    formSettings,
    loading,
    initialData
  };
};

export default useEditProfile;
