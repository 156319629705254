import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Table, Tr, Th, Td } from "react-super-responsive-table";
import { db } from "../../firebase";
import Breadcrumbs from "../assets/Breadcrumbs";
import Pagination from "../assets/lists/DynamicTable/Pagination";

export default function DriversBlacklist() {
  const [drivers, setDrivers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  const getDrivers = async () => {
    try {
      const blackListRef = collection(db, "drivers-blacklist");
      const snapshot = await getDocs(blackListRef);
      const driversData = [];
      snapshot.forEach((doc) => {
        driversData.push({ id: doc.id, ...doc.data() });
      });
      setDrivers(driversData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);

  const sortDrivers = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedDrivers = [...drivers].sort((a, b) => {
      const valueA = a[key]?.toDate ? a[key]?.toDate() : a[key] || "";
      const valueB = b[key]?.toDate ? b[key]?.toDate() : b[key] || "";

      if (valueA instanceof Date && valueB instanceof Date) {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      } else if (!isNaN(valueA) && !isNaN(valueB)) {
        return direction === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      } else {
        return direction === "asc"
          ? valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase())
          : valueB.toString().toLowerCase().localeCompare(valueA.toString().toLowerCase());
      }
    });

    setDrivers(sortedDrivers);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const filteredDrivers = drivers.filter((driver) => {
    const fieldsToSearch = [
      driver.driverFirst,
      driver.driverLast,
      driver.dob?.toDate().toString(),
      driver.licenseCountry,
      driver.licenseNumber,
      driver.blacklistReason,
      driver.dateBlacklisted?.toDate().toString(),
      driver.blacklistExpirationDate?.toDate().toString(),
    ];
    return fieldsToSearch.some((field) =>
      field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const pageCount = Math.ceil(filteredDrivers.length / itemsPerPage);
  const paginatedDrivers = filteredDrivers.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  const breadcrumbs = [
    { label: "Loss Control" },
    { label: "Drivers Blacklist" },
  ];

  return (
    <div className="my-4">
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold mb-3">Drivers Blacklist</h1>
      <p className="mt-3 mb-4">
        A list of all drivers who have been blacklisted.
      </p>
      <p className="mt-3 mb-4">
        Blacklist Count: {filteredDrivers.length} drivers
      </p>
      <input
        type="text"
        placeholder="Search by driver name, DOB, license country, license number, blacklist reason, date blacklisted, or date blacklisting expiration"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-2 p-2 border rounded w-full"
      />
      <Table>
        <thead>
          <Tr>
            <Th
              className="bg-[#072a48] p-2 text-white rounded-tl-md cursor-pointer"
              onClick={() => sortDrivers("driverFirst")}
            >
              Driver Name
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer"
              onClick={() => sortDrivers("dob")}
            >
              DOB
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer"
              onClick={() => sortDrivers("licenseCountry")}
            >
              License Country
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer"
              onClick={() => sortDrivers("licenseNumber")}
            >
              License Number
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer"
              onClick={() => sortDrivers("blacklistReason")}
            >
              Blacklist Reason
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer"
              onClick={() => sortDrivers("dateBlacklisted")}
            >
              Date Blacklisted
            </Th>
            <Th
              className="bg-[#072a48] p-2 text-white cursor-pointer rounded-tr-md"
              onClick={() => sortDrivers("blacklistExpirationDate")}
            >
              Date Blacklisting Expiration
            </Th>
          </Tr>
        </thead>
        <tbody>
          {paginatedDrivers.map((driver) => (
            <Tr key={driver.id}>
              <Td>
                {driver.driverFirst} {driver.driverLast}
              </Td>
              <Td>
                {driver?.dob?.toDate().toString().slice(4, 15)}
              </Td>
              <Td>{driver.licenseCountry}</Td>
              <Td>{driver.licenseNumber}</Td>
              <Td>{driver.blacklistReason}</Td>
              <Td>
                {driver.dateBlacklisted
                  ? driver.dateBlacklisted.toDate().toString().slice(4, 15)
                  : ""}
              </Td>
              <Td>
                {driver.blacklistExpirationDate
                  ? driver.blacklistExpirationDate.toDate().toString().slice(4, 15)
                  : ""}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}
