const handleChangeCommodity = (i, e, formData, policyID, insuredInfo, setInsuredInfo) => {
  const values = [...(insuredInfo.commodityFields || [])];
  while (values.length <= i) {
    values.push({});
  }
  values[i] = { ...values[i], [e.target.name]: e.target.value };
  setInsuredInfo({
    ...insuredInfo,
    commodityFields: values,
  });
};

export default handleChangeCommodity;
