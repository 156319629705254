import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  LocalMall,
  Person,
  DirectionsCar,
  LocalShipping,
  Assignment,
  Print,
} from "@mui/icons-material";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import useTranslations from "../../Context/actions/useTranslations";
import config from "../../config/env";
import { Link } from "react-router-dom";
import { TEMPORARILY_CLIENT_ROUTE } from "../../utils/routes";
import useUsersState from "../../Context/actions/useUsersState";
import useClientState from "../../Context/actions/useClientState";

const oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

const Client = ({ userInfo }) => {
  console.log("user Info: ", userInfo);
  const insuredID = userInfo?.insuredID;
  const agencyID = userInfo?.agencyID;
  const [insuredCompanyData, setInsuredCompanyData] = useState(null);
  const getInsuredCompanyData = async () => {
    const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);
    const insuredDoc = await getDoc(insuredRef);
    console.log("insuredDoc: ", insuredDoc.data());
    return insuredDoc.data();
  };
  // real time Listener for company data
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "agencies", agencyID, "insureds", insuredID),
      (doc) => {
        console.log("doc.data(): ", doc.data());
        setInsuredCompanyData(doc.data());
      }
    );
    return unsubscribe;
  }, [insuredID, agencyID]);

  const isAdmin = userInfo?.admin || false;
  const { t, changeLanguage } = useTranslations();
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const { user } = useUsersState();
  const { setClientRouteData } = useClientState();
  const email = user?.email;

  const options = [
    {
      icon: <Print fontSize="large" />,
      label: t("client.instructions.print-policy"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=print-policy&email=${email}`,
      disabledRule: selectedPolicy?.documentType !== "Policy",
    },
    {
      icon: <Person fontSize="large" />,
      label: t("client.instructions.drivers"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=drivers&email=${email}`,
    },
    {
      icon: <DirectionsCar fontSize="large" />,
      label: t("client.instructions.power-units"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=power-units&email=${email}`,
    },
    {
      icon: <LocalShipping fontSize="large" />,
      label: t("client.instructions.trailers"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=trailers&email=${email}`,
    },
    {
      icon: <Assignment fontSize="large" />,
      label: t("client.instructions.questionnaire"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=questionnaire&email=${email}`,
    },
    {
      icon: <LocalMall fontSize="large" />,
      label: t("client.instructions.commodities"),
      href: `${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=commodities&email=${email}`,
    },
  ];

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config;

  useEffect(() => {
    const fetchPolicies = async () => {
      const policiesCollection = collection(db, "policy-applications");

      const q = query(
        policiesCollection,
        where("agencyID", "==", userInfo.agencyID),
        where("insuredID", "==", userInfo.insuredID)
      );

      const unsubscribe = onSnapshot(q, async (policiesSnapshot) => {
        const policiesList = await Promise.all(
          policiesSnapshot.docs.map(async (document) => {
            const policyData = {
              id: document.id,
              ...document.data(),
            };
            const insuredRef = doc(
              db,
              "agencies",
              REACT_APP_LICONA_INSURANCE_GROUP_ID,
              "insureds",
              policyData.insuredID
            );
            const insuredDoc = await getDoc(insuredRef);
            return {
              ...policyData,
              company: insuredDoc.data()?.company || "Unknown",
            };
          })
        );
        // set selected ID to the most recent policy judging by the effectiveDate.toDate()
        policiesList.sort(
          (a, b) => b.effectiveDate.toDate() - a.effectiveDate.toDate()
        );
        setSelectedPolicy(policiesList[0]);
        setPolicies(policiesList);
        setLoading(false);
      });
      return () => unsubscribe();
    };

    fetchPolicies();
  }, []);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selected = policies.find((policy) => policy?.id === selectedId);
    setClientRouteData(selected);
    setSelectedPolicy(selected);
  };

  useEffect(() => {
    if (!loading && policies?.length > 0) {
      setClientRouteData(policies[0]);
      setSelectedPolicy(policies[0]);
    }
  }, [loading, policies]);

  return (
    <Box
      sx={{
        display: "flex",
        mb: 2,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {insuredCompanyData?.company || ""}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {t("client.instructions.title")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          mb: 2,
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => changeLanguage("en")}
        >
          <img
            src="https://flagcdn.com/us.svg"
            alt="English"
            style={{ width: 24, height: 16, marginRight: 8 }}
          />
          <Typography>English</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => changeLanguage("es")}
        >
          <img
            src="https://flagcdn.com/mx.svg"
            alt="Español"
            style={{ width: 24, height: 16, marginRight: 8 }}
          />
          <Typography>Español</Typography>
        </Box>
      </Box>
      <div className="w-full mb-3">
        <div className="mb-2 text-left">
          {/* Instructions Section */}
          <Typography variant="body1">
            {/* TODO: Translate this */}
            Viewing details for {selectedPolicy?.documentType || ""}:{" "}
          </Typography>
        </div>
        <FormControl fullWidth>
          {/* Dropdown */}
          <InputLabel id="policy-select-label">Select Policy</InputLabel>
          <Select
            labelId="policy-select-label"
            value={selectedPolicy?.id || ""}
            onChange={handleChange}
            className="w-full"
            displayEmpty
          >
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
                &nbsp;Loading...
              </MenuItem>
            ) : (
              policies
                .filter((policy) => policy.alPolicyNo) // Filter out policies without an alPolicyNo
                .map((policy) => (
                  <MenuItem key={policy.inspasID} value={policy.id}>
                    {policy.alPolicyNo} ({policy.status}) -{" Effective "}
                    {policy.effectiveDate.toDate().toLocaleDateString()}
                  </MenuItem>
                ))
            )}
          </Select>
        </FormControl>
      </div>

      <Grid container spacing={3} justifyContent="center">
        {options.map((option) => (
          <Grid item xs={12} sm={6} md={4} key={option.label}>
            <Link to={option.href}>
              <Card
                sx={{
                  boxShadow: 4,
                  borderRadius: 3,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <fieldset disabled={option.disabledRule}>
                <CardActionArea>
                  <CardContent
                    sx={{
                      textAlign: "center",
                      padding: 3,
                    }}
                  >
                    {option.icon}
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        fontWeight: "medium",
                        color: "#555",
                      }}
                    >
                      {option.label}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </fieldset>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Client;
