import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ApiService from "../../services/southern-api/apiService";

const apiService = new ApiService("southern");

export const updatePolicyFieldLosses = async (
  policyID,
  payload
) => {
  const isTemporalUrl =
    typeof window !== "undefined"
      ? window?.location?.pathname?.includes("temporarily")
      : false;
  const policyRef = doc(db, "policy-applications", policyID);
  if (!isTemporalUrl) {
    await setDoc(
      policyRef,
      payload,
      { merge: true }
    );
  }
};

export const resetPolicyField = async (policyID, fieldName, value) => {
  const policyRef = doc(db, "policy-applications", policyID);
  const updatedValues = {
    [fieldName]: value
  };
  await updateDoc(policyRef, updatedValues);
};

const updatePolicyField = async (
  policyID,
  fieldName,
  value,
  formData,
  setFormData
) => {
  const isTemporalUrl =
    typeof window !== "undefined"
      ? window?.location?.pathname?.includes("temporarily")
      : false;
  const policyRef = doc(db, "policy-applications", policyID);
  if (!isTemporalUrl) {
    await setDoc(
      policyRef,
      {
        [fieldName]: value,
      },
      { merge: true }
    );
  } else {
    if (setFormData) {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
    await apiService.renewalProcessUpdatePolicyField({
      policyID,
      payload: {
        [fieldName]: value,
      },
    });
  }
};

export const updatePolicyInsuredField = async (
  policyID,
  fieldName,
  value,
  formData,
  insuredInfo,
  setInsuredInfo
) => {
  const isTemporalUrl =
    typeof window !== "undefined"
      ? window?.location?.pathname?.includes("temporarily")
      : false;
  const insuredRef = doc(db, "agencies", formData.agencyID, "insureds", formData.insuredID);
  if (!isTemporalUrl) {
    await setDoc(
      insuredRef,
      {
        [fieldName]: value,
      },
      { merge: true }
    );
  } else {
    if (setInsuredInfo) {
      setInsuredInfo({
        ...insuredInfo,
        [fieldName]: value,
      });
    }
    await apiService.renewalProcessUpdatePolicyField({
      policyID,
      payload: {
        [fieldName]: value,
      },
    });
  }
};

export default updatePolicyField;
