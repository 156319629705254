import React from "react";
import useTranslations from "../../../Context/actions/useTranslations";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import checkDriverForFlags from "../../utils/checkDriverForFlags";
import { useMediaQuery } from "@mui/material";

export default function DriverDetailFlags({ driver, singleIcon }) {

  const { t } = useTranslations()
  const redFlags = checkDriverForFlags(driver, t)

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {!singleIcon ? (
        <div>
          {redFlags.map((flag, index) => (
            <p key={index} className="text-[#8B0000] font-bold mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className={"bi bi-flag-fill d-inline mr-1" }
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
              </svg>
              {flag}
            </p>
          ))}
        </div>
      ) : (
        <p>
          <Tooltip
            title={
              <Box>
                {redFlags.map((flag, index) => (
                  <Typography key={index} component={redFlags.length < 2 ? "" : "li"}>
                    {flag}
                  </Typography>
                ))}
              </Box>
            }
            placement="top"
            arrow
            disableHoverListener={isMobileScreen}
            enterTouchDelay={100}
            leaveTouchDelay={3000} 
          >
            {redFlags.length > 0 ? (
              <IconButton sx={{ color: "#8B0000" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-flag-fill d-inline"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                </svg>
              </IconButton>
            ) : null}
          </Tooltip>
        </p>
      )}
    </>
  );
}
