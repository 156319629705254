import React, { useState } from "react";
import useTranslations from "../../Context/actions/useTranslations";
import Pagination from "../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../generals/TableSkeleton";
import PeriodFilters from "./PeriodFilters";
import useGeneralState from "../../Context/actions/useGeneralState";
import { PoliciesTable } from "./Tables/PoliciesTable";
import { EndorsementsTable } from "./Tables/EndorsementsTable";
import {
  handleCopyToClipboardBordereaux,
  handleCopyToClipboardEndo,
  handleCopyToClipboardPolicy
} from "./Tables/utils";
import { BordereauxTable } from "./Tables/BordereauxTable";
import {
  handleBordereauxMainSearch,
  handleBordereauxVinSearch,
  handleEndorsementSearch,
  handlePolicySearch
} from "./Tables/tableFns";

const policysPerPage = 10;

function ReportList({
  itemList,
  setItemList,
  userInfo,
  loading,
  setLoading,
  section,
  setSection,
  bordereauxType,
  setBordereauxType
}) {
  const { t } = useTranslations();

  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const { controlToast } = useGeneralState()
  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");
  const [didRequest, setDidRequest] = useState(false);
  const [type, setType] = useState("effective-date");
  const [program, setProgram] = useState("");
  const [status, setStatus] = useState("");
  const [startDateUsed, setStartDateUsed] = useState(null);
  const [endDateUsed, setEndDateUsed] = useState(null);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  
    const sortedItemList = [...itemList].sort((a, b) => {
      const valueA = a[key]?.toDate?.() ?? a[key] ?? "";
      const valueB = b[key]?.toDate?.() ?? b[key] ?? "";
  
      // Convert to string if types differ
      const normalizedValueA = typeof valueA !== typeof valueB ? valueA.toString() : valueA;
      const normalizedValueB = typeof valueA !== typeof valueB ? valueB.toString() : valueB;
  
      if (normalizedValueA instanceof Date && normalizedValueB instanceof Date) {
        return direction === "asc" ? normalizedValueA - normalizedValueB : normalizedValueB - normalizedValueA;
      } else if (!isNaN(normalizedValueA) && !isNaN(normalizedValueB)) {
        return direction === "asc"
          ? Number(normalizedValueA) - Number(normalizedValueB)
          : Number(normalizedValueB) - Number(normalizedValueA);
      } else {
        return direction === "asc"
          ? normalizedValueA.toString().toLowerCase().localeCompare(normalizedValueB.toString().toLowerCase())
          : normalizedValueB.toString().toLowerCase().localeCompare(normalizedValueA.toString().toLowerCase());
      }
    });
  
    setItemList(sortedItemList);
  };

  let sortedData = itemList.filter((item) => {
    if (!item) return ''
    if (section === "policies") {
      return `${handleCopyToClipboardPolicy(item)}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    } else if (section === "endorsements") {
      return `${handleCopyToClipboardEndo(item)}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    } else if (section === "bordereaux") {
      return `${handleCopyToClipboardBordereaux(item, null, bordereauxType)}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    } else {
      return ''
    }
  });

  // const sortedData = [...filteredData].sort((a, b) => {
  //   if (sortConfig.direction === "asc") {
  //     return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
  //   } else {
  //     return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  //   }
  // });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * policysPerPage;
  const currentItems = sortedData.slice(offset, offset + policysPerPage);

  const pageCount = Math.ceil(sortedData.length / policysPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (event) => {
    handlePageChange(0);
    setSearchTerm(event.target.value);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const onSubmit = async (e, data) => {
    e.preventDefault();
    if (section === "policies") {
      handlePolicySearch(
        data,
        program,
        controlToast,
        setLoading,
        setItemList,
        setStartDateUsed,
        setEndDateUsed,
        setDidRequest,
        userInfo,
        type,
        null,
        status
      );
    } else if (section === "endorsements") {
      handleEndorsementSearch(
        data,
        program,
        controlToast,
        setLoading,
        setItemList,
        setStartDateUsed,
        setEndDateUsed,
        setDidRequest,
        userInfo,
        type,
        null
      );
    } else if (section === "bordereaux") {
      if (bordereauxType === "main") {
        handleBordereauxMainSearch(
          data,
          program,
          controlToast,
          setLoading,
          setItemList,
          setStartDateUsed,
          setEndDateUsed,
          setDidRequest,
          userInfo,
          type,
          bordereauxType
        );
      } else if (bordereauxType === "vin") {
        handleBordereauxVinSearch(
          data,
          program,
          controlToast,
          setLoading,
          setItemList,
          setStartDateUsed,
          setEndDateUsed,
          setDidRequest,
          userInfo,
          type,
          bordereauxType
        );
      }
    }
  };

  return (
    <main>
      <PeriodFilters
        onSubmit={onSubmit}
        section={section}
        itemList={itemList}
        type={type}
        startDateUsed={startDateUsed}
        endDateUsed={endDateUsed}
        program={program}
        status={status}
        bordereauxType={bordereauxType}
        setSection={setSection}
        setItemList={setItemList}
        setType={setType}
        setStartDateUsed={setStartDateUsed}
        setEndDateUsed={setEndDateUsed}
        setProgram={setProgram}
        setStatus={setStatus}
        setBordereauxType={setBordereauxType}
      />
      {!didRequest && !loading ? (
        <p>
          Search your {section} to filter periods or load all issued {section}
        </p>
      ) : loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : itemList.length === 0 ? (
        <p>No {section} were found with these properties</p>
      ) : (
        <>
          <div className="mb-2">
            <h2 className="text-2xl font-semibold mb-1">List of {section}</h2>
          </div>
          <input
            type="text"
            placeholder={t("manage-policies.search")}
            value={searchTerm}
            onChange={handleSearch}
            className="mb-2 p-2 border rounded w-full"
          />
          <div className="overflow-x-auto">
            {section === "policies" ? (
              <PoliciesTable
                currentItems={currentItems}
                handleSort={handleSort}
                sortConfig={sortConfig}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredRow={hoveredRow}
              />
            ) :
            section === "endorsements" ? (
              <EndorsementsTable
                currentItems={currentItems}
                handleSort={handleSort}
                sortConfig={sortConfig}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredRow={hoveredRow}
              />
            ) : (
              <BordereauxTable
                currentItems={currentItems}
                handleSort={handleSort}
                sortConfig={sortConfig}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredRow={hoveredRow}
                bordereauxType={bordereauxType}
              />
            )
          }
          </div>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </main>
  );
}

export default ReportList;









// import React, { useState } from "react";
// import useTranslations from "../../Context/actions/useTranslations";
// import Pagination from "../assets/lists/DynamicTable/Pagination";
// import TableSkeleton from "../generals/TableSkeleton";
// import PeriodFilters from "./PeriodFilters";
// import useGeneralState from "../../Context/actions/useGeneralState";
// import { PoliciesTable } from "./Tables/PoliciesTable";
// import { EndorsementsTable } from "./Tables/EndorsementsTable";
// import { handleCopyToClipboardBordereaux, handleCopyToClipboardEndo, handleCopyToClipboardPolicy } from "./Tables/utils";
// import { BordereauxTable } from "./Tables/BordereauxTable";
// import {
//   handleBordereauxMainSearch,
//   handleBordereauxVinSearch,
//   handleEndorsementSearch,
//   handlePolicySearch
// } from "./Tables/tableFns";

// const policysPerPage = 10;

// function ReportList({
//   itemList,
//   setItemList,
//   userInfo,
//   loading,
//   setLoading,
//   section,
//   setSection,
//   bordereauxType,
//   setBordereauxType
// }) {
//   const { t } = useTranslations();

//   const initialSortConfig = {
//     key: "",
//     direction: "asc",
//   };

//   const { controlToast } = useGeneralState()
//   const [sortConfig, setSortConfig] = useState(initialSortConfig);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [didRequest, setDidRequest] = useState(false);
//   const [type, setType] = useState("effective-date");
//   const [program, setProgram] = useState("");
//   const [status, setStatus] = useState("");
//   const [startDateUsed, setStartDateUsed] = useState(null);
//   const [endDateUsed, setEndDateUsed] = useState(null);

//   const handleSort = (key) => {
//     let direction = "asc";
//     if (sortConfig.key === key && sortConfig.direction === "asc") {
//       direction = "desc";
//     }
//     setSortConfig({ key, direction });

//     const sortedItemList = [...itemList].sort((a, b) => {
//       const valueA = a[key]?.toDate ? a[key]?.toDate() : a[key] || "";
//       const valueB = b[key]?.toDate ? b[key]?.toDate() : b[key] || "";

//       if (valueA instanceof Date && valueB instanceof Date) {
//         return direction === "asc" ? valueA - valueB : valueB - valueA;
//       } else if (!isNaN(valueA) && !isNaN(valueB)) {
//         return direction === "asc"
//           ? Number(valueA) - Number(valueB)
//           : Number(valueB) - Number(valueA);
//       } else {
//         return direction === "asc"
//           ? valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase())
//           : valueB.toString().toLowerCase().localeCompare(valueA.toString().toLowerCase());
//       }
//     });
//     setItemList(sortedItemList);
//   };

//   let filteredData = itemList.filter((item) => {
//     if (!item) return ''
//     if (section === "policies") {
//       return `${handleCopyToClipboardPolicy(item)}`
//         .toLowerCase()
//         .includes(searchTerm.toLowerCase())
//     } else if (section === "endorsements") {
//       return `${handleCopyToClipboardEndo(item)}`
//         .toLowerCase()
//         .includes(searchTerm.toLowerCase())
//     } else if (section === "bordereaux") {
//       return `${handleCopyToClipboardBordereaux(item, null, bordereauxType)}`
//         .toLowerCase()
//         .includes(searchTerm.toLowerCase())
//     } else {
//       return ''
//     }
//   });

//   const sortedData = [...filteredData].sort((a, b) => {
//     if (sortConfig.direction === "asc") {
//       return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
//     } else {
//       return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
//     }
//   });

//   const [currentPage, setCurrentPage] = useState(0);
//   const offset = currentPage * policysPerPage;
//   const currentItems = sortedData.slice(offset, offset + policysPerPage);

//   const pageCount = Math.ceil(sortedData.length / policysPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleSearch = (event) => {
//     handlePageChange(0);
//     setSearchTerm(event.target.value);
//   };

//   const [hoveredRow, setHoveredRow] = useState(null);

//   const handleMouseEnter = (index) => {
//     setHoveredRow(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredRow(null);
//   };

//   const onSubmit = async (e, data) => {
//     e.preventDefault();
//     if (section === "policies") {
//       handlePolicySearch(
//         data,
//         program,
//         controlToast,
//         setLoading,
//         setItemList,
//         setStartDateUsed,
//         setEndDateUsed,
//         setDidRequest,
//         userInfo,
//         type,
//         null,
//         status
//       );
//     } else if (section === "endorsements") {
//       handleEndorsementSearch(
//         data,
//         program,
//         controlToast,
//         setLoading,
//         setItemList,
//         setStartDateUsed,
//         setEndDateUsed,
//         setDidRequest,
//         userInfo,
//         type,
//         null
//       );
//     } else if (section === "bordereaux") {
//       if (bordereauxType === "main") {
//         handleBordereauxMainSearch(
//           data,
//           program,
//           controlToast,
//           setLoading,
//           setItemList,
//           setStartDateUsed,
//           setEndDateUsed,
//           setDidRequest,
//           userInfo,
//           type,
//           bordereauxType
//         );
//       } else if (bordereauxType === "vin") {
//         handleBordereauxVinSearch(
//           data,
//           program,
//           controlToast,
//           setLoading,
//           setItemList,
//           setStartDateUsed,
//           setEndDateUsed,
//           setDidRequest,
//           userInfo,
//           type,
//           bordereauxType
//         );
//       }
//     }
//   };

//   return (
//     <main>
//       <PeriodFilters
//         onSubmit={onSubmit}
//         section={section}
//         itemList={itemList}
//         type={type}
//         startDateUsed={startDateUsed}
//         endDateUsed={endDateUsed}
//         program={program}
//         status={status}
//         bordereauxType={bordereauxType}
//         setSection={setSection}
//         setItemList={setItemList}
//         setType={setType}
//         setStartDateUsed={setStartDateUsed}
//         setEndDateUsed={setEndDateUsed}
//         setProgram={setProgram}
//         setStatus={setStatus}
//         setBordereauxType={setBordereauxType}
//       />
//       {!didRequest && !loading ? (
//         <p>
//           Search your {section} to filter periods or load all issued {section}
//         </p>
//       ) : loading ? (
//         <TableSkeleton rows={5} columns={5} />
//       ) : itemList.length === 0 ? (
//         <p>No {section} were found with these properties</p>
//       ) : (
//         <>
//           <div className="mb-2">
//             <h2 className="text-2xl font-semibold mb-1">List of {section}</h2>
//           </div>
//           <input
//             type="text"
//             placeholder={t("manage-policies.search")}
//             value={searchTerm}
//             onChange={handleSearch}
//             className="mb-2 p-2 border rounded w-full"
//           />
//           <div className="overflow-x-auto">
//             {section === "policies" ? (
//               <PoliciesTable
//                 currentItems={currentItems}
//                 handleSort={handleSort}
//                 sortConfig={sortConfig}
//                 handleMouseEnter={handleMouseEnter}
//                 handleMouseLeave={handleMouseLeave}
//                 hoveredRow={hoveredRow}
//               />
//             ) :
//             section === "endorsements" ? (
//               <EndorsementsTable
//                 currentItems={currentItems}
//                 handleSort={handleSort}
//                 sortConfig={sortConfig}
//                 handleMouseEnter={handleMouseEnter}
//                 handleMouseLeave={handleMouseLeave}
//                 hoveredRow={hoveredRow}
//               />
//             ) : (
//               <BordereauxTable
//                 currentItems={currentItems}
//                 handleSort={handleSort}
//                 sortConfig={sortConfig}
//                 handleMouseEnter={handleMouseEnter}
//                 handleMouseLeave={handleMouseLeave}
//                 hoveredRow={hoveredRow}
//                 bordereauxType={bordereauxType}
//               />
//             )
//           }
//           </div>
//           <Pagination
//             pageCount={pageCount}
//             currentPage={currentPage}
//             handlePageChange={handlePageChange}
//           />
//         </>
//       )}
//     </main>
//   );
// }

// export default ReportList;
