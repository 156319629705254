import React from "react";
import { Modal, Typography, Box, useMediaQuery } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import requestQuote from "../../utils/requestQuote";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";

const RequestQuoteModal = ({
  open,
  onClose,
  policyID,
  formData,
  formDataGL,
  formDataAPD,
  formDataMTC,
  insuredInfo
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  return (
    <Modal open={open} onClose={null}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          overflowY: "auto",
          width: isMobileScreen ? "100%" : 400,
          height: isMobileScreen ? "100%" : "auto",
          borderRadius: isMobileScreen ? 0 : 4,
        }}
      >
          <>
            <Typography className="mt-2" variant="h6" gutterBottom>
              Request Quote
            </Typography>
            <p className="mb-2">
              This will lock the application and request a quote from the underwriters.
            </p>
            <p className="mb-3">
              Are you sure you wish to proceed?
            </p>
            <ApprovalButtons
              onApprove={(e) => {requestQuote(
                e,
                formData,
                policyID,
                userAuth,
                userState,
                insuredInfo,
                formDataGL,
                formDataAPD,
                formDataMTC
              ); onClose();}}
              onDecline={onClose}
              titleAccept={"Continue"}
              titleDecline={"Cancel"}
              disabledAccept={false}
              disabledDecline={false}
              handleAsSubmit={false}
              dissapearDecline={false}
            />
          </>
     
      </Box>
    </Modal>
  );
};

export default RequestQuoteModal;
