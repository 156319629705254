import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "../../assets/forms/Checkbox";
import updatePolicyField from "../../utils/updatePolicyField";
import { Grid } from "@mui/material";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useTranslations from "../../../Context/actions/useTranslations";
import { setTimeToNoonOne } from "../../../utils/helpers";

export default function CoverageCheckboxes({
  policyID,
  formData,
  formDataGL,
  setFormData,
  handleOpenMTCModalValue,
  handleOpenAPDModalValue,
  handleOpenGLModalValue,
  handleUpdateGLData,
  handleDate,
  handleClickDate,
  formDataAPD,
  formDataMTC,
  handleUpdateMTCData,
  handleUpdateAPDData,
}) {
  const { t } = useTranslations();
  const { controlToast } = useGeneralState();

  const isApplication = formData.documentType === "Application";

  const hasAL = isApplication || formData.hasALCoverage;
  const hasGL = isApplication || formDataGL.hasGLCoverage;
  const hasAPD = isApplication || formData.hasAPDCoverage;
  const hasMTC = isApplication || formData.hasMTCCoverage;
  const hasNOA = isApplication || formData.hasNonOwned;
  const hasWComp = isApplication || formData.hasWorkersComp;
  const hasConCargo = isApplication || formData.hasContingentCargo;

  return (
    <Grid container spacing={0}>
      {hasAL && (
        <Grid item xs={formData.hasALCoverage ? 12 : 6}>
          <Checkbox
            label="AL"
            field={"hasALCoverage"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formData}
            disabled={formData.documentType !== "Application"}
          />
          {formData.hasALCoverage && (
            <div className="input-container">
              <label className="text-capitalize mb-1 d-block">
                {/* {t("AL Effective Date")}: */}
                {t("plm.coverage-side-panel.al-effective-date")}
              </label>
              <div className="flex">
                <div
                  style={{
                    position: "relative",
                    zIndex: 20,
                    width: "100%",
                  }}
                  onClick={handleClickDate}
                >
                  <DatePicker
                    wrapperClassName="datePicker"
                    selected={formData?.effectiveDate?.toDate?.() || null}
                    onChange={(date) => {
                      const newDate = setTimeToNoonOne(date);
                      setFormData({
                        ...formData,
                        effectiveDate: newDate,
                      });
                      updatePolicyField(policyID, "effectiveDate", newDate);
                    }}
                    className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                    disabled={formData.documentType !== "Application"}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    if (formData?.effectiveDate) {
                      const date = formData?.effectiveDate?.toDate?.();
                      const dateCopiedToClipboard = handleDate(date);
                      navigator.clipboard.writeText(dateCopiedToClipboard);
                      controlToast(
                        true,
                        "The effective date was copied to clipboard",
                        "success"
                      );
                    }
                  }}
                  className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                  disabled={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy d-inline"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </Grid>
      )}
      
      {hasGL && (
        <Grid item xs={formData.hasGLCoverage ? 12 : 6}>
          <Checkbox
            label="GL"
            field={"hasGLCoverage"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formDataGL}
            disabled={
              !Boolean(
                formData.documentType === "Application" ||
                  formData.documentType === "Policy"
              )
            }
            onChange={handleOpenGLModalValue}
          />
          {formDataGL.hasGLCoverage && (
            <div className="input-container">
              <label className="text-capitalize mb-1 d-block">
                {/* {t("GL Effective Date")}: */}
                {t("plm.coverage-side-panel.gl-effective-date")}
              </label>
              <div className="flex">
                <div
                  style={{
                    position: "relative",
                    zIndex: 19,
                    width: "100%",
                  }}
                  onClick={handleClickDate}
                >
                  <DatePicker
                    wrapperClassName="datePicker"
                    selected={formDataGL?.effectiveDate?.toDate?.() || null}
                    onChange={(date) => {
                      const newDate = setTimeToNoonOne(date);
                      setFormData({
                        ...formData,
                        glEffectiveDate: newDate,
                      });
                      updatePolicyField(policyID, "glEffectiveDate", newDate);
                      handleUpdateGLData({
                        effectiveDate: newDate,
                      });
                    }}
                    className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                    disabled={formDataGL.documentType !== "Application"}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    if (formData?.effectiveDate) {
                      const date = formData?.effectiveDate?.toDate?.();
                      const dateCopiedToClipboard = handleDate(date);
                      navigator.clipboard.writeText(dateCopiedToClipboard);
                      controlToast(
                        true,
                        "The effective date was copied to clipboard",
                        "success"
                      );
                    }
                  }}
                  className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                  disabled={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy d-inline"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </Grid>
      )}

      {hasAPD && (
        <Grid item xs={formData.hasAPDCoverage ? 12 : 6}>
          <Checkbox
            label="APD"
            field={"hasAPDCoverage"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formDataAPD}
            disabled={
              !Boolean(
                formData.documentType === "Application" ||
                  formData.documentType === "Policy"
              )
            }
            onChange={handleOpenAPDModalValue}
          />
          {formDataAPD?.hasAPDCoverage && (
            <div className="input-container">
              <label className="text-capitalize mb-1 d-block">
                {/* {t("APD Effective Date")}: */}
                {t("plm.coverage-side-panel.apd-effective-date")}
              </label>
              <div className="flex">
                <div
                  style={{
                    position: "relative",
                    zIndex: 19,
                    width: "100%",
                  }}
                  onClick={handleClickDate}
                >
                  <DatePicker
                    wrapperClassName="datePicker"
                    selected={formDataAPD?.effectiveDate?.toDate?.() || null}
                    onChange={(date) => {
                      const newDate = setTimeToNoonOne(date);
                      setFormData({
                        ...formData,
                        apdEffectiveDate: newDate,
                      });
                      updatePolicyField(policyID, "apdEffectiveDate", newDate);
                      handleUpdateAPDData({
                        effectiveDate: newDate,
                      });
                    }}
                    className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                    disabled={formDataAPD.documentType !== "Application"}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    if (formData?.effectiveDate) {
                      const date = formData?.effectiveDate?.toDate?.();
                      const dateCopiedToClipboard = handleDate(date);
                      navigator.clipboard.writeText(dateCopiedToClipboard);
                      controlToast(
                        true,
                        "The effective date was copied to clipboard",
                        "success"
                      );
                    }
                  }}
                  className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                  disabled={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy d-inline"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </Grid>
      )}
      
      {hasMTC && (
        <Grid item xs={formData.hasMTCCoverage ? 12 : 6}>
          <Checkbox
            label="MTC"
            field={"hasMTCCoverage"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formDataMTC}
            disabled={
              !Boolean(
                formData.documentType === "Application" ||
                  formData.documentType === "Policy"
              )
            }
            onChange={handleOpenMTCModalValue}
          />
          {formDataMTC?.hasMTCCoverage && (
            <div className="input-container">
              <label className="text-capitalize mb-1 d-block">
                {/* {t("MTC Effective Date")}: */}
                {t("plm.coverage-side-panel.mtc-effective-date")}
              </label>
              <div className="flex">
                <div
                  style={{
                    position: "relative",
                    zIndex: 19,
                    width: "100%",
                  }}
                  onClick={handleClickDate}
                >
                  <DatePicker
                    wrapperClassName="datePicker"
                    selected={formDataMTC?.effectiveDate?.toDate?.() || null}
                    onChange={(date) => {
                      const newDate = setTimeToNoonOne(date);
                      setFormData({
                        ...formData,
                        mtcEffectiveDate: newDate,
                      });
                      updatePolicyField(policyID, "mtcEffectiveDate", newDate);
                      handleUpdateMTCData({
                        effectiveDate: newDate,
                      });
                    }}
                    className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                    disabled={formDataMTC.documentType !== "Application"}
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    if (formData?.effectiveDate) {
                      const date = formData?.effectiveDate?.toDate?.();
                      const dateCopiedToClipboard = handleDate(date);
                      navigator.clipboard.writeText(dateCopiedToClipboard);
                      controlToast(
                        true,
                        "The effective date was copied to clipboard",
                        "success"
                      );
                    }
                  }}
                  className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                  disabled={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-copy d-inline"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </Grid>
      )}
      {hasNOA && (
        <Grid item xs={6}>
          <Checkbox
            label="NOA"
            field={"hasNonOwned"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formData}
            disabled={formData.documentType !== "Application"}
          />
        </Grid>
      )}
      {hasWComp && (
        <Grid item xs={6}>
          <Checkbox
            label="WComp"
            field={"hasWorkersComp"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formData}
            disabled={formData.documentType !== "Application"}
          />
        </Grid>
      )}
      {hasConCargo && (
        <Grid item xs={6}>
          <Checkbox
            label="Con. Cargo"
            field={"hasContingentCargo"}
            policyID={policyID}
            setFormData={setFormData}
            formData={formData}
            disabled={formData.documentType !== "Application"}
          />
        </Grid>
      )}
    </Grid>
  );
}
