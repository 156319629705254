import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

const updateSystemLog = async (
    auth,
    policyID,
    description,
    shortDescription,
    additionalInfo,
    isEndorsement = false
) => {

  const systemActivityPayload = {
    policyID: policyID,
    activityDate: new Date(),
    authorID: auth.currentUser.uid,
    authorName: auth.currentUser.displayName,
    description: description,
    shortDescription,
  }

  if (additionalInfo) {
    systemActivityPayload['additionalInfo'] = additionalInfo;
  }

  if (isEndorsement) {
    systemActivityPayload['isEndorsement'] = true;
  }

  await addDoc(collection(db, "system-activity"), systemActivityPayload);
}

export default updateSystemLog;