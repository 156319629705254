import React from "react";
import useTranslations from "../../../Context/actions/useTranslations";
import downloadInformationSheet from "./utils/downloadInformationSheet";

export default function KPBrokersInformationSheetDownloadButton({
  userInfo,
  formData,
  insuredInfo
}) {
    const { t } = useTranslations();
  return (
    <>
      {(userInfo.role === "Admin" || userInfo.role === "Underwriter") &&
        (formData.hasMTCCoverage || formData.hasAPDCoverage) && (
          <div className="w-full text-center mt-4 mb-4">
            <button
              className="bg-green-900 hover:bg-green-700 rounded w-full p-2 mx-auto text-white text-medium"
              onClick={(e) => downloadInformationSheet(e, formData, insuredInfo, t)}
            >
              {t("plm.quotes.underwriting-details.download")}
            </button>
          </div>
        )}
    </>
  );
}
