import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import LoadingScreen from "../../assets/LoadingScreen";

export default function ViewAllEventualities() {
  const [loading, setLoading] = useState(true);
  const [eventualities, setEventualities] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchField, setSearchField] = useState("Customer");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  // Fetch eventualities from Firestore
  useEffect(() => {
    const fetchEventualities = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(
        collection(db, "loss-control", "driverData", "inspections")
      );
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        firstLast:
          (!doc.data().drvFirstName ? "" : doc.data().drvFirstName) +
          " " +
          (!doc.data().drvLastName
            ? ""
            : doc.data().drvLastName.split(" ")[
                doc.data().drvLastName.split(" ").length - 1
              ]),
        fullName: doc.data().drvFirstName + " " + doc.data().drvLastName,
      }));
      setEventualities(data);
      setFilteredData(data); // Initialize with all data
      setLoading(false);
    };

    fetchEventualities();
  }, []);

  // Handle search filtering
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = eventualities.filter((eventuality) => {
      const fieldValue = eventuality[searchField];
      return fieldValue
        ? fieldValue.toString().toLowerCase().includes(lowerCaseQuery)
        : false;
    });
    setFilteredData(filtered);
  }, [searchQuery, searchField, eventualities]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  return (
    <>
      {loading ? (
        <LoadingScreen text={"Loading Eventualities..."} />
      ) : (
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="search-field-label">Filter By</InputLabel>
              <Select
                labelId="search-field-label"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
              >
                <MenuItem value="Customer">Customer</MenuItem>
                <MenuItem value="drvFirstName">Driver First Name</MenuItem>
                <MenuItem value="drvLastName">Driver Last Name</MenuItem>
                <MenuItem value="drvFullName">
                  Driver Full Name (Sensitive)
                </MenuItem>
                <MenuItem value="firstLast">First & Last Name</MenuItem>
                <MenuItem value="drvLicNbr">Driver License Number</MenuItem>
                <MenuItem value="description">Description</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ m: 1, flexGrow: 1 }}
              label="Filter"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Driver Name</TableCell>
                  <TableCell>Driver License</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Violation Group</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((eventuality, index) => (
                    <TableRow key={index}>
                      <TableCell>{eventuality.Customer}</TableCell>
                      <TableCell>
                        <a
                          href={`/driver/${eventuality.drvLicNbr}`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {eventuality.drvFirstName} {eventuality.drvLastName}
                        </a>
                      </TableCell>
                      <TableCell>{eventuality.drvLicNbr}</TableCell>
                      <TableCell>{eventuality.description}</TableCell>
                      <TableCell>
                        {eventuality.violationGroupDescription}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
}
