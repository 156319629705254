import LoadingScreen from "react-loading-screen";
import {
  Row,
  Col,
  Container,
} from "../../../../Components/generals/GridSystem";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import DriverPanel from "../../../../Components/PolicyLifecycleManager/Drivers";
import PowerUnitPanel from "../../../../Components/PolicyLifecycleManager/Units";
import TrailerPanel from "../../../../Components/PolicyLifecycleManager/Trailers";
import QuestionnairePanel from "../../../../Components/PolicyLifecycleManager/Questionnaire";
import CommoditiesPanel from "../../../../Components/PolicyLifecycleManager/Commodities";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";
import BigAlert from "../../../../Components/generals/Toast/BigAlert";
import PrintOptionsModal from "../../../../Components/PolicyLifecycleManager/PrintOptionsModal";
import usePoliciesPdfState from "../../../../Context/actions/usePoliciesPdfState";
import { setProgressToZero } from "../../../../services/firebase-api/websocketSimulator";
import { filePolicyStructuring } from "../../../../utils/constants";
import useWebSocketSimulator from "../../../../services/southern-api/useWebSocketSimulator";
import { generateRandomUid, parseStaticDate } from "../../../../utils/helpers";
import {
  domesticAlPolicyPayload,
  domesticGlPolicyPayload,
  naftaAlPolicyPayload,
  naftaGlPolicyPayload
} from "../../../../utils/policiesPdfPayloads";
import useManagerTabs from "../../../../hooks/policy/useManagerTabs";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_ROUTE, CLIENT_ROUTE } from "../../../../utils/routes";
import useFormDataState from "../../../../Context/actions/useFormData";

export default function LoggedInModules() {
  const {
    loading,
    loadingText,
    tabProps,
    formData,
    insuredInfo,
    agencyInfo,
    policyID,
    formSaved,
    driverList,
    truckList,
    trailerList,
    setFormSaved,
    downloadFile,
  } = useManagerTabs();

  const { formDataGL, quoteSelected } = useFormDataState()

  const {
    driverPanel,
    powerUnitPanel,
    trailerPanel,
    questionnaire: questionnairePanel,
    commodities: commoditiesPanel,
  } = tabProps;

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const policyRef = doc(db, "policy-applications", policyID);

  const navigate = useNavigate()

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };

  const getEmailFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("email");
  };

  const moduleValue = getModuleValueFromCurrentUrl();
  const emailValue = getEmailFromCurrentUrl();

  const apiService = new ApiService();

  const saveForm = async () => {
    try {
      await setDoc(policyRef, formData, { merge: true });
      setFormSaved(true);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const [didSuccess, setDidSuccess] = useState(false);

  const { progress, initialSetup } = useWebSocketSimulator();
  const { policiesPdf, setPoliciesPdf } = usePoliciesPdfState();

  let isCancelled = false;

  const cancelPolicyPrinting = async () => {
    try {
      setPoliciesPdf({
        loadingCancel: true,
        dateWasCancel: parseStaticDate(new Date()),
      });
      const realType = filePolicyStructuring(policiesPdf?.type);
      await setProgressToZero(`${realType}-${policiesPdf?.userId}`);
      await apiService.abortPolicyPrinting();
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
      navigate(ACCOUNT_ROUTE)
    } catch (error) {
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
      throw error;
    }
  };

  useEffect(() => {
    if (initialSetup) {
      setPoliciesPdf({
        error: false,
        cancelRequest: cancelPolicyPrinting,
      });
    }
  }, [initialSetup]);

  const printPolicy = async (e, option) => {
    isCancelled = false;
    const realType =
      option.includes("NaftaAL") ?
      "NAFTA AL" :
      option.includes("DomesticAL") ?
      "Domestic AL" :
      option.includes("NaftaGL") ?
      "NAFTA GL" :
      "Domestic GL";
    const fnPayload =
      option.includes("NaftaAL") ?
      naftaAlPolicyPayload :
      option.includes("DomesticAL") ?
      domesticAlPolicyPayload :
      option.includes("NaftaGL") ?
      naftaGlPolicyPayload :
      domesticGlPolicyPayload
    const dashedType = filePolicyStructuring(realType)
    const fnPayloadResult = fnPayload(
      formData,
      insuredInfo,
      driverList,
      truckList,
      trailerList,
      agencyInfo,
      option,
      formDataGL,
      quoteSelected
    )
    const randomUid = generateRandomUid("")
    setPoliciesPdf({
      type: realType,
      loading: true,
      error: false,
      userId: randomUid,
    });
    if (isCancelled) {
      throw new Error("Operation cancelled");
    }
    try {
      e.preventDefault();
      const result = await apiService.printPolicy({
        type: realType,
        payload: fnPayloadResult,
        id: formData?.id,
        option,
        userId: randomUid,
      });
      if (isCancelled) {
        throw new Error("Operation cancelled");
      }
      if (result) {
        setPoliciesPdf({
          ...result,
          loading: false,
          error: false,
        });
        if (isCancelled) {
          throw new Error("Operation cancelled");
        } else {
          const name = `${realType} Policy.pdf`;
          downloadFile(result.url, name);
        }
      }
    } catch (error) {
      const abortedError =
        error?.response?.data?.error === "Operation aborted" ||
        error?.message === "Operation cancelled";
      try {
        await setProgressToZero(dashedType);
      } catch (suberror) {
        console.error(suberror);
      } finally {
        console.log(
          "abortedError ? false : error",
          abortedError ? false : error
        );
        setPoliciesPdf({
          loading: false,
          error: abortedError ? false : error,
        });
      }
      return error;
    }
  };

  const { percentage } = progress;

  const printingPolicyPanel = {
    formData,
    insuredInfo,
    policyID,
    userInfo: null,
    saveForm,
    setFormSaved,
    formSaved,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
    isNotModal: true,
    policiesPdf,
    percentage,
    printPolicy,
  };

  useEffect(() => {
    if (didSuccess) {
        navigate(CLIENT_ROUTE)
    }
  }, [didSuccess])

  return (
    <LoadingScreen
      loading={loading && !Boolean(formData?.documentType)}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={loadingText}
    >
      {!loading && Boolean(formData?.documentType) ? (
        <Container className="p-0 px-4 mt-4 mb-4 w-full bg-white rounded" fluid>
          <Row>
            <Col lg={12}>
              <Row>
                <Col
                  spacing={isMobileScreen ? 0 : 12}
                  className={isMobileScreen ? "mt-2" : ""}
                >
                  {didSuccess ? (
                    <BigAlert
                      title={
                        formData?.documentType === "Policy"
                          ? "Your changes were submitted.."
                          : "Your changes were submitted."
                      }
                      subtitle={
                        formData?.documentType === "Policy"
                          ? "An endorsement was created and your changes will not be active until the endorsement is approved. Your agent has been notified."
                          : "The changes will become active on the application immediately. Your agent has been notified."
                      }
                      className="mt-2"
                      status="successful"
                    />
                  ) : moduleValue === "drivers" ? (
                    <DriverPanel
                      {...driverPanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : moduleValue === "power-units" ? (
                    <PowerUnitPanel
                      {...powerUnitPanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : moduleValue === "trailers" ? (
                    <TrailerPanel
                      {...trailerPanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : moduleValue === "commodities" ? (
                    <CommoditiesPanel
                      {...commoditiesPanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : moduleValue === "questionnaire" ? (
                    <QuestionnairePanel
                      {...questionnairePanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : moduleValue === "print-policy" ? (
                    <PrintOptionsModal
                      {...printingPolicyPanel}
                      emailValue={emailValue}
                      setDidSuccess={setDidSuccess}
                    />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}
    </LoadingScreen>
  );
}
