import React from "react";
import {
  ADMIN_ROUTE,
  AGENT_DIRECTORY_ROUTE,
  CALENDAR_ROUTE,
  SUPPORT_TICKETS_LIST_ROUTE,
} from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import {
  AdminPanelSettings,
  CalendarToday,
  Group,
  Support,
} from "@mui/icons-material";

export default function AdminManageMenu() {
  return (
    <>
      <NavDropdown.Item href={ADMIN_ROUTE}>
        <AdminPanelSettings className="mr-2" /> Admin Panel
      </NavDropdown.Item>
      <NavDropdown.Item href={AGENT_DIRECTORY_ROUTE}>
        <Group className="mr-2" /> Agents
      </NavDropdown.Item>
      <NavDropdown.Item href={CALENDAR_ROUTE}>
        <CalendarToday className="mr-2" /> Calendar
      </NavDropdown.Item>
      <NavDropdown.Item href={SUPPORT_TICKETS_LIST_ROUTE}>
        <Support className="mr-2" /> Tickets
      </NavDropdown.Item>
    </>
  );
}
