import React from "react";
import styles from "./driverMapSchema.module.css";

const DriverMap = ({ driverMapData }) => {
  const {
    profileDefinition,
    basicInformation,
    score,
    riskFactor,
    crashes,
    sct,
    training = [],
    policy = [],
    inspections = [],
  } = driverMapData;

  if (!profileDefinition) return null;

  const isPdf = profileDefinition.url?.endsWith(".pdf");

  return (
    <div className={styles.realBody}>
      <div className={styles.titleContainer}>
        <div className={`${styles.hexagon} ${styles.mainHexagon}`}>
          {profileDefinition.url &&
            (isPdf ? (
              <a
                href={profileDefinition.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Avatar
              </a>
            ) : (
              <img
                className={styles.hexagonImg}
                src={profileDefinition.url}
                alt="avatar"
              />
            ))}
          <div>
            <p className={styles.textTitle1}>{profileDefinition.name}</p>
            <div className={styles.textTitle2}>
              <p>DOB</p>
              <p>({profileDefinition.dob})</p>
            </div>
            <p className={styles.textTitle3}>
              License MX: {profileDefinition.licenseMX}
            </p>
            <p className={styles.textTitle4}>
              Expires on: {profileDefinition.expiresOn}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.driverMap}>
        <div className={styles.line}></div>

        {/* Basic Information */}
        <div className={`${styles.branch} ${styles.branchRight}`}>
          <div className={`${styles.connector} ${styles.orange}`}></div>
          <table className={`${styles.basicInfo} ${styles.realTable}`}>
            <tbody>
              <tr>
                <td>License Class: {basicInformation.licenseClass}</td>
              </tr>
              <tr>
                <td>Experience: {basicInformation.experience}</td>
              </tr>
              <tr>
                <td>CURP: {basicInformation.curp}</td>
              </tr>
              <tr>
                <td>Score Status: {basicInformation.scoreStatus}</td>
              </tr>
              <tr>
                <td>
                  No Medicina Preventiva:{" "}
                  {basicInformation.noMedicinaPreventiva}
                </td>
              </tr>
            </tbody>
          </table>
          <div className={`${styles.hexagon} ${styles.orangeBorder}`}>
            Basic Information
          </div>
        </div>

        {/* Score */}
        <div className={`${styles.branch} ${styles.branchLeft}`}>
          <div className={`${styles.connector} ${styles.yellow}`}></div>
          <div className={`${styles.hexagon} ${styles.yellowBorder}`}>
            Score
          </div>
          <table className={`${styles.scoreInfo} ${styles.realTable}`}>
            <tbody>
              <tr>
                <td>Score Points: {score.scorePoints}</td>
              </tr>
              <tr>
                <td>Violations: {score.violations}</td>
              </tr>
              <tr>
                <td>Claims: {score.claims}</td>
              </tr>
              <tr>
                <td>Crashes: {score.crashes}</td>
              </tr>
              <tr>
                <td>Training: {score.training}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Risk Factor */}
        <div className={`${styles.branch} ${styles.branchRight}`}>
          <div className={`${styles.connector} ${styles.rosyRed}`}></div>
          <table className={`${styles.riskFactorInfo} ${styles.realTable}`}>
            <tbody>
              <tr>
                <td>Frequency: {riskFactor.frequency}</td>
              </tr>
              <tr>
                <td>Severity: {riskFactor.severity}</td>
              </tr>
              <tr>
                <td>Combined: {riskFactor.combined}</td>
              </tr>
            </tbody>
          </table>
          <div className={`${styles.hexagon} ${styles.rosyRedBorder}`}>
            Risk Factor
          </div>
        </div>

        {/* Crashes */}
        <div className={`${styles.branch} ${styles.branchLeft}`}>
          <div className={`${styles.connector} ${styles.pink}`}></div>
          <div className={`${styles.hexagon} ${styles.pinkBorder}`}>
            Crashes
          </div>
          <table className={`${styles.crashesInfo} ${styles.realTable}`}>
            <tbody>
              <tr>
                <td>Crashes: {crashes.crashes}</td>
              </tr>
              <tr>
                <td>Fatalities: {crashes.fatalities}</td>
              </tr>
              <tr>
                <td>Injuries: {crashes.injuries}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* SCT */}
        <div className={`${styles.branch} ${styles.branchRight}`}>
          <div className={`${styles.connector} ${styles.grey}`}></div>
          <table className={`${styles.sctInfo} ${styles.realTable}`}>
            <tbody>
              <tr>
                <td>Effective From: {sct.effectiveFrom}</td>
              </tr>
              <tr>
                <td>Effective Until: {sct.effectiveUntil}</td>
              </tr>
              <tr>
                <td>Fit: {sct.fit}</td>
              </tr>
              <tr>
                <td>Exam Type: {sct.examType}</td>
              </tr>
            </tbody>
          </table>
          <div className={`${styles.hexagon} ${styles.greyBorder}`}>SCT</div>
        </div>

        {/* Training */}
        {training.length > 0 && (
          <div className={`${styles.branch} ${styles.branchLeft}`}>
            <div className={`${styles.connector} ${styles.limegreen}`}></div>
            <div className={`${styles.hexagon} ${styles.limegreenBorder}`}>
              Training
            </div>
            <div className={styles.trainings}>
              {training.map((train, index) => (
                <table key={index} className={`${styles.trainingsInfo} ${styles.realTable}`}>
                  <tbody>
                    <tr>
                      <td>Training: {train.training}</td>
                    </tr>
                    <tr>
                      <td>Institution: {train.institution}</td>
                    </tr>
                    <tr>
                      <td>Completion Date: {train.completionDate}</td>
                    </tr>
                    <tr>
                      <td>Training Duration: {train.trainingDuration}</td>
                    </tr>
                    <tr>
                      <td>Description: {train.description}</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        )}

        {/* Policies */}
        {policy.length > 0 && (
          <div className={`${styles.branch} ${styles.branchRight}`}>
            <div className={`${styles.connector} ${styles.purple}`}></div>
            <div className={styles.policy}>
              {policy.map((pol, index) => (
                <table key={index} className={`${styles.policyInfo} ${styles.realTable}`}>
                  <tbody>
                    <tr>
                      <td>Policy Nbr: {pol.policyNbr}</td>
                    </tr>
                    <tr>
                      <td>DOT: {pol.dot}</td>
                    </tr>
                    <tr>
                      <td>Insured Name: {pol.insuredName}</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
            <div className={`${styles.hexagon} ${styles.purpleBorder}`}>
              Policies
            </div>
          </div>
        )}

        {/* Inspections */}
        {inspections.length > 0 && (
          <div className={`${styles.branch} ${styles.branchLeft}`}>
            <div className={`${styles.connector} ${styles.red}`}></div>
            <div className={`${styles.hexagon} ${styles.redBorder}`}>
              Inspections
            </div>
            <div className={styles.inspections}>
              {inspections.map((inspec, index) => (
                <table key={index} className={`${styles.inspectionsInfo} ${styles.realTable}`}>
                  <tbody>
                    <tr>
                      <td>Report No: {inspec.reportNo}</td>
                    </tr>
                    <tr>
                      <td>Eventuality: {inspec.eventuality}</td>
                    </tr>
                    <tr>
                      <td>Expedition Date: {inspec.expeditionDate}</td>
                    </tr>
                    <tr>
                      <td>Eventuality Group: {inspec.eventualityGroup}</td>
                    </tr>
                    <tr>
                      <td>Status: {inspec.status}</td>
                    </tr>
                    <tr>
                      <td>Description: {inspec.description}</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverMap;