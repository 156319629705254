import React from "react";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { numberWithCommas } from "../../../utils/helpers";
import { Button } from "@mui/material";
import useTranslations from "../../../Context/actions/useTranslations";

export default function Limits({
  formData,
  setFormData,
  dropdownOptions,
  policyID,
  disabledRule,
  isAdminOrUnderwriter,
  handleOpenModal,
}) {

  const { t } = useTranslations()

  return (
    <section>
      <h1 className="text-lg mt-3">{t("plm.mtc.limits.title")}</h1>
      <label
        htmlFor={`anyOneLossLimit-select-anyOne-loss`}
        className="block text-sm font-medium text-gray-700"
      >
        {t("plm.mtc.limits.target")}
      </label>
      <select
        disabled={disabledRule}
        id={`anyOneLossLimit-select-anyOne-loss`}
        onChange={async (e) => {
          const mtcDetails = {
            ...(formData.mtcDetails || {}),
            anyOneLossLimit: e.target.value,
          };
          const policyRef = doc(db, "policy-applications", policyID);
          await updateDoc(policyRef, {
            mtcDetails,
          });
        }}
        value={formData?.mtcDetails?.anyOneLossLimit}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
      >
        <option value="">{t("plm.mtc.limits.choose-limit")}</option>
        {dropdownOptions?.anyOneLossLimits
          ?.slice()
          .sort((a, b) => a - b)
          .map((limit) => (
            <option key={limit} value={limit}>
              ${numberWithCommas(limit)}
            </option>
          ))}
      </select>
      {isAdminOrUnderwriter && !disabledRule && (
        <Button
          sx={{ fontSize: "10px" }}
          onClick={() => handleOpenModal("anyOneLossLimits", "mtcDropdowns")}
        >
          {t("plm.mtc.limits.edit-options")}
        </Button>
      )}
    </section>
  );
}
