import React, { useEffect, useMemo, useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import FileUploadModal from "../../../FileUploadModal";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import QuillModal from "../../../QuillModal";
import uploadFile from "./uploadFile";
import TaskCheckbox from "./TaskCheckbox";
import BlueButton from "../../../assets/forms/BlueButton";
import {
  Undo as UndoIcon,
  CheckCircle as CheckCircleIcon,
  Visibility as VisibilityIcon,
  Update as UpdateIcon,
  Assignment as AssignmentIcon,
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import updatePolicyField from "../../../utils/updatePolicyField";
import { Button, ButtonGroup } from "@mui/material";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import ApiService from "../../../../services/southern-api/apiService";
import OFACSection from "../../UnderwriterPanel/OFACSection";
import Popup from "../../../generals/Popup";
import checkDriverForFlags from "../../../utils/checkDriverForFlags";
import useTranslations from "../../../../Context/actions/useTranslations";
import OfacReview from "./TaskComponents/OfacReview";
import DriverReviewed from "./TaskComponents/DriverReviewed";
import UwqCommoditiesReview from "./TaskComponents/UwqCommoditiesReview";
import CabAnalysis from "./TaskComponents/CabAnalysis";
import LossExperienceAnalysis from "./TaskComponents/LossExperienceAnalysis";
import ProposalReview from "./TaskComponents/ProposalReview";
import FormPopup from "../../../generals/Popup/FormPopup";
import SaferReport from "./TaskComponents/SaferReport";
import { formatAmericanDate } from "../../../../utils/driversPayload";

export default function TaskTable({
  policyID,
  formData,
  setProgressState,
  setFile,
  insuredInfo,
  driverList,
}) {
  const { t, timezone } = useTranslations();
  const [taskList, setTaskList] = useState([]);
  const [taskList2, setTaskList2] = useState([]);
  const [files, setFiles] = useState({});

  const closeModal = (taskDescription) => {
    setIsOpen((prev) => ({ ...prev, [taskDescription]: false }));
    setFiles((prev) => ({ ...prev, [taskDescription]: [] }));
  };

  const { controlToast } = useGeneralState();

  const handleUpload = (task) => {
    let description = task.description;
    updatePolicyField(policyID, "underwritingTasks", {
      ...formData.underwritingTasks,
      [description]: { note: task.note },
    });
    controlToast(true, t("plm.tasks.note-added-successfully"), "success");
    closeModal(description);
  };

  const [fileObjectsStateFilings, setFileObjectsStateFilings] = useState([]);
  const [fileObjectsFederalFilings, setFileObjectsFederalFilings] = useState(
    []
  );

  const fileProps = useMemo(() => {
    return {
      "State Filings": {
        label: t("plm.tasks.state-filings"),
        property: "state-filings",
        storageFile: `files/policies/${policyID}/tasks/state-filings`,
        fileInButton: true,
        files: fileObjectsStateFilings,
        setFiles: setFileObjectsStateFilings,
      },
      "Federal Filings": {
        label: t("plm.tasks.federal-filings"),
        property: "federal-filings",
        storageFile: `files/policies/${policyID}/tasks/federal-filings`,
        fileInButton: true,
        files: fileObjectsFederalFilings,
        setFiles: setFileObjectsFederalFilings,
      },
    };
  }, [fileObjectsStateFilings, fileObjectsFederalFilings]);

  const onSecondaryUploadFn = async (urls, results, fileProp, task) => {
    const pdfUrl = results?.[0]?.pdfUrl;
    const filingProofsRef = collection(db, "proof-of-filings");
    const payload = {
      creationDate: new Date(),
      insuredID: formData.insuredID,
      agencyID: formData.agencyID,
      insuredDOT: insuredInfo.dot,
      submittingUser: auth.currentUser.uid,
      submittingUserName: auth.currentUser.displayName,
      url: urls[0],
      filingType: "BIND",
      description: task.description,
      policyID,
    };
    if (pdfUrl) {
      payload["pdfUrl"] = pdfUrl;
      fileProp.setFiles([pdfUrl]);
    }
    await addDoc(filingProofsRef, payload);
  };

  const onSecondaryDeleteBeforeFn = async (fileProp) => {
    try {
      const deleteDocsByURL = async (urlToDelete) => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "proof-of-filings"),
            where("pdfUrl", "==", urlToDelete)
          )
        );
        const deletePromises = [];
        querySnapshot.forEach((doc) => {
          deletePromises.push(deleteDoc(doc.ref));
        });
        await Promise.all(deletePromises);
      };
      await deleteDocsByURL(fileProp.files[0]);
      fileProp.setFiles([]);
    } catch (error) {
      throw error;
    }
  };

  const [viewIssues, setViewIssues] = useState(false);

  const driversWithRedFlags = useMemo(() => {
    const drivers = driverList?.map((driver) => {
      const flags = checkDriverForFlags(driver, t);
      let totalFlags = [];
      if (flags.length > 0 && !driver.goodToGo) {
        totalFlags = [...flags];
      }
      if (driver?.flags?.flaggedByUnderwriter && !driver.goodToGo) {
        totalFlags = [
          ...totalFlags,
          ...driver?.flags?.flaggedByUnderwriterReasons,
        ];
      }
      if (!driver.goodToGo && totalFlags.length === 0) {
        totalFlags = ["Driver has not been accepted yet"];
      }
      return {
        driverItem: driver,
        flags: totalFlags,
      };
    });

    const firstDriverWithRedFlagFound = drivers?.find(
      (driver) => driver?.flags?.length > 0
    );

    const driverListWithRedFlags = drivers?.filter(
      (driver) => driver?.flags?.length > 0
    );

    return {
      drivers: driverListWithRedFlags,
      redFlagFound: Boolean(firstDriverWithRedFlagFound),
    };
  }, [driverList]);

  const [dataWasVerified, setDataWasVerified] = useState(false);

  const handleAllYearsComplete = async () => {
    try {
      setDataWasVerified(false);
      const didNotOperateInYear1ALPrior =
        formData?.didNotOperateInYear1ALPrior || false;
      const didNotOperateInYear2ALPrior =
        formData?.didNotOperateInYear2ALPrior || false;
      const didNotOperateInYear3ALPrior =
        formData?.didNotOperateInYear3ALPrior || false;
      const hadNoLossesYear1ALPrior =
        formData?.hadNoLossesYear1ALPrior || false;
      const hadNoLossesYear2ALPrior =
        formData?.hadNoLossesYear2ALPrior || false;
      const hadNoLossesYear3ALPrior =
        formData?.hadNoLossesYear3ALPrior || false;
      const allYearsComplete =
        (didNotOperateInYear1ALPrior ||
          hadNoLossesYear1ALPrior ||
          formData?.lossHistory?.year1ALLossData?.lossRatioCalculated) &&
        (didNotOperateInYear2ALPrior ||
          hadNoLossesYear2ALPrior ||
          formData?.lossHistory?.year2ALLossData?.lossRatioCalculated) &&
        (didNotOperateInYear3ALPrior ||
          hadNoLossesYear3ALPrior ||
          formData?.lossHistory?.year3ALLossData?.lossRatioCalculated);
      if (
        allYearsComplete &&
        !formData?.underwritingTasks?.["Loss Experience Analysis"]?.completed
      ) {
        const policyRef = doc(db, "policy-applications", policyID);
        await setDoc(
          policyRef,
          {
            underwritingTasks: {
              "Loss Experience Analysis": {
                completed: true,
                completionDate: new Date(),
              },
            },
          },
          { merge: true }
        );
      } else if (
        !allYearsComplete &&
        !formData?.underwritingTasks?.["Loss Experience Analysis"]
      ) {
        const policyRef = doc(db, "policy-applications", policyID);
        await setDoc(
          policyRef,
          {
            underwritingTasks: {
              "Loss Experience Analysis": {
                completed: false,
                completionDate: null,
              },
            },
          },
          { merge: true }
        );
      }
      if (!driversWithRedFlags?.redFlagFound) {
        const policyRef = doc(db, "policy-applications", policyID);
        await setDoc(
          policyRef,
          {
            underwritingTasks: {
              "Drivers Reviewed": {
                completed: true,
                completionDate: new Date(),
              },
            },
          },
          { merge: true }
        );
      } else if (driversWithRedFlags?.redFlagFound) {
        const policyRef = doc(db, "policy-applications", policyID);
        await setDoc(
          policyRef,
          {
            underwritingTasks: {
              "Drivers Reviewed": {
                completed: false,
                completionDate: null,
              },
            },
          },
          { merge: true }
        );
      }
      setDataWasVerified(true);
    } catch (error) {
      setDataWasVerified(true);
      console.error(error);
    }
  };

  useEffect(() => {
    handleAllYearsComplete();
  }, [formData]);

  useEffect(() => {
    const unsubscribe = () => {
      if (!dataWasVerified) {
        return () => {};
      }

      const taskListRef = collection(db, "underwriting-tasks-review");
      const taskList2Ref = collection(db, "underwriting-tasks-filings");

      const unsubscribeTaskList = onSnapshot(taskListRef, (snapshot) => {
        const taskListData = snapshot.docs.map((doc) => doc.data());
        setTaskList(taskListData);
      });

      const unsubscribeTaskList2 = onSnapshot(taskList2Ref, (snapshot) => {
        const taskList2Data = snapshot.docs.map((doc) => doc.data());
        setTaskList2(taskList2Data);
      });

      const stateProofOfFilingsRef = collection(db, "proof-of-filings");
      const stateQ = query(
        stateProofOfFilingsRef,
        where("policyID", "==", policyID),
        where("description", "==", "State Filings")
      );
      const unsubscribeStateProofOfFilings = onSnapshot(stateQ, (snapshot) => {
        const proofOfFilingsData = snapshot.docs.map((doc) => doc.data());
        if (Boolean(proofOfFilingsData?.[0]?.pdfUrl)) {
          fileProps?.["State Filings"].setFiles([
            proofOfFilingsData?.[0]?.pdfUrl || "",
          ]);
        }
      });

      const federalProofOfFilingsRef = collection(db, "proof-of-filings");
      const federalQ = query(
        federalProofOfFilingsRef,
        where("policyID", "==", policyID),
        where("description", "==", "Federal Filings")
      );
      const unsubscribeFederalProofOfFilings = onSnapshot(
        federalQ,
        (snapshot) => {
          const proofOfFilingsData = snapshot.docs.map((doc) => doc.data());
          if (Boolean(proofOfFilingsData?.[0]?.pdfUrl)) {
            fileProps?.["Federal Filings"].setFiles([
              proofOfFilingsData?.[0]?.pdfUrl || "",
            ]);
          }
        }
      );

      return () => {
        unsubscribeTaskList();
        unsubscribeTaskList2();
        unsubscribeStateProofOfFilings();
        unsubscribeFederalProofOfFilings();
      };
    };

    unsubscribe();
  }, [dataWasVerified]);

  const [isOpen, setIsOpen] = useState(false);

  const [ofacResultsModal, setOfacResultsModal] = useState(false);
  const [saferReportResultsModal, setSaferReportResultsModal] = useState(false);
  const apiService = new ApiService("southern");
  const insuredRef = doc(
    db,
    "agencies",
    formData.agencyID,
    "insureds",
    formData.insuredID
  );

  const [ofacLoading, setOfacLoading] = useState(false);
  const [saferReportLoading, setSaferReportLoading] = useState(false);

  const handleOFAC = async (e, newTaskList) => {
    try {
      e.preventDefault();
      setOfacLoading(true);
      controlToast(true, t("plm.tasks.ofac-loading"), "info");
      const response = await apiService.ofacCrawling({
        namedInsured: insuredInfo.company,
      });
      await setDoc(
        insuredRef,
        {
          ofac: response?.results ? response.results : [],
          ofacUrl: response?.screenshot ? response?.screenshot : "",
        },
        { merge: true }
      );
      await updatePolicyField(policyID, "underwritingTasks", newTaskList);
      setOfacLoading(false);
      controlToast(true, t("plm.tasks.ofac-success"), "success");
      if (response?.results?.length > 0) {
        setOfacResultsModal(true);
      }
    } catch (error) {
      setOfacLoading(false);
      controlToast(true, t("plm.tasks.ofac-no-results"), "error");
      await setDoc(
        insuredRef,
        {
          ofac: [],
        },
        { merge: true }
      );
      console.error("Error checking OFAC", error);
    }
  };

  const handleSaferReport = async (e, newTaskList) => {
    const policyRef = doc(db, "policy-applications", policyID);
    try {
      e.preventDefault();
      setSaferReportLoading(true);
      controlToast(true, t("plm.tasks.safer-loading"), "info");
      const result = await apiService.getSaferWithDotScreenshot({
        dot: insuredInfo?.dot,
        policyID,
      });
      const screenshot = result.screenshot;
      await setDoc(
        policyRef,
        {
          saferReport: screenshot,
        },
        { merge: true }
      );
      await updatePolicyField(policyID, "underwritingTasks", newTaskList);
      setSaferReportLoading(false);
      controlToast(true, t("plm.tasks.safer-success"), "success");
      setSaferReportResultsModal(true);
    } catch (error) {
      controlToast(true, t("plm.tasks.safer-error"), "error");
      await setDoc(
        policyRef,
        {
          saferReport: "",
        },
        { merge: true }
      );
      console.error("Error Generating Safer Report", error);
    }
  };

  const ofacExists = formData?.ofac && formData?.ofac?.length > 0;
  const ofacWasRequested = typeof formData?.ofac !== "undefined";

  const saferReportExists = formData?.saferReport;
  const saferReportWasRequested = typeof formData?.saferReport !== "undefined";

  const [openShareModal, setOpenShareModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, payload) => {
    try {
      e.preventDefault();
      setLoading(true);
      const data = {
        email: payload.email,
        driversWithRedFlags: driversWithRedFlags.drivers,
        urlToRedirect: window.location.href,
      };
      await apiService.sendEmailWithRedflags(data);
      setLoading(false);
      controlToast(true, t("plm.tasks.email-sent"), "success");
      setOpenShareModal(false);
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const formSettings = {
    onSubmit: handleSubmit,
    inputs: [
      {
        label: t("plm.tasks.email-to-be-sent-to"),
        name: "email",
        required: true,
        type: "email",
      },
    ],
    buttonLabel: "Send Email",
  };

  return (
    <div>
      <Popup
        maxWidth="50%"
        isOpen={viewIssues}
        onRequestClose={() => setViewIssues(false)}
      >
        <div>
          <div className="flex justify-between items-center">
            <p className="text-2xl text-red-700">
              {t("plm.tasks.red-flags-title")}
            </p>
          </div>

          <BlueButton
            text={t("plm.share-module.share")}
            onClickFunction={(e) => {
              e.preventDefault();
              setOpenShareModal(true);
            }}
            hasContentToTop={true}
            hasContentToBottom={true}
            icon={<IosShareIcon />}
            className="h-[33px] mb-3 mt-2"
          />
          <Table>
            <Thead>
              <Tr>
                <Td className="bg-[#072a48] w-[150px] text-white rounded-tl-md">
                  {t("plm.tasks.driver")}
                </Td>
                <Td className="bg-[#072a48] text-white rounded-tr-md">
                  {t("plm.tasks.red-flags")}
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              {driversWithRedFlags?.drivers?.map((driver, index) => {
                const { flags, driverItem } = driver;
                return (
                  <Tr key={index}>
                    <Td>
                      {driverItem?.driverFirst} {driverItem?.driverLast}
                    </Td>
                    <Td>
                      {flags?.map((flag, index) => {
                        return <li key={index}>{flag}</li>;
                      })}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </Popup>
      <FormPopup
        isOpen={openShareModal}
        onRequestClose={() => setOpenShareModal(false)}
        title={t("plm.tasks.corresponding-email")}
        formSettings={formSettings}
        loading={loading}
        zIndex={1001}
      />
      <Popup
        maxWidth="50%"
        isOpen={ofacResultsModal}
        onRequestClose={() => setOfacResultsModal(false)}
      >
        <div>
          <p className="text-2xl p-3 shadow-md text-center">
            {t("plm.tasks.ofac-results")}
          </p>
          <OFACSection currentItems={formData?.ofac} />
        </div>
      </Popup>
      <Table>
        <Thead>
          <Tr>
            <Td className="bg-[#072a48] w-[150px] text-white rounded-tl-md">
              {t("plm.tasks.status")}
            </Td>
            <Td className="bg-[#072a48] text-white">
              {t("plm.tasks.description")}
            </Td>
            <Td className="bg-[#072a48] text-white">
              {t("plm.tasks.due-date")}
            </Td>
            <Td className="bg-[#072a48] text-white rounded-tr-md">
              {t("plm.tasks.actions")}
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {taskList.map((task, index) => {
            return (
              <>
                {task.description === "Safer Report" ? (
                  <SaferReport
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    handleSaferReport={handleSaferReport}
                    saferReportExists={saferReportExists}
                    saferReportWasRequested={saferReportWasRequested}
                    saferReportLoading={saferReportLoading}
                    saferReport={formData?.saferReport}
                  />
                ) : null}
                {task.description === "CAB Analysis" ? (
                  <CabAnalysis
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    policyID={policyID}
                    taskList={taskList}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setProgressState={setProgressState}
                    updatePolicyField={updatePolicyField}
                    insuredInfo={insuredInfo}
                  />
                ) : null}
                {task.description === "OFAC Review" ? (
                  <OfacReview
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    handleOFAC={handleOFAC}
                    ofacExists={ofacExists}
                    setOfacResultsModal={setOfacResultsModal}
                    insuredInfo={insuredInfo}
                    ofacWasRequested={ofacWasRequested}
                    ofacLoading={ofacLoading}
                  />
                ) : null}
                {task.description === "Drivers Reviewed" ? (
                  <DriverReviewed
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setViewIssues={setViewIssues}
                    policyID={policyID}
                    taskList={taskList}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setProgressState={setProgressState}
                    updatePolicyField={updatePolicyField}
                    insuredInfo={insuredInfo}
                  />
                ) : null}
                {task.description === "Loss Experience Analysis" ? (
                  <LossExperienceAnalysis
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    policyID={policyID}
                    taskList={taskList}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setProgressState={setProgressState}
                    updatePolicyField={updatePolicyField}
                    insuredInfo={insuredInfo}
                  />
                ) : null}
                {task.description === "UWQ & Commodities Review" ? (
                  <UwqCommoditiesReview
                    task={task}
                    formData={formData}
                    index={index}
                    handleUpload={handleUpload}
                    setFiles={setFiles}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    policyID={policyID}
                    taskList={taskList}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setProgressState={setProgressState}
                    updatePolicyField={updatePolicyField}
                    insuredInfo={insuredInfo}
                  />
                ) : null}
                {task.description === "Proposal Review" ? (
                  <ProposalReview
                    task={task}
                    formData={formData}
                    index={index}
                    policyID={policyID}
                    insuredInfo={insuredInfo}
                  />
                ) : null}
              </>
            );
          })}
          {taskList2.map((task, index) => {
            return (
              <Tr key={"otherLossRun" + index}>
                <Td valign="top">
                  <p className="my-1">{task.status}</p>
                </Td>
                <Td valign="top">
                  <p className="my-1">{task.description}</p>
                </Td>
                <Td valign="top">
                  <p className="my-1">
                    {formatAmericanDate(task.dueDate, timezone)}
                  </p>
                </Td>
                <Td valign="top">
                  {task.type === "file" ? (
                    <MultiUpload
                      label={fileProps?.[task?.description]?.label}
                      property={fileProps?.[task?.description]?.property}
                      formData={fileProps?.[task?.description]?.files}
                      setFormData={fileProps?.[task?.description]?.setFiles}
                      storageFile={fileProps?.[task?.description]?.storageFile}
                      multiple={false}
                      changeNormal={true}
                      dissapearWhenImgExists={true}
                      showPastingOption={true}
                      fileInButton={true}
                      showInAPopup={true}
                      onSecondaryUploadFn={(urls, results) => {
                        onSecondaryUploadFn(
                          urls,
                          results,
                          fileProps?.[task?.description],
                          task
                        );
                      }}
                      onSecondaryDeleteBeforeFn={() =>
                        onSecondaryDeleteBeforeFn(
                          fileProps?.[task?.description]
                        )
                      }
                    />
                  ) : null}
                  {task.type === "checklist" && (
                    <>
                      {task?.checkListOptions?.map((checkbox, index) => {
                        return (
                          <div className="d-block">
                            <TaskCheckbox
                              label={checkbox.label}
                              field={checkbox.value}
                              checklistOptions={task.checkListOptions}
                              policyID={policyID}
                              formData={formData}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}
