import { doc, getDoc, setDoc } from "firebase/firestore";
import ApiService from "../services/southern-api/apiService";
import { db } from "../firebase";
import { getLastId } from "./endorsementDetails";
import { parseIssuedDateFromInspas } from "../Components/Finances/Tables/utils";
import { numberWithCommas } from "./helpers";

const apiService = new ApiService()

const humanDate = (date) => {
    if (!date) return '-';
    if (typeof date === 'string' && /^[A-Za-z]{3} \d{1,2} \d{4} \d{1,2}:\d{2}(AM|PM)$/.test(date)) {
        date = date.replace(/\s+/g, ' ').trim();
        const parsedDate = new Date(Date.parse(date));
        if (!isNaN(parsedDate)) {
            return parsedDate.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
            });
        } else {
            console.log('Fecha no válida:', date);
            return '-';
        }
    }
    if (typeof date === 'string' && date?.length === 8) {
        return parseIssuedDateFromInspas(date);
    }
    if (date && date.toDate) {
        return date.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
        });
    }
    return date;
};

const formatPrice = (number, without$ = false) => {
    if (number === undefined || number === null || isNaN(number)) {
      return "0.00";
    }
    const roundedNumber = parseFloat(number).toFixed(2);
    const priceWithCommas = roundedNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return without$ ? `${priceWithCommas}` : `$${priceWithCommas}`;
};

const priceToHandle = (price, withPriceSign) => {
    if (price) {
      const amount = numberWithCommas(
        price.toFixed(2)
      )
      if (amount === NaN || !amount || amount === 'NaN') {
        return 'Not calculated yet'
      }
      return `${withPriceSign ? '$' : ''}${amount}`
    } else {
      return 'Not calculated yet'
    }
}

export const calculateTaxesAmounts = (alAmount, glAmount, withPriceSign = true) => {
    if (!alAmount && !glAmount) return 'Not amount found'
    if (alAmount && !glAmount) return priceToHandle(alAmount, withPriceSign)
    if (!alAmount && glAmount) return priceToHandle(glAmount, withPriceSign)
    const amount = alAmount + glAmount
    return priceToHandle(amount, withPriceSign)
}

const getData = async (data, agencyID, insuredID, type) => {
    let subcollection =
        type?.includes('Driver') ? 'drivers' :
        type?.includes('Power Unit') ? 'power-units' : 
        type?.includes('Trailer') ? 'trailers' : ''
    let items = [];
    for (const referenceId of data) {
      const docRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        subcollection,
        getLastId(referenceId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        items.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    return items;
};

const getSwapPowerUnitData = async (data, agencyID, insuredID, type) => {
    const oldVehicles = await getData(data?.oldVehicles, agencyID, insuredID, type)
    const newVehicles = await getData(data?.newVehicles, agencyID, insuredID, type)
    return { oldVehicles, newVehicles }
};

export const endorsementPdfPayloads = async (
    endorsement,
    auth,
    insuredInfo,
    agencyInfo
) => {
    try {
        let data = []
        if (
            endorsement?.type === 'Add Drivers' ||
            endorsement?.type === 'Remove Drivers' ||
            endorsement?.type === 'Exclude Drivers'
        ) {
            const results = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            data = results?.map((driver, index) => {
                const policyDetail = driver?.policyDetails?.find(
                    (policy) => policy.id === endorsement?.policyID
                )
                return {
                    no: String(index + 1) || '-',
                    driverName: driver?.driverFirst ? `${driver?.driverFirst} ${driver?.driverLast ? `${driver?.driverLast}` : ''}` : '-',
                    dob: humanDate(driver?.dob),
                    licenseNumber: driver?.licenseNumber || '-',
                    expDate: humanDate(driver?.licenseExpirationDate || driver?.licenseExpiration),
                    licenseType: policyDetail?.inspasData?.LicClass || '-'
                }
            })
            data = await Promise.all(data)
        }
        if (
            endorsement?.type === 'Add Driver' ||
            endorsement?.type === 'Remove Driver' ||
            endorsement?.type === 'Exclude Driver'
        ) {
            const drivers = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            const driver = drivers?.[0]
            data = [{
                no: '1',
                driverName: driver?.driverFirst ? `${driver?.driverFirst} ${driver?.driverLast ? `${driver?.driverLast}` : ''}` : '-',
                dob: humanDate(driver?.dob),
                licenseNumber: driver?.licenseNumber || '-',
                expDate: humanDate(driver?.licenseExpirationDate || driver?.licenseExpiration),
                licenseType: driver?.licenseClass || '-'
            }]
            data = await Promise.all(data)
        }
        if (
            endorsement?.type === 'Add Power Units' ||
            endorsement?.type === 'Remove Power Units'
        ) {
            const results = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            data = results?.map(async (powerUnit, index) => {
                const policyDetail = powerUnit?.policiesDetails?.find(policyDetail => policyDetail.id === endorsement?.policyID)
                return {
                    no: String(index + 1) || '-',
                    vin: powerUnit?.truckVIN || '-',
                    make: powerUnit?.truckMake || '-',
                    model: powerUnit?.truckModel || '-',
                    year: powerUnit?.truckYear || '-',
                    grossWeight: powerUnit?.truckWeight || '-',
                    radius: policyDetail?.truckRadius || '-',
                    puNet: formatPrice(policyDetail?.truckACV),
                }
            })
            data = await Promise.all(data)
        }
        if (
            endorsement?.type === 'Add Power Unit' ||
            endorsement?.type === 'Remove Power Unit'
        ) {
            const powerUnits = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            const powerUnit = powerUnits?.[0]
            const policyDetail = powerUnit?.policiesDetails?.find(policyDetail => policyDetail.id === endorsement?.policyID)
            data = [{
                no: '1',
                vin: powerUnit?.truckVIN || '-',
                make: powerUnit?.truckMake || '-',
                model: powerUnit?.truckModel || '-',
                year: powerUnit?.truckYear || '-',
                grossWeight: powerUnit?.truckWeight || '-',
                radius: policyDetail?.truckRadius || '-',
                puNet: formatPrice(policyDetail?.truckACV),
            }]
            data = await Promise.all(data)
        }
        if (
            endorsement?.type === 'Add Trailers' ||
            endorsement?.type === 'Remove Trailers'
        ) {
            const results = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            data = results?.map((trailer, index) => {
                return {
                    no: String(index + 1) || '-',
                    vin: trailer?.trailerVIN || '-',
                    make: trailer?.trailerMake || '-',
                    year: trailer?.trailerYear || '-',
                    acv: trailer?.trailerACV || '-',
                }
            })
            data = await Promise.all(data)
        }
        if (
            endorsement?.type === 'Add Trailer' ||
            endorsement?.type === 'Remove Trailer'
        ) {
            const trailers = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            const trailer = trailers?.[0]
            data = [{
                no: '1',
                vin: trailer?.trailerVIN || '-',
                make: trailer?.trailerMake || '-',
                year: trailer?.trailerYear || '-',
                acv: trailer?.trailerACV || '-',
            }]
            data = await Promise.all(data)
        }
        if (endorsement?.type === 'Adjust Radius') {
            const powerUnits = await getData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            const powerUnit = powerUnits?.[0]
            const policyDetail = powerUnit?.policiesDetails?.find(policyDetail => policyDetail.id === endorsement?.policyID)
            data = [{
                no: '1',
                vin: powerUnit?.truckVIN || '-',
                make: powerUnit?.truckMake || '-',
                model: powerUnit?.truckModel || '-',
                year: powerUnit?.truckYear || '-',
                grossWeight: powerUnit?.truckWeight || '-',
                newRadius: policyDetail?.truckRadius || '-',
                oldRadius: policyDetail?.oldRadius || '-',
                puNet: formatPrice(policyDetail?.truckACV),
            }]
            data = await Promise.all(data)
        }
        if (endorsement?.type === 'Swap Power Units') {
            const {
                oldVehicles,
                newVehicles
            } = await getSwapPowerUnitData(
                endorsement?.data,
                endorsement?.agencyID,
                endorsement?.insuredID,
                endorsement?.type
            )
            data = {
                newVehicles: newVehicles?.map((powerUnit, index) => {
                    const policyDetail = powerUnit?.policiesDetails?.find(policyDetail => policyDetail.id === endorsement?.policyID)
                    return {
                        no: String(index + 1) || '-',
                        vin: powerUnit?.truckVIN || '-',
                        make: powerUnit?.truckMake || '-',
                        model: powerUnit?.truckModel || '-',
                        year: powerUnit?.truckYear || '-',
                        grossWeight: powerUnit?.truckWeight || '-',
                        radius: policyDetail?.truckRadius || '-',
                        puNet: formatPrice(policyDetail?.truckACV),
                    }                
                }),
                oldVehicles: oldVehicles?.map((powerUnit, index) => {
                    const policyDetail = powerUnit?.policiesDetails?.find(policyDetail => policyDetail.id === endorsement?.policyID)
                    return {
                        no: String(index + 1) || '-',
                        vin: powerUnit?.truckVIN || '-',
                        make: powerUnit?.truckMake || '-',
                        model: powerUnit?.truckModel || '-',
                        year: powerUnit?.truckYear || '-',
                        grossWeight: powerUnit?.truckWeight || '-',
                        radius: policyDetail?.truckRadius || '-',
                        puNet: formatPrice(policyDetail?.truckACV),
                    }                
                }),
            }
        }
    
        let premium = null
        let surplusLinesTax = null
        let stampingFee = null
        let processingFee = null
        let totalAmount = null
        let otherFees = null
        if (
            endorsement?.type !== 'Swap Power Units'
        ) {
            premium = endorsement?.alPremiums?.GWP || '$0.00'
            surplusLinesTax = endorsement?.alPremiums?.surplusLinesTaxAmount || '$0.00'
            stampingFee = endorsement?.alPremiums?.stampTaxAmount || '$0.00'
            processingFee = endorsement?.alPremiums?.processingFeeAmount || '$0.00'
            totalAmount = endorsement?.alPremiums?.totalPremium || '$0.00'
            otherFees = endorsement?.alPremiums?.otherFees || '$0.00'
        } else if (
            endorsement?.data?.newVehicles ||
            endorsement?.policyID ||
            endorsement?.type === 'Swap Power Units'
        ) {
            const alPremiums = endorsement?.alPremiums
            premium = alPremiums?.GWP || '$0.00'
            surplusLinesTax = alPremiums?.surplusLinesTaxAmount || '$0.00'
            stampingFee = alPremiums?.stampTaxAmount || '$0.00'
            processingFee = alPremiums?.processingFeeAmount || '$0.00'
            totalAmount = alPremiums?.totalPremium || '$0.00'
            otherFees = alPremiums?.otherFees || '$0.00'
        }
    
        let endoPayload = {
            id: endorsement?.id,
            userId: auth?.uid,
            type: endorsement?.type,
            payload: [{
              policyNo: endorsement?.alPolicyNo || '-',
              effectiveDate: humanDate(endorsement?.endoEffectiveDate),
              namedInsured: endorsement?.namedInsured || '-',
              dba: agencyInfo?.dba || '-',
              issueDate: humanDate(endorsement?.issuedDate ? endorsement?.issuedDate : endorsement?.IssuedDate),
              premium: formatPrice(premium).replace('$', ''),
              surplusLinesTax: formatPrice(surplusLinesTax).replace('$', ''),
              stampingFee: formatPrice(stampingFee).replace('$', ''),
              processingFee: formatPrice(processingFee).replace('$', ''),
              totalAmount: formatPrice(totalAmount).replace('$', ''),
            }]
        }
    
        if (
            endoPayload?.type === 'Add Drivers' ||
            endoPayload?.type === 'Add Driver' ||
            endoPayload?.type === 'Remove Drivers' ||
            endoPayload?.type === 'Remove Driver' ||
            endoPayload?.type === 'Add Power Units' ||
            endoPayload?.type === 'Add Power Unit' ||
            endoPayload?.type === 'Remove Power Units' ||
            endoPayload?.type === 'Remove Power Unit' ||
            endoPayload?.type === 'Add Trailers' ||
            endoPayload?.type === 'Add Trailer' ||
            endoPayload?.type === 'Remove Trailers' ||
            endoPayload?.type === 'Remove Trailer' ||
            endoPayload?.type === 'Exclude Drivers' ||
            endoPayload?.type === 'Exclude Driver' ||
            endoPayload?.type === 'Adjust Radius' ||
            endoPayload?.type === 'Swap Power Units'
        ) {
            endoPayload.payload[0].company = insuredInfo?.company || '-'
            endoPayload.payload[0].data = data || []
            endoPayload.payload[0].endoNo = endorsement?.endorsementNumber || '-'
        }
        if (endoPayload?.type === 'Add Additional Insureds') {
            endoPayload.payload[0].name = insuredInfo?.company || '-'
            endoPayload.payload[0].address = insuredInfo?.address || '-'
            endoPayload.payload[0].otherFees = formatPrice(otherFees) || '-'
        }
        if (endoPayload?.type === 'Cancellation') {
            const alPremiums = endorsement?.alPremiums;
            endoPayload.payload[0].company = insuredInfo?.company || '-'
            endoPayload.payload[0].address = insuredInfo?.address || '-'
            endoPayload.payload[0].reason1 = false
            endoPayload.payload[0].reason2 = true
            endoPayload.payload[0].otherReasons = endorsement?.cancellationReasons?.join(', ') || '-'
            endoPayload.payload[0].premium = priceToHandle(alPremiums?.GWP)
            endoPayload.payload[0].processingFee = priceToHandle(alPremiums?.processingFeeAmount)
            endoPayload.payload[0].surplusLinesTax = priceToHandle(alPremiums?.surplusLinesTaxAmount)
            endoPayload.payload[0].stampingFee = priceToHandle(alPremiums?.stampTaxAmount)
            endoPayload.payload[0].totalAmount = priceToHandle(alPremiums?.totalPremium)
        }
        if (
            endoPayload?.type === 'Add Drivers' ||
            endoPayload?.type === 'Add Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Driver(s)'
        } else if (
            endoPayload?.type === 'Remove Drivers' ||
            endoPayload?.type === 'Remove Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Driver(s)'
        } else if (
            endoPayload?.type === 'Add Power Units' ||
            endoPayload?.type === 'Add Power Unit'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Power Unit(s)'
        } else if (
            endoPayload?.type === 'Remove Power Units' ||
            endoPayload?.type === 'Remove Power Unit'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Power Unit(s)'
        } else if (
            endoPayload?.type === 'Add Trailers' ||
            endoPayload?.type === 'Add Trailer'
        ) {
            endoPayload.payload[0].tableTitle = 'Added Trailer(s)'
        } else if (
            endoPayload?.type === 'Remove Trailers' ||
            endoPayload?.type === 'Remove Trailer'
        ) {
            endoPayload.payload[0].tableTitle = 'Removed Trailer(s)'
        } else if (
            endoPayload?.type === 'Exclude Drivers' ||
            endoPayload?.type === 'Exclude Driver'
        ) {
            endoPayload.payload[0].tableTitle = 'Excluded Driver(s)'
        } else if (endoPayload?.type === 'Adjust Radius') {
            endoPayload.payload[0].tableTitle = 'Adjust Operational Radius'
        } else if (endoPayload?.type === 'Swap Power Units') {
            endoPayload.payload[0].tableTitle1 = 'Swapped Power Unit(s)'
            endoPayload.payload[0].tableTitle2 = 'Swapped Power Unit(s)'
        }

        if (
            endorsement?.type === 'Add Additional Insureds' ||
            endorsement?.type === 'Blanket Additional Insured'
        ) {
            const address =
                endorsement?.data?.addressLine1 ?
                `${endorsement?.data?.addressLine1} ${endorsement?.data?.addressLine2 ? `, ${endorsement?.data?.addressLine2}` : ''} ${endorsement?.data?.city ? `, ${endorsement?.data?.city}` : ''} ${endorsement?.data?.state ? `, ${endorsement?.data?.state}` : ''} ${endorsement?.data?.zip ? `, ${endorsement?.data?.zip}` : ''}` :
                endorsement?.data?.Address ?
                `${endorsement?.data?.Address} ${endorsement?.data?.AddressLineTwo ? `, ${endorsement?.data?.AddressLineTwo}` : ''} ${endorsement?.data?.City ? `, ${endorsement?.data?.City}` : ''} ${endorsement?.data?.State ? `, ${endorsement?.data?.State}` : ''} ${endorsement?.data?.Zip ? `, ${endorsement?.data?.Zip}` : ''}` : '-'

            const premium = endorsement?.alPremiums?.GWP || ''
            const surplusLinesTax = endorsement?.alPremiums?.surplusLinesTaxAmount || ''
            const stampingFee = endorsement?.alPremiums?.stampTaxAmount || ''
            const processingFee = endorsement?.alPremiums?.processingFeeAmount || ''
            const otherFees = endorsement?.alPremiums?.otherFees || ''
            const totalAmount = endorsement?.alPremiums?.totalPremium || ''

            endoPayload = {
                id: endorsement?.id,
                userId: auth?.uid,
                type: endorsement?.type,
                payload: [{
                    policyNo: endorsement?.alPolicyNo || '-',
                    namedInsured: endorsement?.namedInsured || '-',
                    effectiveDate: humanDate(endorsement?.endoEffectiveDate),
                    issueDate: humanDate(endorsement?.issuedDate ? endorsement?.issuedDate : endorsement?.IssuedDate),
                    dba: agencyInfo?.dba || '-',
                    name: endorsement?.data?.name || '-',
                    address,
                    premium: formatPrice(premium).replace('$', ''),
                    surplusLinesTax: formatPrice(surplusLinesTax).replace('$', ''),
                    stampingFee: formatPrice(stampingFee).replace('$', ''),
                    processingFee: formatPrice(processingFee).replace('$', ''),
                    otherFees: formatPrice(otherFees).replace('$', ''),
                    totalAmount: formatPrice(totalAmount).replace('$', ''),
                }]
            }
        }

        if (endorsement?.type === 'Add Waiver Of Subrogation') {
            const waiverData = endorsement?.data
            waiverData['waiverId'] =
                waiverData.type === "AL" ? "auto-liability" :
                waiverData.type === "GL" ? "general-liability" : "";
            waiverData['effectiveDate'] = endorsement?.endoEffectiveDate?.seconds
            const result = await apiService.createLiabilityPdf(waiverData)
            window.open(result?.url);
            return
        }

        const pdfResult = await apiService.printEndorsement(endoPayload)
        if (pdfResult?.url) {
            const endoRef = doc(
                db,
                "policy-applications",
                endorsement?.policyID,
                "endorsements",
                endorsement?.id,
            );
            await setDoc(endoRef, {
                file: [pdfResult?.url]
            }, { merge: true });
            window.open(pdfResult?.url);
            return
        }
    } catch (error) {
        console.log('error', error)
    }
};
