import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { POLICY_ROUTE } from "../../../utils/routes";
import useTranslations from "../../../Context/actions/useTranslations";
import Pagination from "../../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../../generals/TableSkeleton";
import {
  activeFilters,
  handleFilterStatus,
  managePoliciesOptions,
} from "../../../utils/searchFilters";
import ListFilters from "./ListFilters";

const policysPerPage = 10;

function PoliciesList({
  policyList,
  setPolicyList,
  userInfo,
  loading,
  setLoading,
}) {
  const { t, language, timezone } = useTranslations();

  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [currentFilters, setCurrentFilters] = useState({
    inForce: true,
    inRenewal: true,
    inCancellation: true,
  });

  const [hasSelectedInForce, setHasSelectedInForce] = useState(false);

  useEffect(() => {
    let filters = activeFilters(managePoliciesOptions, currentFilters);
    if (filters.includes("In-Force") && !hasSelectedInForce) {
      setHasSelectedInForce(true);
      if (!filters.includes("In-Renewal")) {
        filters.push("In-Renewal");
      }
      if (!filters.includes("In-Cancellation")) {
        filters.push("In-Cancellation");
      }
      setCurrentFilters((prev) => ({
        ...prev,
        inRenewal: true,
        inCancellation: true,
        inForce: true,
      }));
    } else if (!filters.includes("In-Force") && hasSelectedInForce) {
      setHasSelectedInForce(false);
    }
    setLoading(true);
    if (userInfo.agencyID) {
      const policiesCollection = collection(db, "policy-applications");

      const buildQuery = () => {
        let q = query(
          policiesCollection,
          where("documentType", "==", "Policy")
        );

        if (userInfo.role !== "Admin" && userInfo.role !== "Underwriter") {
          q = query(q, where("agencyID", "==", userInfo.agencyID));
        }

        if (filters.length > 0) {
          q = query(q, where("status", "in", filters));
        }

        return q;
      };

      const policiesQuery = buildQuery();
      const unsubscribe = onSnapshot(policiesQuery, async (snapshot) => {
        const updatedPolicyListPromises = snapshot.docs.map(
          async (policyDoc) => {
            const policyData = policyDoc.data();
            const { namedInsured, agencyID, ...rest } = policyData;

            if (agencyID) {
              const insuredDocRef = doc(
                db,
                "agencies",
                userInfo.agencyID,
                "insureds",
                policyData.insuredID
              );
              const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
              const insuredDocSnapshot = await getDoc(insuredDocRef);
              const agencyDocSnapshot = await getDoc(agencyDocRef);
              const agencyData = agencyDocSnapshot.data();

              if (insuredDocSnapshot.exists()) {
                const companyName = insuredDocSnapshot.data().company;
                return {
                  id: policyDoc.id,
                  namedInsured: companyName,
                  agencyID,
                  insuredInfo: insuredDocSnapshot.data(),
                  agencyInfo: agencyData,
                  ...rest,
                };
              }
            } else {
              console.error(
                "userInfo.agencyID is null or undefined. Skipping database operation."
              );
            }
            return null;
          }
        );
        let updatedPolicyList = await Promise.all(updatedPolicyListPromises);
        const updatedListWithGL = await Promise.all(
          updatedPolicyList.map(async (policy) => {
            if (policy?.agencyID && policy?.insuredID) {
              const glPoliciesCollection = collection(db, "gl-policies");
              const policyGLRef = query(
                glPoliciesCollection,
                where("alPolicyID", "==", policy.id),
              );
              const glPolicySnapshot = await getDocs(policyGLRef);
              let glPolicyData = null;
              if (glPolicySnapshot?.docs?.[0]?.exists()) {
                glPolicyData = {
                  id: glPolicySnapshot.docs[0].id,
                  ...glPolicySnapshot.docs[0].data(),
                };
              }
              return { ...policy, glPolicyData };
            } else {
              return { ...policy, glPolicyData: null };
            }
          })
        );
        updatedPolicyList = updatedListWithGL;
        if (!filters.includes("Has AL Coverage") && filters.includes("Has GL Coverage")) {
          updatedPolicyList = updatedPolicyList.filter(policy => {
            return Boolean(policy?.glPolicyData !== null);
          });
        } else if (filters.includes("Has AL Coverage") && !filters.includes("Has GL Coverage")) {
          updatedPolicyList = updatedPolicyList.filter(policy => {
            return Boolean(policy?.glPolicyData === null);
          });
        }
        setPolicyList(updatedPolicyList.filter(Boolean));
        setLoading(false);
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID, currentFilters]);

  const policyListToFilter = Array.isArray(policyList) ? policyList : [];

  const filteredData = policyListToFilter.filter((policy) =>
    `${policy?.status} ${policy?.documentType} ${policy?.namedInsured} ${policy?.agencyInfo?.name} ${policy?.effectiveDate} ${policy?.totalWrittenPremium} ${policy?.insuredInfo?.dot}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * policysPerPage;
  const currentItems = sortedData.slice(offset, offset + policysPerPage);

  const pageCount = Math.ceil(sortedData.length / policysPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const navigate = useNavigate();
  const handleRowClick = (application) => {
    navigate(`${POLICY_ROUTE}/${application.id}`);
  };

  return (
    <main>
      <div>
        <ListFilters
          options={managePoliciesOptions}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
        />
      </div>
      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : policyList.length === 0 ? (
        <p>{t("manage-policies.no-policies")}</p>
      ) : (
        <div>
          <div className="mb-2">
            <h2 className="text-2xl font-semibold mb-1">
              {t("manage-policies.policy-list")}
            </h2>
          </div>
          <input
            type="text"
            placeholder={t("manage-policies.search")}
            value={searchTerm}
            onChange={handleSearch}
            className="mb-2 p-2 border rounded w-full"
          />
          <Table>
            <Thead>
              <Tr>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "status",
                      "bg-blue-500": sortConfig.key === "status",
                    }
                  )}
                  onClick={() => handleSort("status")}
                >
                  {t("manage-policies.status")}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "documentType",
                      "bg-blue-500": sortConfig.key === "documentType",
                    }
                  )}
                  onClick={() => handleSort("documentType")}
                >
                  {t("manage-policies.policy-no")}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "namedInsured",
                      "bg-blue-500": sortConfig.key === "namedInsured",
                    }
                  )}
                  onClick={() => handleSort("namedInsured")}
                >
                  {t("manage-policies.named-insured")}
                </Td>
                {userInfo.role === "Admin" ||
                userInfo.role === "Underwriter" ? (
                  <Td
                    className={classNames(
                      "bg-[#072a48] text-white cursor-pointer",
                      {
                        "hover:bg-[#072a48]": sortConfig.key !== "agencyName",
                        "bg-blue-500": sortConfig.key === "agencyName",
                      }
                    )}
                    onClick={() => handleSort("agencyName")}
                  >
                    {t("manage-policies.agency-name")}
                  </Td>
                ) : null}
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "glPolicyData",
                      "bg-blue-500": sortConfig.key === "glPolicyData",
                    }
                  )}
                  onClick={() => handleSort("glPolicyData")}
                >
                  {t("manage-policies.gl-coverage")}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "effectiveDate",
                      "bg-blue-500": sortConfig.key === "effectiveDate",
                    }
                  )}
                  onClick={() => handleSort("effectiveDate")}
                >
                  {t("manage-policies.effective-date")}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "dot",
                      "bg-blue-500": sortConfig.key === "dot",
                    }
                  )}
                  onClick={() => handleSort("dot")}
                >
                  {t("manage-policies.dot")}
                </Td>
              </Tr>
            </Thead>

            <Tbody>
              {currentItems.map((policy, index) => (
                <Tr
                  key={policy.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleRowClick(policy)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === index ? "#e6f7ff" : "inherit",
                  }}
                >
                  <Td>
                    {handleFilterStatus(
                      policy?.status,
                      managePoliciesOptions,
                      language
                    )}
                  </Td>
                  <Td>{policy?.alPolicyNo} </Td>
                  <Td title={policy.insuredID}>{policy?.namedInsured} </Td>
                  {userInfo.role === "Admin" ||
                  userInfo.role === "Underwriter" ? (
                    <Td title={policy.agencyID}>{policy?.agencyInfo?.name} </Td>
                  ) : null}
                  <Td>
                    {policy?.glPolicyData !== null ? "Yes" : "No"}
                  </Td>
                  <Td>
                    {policy?.effectiveDate && (
                      <>
                        {policy?.effectiveDate
                          ?.toDate()
                          .toLocaleDateString(timezone, {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })}
                      </>
                    )}{" "}
                  </Td>
                  <Td>{policy?.insuredInfo?.dot} </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </main>
  );
}

export default PoliciesList;
