function isAlphaNumeric(str) {
  if (/^[a-zA-Z0-9]+$/.test(str) === false) {
    return false;
  } else if (/[a-zA-Z]/g.test(str) === false) {
    return false;
  } else if (/\d/.test(str) === false) {
    return false;
  } else {
    return true;
  }
}

export default isAlphaNumeric;
