const updateCommodityTotal = (policyID, formData, insuredInfo, setInsuredInfo) => {
  let customCommodityTotal = 0;
  insuredInfo.commodityFields.forEach((element) => {
    customCommodityTotal =
      parseInt(customCommodityTotal, 10) + parseInt(element.commodityLoad, 10);
  });
  const standardCommoditiesTotal = Object.values(
    insuredInfo.standardCommodityPercentages ?? {}
  )
    .filter((value) => !isNaN(parseInt(value, 10)))
    .reduce((a, b) => a + parseInt(b, 10), 0);
  const total =
    parseInt(customCommodityTotal, 10) + parseInt(standardCommoditiesTotal);

  setInsuredInfo({
    ...insuredInfo,
    commodityTotal: total,
  })
  return total;
};

export default updateCommodityTotal;
