import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { auth, db } from "../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import sendAbsenceEmail from "../utils/sendAbsenceEmail";

const AbsenceForm = () => {
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [date, setDate] = useState(new Date());
  const [type, setType] = useState("");
  const [reason, setReason] = useState("");
  const [hasMedicalExcuse, setHasMedicalExcuse] = useState(false);
  const [showMedicalExcuse, setShowMedicalExcuse] = useState(false);
  const [comments, setComments] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      setEmail(auth.currentUser.email);
      setDisplayName(auth.currentUser.displayName);
    }
  }, []);

  const reasons = {
    absence: ["Illness", "Personal Leave", "Family Emergency", "Other"],
    tardy: ["Traffic", "Public Transportation Delay", "Other"],
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setReason("");
    setShowMedicalExcuse(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setShowMedicalExcuse(event.target.value === "Illness");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Save to Firestore
    const collectionRef = collection(db, "absencesAndTardies");
    await addDoc(collectionRef, {
      email,
      date,
      type,
      reason,
      hasMedicalExcuse,
      comments,
      displayName,
    });

    // Send email notification
    await sendAbsenceEmail(
      email,
      date,
      type,
      reason,
      hasMedicalExcuse,
      comments,
      displayName
    );

    // Show success message
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Card sx={{ width: 400 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Thank you!
            </Typography>
            <Typography variant="body1" component="div">
              Your {type} information has been submitted successfully.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Card sx={{ width: 400 }}>
        <CardHeader
          title={`Employee ${
            type
              ? type.charAt(0).toUpperCase() + type.slice(1)
              : "Absence/Tardy"
          } Form`}
          subheader="Please fill out the following information"
          sx={{ mb: -4 }}
        />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!!auth.currentUser}
              required
            />
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              disabled={!!auth.currentUser}
              required
            />
            <TextField
              select
              label="Type"
              fullWidth
              margin="normal"
              value={type}
              onChange={handleTypeChange}
              required
            >
              <MenuItem value="absence">Absence</MenuItem>
              <MenuItem value="tardy">Tardy</MenuItem>
            </TextField>
            {type && (
              <>
                <Typography variant="body1" marginTop="normal">
                  Date of {type.charAt(0).toUpperCase() + type.slice(1)}
                </Typography>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  className="form-control"
                  required
                />
                <TextField
                  select
                  label="Reason"
                  fullWidth
                  margin="normal"
                  value={reason}
                  onChange={handleReasonChange}
                  required
                >
                  {reasons[type].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
            {showMedicalExcuse && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasMedicalExcuse}
                    onChange={(e) => setHasMedicalExcuse(e.target.checked)}
                  />
                }
                label="Do you have a medical excuse?"
              />
            )}
            <TextField
              label="Comments"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: "#072a48" }}
              fullWidth
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AbsenceForm;
