import React, { useState, useEffect } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import InputField from "./InputField"; // Adjust the path as necessary
import EditDropdownModal from "./EditDropdownModal"; // Adjust the path as necessary
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import numberWithCommas from "../../../utils/numberWithCommas";
import useTranslations from "../../../../Context/actions/useTranslations";
import CustomMTCLineItems from "../UnderwritingDetails/CustomMTCLineItems";

export default function ProvideQuoteFigures({
  formData,
  policyID,
  userInfo,
  isNewQuote,
  insuredInfo
}) {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [apdFields, setApdFields] = useState([]);
  const [mtcFields, setMtcFields] = useState([]);
  const [alFields, setAlFields] = useState([]);
  const [financeFields, setFinanceFields] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const { t } = useTranslations();

  const userIsUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";
  const disabledRule =
    formData.documentType !== "Quote Request" ||
    !userIsUnderwriter ||
    !isNewQuote;

  // Fetch data from Firestore in real-time
  useEffect(() => {
    const unsubscribeDropdown = onSnapshot(
      doc(db, "dropdownData", "dropdownOptions"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "dropdownOptions",
            }))
          );
        }
      }
    );

    const unsubscribeApd = onSnapshot(
      doc(db, "dropdownData", "apdFields"),
      (doc) => {
        if (doc.exists()) {
          setApdFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "apdFields",
            }))
          );
        }
      }
    );

    const unsubscribeMtc = onSnapshot(
      doc(db, "dropdownData", "mtcFields"),
      (doc) => {
        if (doc.exists()) {
          setMtcFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "mtcFields",
            }))
          );
        }
      }
    );

    const unsubscribeAl = onSnapshot(
      doc(db, "dropdownData", "alFields"),
      (doc) => {
        if (doc.exists()) {
          setAlFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "alFields",
            }))
          );
        }
      }
    );

    const unsubscribeFinance = onSnapshot(
      doc(db, "dropdownData", "financeFields"),
      (doc) => {
        if (doc.exists()) {
          setFinanceFields(
            doc.data().fields.map((field) => ({
              ...field,
              parent: "financeFields",
            }))
          );
        }
      }
    );

    // Cleanup listeners on component unmount
    return () => {
      unsubscribeDropdown();
      unsubscribeApd();
      unsubscribeMtc();
      unsubscribeAl();
      unsubscribeFinance();
    };
  }, []);

  const handleEditClick = (field) => {
    setCurrentField(field);
    setEditModalOpen(true);
  };

  const handleUpdateOptions = (updatedOptions) => {
    // check permissions to make sure is underwriter or admin
    if (!userIsUnderwriter) return;
    if (currentField) {
      updateDoc(doc(db, "dropdownData", currentField?.parent), {
        fields: dropdownOptions.map((opt) =>
          opt.field === currentField?.field
            ? { ...currentField, options: updatedOptions }
            : opt
        ),
      }).catch((error) => {
        console.error("Error updating dropdown options:", error);
      });
    }
  };

  const renderField = (
    field,
    value,
    onChange,
    disabled,
    useCondition,
    condition1,
    condition2
  ) => {
    const condition = !condition1
      ? null
      : !condition2
      ? formData?.[condition1]
      : formData?.[condition1]?.[condition2];

    const shouldDisplay = !useCondition || (useCondition && condition);

    if (shouldDisplay) {
      if (field?.isDropdown) {
        return (
          <Box py={2}>
            <FormControl fullWidth variant="outlined" disabled={disabled}>
              <InputLabel>{field?.label}</InputLabel>
              <Select
                required={!field?.notRequired}
                value={value}
                onChange={onChange}
                label={field?.label}
              >
                {/* blank menu item */}
                {field?.notRequired && <MenuItem value="N/A">{"N/A"}</MenuItem>}
                {field?.options.map((option, i) => (
                  <MenuItem key={option.field + "-" + i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!field.doNotAllowOptionEditing && !disabled && (
              <Button
                sx={{ fontSize: "10px" }}
                onClick={() => handleEditClick(field)}
              >
                {t("plm.quotes.underwriting-details.edit-options")}
              </Button>
            )}
          </Box>
        );
      } else {
        return (
          <InputField
            required={!field?.notRequired}
            label={field?.label}
            type={field?.isNumber ? "number" : "text"}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      }
    }
  };

  const renderDisabledFieldWithTIVAsValue = (
    field,
    value,
    onChange,
    disabled
  ) => {
    return (
      <Box py={2}>
        <Tooltip title={t("plm.quotes.underwriting-details.this.amount")}>
          <TextField
            fullWidth
            label={field?.label}
            value={"$" + numberWithCommas(formData?.TIV || 0)}
            onChange={onChange}
            disabled={true}
            variant="outlined"
          />
        </Tooltip>
      </Box>
    );
  };

  const referenceToUse = isNewQuote ? formData?.stagingArea : formData;
  return (
    <>
          <Typography variant="h5" component="h2">
            {t("plm.quotes.underwriting-details.policy-details")}
          </Typography>
          <Typography className="my-2">
            {t("plm.quotes.underwriting-details.developing")}
          </Typography>

          <Grid container spacing={2}>
            {formData.hasALCoverage &&
              alFields.map(
                (field, i) =>
                  (!field.useCondition ||
                    (field?.useCondition &&
                      formData[field?.condition1] &&
                      (!field.condition2 ||
                        formData[field?.condition1][field?.condition2]))) && (
                    <Grid item xs={12} sm={6} key={field?.field + "-" + i}>
                      {renderField(
                        field,
                        !isNewQuote
                          ? formData?.alDetails?.[field?.field] || ""
                          : referenceToUse?.alDetails?.[field?.field] || "",
                        (e) => {
                          updateDoc(doc(db, "policy-applications", policyID), {
                            stagingArea: {
                              ...formData.stagingArea,
                              alDetails: {
                                ...referenceToUse?.alDetails,
                                [field?.field]: field?.isDropdown
                                  ? e.target.value
                                  : parseFloat(e.target.value),
                              },
                            },
                          });
                        },
                        disabledRule,
                        field?.useCondition || false,
                        field?.condition1 || null,
                        field?.condition2 || null
                      )}
                    </Grid>
                  )
              )}
          </Grid>
          {formData?.hasAPDCoverage && (
            <>
              <Box my={2}>
                {renderField(
                  dropdownOptions[1],
                  !isNewQuote
                    ? formData.apdCarrier || ""
                    : referenceToUse?.apdCarrier || "",
                  (e) => {
                    updateDoc(doc(db, "policy-applications", policyID), {
                      stagingArea: {
                        ...formData.stagingArea,
                        apdCarrier: e.target.value,
                      },
                    });
                  },
                  disabledRule
                )}
              </Box>

              <Grid container spacing={2}>
                {formData.hasAPDCoverage &&
                  apdFields.map(
                    (field, i) =>
                      (!field.useCondition ||
                        (field?.useCondition &&
                          formData[field?.condition1] &&
                          (!field.condition2 ||
                            formData[field?.condition1][
                              field?.condition2
                            ]))) && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            key={field?.field + "-" + i}
                          >
                            {field?.field === "collisionLimit" ||
                            field?.field === "comprehensiveLimit" ? (
                              <>
                                {renderDisabledFieldWithTIVAsValue(
                                  field,
                                  formData?.apdDetails?.[field?.field] || "",
                                  (e) => {
                                    updateDoc(
                                      doc(db, "policy-applications", policyID),
                                      {
                                        stagingArea: {
                                          ...formData.stagingArea,
                                          apdDetails: {
                                            ...referenceToUse?.apdDetails,
                                            [field?.field]: field?.isDropdown
                                              ? e.target.value
                                              : parseFloat(e.target.value),
                                          },
                                        },
                                      }
                                    );
                                  },
                                  disabledRule
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {renderField(
                                  field,
                                  !isNewQuote
                                    ? formData?.apdDetails?.[field?.field] || ""
                                    : referenceToUse?.apdDetails?.[
                                        field?.field
                                      ] || "",
                                  (e) => {
                                    updateDoc(
                                      doc(db, "policy-applications", policyID),
                                      {
                                        stagingArea: {
                                          ...formData.stagingArea,
                                          apdDetails: {
                                            ...referenceToUse?.apdDetails,
                                            [field?.field]: field?.isDropdown
                                              ? e.target.value
                                              : parseFloat(e.target.value),
                                          },
                                        },
                                      }
                                    );
                                  },
                                  disabledRule,
                                  field?.useCondition || false,
                                  field?.condition1 || null,
                                  field?.condition2 || null
                                )}
                              </>
                            )}
                          </Grid>
                        </>
                      )
                  )}
                {formData.hasAPDCoverage &&
                  formData?.apdDetails?.trailerInterchangeTarget && (
                    <Grid item xs={12} sm={6}>
                      <Box py={2}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          disabled={false}
                        >
                          <InputLabel>
                            {t(
                              "plm.quotes.underwriting-details.trailer-interchange-limit"
                            )}
                          </InputLabel>
                          <Select
                            required={true}
                            value={
                              !isNewQuote
                                ? formData?.apdDetails
                                    ?.trailerInterchangeLimit || ""
                                : referenceToUse?.apdDetails
                                    ?.trailerInterchangeLimit || ""
                            }
                            onChange={(e) => {
                              updateDoc(
                                doc(db, "policy-applications", policyID),
                                {
                                  stagingArea: {
                                    ...formData.stagingArea,
                                    apdDetails: {
                                      ...referenceToUse?.apdDetails,
                                      trailerInterchangeLimit: parseFloat(
                                        e.target.value
                                      ),
                                    },
                                  },
                                }
                              );
                            }}
                            label={t(
                              "plm.quotes.underwriting-details.trailer-interchange-limit"
                            )}
                          >
                            {dropdownOptions[2]?.options?.map((option, i) => (
                              <MenuItem
                                key={option.field + "-" + i}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          sx={{ fontSize: "10px" }}
                          onClick={() => handleEditClick(dropdownOptions[2])}
                        >
                          {t("plm.quotes.underwriting-details.edit-options")}
                        </Button>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </>
          )}
          {formData.hasMTCCoverage && (
            <>
              <Box my={2}>
                {renderField(
                  dropdownOptions[0],
                  !isNewQuote
                    ? formData.mtcCarrier || ""
                    : referenceToUse?.mtcCarrier || "",
                  (e) => {
                    updateDoc(doc(db, "policy-applications", policyID), {
                      stagingArea: {
                        ...formData.stagingArea,
                        mtcCarrier: e.target.value,
                      },
                    });
                  },
                  disabledRule
                )}
              </Box>


              <Grid container spacing={2}>
                {!formData.hasAPDCoverage &&
                  formData.hasMTCCoverage &&
                  formData?.mtcDetails?.trailerInterchangeTarget && (
                    <Grid item xs={12} sm={6}>
                      <Box py={2}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          disabled={false}
                        >
                          <InputLabel>
                            {t(
                              "plm.quotes.underwriting-details.trailer-interchange-limit"
                            )}
                          </InputLabel>
                          <Select
                            className="w-full"
                            required={true}
                            value={
                              !isNewQuote
                                ? formData?.mtcDetails
                                    ?.trailerInterchangeLimit || ""
                                : referenceToUse?.mtcDetails
                                    ?.trailerInterchangeLimit || ""
                            }
                            onChange={(e) => {
                              updateDoc(
                                doc(db, "policy-applications", policyID),
                                {
                                  stagingArea: {
                                    ...formData.stagingArea,
                                    mtcDetails: {
                                      ...referenceToUse?.mtcDetails,
                                      trailerInterchangeLimit: parseFloat(
                                        e.target.value
                                      ),
                                    },
                                  },
                                }
                              );
                            }}
                            label={t(
                              "plm.quotes.underwriting-details.trailer-interchange-limit"
                            )}
                          >
                            {dropdownOptions[2]?.options?.map((option, i) => (
                              <MenuItem
                                key={option.field + "-" + i}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          sx={{ fontSize: "10px" }}
                          onClick={() => handleEditClick(dropdownOptions[2])}
                        >
                          {t("plm.quotes.underwriting-details.edit-options")}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                {mtcFields.map(
                  (field, i) =>
                    (!field.useCondition ||
                      (field?.useCondition &&
                        formData[field?.condition1] &&
                        (!field.condition2 ||
                          formData[field?.condition1][field?.condition2]))) && (
                      <Grid item xs={12} sm={6} key={field?.field + "-" + i}>
                        {renderField(
                          field,
                          !isNewQuote
                            ? formData?.mtcDetails?.[field?.field] || ""
                            : referenceToUse?.mtcDetails?.[field?.field] || "",
                          (e) => {
                            updateDoc(
                              doc(db, "policy-applications", policyID),
                              {
                                stagingArea: {
                                  ...formData.stagingArea,
                                  mtcDetails: {
                                    ...referenceToUse?.mtcDetails,
                                    [field?.field]: field?.isDropdown
                                      ? e.target.value
                                      : parseFloat(e.target.value),
                                  },
                                },
                              }
                            );
                          },
                          disabledRule,
                          field?.useCondition || false,
                          field?.condition1 || null,
                          field?.condition2 || null
                        )}
                      </Grid>
                    )
                )}
              </Grid>
              
              <CustomMTCLineItems
                formData={formData}
                userInfo={userInfo}
                insuredInfo={insuredInfo}
              />
            </>
          )}
          {/* Payment Plan Data */}
          {formData.paymentPlan === "External Finance" &&
            financeFields.map(
              (field, i) =>
                (!field.useCondition ||
                  (field?.useCondition &&
                    formData[field?.condition1] &&
                    (!field.condition2 ||
                      formData[field?.condition1][field?.condition2]))) && (
                  <Grid item xs={12} sm={6} key={field?.field + "-" + i}>
                    {renderField(
                      field,
                      !isNewQuote
                        ? formData?.financeDetails?.[field?.field] || ""
                        : referenceToUse?.financeDetails?.[field?.field] || "",
                      (e) => {
                        updateDoc(doc(db, "policy-applications", policyID), {
                          stagingArea: {
                            ...formData.stagingArea,
                            financeDetails: {
                              ...referenceToUse?.financeDetails,
                              [field?.field]: field?.isDropdown
                                ? e.target.value
                                : parseFloat(e.target.value),
                            },
                          },
                        });
                      },
                      disabledRule,
                      field?.useCondition || false,
                      field?.condition1 || null,
                      field?.condition2 || null
                    )}
                  </Grid>
                )
            )}


      {/* Modal for Editing Dropdown Options */}
      {currentField && (
        <EditDropdownModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          field={currentField}
          onUpdateOptions={handleUpdateOptions}
        />
      )}
    </>
  );
}
