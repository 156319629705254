import React, { useState } from "react";
import CreateUserPanel from "../../Components/agent/createUsers/CreateUserPanel";
import UserList from "../../Components/agent/createUsers/UserList";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import ApprovalButtons from "../../Components/assets/forms/ApprovalButtons";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import Popup from "../../Components/generals/Popup";
import ApiService from "../../services/southern-api/apiService";
import RedButton from "../../Components/assets/forms/RedButton";
import useGeneralState from "../../Context/actions/useGeneralState";
import ClientList from "../../Components/agent/createClients/ClientList";
import AddInsured from "../../Components/PolicyLifecycleManager/CreateNewApp/SelectInsured";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { auth, db } from "../../firebase";
import BigAlert from "../../Components/generals/Toast/BigAlert";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function ManageClients(props) {
  const driverList = props.driverList;
  const truckList = props.truckList;
  const trailerList = props.trailerList;

  const [showCreateUserPanel2, setShowCreateUserPanel2] = useState(false);
  const [showCreateUserPanel, setShowCreateUserPanel] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const initialUserFormData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    status: "Active",
    admin: false,
  };
  const [clientFormData, setClientFormData] = useState(initialUserFormData);
  const [clientOwner, setClientOwner] = useState(false);

  const handleChange = (e) => {
    setClientFormData({
      ...clientFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = {
      ...newClientData,
      ...clientFormData,
    };
    setNewClientData(newData);
    handleUserCreation(newData);
  };

  const handleCloseDeleteUserModal = () => {
    setShowDeleteUserModal(false);
    setSelectedUser(null);
  };

  const userInfo = props.userInfo;
  const [userList, setUserList] = useState([]);

  const { t } = useTranslations();
  const { controlToast } = useGeneralState();

  const breadcrumbs = [
    { label: t("breadcrumbs.home"), link: ACCOUNT_ROUTE },
    { label: t("Manage Clients") },
  ];

  const userCanModify = userInfo.userPermissions === true;
  const permissions = userInfo.userPermissions;
  const userIsOwner = userInfo.companyOwner;
  const hasPermissionToView =
    userInfo?.clientPermissions || userIsOwner || false;

  const apiService = new ApiService();

  const [loading, setLoading] = useState(false);

  const handleSubmitDelete = async () => {
    try {
      setLoading(true);
      await apiService.deleteUserWithFlag(selectedUser.email);
      controlToast(
        true,
        `${selectedUser.first} ${selectedUser.last} was deleted successfully`,
        "success"
      );
      handleCloseDeleteUserModal();
    } catch (error) {
      console.error(error);
      controlToast(true, "Error deleting user", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteUserModal = (e, user) => {
    e.preventDefault();
    setSelectedUser(user);
    setShowDeleteUserModal(true);
  };

  const [showVerification, setShowVerification] = useState(false);
  const [showExistingPolicyModal, setShowExistingPolicyModal] = useState(false);
  const [newClientData, setNewClientData] = useState({});
  const [clientCompanyName, setClientCompanyName] = useState("");
  const [clientCompanyID, setClientCompanyID] = useState("");

  const goBack = (e) => {
    e.preventDefault();
    setSelectedUser(null);
    setShowCreateUserPanel(false);
    setShowCreateUserPanel2(false);
    setUserCreationSuccess(false);
  };

  const formFields = [
    { label: "First Name", name: "firstName", type: "text", xs: 12, sm: 6 },
    { label: "Last Name", name: "lastName", type: "text", xs: 12, sm: 6 },
    { label: "Phone Number", name: "phoneNumber", type: "number", xs: 12 },
    { label: "Email Address", name: "email", type: "email", xs: 12 },
    { label: "Password", name: "password", type: "password", xs: 12 },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      xs: 12,
    },
  ];

  const [userCreationSuccess, setUserCreationSuccess] = useState(false);
  const handleUserCreation = async (userFormData) => {
    setLoading(true);

    if (userFormData.confirmPassword !== userFormData.password) {
      setLoading(false);
      return controlToast(true, "Passwords must match.", "error");
    }

    const fullName = `${userFormData.firstName} ${userFormData.lastName}`;

    const payload = userFormData;

    try {
      await apiService.createClientUsers(payload);
      setClientFormData(initialUserFormData);
      controlToast(
        true,
        `
        ${fullName} was successfully created
      `,
        "success"
      );
      setLoading(false);
      setShowCreateUserPanel(false);
      setShowCreateUserPanel2(false);
      setUserCreationSuccess(true);
    } catch (error) {
      const realError = error?.error?.message;
      setLoading(false);
      controlToast(
        true,
        `${realError} If you believe you received this message in error, you may try again or contact an administrator`,
        "error"
      );
    }
  };

  return (
    <>
      <Popup
        maxWidth="50%"
        isOpen={showDeleteUserModal}
        onRequestClose={handleCloseDeleteUserModal}
      >
        <h2 className="text-xl font-bold mt-2 mb-4">
          You are about to delete the user "{selectedUser?.first}{" "}
          {selectedUser?.last}". To confirm, click the button below.
        </h2>
        <div>
          <RedButton
            text={loading ? `Deleting User` : `Delete User`}
            onClickFunction={handleSubmitDelete}
            className="w-[100%]"
            disabled={loading}
          />
        </div>
      </Popup>
      <div className="max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6 my-4">
        <Breadcrumbs items={breadcrumbs} />
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
          <span className="text-3xl font-semibold mb-4 md:mb-0">
            {t("Manage Clients")}
          </span>
          {hasPermissionToView &&
            !showCreateUserPanel &&
            !showCreateUserPanel2 &&
            permissions &&
            (userCanModify || userIsOwner) && (
              <button
                type="button"
                className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
                onClick={() => setShowCreateUserPanel(true)}
              >
                {t("Add Client")}
              </button>
            )}
        </div>
        <p className="mb-4">{t("agent.clients-policies")}</p>
        {!hasPermissionToView ? (
          <p>
            You do not have permission to manage clients. Please contact an
            administrator.
          </p>
        ) : (
          <>
            {!showCreateUserPanel && !showCreateUserPanel2 && (
              <ClientList
                userList={userList}
                setUserList={setUserList}
                userInfo={userInfo}
                handleOpenDeleteUserModal={handleOpenDeleteUserModal}
              />
            )}
            {showCreateUserPanel && (
              <>
                <AddInsured
                  customText={
                    "Please select an insured to add an account for. Or you may add a new insured."
                  }
                  customFunction={(e) => {
                    console.log(e.target.value);
                    const originalValue = e.target.value;
                    // split into two values at the first hyphen
                    const splitValue = originalValue.split("-");
                    const insuredID = splitValue[0];
                    const namedInsured = splitValue[1];
                    const newData = {
                      insuredID,
                      namedInsured,
                      agencyID: userInfo.agencyID,
                      createdByUserID: auth.currentUser.uid,
                      role: "Client",
                    };
                    setClientCompanyID(insuredID);
                    setClientCompanyName(namedInsured);
                    setNewClientData(newData);
                    setShowCreateUserPanel(false);
                    setShowCreateUserPanel2(true);
                    // check if the client already has a user in the users collection with a clientOwner field of true
                    const checkForClientOwner = async () => {
                      const usersRef = collection(db, "users");
                      const q = query(
                        usersRef,
                        where("insuredID", "==", insuredID),
                        where("clientOwner", "==", true)
                      );
                      const querySnapshot = await getDocs(q);
                      if (querySnapshot.empty) {
                        setClientOwner(true);
                      } else {
                        alert(
                          namedInsured +
                            " already has a user listed as its owner. Any further users created will have limited permissions set by the owner."
                        );
                        setClientOwner(false);
                      }
                      setClientOwner(clientOwner);
                    };
                    const createNewClient = () => {};
                  }}
                  setShowCreatePolicyPanel={setShowCreateUserPanel}
                  useIDAsValue={true}
                  agencyID={userInfo.agencyID}
                  showVerification={showVerification}
                  setShowVerification={setShowVerification}
                  showExistingPolicyModal={showExistingPolicyModal}
                  setShowExistingPolicyModal={setShowExistingPolicyModal}
                  driverList={driverList}
                  truckList={truckList}
                  trailerList={trailerList}
                />
              </>
            )}
            {showCreateUserPanel2 && (
              <Container maxWidth="sm">
                <Box sx={{ mt: 2 }}>
                  <div className="mb-3">
                    <Typography variant="h4" gutterBottom>
                      New Client User Creation
                    </Typography>
                    <Typography variant="body2">
                      You are creating a new user for {clientCompanyName}.
                    </Typography>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {formFields.map((field) => (
                        <Grid item xs={field.xs} sm={field.sm} key={field.name}>
                          <TextField
                            label={field.label}
                            name={field.name}
                            variant="outlined"
                            fullWidth
                            type={field.type}
                            value={clientFormData[field.name]}
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <ApprovalButtons
                      onApprove={null}
                      handleAsSubmit={true}
                      onDecline={(e) => {
                        goBack(e);
                      }}
                      titleAccept="Create User"
                      titleDecline="Cancel"
                      disabledAccept={false}
                    />
                  </form>
                </Box>
              </Container>
            )}
            {userCreationSuccess && (
              <>
                <BigAlert
                  title="User Successfully Created."
                  subtitle="You have successfully created a new user."
                  okButton="OK"
                  className="mt-2"
                  status="successful"
                  fn={goBack}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
