import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";
import TableSkeleton from "../../../Components/generals/TableSkeleton";
import UserListCheckbox from "./UserListCheckbox";
import Pagination from "../../../Components/assets/lists/DynamicTable/Pagination";
import { Delete as DeleteIcon, Edit, Shield } from "@mui/icons-material";
import Popup from "../../generals/Popup";
import StandardPermissions from "./StandardPermissions";

const usersPerPage = 10;

function UserList(props) {
  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const { t } = useTranslations();

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");
  const userList = props.userList;
  const setUserList = props.setUserList;
  const userInfo = props.userInfo;
  const handleOpenDeleteUserModal = props.handleOpenDeleteUserModal;

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (userInfo.agencyID) {
      const usersCollection = collection(db, "users");
      const q = query(
        usersCollection,
        where("agencyID", "==", userInfo.agencyID),
        where("status", "!=", "Deleted")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedUserList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserList(updatedUserList);
        setLoading(false);
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID]);

  const filteredData = userList.filter((user) => {
    const {
      first,
      last,
      email,
      userPermissions,
      policyPermissions,
      applicationPermissions,
    } = user;
    return `${first} ${last} ${email} ${userPermissions} ${policyPermissions} ${applicationPermissions}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * usersPerPage;
  const currentItems = sortedData.slice(offset, offset + usersPerPage);

  const pageCount = Math.ceil(sortedData.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const userCanView = userInfo.userPermissions === true;
  const userCanModify = userInfo.userPermissions === true;
  const permissions = userInfo.userPermissions;
  const userIsOwner = userInfo.companyOwner;
  const role = userInfo.role;
  const userIsAdmin = role === "Admin";

  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenPermissionsModal = (user) => {
    setSelectedUser(user);
    setOpenPermissionModal(true);
  };

  useEffect(() => {
    const newUserSelected = userList.find(
      (user) => user.id === selectedUser?.id
    );
    setSelectedUser(newUserSelected);
  }, [props.userList]);

  const handleClosePermissionsModal = () => {
    setOpenPermissionModal(false);
  };

  // TODO: Translate this page

  return (
    <main>
      <Popup
        isOpen={openPermissionModal}
        onRequestClose={() => {
          if (!permissionsLoading) {
            handleClosePermissionsModal();
          }
        }}
        maxWidth="500px"
      >
        {selectedUser && ( // Ensure selectedUser is defined before rendering the modal content
          <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">
              Permissions For {selectedUser.first + " " + selectedUser.last}
            </h2>
            {/* Permission Controls */}
            <div className="space-y-4">
              <StandardPermissions
                selectedUser={selectedUser}
                userIsAdmin={userIsAdmin}
              />
            </div>
          </div>
        )}
      </Popup>

      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : (permissions && userCanView) || userIsOwner || userIsAdmin ? (
        <div>
          {userList.length === 0 ? (
            <p>{t("agent.add-one-user")}</p>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="my-2 p-2 border rounded w-full"
              />
              <fieldset
                disabled={
                  !permissions && !userIsOwner && !userCanModify && !userIsAdmin
                }
              >
                <Table>
                  <Thead>
                    <Tr>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                          {
                            "hover:bg-[#072a48]": sortConfig.key !== "first",
                            "bg-blue-500": sortConfig.key === "first",
                          }
                        )}
                        onClick={() => handleSort("first")}
                      >
                        {t("Full Name")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer",
                          {
                            "hover:bg-[#072a48]": sortConfig.key !== "email",
                            "bg-blue-500": sortConfig.key === "email",
                          }
                        )}
                        onClick={() => handleSort("email")}
                      >
                        {t("E-Mail Address")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white cursor-pointer text-center"
                        )}
                      >
                        {t("Agent")}
                      </Td>
                      <Td
                        className={classNames(
                          "bg-[#072a48] text-white rounded-tr-md cursor-pointer"
                        )}
                      >
                        Actions
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((user, i) => (
                      <Tr key={user.id}>
                        <Td>
                          <span title={`User ID: ${user.id}`}>
                            {user.first + " " + user.last}
                            {user.companyOwner && "*"}
                          </span>
                        </Td>
                        <Td>{user.email}</Td>
                        <Td>
                          <UserListCheckbox
                            disabled={user.companyOwner && !userIsAdmin}
                            name={"userIsAgent"}
                            userData={user}
                            field={"userIsAgent"}
                            userID={user.id}
                          />
                        </Td>
                        <Td>
                          <button
                            onClick={() => handleOpenPermissionsModal(user)}
                          >
                            {/* mui edit icon */}
                            <Shield
                              sx={{
                                color: "#0c4a6e",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                            />
                          </button>
                          {userInfo.role === "Admin" ? (
                            <DeleteIcon
                              sx={{
                                color: "#8B0000",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={(e) =>
                                handleOpenDeleteUserModal(e, user)
                              }
                            />
                          ) : null}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </fieldset>
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <p>
          {/* TODO: Add a request permission button. Low priority. */}
          You do not have permission to view users. Please contact an
          administrator.
        </p>
      )}
    </main>
  );
}

export default UserList;
