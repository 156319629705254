import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import calculateTaxesAPD from "./premiumCalculation/calculateTaxesAPD";
import calculateTaxesMTC from "./premiumCalculation/calculateTaxesMTC";

const calculateAPDMTCPremiums = async (
  policyID,
  formData,
  powerUnitCount,
  apdDetails,
  mtcDetails,
  updatePolicy
) => {
  const apdPremium = formData.hasAPDCoverage
    ? apdDetails.totalPremiumBeforeTaxes
    : null;
  const mtcPremium = formData.hasMTCCoverage
    ? mtcDetails.totalPremiumBeforeTaxes
    : null;
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;

  const apdTaxes = await calculateTaxesAPD(
    policyID,
    formData,
    apdPremium,
    powerUnitCount,
    updatePolicy
  );

  const mtcTaxes = await calculateTaxesMTC(
    policyID,
    formData,
    mtcPremium,
    powerUnitCount,
    updatePolicy
  );

  const apdReturn = {
    ...apdTaxes,
    ...apdDetails,
  };

  const mtcReturn = {
    ...mtcTaxes,
    ...mtcDetails,
  };

  if (updatePolicy) {
    const mtcPolicyID = formData?.mtcPolicyID;
    const apdPolicyID = formData?.apdPolicyID;
    const mtcPolicyRef = !hasMTC ? null : doc(db, "mtc-policies", mtcPolicyID);
    const apdPolicyRef = !hasAPD ? null : doc(db, "apd-policies", apdPolicyID);
    hasMTC &&
      (await setDoc(
        mtcPolicyRef,
        {
          mtcPremiums: mtcReturn,
          GWP: mtcPremium,
        },
        { merge: true }
      ));
    hasAPD &&
      (await setDoc(
        apdPolicyRef,
        {
          apdPremiums: apdReturn,
          GWP: apdPremium,
        },
        { merge: true }
      ));
  }
};

export default calculateAPDMTCPremiums;
