import { db } from "../../../../../firebase"; // Updated import path
import {
  collectionGroup,
  query,
  where,
  getDocs,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";

const handleSearch = async (
  initialSearch,
  queryText,
  searchFunction,
  userAgencyID,
  selectedStatus,
  setOutstandingEndorsements,
  setResults,
  setLoading
) => {
  let endorsementsQuery;
  setLoading(true);
  const searchValue = queryText.toUpperCase(); // Convert to uppercase for consistent querying
  if (searchFunction === "company") {
    // Search for company name in namedInsured field
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("namedInsured", ">=", searchValue),
      where("namedInsured", "<", searchValue + "\uf8ff"),
      orderBy("namedInsured", "desc"),
      orderBy("endorsementNumber", "desc")
    );
  } else if (searchFunction === "driver") {
    // Search for driver name in searchData array
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("searchData", "array-contains", searchValue),
      orderBy("endorsementNumber", "desc")
    );
  } else if (searchFunction === "license") {
    // Search for license number in searchData array
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("searchData", "array-contains", searchValue),
      orderBy("endorsementNumber", "desc")
    );
  } else if (searchFunction === "status") {
    // Filter by endorsement status
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("status", "==", selectedStatus),
      orderBy("endorsementNumber", "desc")
    );
  } else if (searchFunction === 'VIN') {
    // Search for VIN number in searchData array
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("searchData", "array-contains", searchValue),
      orderBy("endorsementNumber", "desc")
    );
  }

  if (endorsementsQuery) {
    try {
      const querySnapshot = await getDocs(endorsementsQuery);
      const searchResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        endorsementNumber: Number(doc.data().endorsementNumber),
      }));
      // .sort((a, b) => b.endorsementNumber - a.endorsementNumber); I commented this out because I think it is unnecessary and was causing too intensive data processing
      
      initialSearch && setOutstandingEndorsements(searchResults.length);
      
      setResults(searchResults);
    } catch (error) {
      console.error("Error fetching endorsements:", error);
    }
  } else {
    setResults([]); // Clear results if the input is empty
  }
  setLoading(false);
};

export default handleSearch;
