import React from 'react'
import UserListCheckbox from './UserListCheckbox'

export default function UnderwriterPermissions({
    selectedUser,
    userIsAdmin
}) {
  return (
    <>
    {/* Bind Permissions */}
    <div className="flex items-center">
                <UserListCheckbox
                  disabled={selectedUser.companyOwner && !userIsAdmin}
                  name={"hasBindAuthority"}
                  userData={selectedUser} // Use selectedUser data
                  field={"hasBindAuthority"}
                  userID={selectedUser.id}
                />
                <label
                  htmlFor="hasBindAuthority"
                  className="text-md font-medium ml-2"
                >
                  Bind Authority
                </label>
              </div>

              {/* Finance Permissions */}
              <div className="flex items-center">
                <UserListCheckbox
                  disabled={selectedUser.companyOwner && !userIsAdmin}
                  name={"hasFinanceAuthority"}
                  userData={selectedUser} // Use selectedUser data
                  field={"hasFinanceAuthority"}
                  userID={selectedUser.id}
                />
                <label
                  htmlFor="hasFinanceAuthority"
                  className="text-md font-medium ml-2"
                >
                  View/Edit Finance Section
                </label>
              </div>
    </>
  )
}
