import { Card, CardContent, CardHeader, Typography, Box } from "@mui/material";
import React from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";

export default function PortalCard({ title, children, showByDefault = false }) {
  const [show, setShow] = React.useState(showByDefault ? true : false);

  const handleHeaderClick = () => {
    setShow((prevShow) => !prevShow); // Toggle the show state when the header is clicked
  };

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader
        sx={{
          backgroundColor: "#072a48",
          color: "white",
          paddingY: 1, // Reduced vertical padding
          paddingX: 2, // Optional: adjust horizontal padding
          cursor: 'pointer',  
          '&:hover': {
            backgroundColor: '#0c4a6e', 
          }
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              cursor: 'pointer',  
              '&:hover': {
                backgroundColor: '#0c4a6e', 
              }
            }}
            onClick={handleHeaderClick} 
          >
            <Typography variant="h6">{title}</Typography>
            <ExpandCollapseArrow 
              show={show} 
              setShow={setShow} 
            />
          </Box>
        }
      />
      {show && <CardContent>{children}</CardContent>}
    </Card>
  );
}
