import React from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import FileUploadIndicator from "./FileUploadIndicator";
import MultiUpload from "../assets/forms/DynamicPropertiesInputs/MultiUpload";
import BlueButton from "../assets/forms/BlueButton";
import { OpenInNew, Plagiarism as PlagiarismIcon } from "@mui/icons-material";
import useTranslations from "../../Context/actions/useTranslations";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function DocumentsTable({ documentUploadInfo, formData }) {
  const { t } = useTranslations();

  const renderDocumentRow = (documentInfo) => {
    const {
      name,
      key,
      files,
      setFiles,
      storageFile,
      multiple,
      apiRequestFileFn,
    } = documentInfo;

    const isUploaded = files?.length > 0;

    const parseCamelCase = (camelCaseString) => {
      const words = camelCaseString.replace(/([A-Z])/g, " $1").trim();
      const capitalizedWords = words.replace(/\b\w/g, (char) =>
        char.toUpperCase()
      );
      return capitalizedWords;
    };

    const isApiButton = typeof apiRequestFileFn === "function" && !isUploaded;

    const isCabReport = name === "CAB Report";

    return (
      <Tr key={key}>
        {multiple ? (
          <Td colSpan={2} valign="top" className=" py-2 border-gray-300">
            <p className="text-[15px] font-semibold text-[#072a48]">
              {parseCamelCase(key)}
            </p>
            <p className="my-1">
              {t("plm.documents.description3")} {parseCamelCase(key)}{" "}
              {t("plm.documents.description4")}
            </p>
          </Td>
        ) : (
          <>
            <Td
              valign="top"
              className={`${
                isUploaded
                  ? "bg-green-200 bg-opacity-40"
                  : "bg-red-200 bg-opacity-40"
              }`}
            >
              <FileUploadIndicator isUploaded={isUploaded} />
            </Td>
            <Td
              valign="top"
              className={`${
                isUploaded
                  ? "bg-green-200 bg-opacity-40"
                  : "bg-red-200 bg-opacity-40"
              } text-[15px] py-2 font-semibold text-[#072a48] border-b border-gray-300`}
            >
              <p className="mt-1">{name}</p>
            </Td>
          </>
        )}
        <Td
          valign="top"
          className={`${
            multiple
              ? ""
              : isUploaded
              ? "bg-green-200 bg-opacity-40"
              : "bg-red-200 bg-opacity-40"
          }`}
        >
          {isCabReport && (
            <BlueButton
              text={t("plm.documents.description5")}
              className="w-full"
              icon={<OpenInNew />}
              onClickFunction={() => window.open(documentInfo.URL, "_blank")}
              hasContentToBottom={true}
            />
          )}
          {isApiButton ? (
            <BlueButton
              text={name}
              onClickFunction={apiRequestFileFn}
              icon={<PlagiarismIcon />}
              className="w-full"
              hasContentToBottom={true}
            />
          ) : (
            <MultiUpload
              showAssignDocuments={true}
              label={name}
              property={key}
              policyFormData={formData}
              formData={files}
              setFormData={setFiles}
              storageFile={storageFile}
              multiple={Boolean(multiple)}
              changeNormal={true}
              fileInButton={true}
              width="100%"
              dissapearWhenImgExists={true}
              showPastingOption={true}
              hideTitle={true}
            />
          )}
        </Td>
      </Tr>
    );
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const renderDocumentCard = (documentInfo) => {
    const {
      name,
      isApiButton,
      apiRequestFileFn,
      multiple,
      URL,
      key,
      files,
      storageFile,
      setFiles,
    } = documentInfo;

    const isCabReport = name === "CAB Report";
    const isUploaded = files?.length > 0;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Card
          key={key}
          style={{
            marginBottom: "16px",
            backgroundColor: isUploaded ? "#e8f5e9" : "#ffebee",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
            <FileUploadIndicator isUploaded={isUploaded} />

            {isCabReport && (
              <Button
                variant="contained"
                startIcon={<OpenInNew />}
                fullWidth
                onClick={() => window.open(URL, "_blank")}
                style={{ marginTop: "8px", backgroundColor: "#072a48" }}
              >
                {t("plm.documents.description5")}
              </Button>
            )}
            <div className="mt-2">
              {isApiButton ? (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PlagiarismIcon />}
                  fullWidth
                  onClick={apiRequestFileFn}
                  style={{ marginTop: "8px" }}
                >
                  {name}
                </Button>
              ) : (
                <MultiUpload
                  showAssignDocuments={true}
                  label={name}
                  property={key}
                  policyFormData={formData}
                  formData={files}
                  setFormData={setFiles}
                  storageFile={storageFile}
                  multiple={Boolean(multiple)}
                  changeNormal={true}
                  fileInButton={true}
                  width="100%"
                  dissapearWhenImgExists={true}
                  showPastingOption={true}
                  hideTitle={true}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <main>
      {isMobileScreen ? (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", margin: 0 }}
        >
          {documentUploadInfo.map((documentInfo) => {
            if (documentInfo?.name) return renderDocumentCard(documentInfo);
          })}
        </Grid>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Td className="bg-[#072a48] w-[150px] text-white rounded-tl-md">
                {t("plm.documents.status")}
              </Td>
              <Td className="bg-[#072a48] text-white">
                {t("plm.documents.document")}
              </Td>
              <Td className="bg-[#072a48] text-white rounded-tr-md">
                {t("plm.documents.actions")}
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {documentUploadInfo.map((documentInfo) => {
              if (documentInfo?.name) return renderDocumentRow(documentInfo);
            })}
          </Tbody>
        </Table>
      )}
    </main>
  );
}
