import React, { useEffect, useState } from "react";
import updatePolicyField, {
  resetPolicyField,
} from "../../../utils/updatePolicyField";
import { auth } from "../../../../firebase";
import YearCard from "./ALorGLYearCard";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function ALLossHistory({
  formData,
  setFormData,
  policyID,
  displayALLossHistory,
  setDisplayALLossHistory,
  currentYear,
  setResultAggregateLossRatio,
}) {
  const { t } = useTranslations();

  const [showYear1, setShowYear1] = useState(false);
  const [showYear2, setShowYear2] = useState(false);
  const [showYear3, setShowYear3] = useState(false);

  const [imgUrl, setImgUrl] = useState(null);
  const [year1Progress, setYear1Progress] = useState(0);
  const [year2Progress, setYear2Progress] = useState(0);
  const [year3Progress, setYear3Progress] = useState(0);

  const [year1LossData, setYear1LossData] = useState({
    lossRatioCalculated: false,
  });
  const [year2LossData, setYear2LossData] = useState({
    lossRatioCalculated: false,
  });
  const [year3LossData, setYear3LossData] = useState({
    lossRatioCalculated: false,
  });

  const resetLosses = async (e, property) => {
    e.preventDefault();
    const updatedValues = Object.fromEntries(
      Object.entries(formData.lossHistory).filter(([key]) => key !== property)
    );
    setTimeout(() => {
      resetPolicyField(policyID, "lossHistory", updatedValues);
    }, 0);
  };

  const handleSubmit = async (e, years, lossData, property) => {
    e.preventDefault();
    const data = {
      ...lossData,
      author: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
      createdOn: new Date(),
      years,
      annualPremium: lossData.numberOfUnits * lossData.premiumPPU,
      lossRatio:
        (lossData.losses / (lossData.numberOfUnits * lossData.premiumPPU)) *
          100 || 0,
      lossRatioCalculated: true,
    };
    updatePolicyField(policyID, "lossHistory", {
      ...formData.lossHistory,
      [property]: { ...lossData, ...data },
    });
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const didNotOperateInYear1ALPrior =
    formData?.didNotOperateInYear1ALPrior || false;
  const didNotOperateInYear2ALPrior =
    formData?.didNotOperateInYear2ALPrior || false;
  const didNotOperateInYear3ALPrior =
    formData?.didNotOperateInYear3ALPrior || false;

  const hadNoLossesYear1ALPrior = formData?.hadNoLossesYear1ALPrior || false;
  const hadNoLossesYear2ALPrior = formData?.hadNoLossesYear2ALPrior || false;
  const hadNoLossesYear3ALPrior = formData?.hadNoLossesYear3ALPrior || false;

  const year1Losses =
    !didNotOperateInYear1ALPrior && !hadNoLossesYear1ALPrior
      ? formData?.lossHistory?.year1ALLossData?.losses
      : 0;
  const year2Losses =
    !didNotOperateInYear2ALPrior && !hadNoLossesYear2ALPrior
      ? formData?.lossHistory?.year2ALLossData?.losses
      : 0;
  const year3Losses =
    !didNotOperateInYear3ALPrior && !hadNoLossesYear3ALPrior
      ? formData?.lossHistory?.year3ALLossData?.losses
      : 0;

  const year1TotalPremium =
    !didNotOperateInYear1ALPrior && !hadNoLossesYear1ALPrior
      ? formData?.lossHistory?.year1ALLossData?.numberOfUnits *
        formData?.lossHistory?.year1ALLossData?.premiumPPU
      : 0;
  const year2TotalPremium =
    !didNotOperateInYear2ALPrior && !hadNoLossesYear2ALPrior
      ? formData?.lossHistory?.year2ALLossData?.numberOfUnits *
        formData?.lossHistory?.year2ALLossData?.premiumPPU
      : 0;
  const year3TotalPremium =
    !didNotOperateInYear3ALPrior && !hadNoLossesYear3ALPrior
      ? formData?.lossHistory?.year3ALLossData?.numberOfUnits *
        formData?.lossHistory?.year3ALLossData?.premiumPPU
      : 0;

  const total3YearLosses = year1Losses + year2Losses + year3Losses;
  const total3YearPremium =
    year1TotalPremium + year2TotalPremium + year3TotalPremium === 0
      ? 1
      : year1TotalPremium + year2TotalPremium + year3TotalPremium;

  const aggregateLossRatio = (total3YearLosses / total3YearPremium) * 100 || 0;
  const allYearsComplete =
    (didNotOperateInYear1ALPrior ||
      hadNoLossesYear1ALPrior ||
      formData?.lossHistory?.year1ALLossData?.lossRatioCalculated) &&
    (didNotOperateInYear2ALPrior ||
      hadNoLossesYear2ALPrior ||
      formData?.lossHistory?.year2ALLossData?.lossRatioCalculated) &&
    (didNotOperateInYear3ALPrior ||
      hadNoLossesYear3ALPrior ||
      formData?.lossHistory?.year3ALLossData?.lossRatioCalculated);

  useEffect(() => {
    setResultAggregateLossRatio(allYearsComplete);
  }, [allYearsComplete]);

  return (
    <div className="card mb-2">
      <div className="card-header">
        <button onClick={(e) => setDisplayALLossHistory(!displayALLossHistory)}>
          {t("plm.losses.al-gl.title")}:{" "}
          {allYearsComplete ? (
            <>
              {(formData?.didNotOperateInYear1ALPrior === true ||
                formData?.hadNoLossesYear1ALPrior) &&
              (formData?.didNotOperateInYear2ALPrior === true ||
                formData?.hadNoLossesYear2ALPrior) &&
              (formData?.didNotOperateInYear3ALPrior === true ||
                formData?.hadNoLossesYear3ALPrior)
                ? t("plm.losses.al-gl.no-losses-provided")
                : (aggregateLossRatio || 0).toFixed(2)}
            </>
          ) : (
            <span className="text-[#8B0000]">
              {t("plm.losses.al-gl.incomplete")}
            </span>
          )}
        </button>
        <ExpandCollapseArrow
          show={displayALLossHistory}
          setShow={setDisplayALLossHistory}
        />
      </div>
      {displayALLossHistory && (
        <div className="card-body">
          <Container className="p-0">
            <Row>
              <Col sm={12} md={4} className="p-0">
                <YearCard
                  coverageType={"AL"}
                  currentYear={currentYear}
                  showYearX={showYear1}
                  setShowYearX={setShowYear1}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear1Progress}
                  setImgUrl={setImgUrl}
                  policyID={policyID}
                  yearXLossData={year1LossData}
                  setYearXLossData={setYear1LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={1}
                  numberOfYearsPrior={0}
                  yearXLossRunsFile={formData.year1LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear1Prior}
                  hadNoLossesYearXALPrior={formData.hadNoLossesYear1ALPrior}
                  progressState={year1Progress}
                />
              </Col>
              <Col sm={12} md={4} className={isMobileScreen ? "p-0 pt-1" : ""}>
                <YearCard
                  coverageType={"AL"}
                  currentYear={currentYear}
                  showYearX={showYear2}
                  setShowYearX={setShowYear2}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear2Progress}
                  setImgUrl={setImgUrl}
                  policyID={policyID}
                  yearXLossData={year2LossData}
                  setYearXLossData={setYear2LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={2}
                  numberOfYearsPrior={1}
                  yearXLossRunsFile={formData.year2LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear2Prior}
                  hadNoLossesYearXALPrior={formData.hadNoLossesYear2ALPrior}
                  progressState={year2Progress}
                />
              </Col>
              <Col sm={12} md={4} className={isMobileScreen ? "p-0 pt-1" : ""}>
                <YearCard
                  coverageType={"AL"}
                  currentYear={currentYear}
                  showYearX={showYear3}
                  setShowYearX={setShowYear3}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear3Progress}
                  setImgUrl={setImgUrl}
                  policyID={policyID}
                  yearXLossData={year3LossData}
                  setYearXLossData={setYear3LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={3}
                  numberOfYearsPrior={2}
                  yearXLossRunsFile={formData.year3LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear3Prior}
                  hadNoLossesYearXALPrior={formData.hadNoLossesYear3ALPrior}
                  progressState={year3Progress}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}
