import React from "react";
import { Typography } from "@mui/material";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import useTranslations from "../../../../Context/actions/useTranslations";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";

export default function Step0({
  driverData,
  goToFirstStep,
  goBack,
  fileObjectsLicenseFront,
  setFileObjectsLicenseFront,
}) {
  const { t } = useTranslations();

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Upload Driver's License
      </Typography>

      <div className="mb-2">
        <MultiUpload
          label={t("plm.drivers.details.front-license")}
          property="driverLicenseFront"
          formData={fileObjectsLicenseFront}
          setFormData={setFileObjectsLicenseFront}
          storageFile={`files/drivers/${driverData?.licenseNumber}`}
          multiple={false}
          changeNormal={true}
          fileInButton={true}
          showInAPopup={true}
          dissapearWhenImgExists={true}
          width="100%"
        />
      </div>

      <ApprovalButtons
        onApprove={(e) => {
          e.preventDefault();
          goToFirstStep();
        }}
        onDecline={goBack}
        titleAccept={t("plm.drivers.add-driver.continue")}
        titleDecline={t("plm.drivers.add-driver.go-back")}
      />
    </div>
  );
}
