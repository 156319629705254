import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const getPoliciesWithPowerUnits = async (
  customersData,
  setLoading,
  setUnitList,
  shouldQueryAll
) => {
  setLoading(true);

  try {
    const policiesRef = collection(db, "policy-applications");
    const queryOnlyActive = query(
      policiesRef,
      where("status", "==", "In-Force")
      // limit(10)
    );
    const queryAll = query(policiesRef);
    const policySnapshot = await getDocs(
      shouldQueryAll ? queryAll : queryOnlyActive
    );
    const aggregatedData = await Promise.all(
      policySnapshot.docs.map(async (policyDoc) => {
        const policyData = policyDoc.data();
        const customerDataFilter = customersData.filter(
          (customer) => customer.id === policyData.insuredID
        );
        const customerData = customerDataFilter[0];
        console.log("customer data: ", customerData);
        const powerUnitCollectionRef = collection(
          db,
          "agencies",
          policyData.agencyID,
          "insureds",
          policyData.insuredID,
          "power-units"
        );
        const powerUnitQuery = shouldQueryAll
          ? query(
              powerUnitCollectionRef,
              where("policies", "array-contains", policyDoc.id)
            )
          : query(powerUnitCollectionRef);
        const powerUnitSnapshot = await getDocs(powerUnitQuery);

        let powerUnitMap = [];
        if (!shouldQueryAll) {
          powerUnitMap = powerUnitSnapshot.docs.map((unitDoc) => {
            const policyDetails = unitDoc
              .data()
              .policiesDetails.filter((policy) => policy.id === policyDoc.id);
            const truckRadius = policyDetails[0]?.truckRadius;
            return {
              ...unitDoc.data(),
              id: unitDoc.id,
              policyNumber: policyData.alPolicyNo,
              effectiveDate: policyData.effectiveDate,
              expirationDate: policyData.expirationDate,
              dot: customerData?.dot || "N/A",
              namedInsured: customerData?.company || "N/A",
              policyID: policyDoc.id,
              truckRadius,
            };
          });
        } else if (shouldQueryAll) {
          powerUnitMap = powerUnitSnapshot.docs.flatMap((unitDoc) => {
            // Create a Set to track unique effective dates
            const seenEffectiveDates = new Set();

            // Filter policiesDetails for the current unitDoc
            const filteredPoliciesDetails = unitDoc
              .data()
              .policiesDetails.filter((policy) => {
                // Ensure the policy is relevant to the current policyDoc and not duplicate
                const isDuplicate = seenEffectiveDates.has(
                  policy.effectiveDate
                );
                if (!isDuplicate && policy.id === policyDoc.id) {
                  seenEffectiveDates.add(policy.effectiveDate);
                  return true;
                }
                return false;
              });

            console.log(
              "docref:",
              `/insureds/${policyData.insuredID}/power-units/${unitDoc.id}`
            );

            // Map each filtered policyDetail into a new entry
            return filteredPoliciesDetails.map((policy) => ({
              ...unitDoc.data(),
              id: unitDoc.id,
              policyNumber: policyData.alPolicyNo,
              effectiveDate: policy.effectiveDate,
              expirationDate: policyData.expirationDate,
              dot: customerData?.dot || "N/A",
              namedInsured: customerData?.company || "N/A",
              truckRadius: policy.truckRadius,
              addedDate: policy.addedDate,
            }));
          });
        }
        console.log("power unit map: ", powerUnitMap);
        return powerUnitMap;
      })
    );

    // Flatten nested arrays and set state once
    setUnitList(aggregatedData.flat());
  } catch (error) {
    console.error("Error fetching policies and power units:", error);
  } finally {
    setLoading(false);
  }
};

export default getPoliciesWithPowerUnits;
