import React, { useEffect, useState } from "react";
import CreateUserPanel from "../../Components/agent/createUsers/CreateUserPanel";
import UserList from "../../Components/agent/createUsers/UserList";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import Commodities from "../../Components/PolicyLifecycleManager/Commodities";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import Popup from "../../Components/generals/Popup";
import ApiService from "../../services/southern-api/apiService";
import RedButton from "../../Components/assets/forms/RedButton";
import useGeneralState from "../../Context/actions/useGeneralState";
import ClientList from "../../Components/agent/createClients/ClientList";
import AddInsured from "../../Components/PolicyLifecycleManager/CreateNewApp/SelectInsured";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { auth, db } from "../../firebase";
import BigAlert from "../../Components/generals/Toast/BigAlert";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import InsuredInfo from "../PolicyLifecycleManager/CoveragesSidePanel/InsuredInfo";
import InsuredsPoliciesList from "./InsuredsPoliciesList";
import PortalCard from "../assets/cards/PortalCard";
import PortalPanel from "../assets/cards/PortalPanel";
import History from "../PolicyLifecycleManager/History";

export default function InsuredProfile({
  userInfo,
  loading,
  setLoading,
  // insuredID,
}) {
  const { t } = useTranslations();
  const { controlToast } = useGeneralState();

  const insuredID = "2196912"; // tx international

  const hasPermissionToView =
    userInfo.insuredPermissions || userInfo.companyOwner || userInfo.admin;

  const breadcrumbs = [{ label: t("Insured Profile") }];

  const apiService = new ApiService();
  const [didSuccess, setDidSuccess] = useState(false);
  const [displayCompanyDetails, setDisplayCompanyDetails] = useState(false);
  const [insuredInfo, setInsuredInfo] = useState({});
  const [tempFormDataForTestingPurposes, setTempFormDataForTestingPurposes] =
    useState({});

  const agencyID = userInfo.agencyID;

  const policyID = "rD8h6xSO4Uksyo8hgST1";
  useEffect(() => {
    if (!insuredID) return; // Ensure insuredID exists before attempting to set up the listener

    const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);

    // Set up a real-time listener
    const unsubscribe = onSnapshot(insuredRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setInsuredInfo(docSnapshot.data());
      } else {
        console.log("No such document!");
      }
    });

    // Async function to fetch temporary data
    const fetchTempData = async () => {
      try {
        const tempDataRef = doc(db, "policy-applications", policyID);
        const tempData = await getDoc(tempDataRef);
        setTempFormDataForTestingPurposes(tempData.data());
      } catch (error) {
        console.error("Error fetching temp data:", error);
      }
    };

    fetchTempData(); // Call the async function to fetch temp data

    // Cleanup the listener when the component unmounts or insuredID changes
    return () => unsubscribe();
  }, [insuredID, agencyID]);

  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    const policiesRef = collection(db, "policy-applications");
    // realtime query policies collection where insuredID is equal to the current insuredID
    const q = query(policiesRef, where("insuredID", "==", insuredID));
    const unsubscribePolicies = onSnapshot(q, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const coverage =
          (data.hasALCoverage ? "AL " : "") +
          (data.hasGLCoverage ? "GL " : "") +
          (data.hasAPDCoverage ? "APD" : "") +
          (data.hasMTCCoverage ? "MTC" : "");
        values.push({
          ...doc.data(),
          id: doc.id,
          coverage,
          policyNumber: doc.data().alPolicyNo,
        });
      });
      // sort by effective date
      values.sort((a, b) => b.effectiveDate - a.effectiveDate);
      setPolicies(values);
    });
    return () => unsubscribePolicies();
  }, [insuredID]);

  return (
    <PortalPanel>
      <Breadcrumbs items={breadcrumbs} />
      {/* <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t("Insured Profile")}
        </span>
      </div> */}
      {/* <p className="mb-4">{t("agent.clients-policies")}</p> */}
      {!hasPermissionToView ? (
        <p>
          You do not have permission to manage clients. Please contact an
          administrator.
        </p>
      ) : (
        <>
          <div className="mt-4">
            <InsuredInfo
              insuredInfo={insuredInfo}
              setDisplayCompanyDetails={setDisplayCompanyDetails}
            />
          </div>
          <PortalCard title={"Policies & Applications"}>
            <InsuredsPoliciesList policiesDetails={policies} />
          </PortalCard>
          <PortalCard title={"Commodities"}>
            <Commodities
              formData={tempFormDataForTestingPurposes}
              policyID={policyID}
              userInfo={userInfo}
              emailValue={""} // TODO: Put a value here.
              insuredInfo={insuredInfo}
              setDidSuccess={setDidSuccess}
            />
          </PortalCard>
          {/* TODO: Uncomment this once we have fixed system log to include insuredID */}
          {/* <PortalCard title={"History"}> 
            <History insuredID={insuredID} searchByInsured={true} />
          </PortalCard> */}
        </>
      )}
    </PortalPanel>
  );
}
