import React from "react";

const DriverMapTemplate = ({
    driverPayload
}) => {

    const {
        profileDefinition,
        basicInformation,
        score,
        riskFactor,
        crashes,
        sct,
        training,
        policy,
        inspections
    } = driverPayload

  return (
    <div className="p-5">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center text-center">
          {profileDefinition?.url && (
            profileDefinition?.url.endsWith(".pdf") ? (
              <a href={profileDefinition?.url} target="_blank" className="text-green-500">Download Avatar</a>
            ) : (
              <img className="w-16 h-auto mb-2" src={profileDefinition?.url} alt="avatar" />
            )
          )}
          <p className="text-lg font-bold">{profileDefinition?.name}</p>
          <p className="text-sm">DOB ({profileDefinition?.dob})</p>
          <p className="text-sm">License MX: {profileDefinition?.licenseMX}</p>
          <p className="text-sm">Expires on: {profileDefinition?.expiresOn}</p>
        </div>

        <div className="relative">
          <div className="absolute inset-0 border-l-2 border-green-500 h-full"></div>

          <div className="flex items-center justify-start mb-5 ml-44 relative">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
            <div className="flex items-center justify-end mr-16">
              <div className="border-2 border-green-500 rounded-lg p-4">
                <p className="font-semibold">Basic Information</p>
                <table className="w-52 border-collapse bg-white">
                  <tbody>
                    <tr><td className="border-b p-2">License Class: {basicInformation?.licenseClass}</td></tr>
                    <tr><td className="border-b p-2">Experience: {basicInformation?.experience}</td></tr>
                    <tr><td className="border-b p-2">CURP: {basicInformation?.curp}</td></tr>
                    <tr><td className="border-b p-2">Score Status: {basicInformation?.scoreStatus}</td></tr>
                    <tr><td className="border-b p-2">No Medicina Preventiva: {basicInformation?.noMedicinaPreventiva}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mb-5 ml-44 relative">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
            <div className="flex items-center justify-end mr-16">
              <div className="border-2 border-green-500 rounded-lg p-4">
                <p className="font-semibold">Score</p>
                <table className="w-52 border-collapse bg-white">
                  <tbody>
                    <tr><td className="border-b p-2">Score Points: {score?.scorePoints}</td></tr>
                    <tr><td className="border-b p-2">Violations: {score?.violations}</td></tr>
                    <tr><td className="border-b p-2">Claims: {score?.claims}</td></tr>
                    <tr><td className="border-b p-2">Crashes: {score?.crashes}</td></tr>
                    <tr><td className="border-b p-2">Training: {score?.training}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mb-5 ml-44 relative">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
            <div className="flex items-center justify-end mr-16">
              <div className="border-2 border-green-500 rounded-lg p-4">
                <p className="font-semibold">Risk Factor</p>
                <table className="w-52 border-collapse bg-white">
                  <tbody>
                    <tr><td className="border-b p-2">Frequency: {riskFactor?.frequency}</td></tr>
                    <tr><td className="border-b p-2">Severity: {riskFactor?.severity}</td></tr>
                    <tr><td className="border-b p-2">Combined: {riskFactor?.combined}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mb-5 ml-44 relative">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
            <div className="flex items-center justify-end mr-16">
              <div className="border-2 border-green-500 rounded-lg p-4">
                <p className="font-semibold">Crashes</p>
                <table className="w-52 border-collapse bg-white">
                  <tbody>
                    <tr><td className="border-b p-2">Crashes: {crashes?.crashes}</td></tr>
                    <tr><td className="border-b p-2">Fatalities: {crashes?.fatalities}</td></tr>
                    <tr><td className="border-b p-2">Injuries: {crashes?.injuries}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mb-5 ml-44 relative">
            <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
            <div className="flex items-center justify-end mr-16">
              <div className="border-2 border-green-500 rounded-lg p-4">
                <p className="font-semibold">SCT</p>
                <table className="w-52 border-collapse bg-white">
                  <tbody>
                    <tr><td className="border-b p-2">Effective From: {sct?.effectiveFrom}</td></tr>
                    <tr><td className="border-b p-2">Effective Until: {sct?.effectiveUntil}</td></tr>
                    <tr><td className="border-b p-2">Fit: {sct?.fit}</td></tr>
                    <tr><td className="border-b p-2">Exam Type: {sct?.examType}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {training?.length > 0 && (
            <div className="flex items-center justify-start mb-5 ml-44 relative">
              <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
              <div className="flex items-center justify-end mr-16">
                <div className="border-2 border-green-500 rounded-lg p-4">
                  <p className="font-semibold">Training</p>
                  {training?.map((item, index) => (
                    <div key={index} className="mb-3">
                      <table className="w-52 border-collapse bg-white">
                        <tbody>
                          <tr><td className="border-b p-2">Training: {item?.training}</td></tr>
                          <tr><td className="border-b p-2">Institution: {item?.institution}</td></tr>
                          <tr><td className="border-b p-2">Completion Date: {item?.completionDate}</td></tr>
                          <tr><td className="border-b p-2">Training Duration: {item?.trainingDuration}</td></tr>
                          <tr><td className="border-b p-2">Description: {item?.description}</td></tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {policy?.length > 0 && (
            <div className="flex items-center justify-start mb-5 ml-44 relative">
              <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
              <div className="flex items-center justify-end mr-16">
                <div className="border-2 border-green-500 rounded-lg p-4">
                  <p className="font-semibold">Policy</p>
                  {policy?.map((item, index) => (
                    <div key={index} className="mb-3">
                      <table className="w-52 border-collapse bg-white">
                        <tbody>
                          <tr><td className="border-b p-2">Policy Nbr: {item?.policyNbr}</td></tr>
                          <tr><td className="border-b p-2">DOT: {item?.dot}</td></tr>
                          <tr><td className="border-b p-2">Insured Name: {item?.insuredName}</td></tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {inspections?.length > 0 && (
            <div className="flex items-center justify-start mb-5 ml-44 relative">
              <div className="absolute left-[-8px] top-1/2 transform -translate-y-1/2 w-2 h-12 border-l-2 border-green-500"></div>
              <div className="flex items-center justify-end mr-16">
                <div className="border-2 border-green-500 rounded-lg p-4">
                  <p className="font-semibold">Inspections</p>
                  {inspections?.map((item, index) => (
                    <div key={index} className="mb-3">
                      <table className="w-52 border-collapse bg-white">
                        <tbody>
                          <tr><td className="border-b p-2">Inspection Nbr: {item?.inspectionNbr}</td></tr>
                          <tr><td className="border-b p-2">Inspection Date: {item?.inspectionDate}</td></tr>
                          <tr><td className="border-b p-2">Location: {item?.location}</td></tr>
                          <tr><td className="border-b p-2">Status: {item?.status}</td></tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverMapTemplate;