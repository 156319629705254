import React, { useEffect, useState } from 'react';
import { chooseKeysToPickInPayload, setupPagination, superSearcherFn } from "../../utils/helpers";
import useGeneralState from '../../Context/actions/useGeneralState';
import usePaginationsState from '../../Context/actions/usePaginationsState';

function useDynamicTable(props) {
  const {
    filterKeys,
    tableData,
    setTableData,
    alternativeTableData,
    setAlternativeTableData,
    deletionMode,
    deleteMode,
    superEndoSearcher,
    sortDefault = { key: "", direction: "asc" },
    name,
  } = props;

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState(sortDefault);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  const { paginations, setPaginations } = usePaginationsState();

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setCurrentPage(0);
    setSearchTerm(event.target.value);
  };

  const filterDataAsync = async () => {
    const filteredResults = [];

    for (const item of tableData) {
      const foundMatchesInEndo = await superSearcherFn(
        superEndoSearcher,
        item?.data,
        searchTerm?.toLowerCase(),
        item?.searchData,
        item?.agencyID,
        item?.insuredID,
        item?.type
      );
      const parsedData = chooseKeysToPickInPayload(filterKeys, item);
      const searchInLowercase = searchTerm.toLowerCase();
      const joinedData = Object.values(parsedData)
        .join(" ")
        .toLowerCase()
        .includes(searchInLowercase);

      if (joinedData || foundMatchesInEndo) {
        filteredResults.push(item);
      }
    }

    return filteredResults;
  };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const performFilter = async () => {
      setLoadingSearch(true);
      const results = await filterDataAsync();
      setFilteredData(results);
      setLoadingSearch(false);
    };
    performFilter();
  }, [tableData, searchTerm]);

  const sortedData = [...filteredData].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    const aHasSeconds = aValue && (aValue.seconds !== undefined || aValue._seconds !== undefined);
    const bHasSeconds = bValue && (bValue.seconds !== undefined || bValue._seconds !== undefined);
  
    if (aHasSeconds && bHasSeconds) {
      const aSeconds = aValue.seconds !== undefined ? aValue.seconds : aValue._seconds;
      const aNanoseconds = aValue.nanoseconds !== undefined ? aValue.nanoseconds : aValue._nanoseconds;
      const bSeconds = bValue.seconds !== undefined ? bValue.seconds : bValue._seconds;
      const bNanoseconds = bValue.nanoseconds !== undefined ? bValue.nanoseconds : bValue._nanoseconds;
      const aTimestamp = aSeconds * 1000 + aNanoseconds / 1e6;
      const bTimestamp = bSeconds * 1000 + bNanoseconds / 1e6;
      return sortConfig.direction === "asc" ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
    }
  
    if (typeof aValue === "string" && typeof bValue === "string") {
      if (sortConfig.direction === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    }

    if (typeof a?.driverFirst === "string" && typeof b?.driverFirst === "string") {
      const aValue = `${a?.driverFirst} ${a?.driverLast}`;
      const bValue = `${b?.driverFirst} ${b?.driverLast}`;
      if (sortConfig.direction === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    }
  
    if (sortConfig.direction === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const offset = currentPage * itemsPerPage;

  let currentItems = !deletionMode || (deleteMode && deletionMode) ?
    sortedData.slice(offset, offset + itemsPerPage) : sortedData;

  const pageCount = Math.ceil(sortedData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (name && paginations) {
      setupPagination(
        paginations[`${name}Pagination`]?.totalCount,
        pageNumber,
        paginations,
        setPaginations,
        name
      )
    }
    setCurrentPage(pageNumber);
  };

  const { controlToast } = useGeneralState();

  const handleDeletionBehavior = (e, dataIndex) => {
    e.preventDefault();
    const newValue = { ...alternativeTableData[dataIndex] };
    const newArray = [...alternativeTableData];
    newValue['tableInfo']['actions']['actions'][0]['checkbox'] = false;
    newArray[dataIndex] = newValue;
    const filteredArray = newArray.filter(elem => {
      return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox);
    });
    const mappedArray = filteredArray.map(elem => {
      return {
        ...elem,
        tableInfo: {
          ...elem.tableInfo,
          actions: {
            ...elem.tableInfo.actions,
            actions: ["deletion-behavior"],
          },
        },
      };
    });
    setAlternativeTableData(newArray);
    setTableData(mappedArray);
  };

  const handleSingleCheckbox = (dataIndex, action) => {
    if (alternativeTableData?.length >= 10) {
      controlToast(true, 'You can not delete more than 10 drivers', 'info');
      return;
    }
    const newValue = { ...tableData[dataIndex] };
    const newArray = [...tableData];
    newValue['tableInfo']['actions']['actions'][0]['checkbox'] = !Boolean(action.checkbox);
    newArray[dataIndex] = newValue;
    const filteredArray = newArray.filter(elem => {
      return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox);
    });
    const mappedArray = filteredArray.map(elem => {
      return {
        ...elem,
        tableInfo: {
          ...elem.tableInfo,
          actions: {
            ...elem.tableInfo.actions,
            actions: ["deletion-behavior"],
          },
        },
      };
    });
    setTableData(newArray);
    setAlternativeTableData(mappedArray);
  };

  return {
    searchTerm,
    handleSearch,
    handleSort,
    currentItems,
    pageCount,
    currentPage,
    handlePageChange,
    sortConfig,
    handleSingleCheckbox,
    handleDeletionBehavior,
    loadingSearch
  };
}

export default useDynamicTable;






// import React, { useEffect, useState } from 'react';
// import { chooseKeysToPickInPayload, setupPagination, superSearcherFn } from "../../utils/helpers";
// import useGeneralState from '../../Context/actions/useGeneralState';
// import usePaginationsState from '../../Context/actions/usePaginationsState';

// function useDynamicTable(props) {
//   const {
//     filterKeys,
//     tableData,
//     setTableData,
//     alternativeTableData,
//     setAlternativeTableData,
//     deletionMode,
//     deleteMode,
//     superEndoSearcher,
//     sortDefault = { key: "", direction: "asc" },
//     name,
//   } = props;

//   const itemsPerPage = 10;
//   const [currentPage, setCurrentPage] = useState(0);
//   const [sortConfig, setSortConfig] = useState(sortDefault);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [loadingSearch, setLoadingSearch] = useState(false);

//   const { paginations, setPaginations } = usePaginationsState();

//   const handleSort = (key) => {
//     const direction =
//       sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
//     setSortConfig({ key, direction });
//   };

//   const handleSearch = (event) => {
//     setCurrentPage(0);
//     setSearchTerm(event.target.value);
//   };

//   const filterDataAsync = async () => {
//     const filteredResults = [];

//     for (const item of tableData) {
//       const foundMatchesInEndo = await superSearcherFn(
//         superEndoSearcher,
//         item?.data,
//         searchTerm?.toLowerCase(),
//         item?.searchData,
//         item?.agencyID,
//         item?.insuredID,
//         item?.type
//       );
//       const parsedData = chooseKeysToPickInPayload(filterKeys, item);
//       const searchInLowercase = searchTerm.toLowerCase();
//       const joinedData = Object.values(parsedData)
//         .join(" ")
//         .toLowerCase()
//         .includes(searchInLowercase);

//       if (joinedData || foundMatchesInEndo) {
//         filteredResults.push(item);
//       }
//     }

//     return filteredResults;
//   };

//   const [filteredData, setFilteredData] = useState([]);

//   useEffect(() => {
//     const performFilter = async () => {
//       setLoadingSearch(true);
//       const results = await filterDataAsync();
//       setFilteredData(results);
//       setLoadingSearch(false);
//     };
//     performFilter();
//   }, [tableData, searchTerm]);

//   const sortedData = [...filteredData].sort((a, b) => {
//     const aValue = a[sortConfig.key];
//     const bValue = b[sortConfig.key];

//     const aHasSeconds = aValue && (aValue.seconds !== undefined || aValue._seconds !== undefined);
//     const bHasSeconds = bValue && (bValue.seconds !== undefined || bValue._seconds !== undefined);
  
//     if (aHasSeconds && bHasSeconds) {
//       const aSeconds = aValue.seconds !== undefined ? aValue.seconds : aValue._seconds;
//       const aNanoseconds = aValue.nanoseconds !== undefined ? aValue.nanoseconds : aValue._nanoseconds;
//       const bSeconds = bValue.seconds !== undefined ? bValue.seconds : bValue._seconds;
//       const bNanoseconds = bValue.nanoseconds !== undefined ? bValue.nanoseconds : bValue._nanoseconds;
//       const aTimestamp = aSeconds * 1000 + aNanoseconds / 1e6;
//       const bTimestamp = bSeconds * 1000 + bNanoseconds / 1e6;
//       return sortConfig.direction === "asc" ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
//     }
  
//     if (typeof aValue === "string" && typeof bValue === "string") {
//       if (sortConfig.direction === "asc") {
//         return aValue.localeCompare(bValue);
//       } else {
//         return bValue.localeCompare(aValue);
//       }
//     }

//     if (typeof a?.driverFirst === "string" && typeof b?.driverFirst === "string") {
//       const aValue = `${a?.driverFirst} ${a?.driverLast}`;
//       const bValue = `${b?.driverFirst} ${b?.driverLast}`;
//       if (sortConfig.direction === "asc") {
//         return aValue.localeCompare(bValue);
//       } else {
//         return bValue.localeCompare(aValue);
//       }
//     }
  
//     if (sortConfig.direction === "asc") {
//       return aValue > bValue ? 1 : -1;
//     } else {
//       return aValue < bValue ? 1 : -1;
//     }
//   });

//   const offset = currentPage * itemsPerPage;

//   let currentItems = !deletionMode || (deleteMode && deletionMode) ?
//     sortedData.slice(offset, offset + itemsPerPage) : sortedData;

//   const pageCount = Math.ceil(sortedData.length / itemsPerPage);

//   const handlePageChange = (pageNumber) => {
//     if (name && paginations) {
//       setupPagination(
//         paginations[`${name}Pagination`]?.totalCount,
//         pageNumber,
//         paginations,
//         setPaginations,
//         name
//       )
//     }
//     setCurrentPage(pageNumber);
//   };

//   const { controlToast } = useGeneralState();

//   const handleDeletionBehavior = (e, dataIndex) => {
//     e.preventDefault();
//     const newValue = { ...alternativeTableData[dataIndex] };
//     const newArray = [...alternativeTableData];
//     newValue['tableInfo']['actions']['actions'][0]['checkbox'] = false;
//     newArray[dataIndex] = newValue;
//     const filteredArray = newArray.filter(elem => {
//       return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox);
//     });
//     const mappedArray = filteredArray.map(elem => {
//       return {
//         ...elem,
//         tableInfo: {
//           ...elem.tableInfo,
//           actions: {
//             ...elem.tableInfo.actions,
//             actions: ["deletion-behavior"],
//           },
//         },
//       };
//     });
//     setAlternativeTableData(newArray);
//     setTableData(mappedArray);
//   };

//   const handleSingleCheckbox = (dataIndex, action) => {
//     if (alternativeTableData?.length >= 10) {
//       controlToast(true, 'You can not delete more than 10 drivers', 'info');
//       return;
//     }
//     const newValue = { ...tableData[dataIndex] };
//     const newArray = [...tableData];
//     newValue['tableInfo']['actions']['actions'][0]['checkbox'] = !Boolean(action.checkbox);
//     newArray[dataIndex] = newValue;
//     const filteredArray = newArray.filter(elem => {
//       return Boolean(elem?.tableInfo?.actions?.actions?.[0]?.checkbox);
//     });
//     const mappedArray = filteredArray.map(elem => {
//       return {
//         ...elem,
//         tableInfo: {
//           ...elem.tableInfo,
//           actions: {
//             ...elem.tableInfo.actions,
//             actions: ["deletion-behavior"],
//           },
//         },
//       };
//     });
//     setTableData(newArray);
//     setAlternativeTableData(mappedArray);
//   };

//   return {
//     searchTerm,
//     handleSearch,
//     handleSort,
//     currentItems,
//     pageCount,
//     currentPage,
//     handlePageChange,
//     sortConfig,
//     handleSingleCheckbox,
//     handleDeletionBehavior,
//     loadingSearch
//   };
// }

// export default useDynamicTable;