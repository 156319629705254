import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export default function LoadingScreen({ text }) {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column", // Stack items vertically
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      height: "100vh", // Full viewport height
      width: "100%", // Full width
      position: "fixed", // Fixed position to overlay other content
      top: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.5)", // Optional: semi-transparent background
      backdropFilter: "blur(10px)", // Blurs the background
      zIndex: 9999, // Ensure it overlays above other content
    }}
  >
    <Typography variant="h6" sx={{ display: "block", marginBottom: 2 }}>
      {text}
    </Typography>
    <CircularProgress />
  </Box>
  )
}
