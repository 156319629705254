import React, { useState } from "react";
import BlueButton from "../../assets/forms/BlueButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useTranslations from "../../../Context/actions/useTranslations";
import PreviousYearsAdjustments from "./PreviousYearsAdjustments";

export default function PreviousYearsAdjustmentsModal({ formData }) {
  const insuredID = formData.insuredID;
  const agencyID = formData.agencyID;

  const { t } = useTranslations();

  const [openAssignDocumentModal, setOpenAssignDocumentModal] = useState(false);

  const handleOpenModal = () => {
    setOpenAssignDocumentModal(true);
  };

  const handleCloseModal = () => {
    setOpenAssignDocumentModal(false);
  };

  return (
    <>
      {formData.documentType !== "Policy" &&
        formData.newOrRenewal !== "New Business" && (
          <div>
            <BlueButton
              text={t("plm.quotes.previous-adjustments-button")}
              hasContentToBottom={true}
              onClickFunction={handleOpenModal}
              icon={<VisibilityIcon />}
            />

            {/* Modal */}
            <PreviousYearsAdjustments
              formData={formData}
              insuredID={insuredID}
              agencyID={agencyID}
              show={openAssignDocumentModal}
              setShow={setOpenAssignDocumentModal}
            />
          </div>
        )}
    </>
  );
}
