import React, { useEffect, useMemo, useState } from "react";
import DynamicTable from "../../assets/lists/DynamicTable";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Form from "../../generals/Forms/Form";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import ApiService from "../../../services/southern-api/apiService";
import requestExceptionModeSetting from "./utils/requestExceptionModeSetting";
import approveAUEndorsement from "./utils/removePowerUnit/approveAUEndorsement";
import Modals from "./AddPowerUnits/Modals";
import ButtonInstructions from "./RemovePowerUnits/ButtonInstructions";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import PremiumsTable from "./AddPowerUnits/PremiumsTable";

export default function RemoveMultiple({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  subcollection,
  tableDataRequest,
  columns,
  filterKeysToSearch,
  requestExceptionInputs,
  insuredInfo,
  subcollectionName,
  identifier,
}) {
  const apiService = new ApiService();

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const determineDeletionStatus = () => {
    return endorsement?.type?.includes("Exclude") ? "Excluded" : "Deleted";
  };

  const deletionStatus = determineDeletionStatus();

  const [vinArray, setVinArray] = useState([]);
  const [details, setDetails] = useState([]);
  const [data, setData] = useState([]);
  const policyID = paramPolicyID;
  const endoID = paramRefID;

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  useEffect(() => {
    tableDataRequest()
      .then((res) => {
        const { tableData, matchedSubcollections } = res;
        setDetails(tableData);
        setData(matchedSubcollections);
        if (tableData) {
          // Generate VIN array
          const values = tableData.map((unit) => unit.truckVIN);
          setVinArray(values);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [tableDataRequest]);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);
  const [unitsRated, setUnitsRated] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");

  const handleOpenDetailsModal = (id) => {
    setSelectedDriver(id);
    setOpenDetailsModal(!openDetailsModal);
  };

  return (
    <div>
      <Modals
        openInCancellationModal={openInCancellationModal}
        setOpenInCancellationModal={setOpenInCancellationModal}
        rejectionModal={rejectionModal}
        setRejectionModal={setRejectionModal}
        endorsement={endorsement}
        isRequestException={isRequestException}
        paramPolicyID={paramPolicyID}
        paramRefID={paramRefID}
        data={data}
        subcollection={subcollection}
        subcollectionName={subcollectionName}
        identifier={identifier}
        deletionStatus={deletionStatus}
        insuredInfo={insuredInfo}
        setSuccess={setSuccess}
        apiService={apiService}
        user={user}
        selectedDriver={selectedDriver}
        openDetailsModal={openDetailsModal}
        setOpenDetailsModal={setOpenDetailsModal}
        endoID={endoID}
        policyID={policyID}
      />
      <DynamicTable
        columns={columns}
        tableData={details}
        deleteThisItem={() => {}}
        onClickItem={(e, id) => {
          e.preventDefault();
          if (subcollection === "drivers") {
            handleOpenDetailsModal(id);
          }
        }}
        filterKeys={filterKeysToSearch}
      />

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      (user.role === "Underwriter" || user.role === "Admin") ? (
        <>
          {subcollection === "power-units" && !unitsRated ? (
            <RatePowerUnitEndorsement
              truckList={data}
              policyID={paramPolicyID}
              endoID={paramRefID}
              setSuccess={setUnitsRated}
              endoType={endorsement.type}
            />
          ) : (
            <>
              {subcollection === "power-units" ? (
                <PremiumsTable data={endorsement.alPremiums} />
              ) : null}
              <ButtonInstructions
                isRequestException={isRequestException}
                subcollection={subcollection}
              />
              {isRequestException && (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              )}
              <ApprovalButtons
                onApprove={(e) =>
                  approveAUEndorsement(
                    e,
                    null,
                    endorsement,
                    paramPolicyID,
                    paramRefID,
                    details,
                    subcollection,
                    subcollectionName,
                    identifier,
                    deletionStatus,
                    insuredInfo,
                    setSuccess,
                    apiService,
                    data,
                    policyID,
                    endoID,
                    setOpenInCancellationModal,
                    vinArray
                  )
                }
                onDecline={() => setRejectionModal(true)}
              />
            </>
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}

      {/* {(endorsement.status === "Submitted" || isRequestException) &&
      (user.role === "Underwriter" || user.role === "Admin") ? (
        <>
          {!unitsRated ? (
            <RatePowerUnitEndorsement
              truckList={data}
              policyID={paramPolicyID}
              endoID={paramRefID}
              setSuccess={setUnitsRated}
              endoType={endorsement.type}
            />
          ) : (
            <>
              <PremiumsTable data={endorsement.alPremiums} />
              <ButtonInstructions
                isRequestException={isRequestException}
                subcollection={subcollection}
              />
              {isRequestException && (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              )}
              <ApprovalButtons
                onApprove={(e) =>
                  approveAUEndorsement(
                    e,
                    null,
                    endorsement,
                    paramPolicyID,
                    paramRefID,
                    details,
                    subcollection,
                    subcollectionName,
                    identifier,
                    deletionStatus,
                    insuredInfo,
                    setSuccess,
                    apiService,
                    data,
                    policyID,
                    endoID,
                    setOpenInCancellationModal
                  )
                }
                onDecline={() => setRejectionModal(true)}
              />
            </>
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null} */}
    </div>
  );
}
