import React from "react";
import Popup from "../../../../generals/Popup";
import BlueButton from "../../../../assets/forms/BlueButton";
import { Download as DownloadIcon } from "@mui/icons-material";
import DriverMapSchema from "./DriverMapSchema";

export default function DriverMapModal({
    isOpen,
    onRequestClose,
    driverMapFile,
    driverMapData,
}) {

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      showExpandButton={true}
      expandByDefault={true}
    >
      {driverMapFile ?
        <BlueButton
          text="Download Driver Map"
          onClickFunction={(e) => {
            e.preventDefault();
            window.open(driverMapFile, "_blank");
          }}
          hasContentToTop={true}
          hasContentToBottom={true}
          icon={<DownloadIcon />}
        /> : null
      }
      {driverMapData?.payload ?
        <DriverMapSchema
          driverMapData={driverMapData?.payload}
        /> : null
      }
      {/* <DriverMapList
        driverMapData={driverMapData}
        driverMapSkeletonLoader={driverMapLoading}
        licenseCountry={driver?.licenseCountry}
        crashesArray={crashesArray}
      /> */}
    </Popup>
  );
}
