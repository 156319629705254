import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import Popup from "../../../generals/Popup";
import DriverDetails from "../../../PolicyLifecycleManager/Drivers/DriverDetails";

export default function DriverDetailsModal({
  driverModalIsOpen,
  closeDriverModal,
  driver,
  setDriverModalIsOpen,
  userInfo,
  namedInsured
}) {
  const driverID = driver.id;
  const policyID = driver?.policiesDetails[0]?.id;
  const [gotData, setGotData] = useState(false);
  const [formData, setFormData] = useState({});
  const getFormData = async () => {
    const formDataRef = doc(db, "policy-applications", policyID);
    const formData = await getDoc(formDataRef);
    const document = formData.data();
    setFormData(document);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (driverModalIsOpen) {
        await getFormData();
        setGotData(true);
      }
    };

    fetchData();
  }, [policyID, driverModalIsOpen]);

  const [isFullScreen, setIsFullScreen] = useState(true);

  return (
    <div>
      <Popup
        showExpandButton={true}
        isOpen={driverModalIsOpen}
        onRequestClose={() => setDriverModalIsOpen(false)}
        expandByDefault={true}
        maxWidth={"1000px"}
        setIsFullScreenActive={setIsFullScreen}
      >
        {!gotData ? (
          <h1>Loading...</h1>
        ) : (
          <>
            {driverModalIsOpen && (
              <DriverDetails
                selectedDriver={driverID}
                setShowDriverDetails={setDriverModalIsOpen}
                policyID={policyID}
                setShowTable={() => {
                  return null;
                }}
                userInfo={userInfo}
                formData={formData}
                namedInsured={""}
              />
            )}
          </>
        )}
      </Popup>
    </div>
  );
}
