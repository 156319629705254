import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const scoreClaimsAmount = async (numberOfClaims) => {
    if (!numberOfClaims) numberOfClaims = 0;

    // Get Claim Matrix
    let matrix = [];
    const querySnapshot = await getDocs(collection(db, "loss-control", "tables", "claimsDollarAmount"));
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        matrix.push({
            minClaimDollarAmount: data.minClaimDollarAmount,
            maxClaimDollarAmount: data.maxClaimDollarAmount,
            score: data.score
        });
    });

    // Get Score
    function getScore(number) {
        if (number === 0) {
            // Check if there's a range with minClaimDollarAmount = 0
            const zeroClaim = matrix.find(range => range.minClaimDollarAmount === 0);
            if (zeroClaim) return zeroClaim.score;
        }

        // Find the appropriate range
        for (let i = 0; i < matrix.length; i++) {
            const { minClaimDollarAmount, maxClaimDollarAmount, score } = matrix[i];
            if (number >= minClaimDollarAmount && number <= maxClaimDollarAmount) {
                return score;
            }
        }

        // Return the score for the highest range if no match is found
        return matrix[matrix.length - 1].score;
    }

    const returnValue = getScore(numberOfClaims);
    return returnValue;
}

export default scoreClaimsAmount;
