import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../../firebase.js";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  collection,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase.js";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styling/app.css";
import { Col, Row } from "react-bootstrap";
import useUsersState from "../../../Context/actions/useUsersState.js";

// Tabs Stuff
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SubmitBusiness(props) {
  let { paramRefID, paramSubmissionID } = useParams();
  let navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { user } = useUsersState();

  // Save Submission
  const saveSubmission = async () => {
    if (paramRefID !== auth.currentUser.uid)
      return alert(
        "Error: You are not the author. Only the author may save/submit."
      );
    if (formData.status !== "Incomplete" && formData.status !== "")
      return alert("Error: Submission already submitted.");
    if (paramSubmissionID === "new") {
      const newApplicationRef = doc(collection(db, "submissions"));

      await setDoc(newApplicationRef, {
        ...formData,
        status: "Incomplete",
        nextStep: "Await Submission",
        author: auth.currentUser.displayName,
        authorID:  auth.currentUser.uid,
      });
      navigate("/account#submission-history");
    } else {
      await setDoc(doc(db, "submissions", paramSubmissionID), {
        ...formData,
        status: "Incomplete",
        nextStep: "Await Submission",
        author: auth.currentUser.displayName,
        authorID:  auth.currentUser.uid,
      });
    }
    setFormData({...formData, status: "Incomplete"});
          // Log System Activity
          await addDoc(collection(db, "system-activity"), {
            activityDate: new Date(),
            authorID: auth.currentUser.uid,
            authorName: auth.currentUser.displayName,
            description:
              "Business Submission Saved by: " +
              user.first + " " +
              user.last +
              " of " +
              user.company,
          });
    alert("Submission Saved");
    navigate("/account#submission-history");
  };

  const submitSubmission = async () => {
    if (paramRefID !== auth.currentUser.uid)
      return alert("You are not the author. Only the author may save/submit.");
    if (formData.status !== "Incomplete" && formData.status !== "")
      return alert("Error: Submission already submitted.");
    const issues = document.querySelector("#issues");
    // ALERT ALERT ALERT RED ALERT
    // Remove the or statement to restrict subsmission to only complete forms
    // ALERT ALERT ALERT RED ALERT
    if (issues.textContent.length === 0 || issues.textContent.length !== 0) {
      if (paramSubmissionID === "new") {
        const newApplicationRef = doc(collection(db, "submissions"));

        await setDoc(newApplicationRef, {
          ...formData,
          status: "Submitted",
          author: auth.currentUser.displayName,
          authorID:  auth.currentUser.uid,
          nextStep: "Review Submission",
        });
        navigate("/account#submission-history");
      } else {
        await updateDoc(doc(db, "submissions", paramSubmissionID), {
          ...formData,
          status: "Submitted",
          author: auth.currentUser.displayName,
          authorID:  auth.currentUser.uid,
          nextStep: "Review Submission",
        });
        setFormData({...formData, status: "Submitted"});
         // Log System Activity
         await addDoc(collection(db, "system-activity"), {
          activityDate: new Date(),
          authorID: auth.currentUser.uid,
          authorName: auth.currentUser.displayName,
          description:
            "Business Submission Submitted for: " + formData.namedInsured + " (Submitted by: " +
            user.first + " " +
            user.last +
            " of " +
            user.company + ")"
        });
        sendEmail()
        .then(alert("Your submission has been transmitted to the underwriters."))
        .then(navigate("/account#submission-history"));
      }
    } else {
      alert(
        "You may not submit your Submission because it is incomplete. Please see the list of incomplete fields on the Submit tab of your Submission."
      );
    }
  };

  //   Get Data
  const [myData, setMyData] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  const [gotData, setGotData] = useState(false);
  const getSubmission = async () => {
    const docRef = doc(db, "submissions", paramSubmissionID);
    const docSnap = await getDoc(docRef);
    setGotData(false);
    setDataUpdated(true);
    if (docSnap.exists()) {
      setMyData(docSnap.data());
      setGotData(true);
      setDataUpdated(true);
      if (dataUpdated === true) fixData();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const [commodityTotal, setCommodityTotal] = useState(0);

  const updateCommodityTotal = () => {
    let total = 0;
    formData.commodityFields?.forEach((element) => {
      total = parseInt(total, 10) + parseInt(element.commodityLoad, 10);
    });
    setCommodityTotal(parseInt(total, 10));
  };

  const handleBlurCommodity = async (e, i) => {
    e.preventDefault();
    // const values = [...commodityFields];
    // if (e.target.value === "") {
    //   e.target.className = "border p-3 text-xl font-medium";
    // } else {
    //   e.target.className = "text-xl font-medium border-0 p-3";
    //   updateCommodityTotal();
    // }
    // await updateDoc(factfinderRef, { commodityFields: values });
  };
  const handleAddCommodity = () => {
    const i = formData.commodityFields.length;
    const lastID = formData.commodityFields[i - 1].id;
    setFormData({...formData, commodityFields: [
      ...formData.commodityFields,
      { id: lastID + 1, commodityDescription: "", commodityLoad: 0 },
    ]});
  };

  const handleSubtractCommodity = async (e, i) => {
    e.preventDefault();
    const values = [...formData.commodityFields];
    values.splice(i, 1);
    setFormData({...formData, commodityFields: values});
    if (values.length === 0) {
        setFormData({...formData, commodityFields: [
            {
              id: 1,
              commodityDescription: "",
              commodityLoad: 0,
            },
          ]});
    }
    // await updateDoc(factfinderRef, { commodityFields: values });
  };

  const handleChangeInputCommodity = (i, e) => {
    const values = [...formData.commodityFields];
    values[i][e.target.name] = e.target.value;
    setFormData({...formData, commodityFields: values});
  };


  //   Form Handling
  const [formData, setFormData]  = useState({
    status: "",
    nextStep: "",
    authorID: paramRefID,
    lastUpdated: date,
    namedInsured: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    physicalAddress: "",
    physicalCity: "",
    physicalState: "",
    physicalZip: "",
    DBA: "",
    phone: "",
    fax: "",
    MCNumber: "",
    DOTNumber: "",
    maximumTrailerValue: "",
    numberOfUnits: "",
    TIV: "",
    numberOfTrailers: "",
    anyOneLossLimitMTC: "",
    effectiveDate: new Date(),
    deductibleMTC: "",
    TILimitMTC: "",
    anyOneUnitLimitMTC: "",
    maximumUnitValue: "",
    APDCarrier1YearPrior: "",
    APDCarrier2YearsPrior: "",
    APDCarrier3YearsPrior: "",
    APDDeductible1YearPrior: "",
    APDDeductible2YearsPrior: "",
    APDDeductible3YearsPrior: "",
    APDNumberOfClaims1YearPrior: "",
    APDNumberOfClaims2YearsPrior: "",
    APDNumberOfClaims3YearsPrior: "",
    TIV1YearPrior: "",
    TIV2YearsPrior: "",
    TIV3YearsPrior: "",
    APDLossRuns1YearPrior: "",
    APDLossRuns2YearsPrior: "",
    APDLossRuns3YearsPrior: "",
    APDDollarAmountOfLosses1YearPrior: "",
    APDDollarAmountOfLosses2YearsPrior: "",
    APDDollarAmountOfLosses3YearsPrior: "",
    MTCCarrier1YearPrior: "",
    MTCDeductible1YearPrior: "",
    MTCNumberOfClaims1YearPrior: "",
    MTCDollarAmountOfLosses1YearPrior: "",
    MTCLossRuns1YearPrior: "",
    numberOfUnits1YearPrior: "",
    numberOfUnits2YearsPrior: "",
    numberOfUnits3YearsPrior: "",
    MTCCarrier2YearsPrior: "",
    MTCDeductible2YearsPrior: "",
    MTCNumberOfClaims2YearsPrior: "",
    MTCDollarAmountOfLosses2YearsPrior: "",
    MTCLossRuns2YearsPrior: "",
    MTCCarrier3YearsPrior: "",
    MTCDeductible3YearsPrior: "",
    MTCNumberOfClaims3YearsPrior: "",
    MTCDollarAmountOfLosses3YearsPrior: "",
    MTCLossRuns3YearsPrior: "",
    driverDocuments: "",
    activeDriverList: "",
    activeTrailerAndUnitList: "",
    APD: false,
    MTC: false,
    XSMTC: false,
    claimsNotificationClause: false,
    MTCNewlyAcquiredEquipmentExtension: false,
    profitSharing: false,
    inFullPremium: false,
    debrisRemoval: false,
    reefer: false,
    trailerInterchangeMTC: false,
    targetInclusionEndorsement: false,
    towingLimit: "",
    notificationOfClaims: false,
    APDnewlyAcquiredEquipment: false,
    towingStorageCleanUp: false,
    automaticLossPayee: false,
    nonOwnedHiredAuto: false,
    nonOwnedTrailer: false,
    APDTrailerInterchange: false,
    TILimitAPD: "",
    APDDeductible: "",
    anyOneLossLimitAPD: "",
    commodityFields: [{
        id: 1,
        commodityDescription: "",
        commodityLoad: "",
      }],
    comments: "",
  });

  const fixData = () => {
    const convertedDate = convertDate(myData.effectiveDate.seconds)
    setFormData({...formData, ...myData, effectiveDate: convertedDate})
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);

  const handleSubmit = (e, changer) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];

    if (!file) return;

    const storageRef = ref(storage, `files/business-submissions/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
          setFormData({...formData, [changer]: downloadURL});
        });
      }
    );
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  const [disabled, setDisabled] = useState(false);

  const sendEmail = async () => {
    axios
      .post("https://sstools-api-d4ca8.uc.r.appspot.com/send-email/business-submission", {
        ...formData,
        effectiveDate: convertDate(formData.effectiveDate),
        commodityFields: formData.commodityFields.toString(),
        submitter: user.first + " " + user.last,
        submitterCompany: user.company,
        submitterEmail: user.email
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  };
  
  useEffect(() => {
    getSubmission();
    if (myData.status === "Submitted") setDisabled(true);
  }, [dataUpdated]);

  return (
    <fieldset>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Factfinder Tabs"
            // disable the tab indicator because it doesn't work well with wrapped container
            // TabIndicatorProps={{ sx: { display: "none" } }}
            indicatorColor="black"
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
                backgroundColor: "lightGray",
                marginTop: "5px",
                justifyContent: "center",
              },
            }}
          >
            <Tab label="Status" {...a11yProps(0)} />
            <Tab label="Insured Info" {...a11yProps(1)} />
            <Tab label="Policy Info" {...a11yProps(2)} />
            <Tab label="MTC" {...a11yProps(3)} />
            <Tab label="APD" {...a11yProps(4)} />
            <Tab label="Documentation" {...a11yProps(5)} />
            <Tab label="Submit" {...a11yProps(6)} />
          </Tabs>
        </Box>
        {/* Status */}
        <TabPanel value={value} index={0} className="bg-gray-100">
          {formData.status !== "" && (
            <p className="pt-1 text-xl text-[#072a48] text-center">
              Current Submission Status:
            </p>
          )}
          {formData.status === "Incomplete" ? (
            <>
              <p className="pt-1 pb-3 text-3xl font-bold text-[#8B0000] text-center">
                {formData.status}
              </p>
            </>
          ) : (
            <p className="pt-1 pb-3 text-3xl font-bold text-green-600 text-center">
              {formData.status}
            </p>
          )}
          <div>
            <p className="max-w-[500px] mx-auto">More About Statuses</p>
            <p className="max-w-[500px] mx-auto font-bold text-md text-[#072a48] mt-2">
              Incomplete/No Status
            </p>
            <p className="max-w-[500px] mx-auto">
              Your status will not change to Submitted until you have completed
              all fields and have clicked the Submit button in the Submit tab.
              If the information for a particular field truly does not exist,
              you may enter 0 or N/A.
            </p>
            <p className="max-w-[500px] mx-auto font-bold text-md text-[#072a48] mt-4">
              Submitted
            </p>
            <p className="max-w-[500px] mx-auto">
              Once your submission is complete and has been submitted, it will
              go into review by our underwriting team. You will be notified via
              e-mail if your submission was accepted or if the underwriters have
              any further questions.
            </p>
          </div>
          <div className="text-center w-full">
            <button
              onClick={() => {
                setValue(value + 1);
              }}
              className="button-2 mt-4"
            >
              Continue
            </button>
          </div>
        </TabPanel>
        {/* Insured Information */}
        <TabPanel value={value} index={1} className="bg-gray-100">
          <h1 className="title-1">Insured Information</h1>
          <div>
            <label className="input-label">Named Insured:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, namedInsured: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.namedInsured}
            />
          </div>
          <div>
            <label className="input-label">Trading As (DBA):</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, DBA: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.DBA}
            />
          </div>
          <div>
            <label className="input-label">Address:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, address: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.address}
            />
          </div>
          <div>
            <label className="input-label">City:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, city: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.city}
            />
          </div>
          <div>
            <label className="input-label">State:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, state: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.state}
            />
          </div>
          <div>
            <label className="input-label">Zip:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, zip: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.zip}
            />
          </div>
          <div>
            <label className="input-label">
              Physical Address (If Different from Above):
            </label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, physicalAddress: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.physicalAddress}
            />
          </div>
          <div>
            <label className="input-label">City:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, physicalCity: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.physicalCity}
            />
          </div>
          <div>
            <label className="input-label">State:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, physicalState: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.physicalState}
            />
          </div>
          <div>
            <label className="input-label">Zip:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, physicalZip: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.physicalZip}
            />
          </div>
          <div>
            <label className="input-label">Phone Number:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, phone: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.phone}
            />
          </div>
          <div>
            <label className="input-label">Fax Number:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, fax: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.fax}
            />
          </div>
          <div>
            <label className="input-label">MC Number:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, MCNumber: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.MCNumber}
            />
          </div>
          <div>
            <label className="input-label">DOT Number:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, DOTNumber: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.DOTNumber}
            />
          </div>

          <article className="back-next-container text-center">
            {formData.status === "Incomplete" || formData.status === "" ? (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Next
                </button>
              </>
            )}
            <button
              onClick={() => setValue(value - 1)}
              className="button-1"
              disabled={value === 0}
            >
              Back
            </button>
          </article>
        </TabPanel>
        {/* Policy Info */}
        <TabPanel value={value} index={2} className="bg-gray-100">
          <h1 className="title-1">Policy Information</h1>
          <div>
            <label className="input-label">Proposed Effective Date:</label>
            <DatePicker wrapperClassName="datePicker"
              selected={formData.effectiveDate}
              onChange={(date) => {
                setFormData({...formData, effectiveDate: date});
              }}
              className="text-input w-full"
            />
          </div>

          <div>
            <label className="input-label">Coverages:</label>
            <div className="w-full text-bottom mb-1">
              <input
                disabled={disabled}
                onChange={(e) => setFormData({...formData, APD: (!formData.APD)})}
                
                type="checkbox"
                name="APD"
                id="APD"
                className="form-input"
                value="APD"
                checked={formData.APD}
              />
              <label htmlFor="APD" className="checkbox-input-label ml-2 mr-2">
                Auto Physical Damage
              </label>
            </div>
            <div className="w-full text-bottom mb-1">
              <input
                disabled={disabled}
                onChange={(e) => setFormData({...formData, MTC: (!formData.MTC)})}
                
                type="checkbox"
                name="MTC"
                id="MTC"
                className="form-input"
                value="MTC"
                checked={formData.MTC}
              />
              <label htmlFor="MTC" className="checkbox-input-label ml-2">
                Motor Truck Cargo
              </label>
            </div>
            <div className="w-full text-bottom mb-1">
              <input
                disabled={disabled}
                onChange={(e) => setFormData({...formData, XSMTC: (!formData.XSMTC)})}
                
                type="checkbox"
                name="XSMTC"
                id="XSMTC"
                className="form-input"
                value="XSMTC"
                checked={formData.XSMTC}
              />
              <label htmlFor="XSMTC" className="checkbox-input-label ml-2">
                Excess Motor Truck Cargo
              </label>
            </div>
          </div>
          <div>
            <label className="input-label mt-3">Total Insured Value:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, TIV: e.target.value})}
              
              className="text-input w-full"
              type="number"
              value={formData.TIV}
            />
          </div>
          <div>
            <label className="input-label">Number of Units:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, numberOfUnits: e.target.value})}
              
              className="text-input w-full"
              type="number"
              value={formData.numberOfUnits}
            />
          </div>
          <div>
            <label className="input-label">Number of Trailers:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, numberOfTrailers: e.target.value})}
              
              className="text-input w-full"
              type="number"
              value={formData.numberOfTrailers}
            />
          </div>
          <div>
            <label className="input-label">Maximum Unit Value</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, maximumUnitValue: e.target.value})}
              
              className="text-input w-full"
              type="text"
              value={formData.maximumUnitValue}
            />
          </div>
          <div>
            <label className="input-label">Maximum Trailer Value:</label>
            <input
              disabled={disabled}
              onChange={(e) => setFormData({...formData, maximumTrailerValue: e.target.value})}
              
              className="text-input w-full"
              type="number"
              value={formData.maximumTrailerValue}
            />
          </div>

          <article className="back-next-container text-center">
            {formData.status === "Incomplete" || formData.status === "" ? (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Next
                </button>
              </>
            )}
            <button
              onClick={() => setValue(value - 1)}
              className="button-1"
              disabled={value === 0}
            >
              Back
            </button>
          </article>
        </TabPanel>
        {/* MTC  */}
        <TabPanel value={value} index={3} className="bg-gray-100">
          {formData.MTC === false ? (
            <p>
              Add Motor Truck Cargo coverage in the Policy Information tab to
              see these fields.
            </p>
          ) : (
            <section>
              <h1 className="title-1">Motor Truck Cargo</h1>

              <div>
                <label className="input-label">Any One Loss Limit:</label>
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, anyOneLossLimitMTC: e.target.value})}
                  
                  className="text-input w-full"
                  type="number"
                  value={formData.anyOneLossLimitMTC}
                />
              </div>

              <div>
                <label className="input-label">Any one unit limit:</label>
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, anyOneUnitLimitMTC: e.target.value})}
                  
                  className="text-input w-full"
                  type="number"
                  value={formData.anyOneUnitLimitMTC}
                />
              </div>
              <div>
                <label className="input-label">Deductible:</label>
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, deductibleMTC: e.target.value})}
                  
                  className="text-input w-full"
                  type="number"
                  value={formData.deductibleMTC}
                />
              </div>

              <label className="input-label">
                MTC Wordings & Non-Standard Clauses:
              </label>
              <div>
                <p className="mb-2">Select all that apply:</p>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                      setFormData({...formData, claimsNotificationClause: !formData.claimsNotificationClause})
                    }
                    
                    type="checkbox"
                    name="claimsNotificationClause"
                    id="claimsNotificationClause"
                    className="form-input"
                    value="claimsNotificationClause"
                    checked={formData.claimsNotificationClause}
                  />
                  <label
                    htmlFor="claimsNotificationClause"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Claims Notifications Clause
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                      setFormData({...formData, MTCNewlyAcquiredEquipmentExtension:
                        !formData.MTCNewlyAcquiredEquipmentExtension}
                      )
                    }
                    
                    type="checkbox"
                    name="MTCNewlyAcquiredEquipmentExtension"
                    id="MTCNewlyAcquiredEquipmentExtension"
                    className="form-input"
                    value="MTCNewlyAcquiredEquipmentExtension"
                    checked={formData.MTCNewlyAcquiredEquipmentExtension}
                  />
                  <label
                    htmlFor="MTCNewlyAcquiredEquipmentExtension"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Newly Acquired Equipment Extension
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, profitSharing: (!formData.profitSharing)})}
                    
                    type="checkbox"
                    name="profitSharing"
                    id="profitSharing"
                    className="form-input"
                    value="profitSharing"
                    checked={formData.profitSharing}
                  />
                  <label
                    htmlFor="profitSharing"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    50/50/50 Profit Sharing Endorsement
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, InFullPremium: (!formData.inFullPremium)})}
                    
                    type="checkbox"
                    name="inFullPremium"
                    id="inFullPremium"
                    className="form-input"
                    value="inFullPremium"
                    checked={formData.inFullPremium}
                  />
                  <label
                    htmlFor="inFullPremium"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    In Full Premium
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, debrisRemoval: (!formData.debrisRemoval)})}
                    
                    type="checkbox"
                    name="debrisRemoval"
                    id="debrisRemoval"
                    className="form-input"
                    value="debrisRemoval"
                    checked={formData.debrisRemoval}
                  />
                  <label
                    htmlFor="debrisRemoval"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Debris Removal (USD 5,000 Limit)
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, reefer: (!formData.reefer)})}
                    
                    type="checkbox"
                    name="reefer"
                    id="reefer"
                    className="form-input"
                    value="reefer"
                    checked={formData.reefer}
                  />
                  <label
                    htmlFor="reefer"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Refrigerated Breakdown (USD 2,500 Limit)
                  </label>
                </div>
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                      setFormData({...formData, trailerInterchangeMTC: (!formData.trailerInterchangeMTC)})
                    }
                    
                    type="checkbox"
                    name="trailerInterchangeMTC"
                    id="trailerInterchangeMTC"
                    className="form-input"
                    value="trailerInterchangeMTC"
                    checked={formData.trailerInterchangeMTC}
                  />
                  <label
                    htmlFor="trailerInterchangeMTC"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Attached Trailer Interchange
                  </label>
                </div>
                {formData.trailerInterchangeMTC === true && (
                  <>
                    <div>
                      <label className="input-label mt-2">
                        Trailer Interchange Limit:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) => setFormData({...formData, TILimitMTC: e.target.value})}
                        
                        className="text-input w-full"
                        type="number"
                        value={formData.TILimitMTC}
                      />
                    </div>
                  </>
                )}
                <div className="w-full text-bottom mb-1">
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                      setFormData({...formData, targetInclusionEndorsement: !formData.targetInclusionEndorsement})
                    }
                    
                    type="checkbox"
                    name="targetInclusionEndorsement"
                    id="targetInclusionEndorsement"
                    className="form-input"
                    value="targetInclusionEndorsement"
                    checked={formData.targetInclusionEndorsement}
                  />
                  <label
                    htmlFor="targetInclusionEndorsement"
                    className="checkbox-input-label ml-2 mr-2"
                  >
                    Target Inclusion Endorsement
                  </label>
                </div>
              </div>

              <label className="input-label mb-2 mt-4">
                Commodities Hauled:
              </label>
              <div>
                {formData.commodityFields.map((field, i) => (
                  <div key={"commodity" + field.id} id={"commodity" + field.id}>
                    <Row>
                      <Col>
                        <div>
                          <label className="input-label">
                            Describe Commodity {i + 1}:
                          </label>
                          <input
                            name="commodityDescription"
                            disabled={disabled}
                            
                            onChange={(e) => handleChangeInputCommodity(i, e)}
                            onBlur={handleBlurCommodity}
                            className={`text-input w-full ${
                              field.commodityDescription === "" && `bg-red-100`
                            }`}
                            type="text"
                            value={field.commodityDescription}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <label className="input-label">
                            Commodity {i + 1} % of Hauls:
                          </label>
                          <input
                            name="commodityLoad"
                            disabled={disabled}
                            
                            onChange={(e) => handleChangeInputCommodity(i, e)}
                            onBlur={handleBlurCommodity}
                            className={`text-input w-full ${
                              field.commodityLoad === 0 && `bg-red-100`
                            }`}
                            type="number"
                            value={field.commodityLoad}
                          />
                        </div>
                      </Col>
                    </Row>
                    <button
                      className="text-[#072a48]"
                      onClick={(e) => handleSubtractCommodity(e, i)}
                    >
                      - Remove Commodity {i + 1}
                    </button>
                  </div>
                ))}
                <button
                  className="text-[#072a48] mr-10"
                  onClick={() => handleAddCommodity()}
                >
                  + Add a Commodity
                </button>
              </div>

              <label className="input-label mb-2 mt-4">Loss History:</label>
              <p>
                Enter 0 for any amounts nd N/A for carrier if the company was
                not operating during that year
              </p>
              <label className="input-label mt-3">1st Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, MTCCarrier1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.MTCCarrier1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, MTCDeductible1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDeductible1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCNumberOfClaims1YearPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCNumberOfClaims1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCDollarAmountOfLosses1YearPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDollarAmountOfLosses1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Units:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, numberOfUnits1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.numberOfUnits1YearPrior}
                  />
                </div>
                <div>
                  {formData.MTCLossRuns1YearPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "MTCLossRuns1YearPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, MTCLossRuns1YearPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>
              <label className="input-label mt-3">2nd Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, MTCCarrier2YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.MTCCarrier2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCDeductible2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDeductible2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCNumberOfClaims2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCNumberOfClaims2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCDollarAmountOfLosses2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDollarAmountOfLosses2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Units:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, numberOfUnits2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.numberOfUnits2YearsPrior}
                  />
                </div>
                <div>
                  {formData.MTCLossRuns2YearsPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "MTCLossRuns2YearsPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, MTCLossRuns2YearsPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>
              <label className="input-label mt-3">3rd Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, MTCCarrier3YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.MTCCarrier3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCDeductible3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDeductible3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCNumberOfClaims3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCNumberOfClaims3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, MTCDollarAmountOfLosses3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.MTCDollarAmountOfLosses3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Units:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, numberOfUnits3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.numberOfUnits3YearsPrior}
                  />
                </div>
                <div>
                  {formData.MTCLossRuns3YearsPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "MTCLossRuns3YearsPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, MTCLossRuns3YearsPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>

              <article className="back-next-container text-center">
                {formData.status === "Incomplete" || formData.status === "" ? (
                  <>
                    <button
                      onClick={() => {
                        setValue(value + 1);
                      }}
                      className="button-2"
                    >
                      Continue
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setValue(value + 1);
                      }}
                      className="button-2"
                    >
                      Next
                    </button>
                  </>
                )}
                <button
                  onClick={() => setValue(value - 1)}
                  className="button-1"
                  disabled={value === 0}
                >
                  Back
                </button>
              </article>
            </section>
          )}
        </TabPanel>
        {/* APD */}
        <TabPanel value={value} index={4} className="bg-gray-100">
          {formData.APD === false ? (
            <p>
              Add Auto Phyiscal Damage coverage in the Policy Information tab to
              see these fields.
            </p>
          ) : (
            <section>
              <h1 className="title-1">Auto Physical Damage</h1>
              <div>
                <label className="input-label">Any One Loss Limit:</label>
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, anyOneLossLimitAPD: e.target.value})}
                  
                  className="text-input w-full"
                  type="number"
                  value={formData.anyOneLossLimitAPD}
                />
              </div>

              <div>
                <label className="input-label">Deductible:</label>
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, APDDeductible: e.target.value})}
                  
                  className="text-input w-full"
                  type="number"
                  value={formData.APDDeductible}
                />
              </div>
              <label className="input-label">
                Wordings & Non-Standard Clauses:
              </label>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) =>
                    setFormData({...formData, notificationOfClaims: !formData.notificationOfClaims})
                  }
                  
                  type="checkbox"
                  name="notificationOfClaims"
                  id="notificationOfClaims"
                  className="form-input"
                  value="notificationOfClaims"
                  checked={formData.notificationOfClaims}
                />
                <label
                  htmlFor="notificationOfClaims"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Notification of Claims
                </label>
              </div>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) =>
                    setFormData({...formData, APDnewlyAcquiredEquipment: !formData.APDnewlyAcquiredEquipment})
                  }
                  
                  type="checkbox"
                  name="APDnewlyAcquiredEquipment"
                  id="APDnewlyAcquiredEquipment"
                  className="form-input"
                  value="APDnewlyAcquiredEquipment"
                  checked={formData.APDnewlyAcquiredEquipment}
                />
                <label
                  htmlFor="APDnewlyAcquiredEquipment"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Newly Acquired Equipment Extension
                </label>
              </div>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) =>
                    setFormData({...formData, towingStorageCleanUp: !formData.towingStorageCleanUp})
                  }
                  
                  type="checkbox"
                  name="towingStorageCleanUp"
                  id="towingStorageCleanUp"
                  className="form-input"
                  value="towingStorageCleanUp"
                  checked={formData.towingStorageCleanUp}
                />
                <label
                  htmlFor="towingStorageCleanUp"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Towing, Storage, & Clean Up
                </label>
              </div>
              {formData.towingStorageCleanUp === true && (
                <>
                  <div>
                    <label className="input-label mt-2">
                      Towing, Storage, & Clean Up Limit:
                    </label>
                    <input
                      disabled={disabled}
                      onChange={(e) => setFormData({...formData, towingLimit: e.target.value})}
                      
                      className="text-input w-full"
                      type="number"
                      value={formData.towingLimit}
                    />
                  </div>
                </>
              )}
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, automaticLossPayee: (!formData.automaticLossPayee)})}
                  
                  type="checkbox"
                  name="automaticLossPayee"
                  id="automaticLossPayee"
                  className="form-input"
                  value="automaticLossPayee"
                  checked={formData.automaticLossPayee}
                />
                <label
                  htmlFor="automaticLossPayee"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Automatic Loss Payee Clause
                </label>
              </div>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, nonOwnedHiredAuto: (!formData.nonOwnedHiredAuto)})}
                  
                  type="checkbox"
                  name="nonOwnedHiredAuto"
                  id="nonOwnedHiredAuto"
                  className="form-input"
                  value="nonOwnedHiredAuto"
                  checked={formData.nonOwnedHiredAuto}
                />
                <label
                  htmlFor="nonOwnedHiredAuto"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Non-Owned Hired Auto Endorsement
                </label>
              </div>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) => setFormData({...formData, nonOwnedTrailer: (!formData.nonOwnedTrailer)})}
                  
                  type="checkbox"
                  name="nonOwnedTrailer"
                  id="nonOwnedTrailer"
                  className="form-input"
                  value="nonOwnedTrailer"
                  checked={formData.nonOwnedTrailer}
                />
                <label
                  htmlFor="nonOwnedTrailer"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Non-Owned Trailer Clause
                </label>
              </div>
              <div className="w-full text-bottom mb-1">
                <input
                  disabled={disabled}
                  onChange={(e) =>
                    setFormData({...formData, APDTrailerInterchange: !formData.APDTrailerInterchange})
                  }
                  
                  type="checkbox"
                  name="APDTrailerInterchange"
                  id="APDTrailerInterchange"
                  className="form-input"
                  value="APDTrailerInterchange"
                  checked={formData.APDTrailerInterchange}
                />
                <label
                  htmlFor="APDTrailerInterchange"
                  className="checkbox-input-label ml-2 mr-2"
                >
                  Trailer Interchange
                </label>
              </div>
              {formData.APDTrailerInterchange === true && (
                <>
                  <div>
                    <label className="input-label mt-2">
                      Trailer Interchange Limit:
                    </label>
                    <input
                      disabled={disabled}
                      onChange={(e) => setFormData({...formData, TILimitAPD: e.target.value})}
                      
                      className="text-input w-full"
                      type="number"
                      value={formData.TILimitAPD}
                    />
                  </div>
                </>
              )}
              <label className="input-label mb-2 mt-4">Loss History:</label>
              <p>
                Enter 0 for any amounts nd N/A for carrier if the company was
                not operating during that year
              </p>
              <label className="input-label mt-3">1st Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, APDCarrier1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.APDCarrier1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, APDDeductible1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDeductible1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDNumberOfClaims1YearPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDNumberOfClaims1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDDollarAmountOfLosses1YearPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDollarAmountOfLosses1YearPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Total Insured Value:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, TIV1YearPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.TIV1YearPrior}
                  />
                </div>
                <div>
                  {formData.APDLossRuns1YearPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "APDLossRuns1YearPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, APDLossRuns1YearPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>
              <label className="input-label mt-3">2nd Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, APDCarrier2YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.APDCarrier2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDDeductible2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDeductible2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDNumberOfClaims2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDNumberOfClaims2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDDollarAmountOfLosses2YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDollarAmountOfLosses2YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Total Insured Value:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, TIV2YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.TIV2YearsPrior}
                  />
                </div>
                <div>
                  {formData.APDLossRuns2YearsPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "APDLossRuns2YearsPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, APDLossRuns2YearsPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>
              <label className="input-label mt-3">3rd Year Prior:</label>
              <section>
                <div>
                  <label className="input-label">Carrier:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, APDCarrier3YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="text"
                    value={formData.APDCarrier3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Deductible:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDDeductible3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDeductible3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Number of Claims:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDNumberOfClaims3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDNumberOfClaims3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">
                    Dollar Amount of Losses:
                  </label>
                  <input
                    disabled={disabled}
                    onChange={(e) =>
                        setFormData({...formData, APDDollarAmountOfLosses3YearsPrior: e.target.value})
                    }
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.APDDollarAmountOfLosses3YearsPrior}
                  />
                </div>
                <div>
                  <label className="input-label">Total Insured Value:</label>
                  <input
                    disabled={disabled}
                    onChange={(e) => setFormData({...formData, TIV3YearsPrior: e.target.value})}
                    
                    className="text-input w-full"
                    type="number"
                    value={formData.TIV3YearsPrior}
                  />
                </div>
                <div>
                  {formData.APDLossRuns3YearsPrior === "" ? (
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, "APDLossRuns3YearsPrior")
                      }
                      className="form"
                    >
                      <input disabled={disabled} type="file" />
                      <button
                        type="submit"
                        className="bg-green-500 px-2 py-1 rounded"
                      >
                        Upload
                      </button>
                    </form>
                  ) : (
                    <>
                      <div>
                        File Uploaded, you may{" "}
                        <button
                        disabled={disabled}
                          className="text-[#8B0000]"
                          onClick={(e) => setFormData({...formData, APDLossRuns3YearsPrior: ""})}
                        >
                          Replace
                        </button>{" "}
                        the file if necessary.
                      </div>
                    </>
                  )}
                </div>
              </section>
              <article className="back-next-container text-center">
                {formData.status === "Incomplete" || formData.status === "" ? (
                  <>
                    <button
                      onClick={() => {
                        setValue(value + 1);
                      }}
                      className="button-2"
                    >
                      Continue
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setValue(value + 1);
                      }}
                      className="button-2"
                    >
                      Next
                    </button>
                  </>
                )}
                <button
                  onClick={() => setValue(value - 1)}
                  className="button-1"
                  disabled={value === 0}
                >
                  Back
                </button>
              </article>
            </section>
          )}
        </TabPanel>
        {/* Required Documentation */}
        <TabPanel value={value} index={5} className="bg-gray-100">
          <h1 className="title-1">Other Required Documentation</h1>
          <div className="mb-3">
            <label className="input-label">
              {formData.driverDocuments === "" ? (
                <span className="text-[#8B0000]">(Not Uploaded) </span>
              ) : (
                <span className="text-green-600">(Uploaded) </span>
              )}
              A zip file containing all driver documents:
            </label>
            <div className="ml-5">
              {formData.driverDocuments !== "" ? (
                <div>
                  File Uploaded, you may{" "}
                  <a
                    className="text-[#072a48]"
                    rel="noreferrer"
                    target="_blank"
                    href={formData.driverDocuments}
                  >
                    View
                  </a>{" "}
                  or{" "}
                  <button
                    disabled={disabled}
                    className="text-[#8B0000]"
                    onClick={(e) => setFormData({...formData, driverDocuments: ""})}
                    >
                    Delete
                  </button>{" "}
                  the file.
                </div>
              ) : (
                <form
                  onSubmit={(e) => handleSubmit(e, "driverDocuments")}
                  className="form"
                >
                  <input disabled={disabled} type="file" />
                  <button
                    type="submit"
                    className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold"
                  >
                    Upload
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label className="input-label">
              {formData.activeDriverList === "" ? (
                <span className="text-[#8B0000]">(Not Uploaded) </span>
              ) : (
                <span className="text-green-600">(Uploaded) </span>
              )}
              Active driver list:
            </label>
            <div className="ml-5">
              {formData.activeDriverList !== "" ? (
                <div>
                  File Uploaded, you may{" "}
                  <a
                    className="text-[#072a48]"
                    target="_blank"
                    rel="noreferrer"
                    href={formData.activeDriverList}
                  >
                    View
                  </a>{" "}
                  or{" "}
                  <button
                  disabled={disabled}
                    className="text-[#8B0000]"
                    onClick={(e) => setFormData({...formData, activeDriverList: ""})}
                  >
                    Delete
                  </button>{" "}
                  the file.
                </div>
              ) : (
                <form
                  onSubmit={(e) => handleSubmit(e, "activeDriverList")}
                  className="form"
                >
                  <input disabled={disabled} type="file" />
                  <button
                    type="submit"
                    className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold"
                  >
                    Upload
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label className="input-label">
              {formData.activeTrailerAndUnitList === "" ? (
                <span className="text-[#8B0000]">(Not Uploaded) </span>
              ) : (
                <span className="text-green-600">(Uploaded) </span>
              )}
              Active trailer & unit list:
            </label>
            <div className="ml-5">
              {formData.activeTrailerAndUnitList !== "" ? (
                <div>
                  File Uploaded, you may{" "}
                  <a
                    className="text-[#072a48]"
                    target="_blank"
                    rel="noreferrer"
                    href={formData.activeTrailerAndUnitList}
                  >
                    View
                  </a>{" "}
                  or{" "}
                  <button
                  disabled={disabled}
                    className="text-[#8B0000]"
                    onClick={(e) => setFormData({...formData, activeTrailerAndUnitList: ""})}
                  >
                    Delete
                  </button>{" "}
                  the file.
                </div>
              ) : (
                <form
                  onSubmit={(e) => handleSubmit(e, "activeTrailerAndUnitList")}
                  className="form"
                >
                  <input disabled={disabled} type="file" />
                  <button
                    type="submit"
                    className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold"
                  >
                    Upload
                  </button>
                </form>
              )}
            </div>
          </div>

          <article className="back-next-container text-center">
            {formData.status === "Incomplete" || formData.status === "" ? (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    setValue(value + 1);
                  }}
                  className="button-2"
                >
                  Next
                </button>
              </>
            )}
            <button
              onClick={() => setValue(value - 1)}
              className="button-1"
              disabled={value === 0}
            >
              Back
            </button>
          </article>
        </TabPanel>
        {/* Comments & Submit */}
        <TabPanel value={value} index={6} className="bg-gray-100 text-center">
          <h1 className="title-1">Save or Submit</h1>
          <label className="input-label">Comments:</label>
          <textarea
            disabled={disabled}
            className="min-w-[300px] min-h-[150px] rounded"
            onChange={(e) => setFormData({...formData, comments: e.target.value})}
            value={formData.comments}
            placeholder="Type any additional comments you have here."
          ></textarea>
          {/* Validation */}
          <div id="issues" className="mb-4 mt-4">
            {/* Insured Info */}
            <div>
              {formData.namedInsured === "" && <p>Named Insured field is incomplete.</p>}
              {formData.address === "" && <p>Address field is incomplete.</p>}
              {formData.city === "" && <p>City field is incomplete.</p>}
              {formData.state === "" && <p>State field is incomplete.</p>}
              {formData.zip === "" && <p>Zip field is incomplete.</p>}
              {formData.phone === "" && <p>Phone field is incomplete.</p>}
              {formData.fax === "" && <p>Fax field is incomplete.</p>}
              {formData.MCNumber === "" && <p>MC Number field is incomplete.</p>}
              {formData.DOTNumber === "" && <p>DOT Number field is incomplete.</p>}
            </div>
            {/* Policy Information */}
            <div>
              {formData.effectiveDate === "" && (
                <p>Effective Date field is incomplete.</p>
              )}
              {formData.TIV === "" && <p>Total Insured Value field is incomplete.</p>}
              {formData.numberOfUnits === "" && (
                <p>Number of Units field is incomplete.</p>
              )}
              
              {formData.numberOfTrailers === "" && (
                <p>Number of Trailers field is incomplete.</p>
              )}
              {formData.maximumUnitValue === "" && <p></p>}
              {formData.maximumTrailerValue === "" && (
                <p>Maximum Trailer Value field is incomplete.</p>
              )}
            </div>
            {/* MTC */}
            {formData.MTC === true && (
              <div>
                {formData.anyOneLossLimitMTC === "" && (
                  <p>Any One Loss (MTC) field is incomplete.</p>
                )}
                {formData.anyOneUnitLimitMTC === "" && (
                  <p>Any One Unit Limit (MTC) field is incomplete.</p>
                )}
                {formData.deductibleMTC === "" && <p>Deductible field is incomplete.</p>}
                {formData.trailerInterchangeMTC === true && (
                  <p>
                    {formData.TILimitMTC === "" && (
                      <p>Trailer Interchange Limit field is incomplete.</p>
                    )}
                  </p>
                )}
                {formData.MTCCarrier1YearPrior === "" && (
                  <p>MTC Carrier 1 Year Prior field is incomplete.</p>
                )}
                {formData.MTCDeductible1YearPrior === "" && (
                  <p>MTC Deductible 1 Year Prior field is incomplete.</p>
                )}
                {formData.MTCNumberOfClaims1YearPrior === "" && (
                  <p>MTC Number of Claims 1 Year Prior field is incomplete.</p>
                )}
                {formData.MTCDollarAmountOfLosses1YearPrior === "" && (
                  <p>
                    MTC Dollar Amount of Losses 1 Year Prior field is
                    incomplete.
                  </p>
                )}
                {formData.numberOfUnits1YearPrior === "" && (
                  <p>Number of Units 1 Year Prior field is incomplete.</p>
                )}
                {formData.numberOfUnits2YearsPrior === "" && (
                  <p>Number of Units 2 Years Prior field is incomplete.</p>
                )}
                {formData.numberOfUnits3YearsPrior === "" && (
                  <p>Number of Units 3 Years Prior field is incomplete.</p>
                )}
                {formData.MTCCarrier2YearsPrior === "" && (
                  <p>MTC Carrier 2 Years Prior field is incomplete.</p>
                )}
                {formData.MTCDeductible2YearsPrior === "" && (
                  <p>MTC Deductible 2 Years Prior field is incomplete.</p>
                )}
                {formData.MTCNumberOfClaims2YearsPrior === "" && (
                  <p>MTC Number of Claims 2 Years Prior field is incomplete.</p>
                )}
                {formData.MTCDollarAmountOfLosses2YearsPrior === "" && (
                  <p>
                    MTC Dollar Amount of Losses 2 Years Prior field is
                    incomplete.
                  </p>
                )}
                {formData.MTCCarrier3YearsPrior === "" && (
                  <p>MTC Carrier 3 Years Prior field is incomplete.</p>
                )}
                {formData.MTCDeductible3YearsPrior === "" && (
                  <p>MTC Deductible 3 Years Prior field is incomplete.</p>
                )}
                {formData.MTCNumberOfClaims3YearsPrior === "" && (
                  <p>MTC Number of Claims 3 Years Prior field is incomplete.</p>
                )}
                {formData.MTCDollarAmountOfLosses3YearsPrior === "" && (
                  <p>
                    MTC Dollar Amount of Losses 3 Years Prior field is
                    incomplete.
                  </p>
                )}
              </div>
            )}
            {/* APD */}
            {formData.APD === true && (
              <div>
                {formData.anyOneLossLimitAPD === "" && (
                  <p>Any One Loss (APD) field is incomplete.</p>
                )}
                {formData.APDDeductible === "" && (
                  <p>APD Deductible field is incomplete.</p>
                )}
                {formData.APDTrailerInterchange === true && (
                  <p>
                    {formData.TILimitAPD === "" && (
                      <p>APD Trailer Interchange Limit field is incomplete.</p>
                    )}
                  </p>
                )}
                {formData.towingStorageCleanUp === true && (
                  <p>
                    {formData.towingLimit === "" && (
                      <p>Towing Limit field is incomplete.</p>
                    )}
                  </p>
                )}
                {formData.APDCarrier1YearPrior === "" && (
                  <p>APD Carrier 1 Year Prior field is incomplete.</p>
                )}
                {formData.APDCarrier2YearsPrior === "" && (
                  <p>APD Carrier 2 Years Prior field is incomplete.</p>
                )}
                {formData.APDCarrier3YearsPrior === "" && (
                  <p>APD Carrier 3 Years Prior field is incomplete.</p>
                )}
                {formData.APDDeductible1YearPrior === "" && (
                  <p>APD Deductible 1 Year Prior field is incomplete.</p>
                )}
                {formData.APDDeductible2YearsPrior === "" && (
                  <p>APD Deductible 2 Years Prior field is incomplete.</p>
                )}
                {formData.APDDeductible3YearsPrior === "" && (
                  <p>APD Deductible 3 Years Prior field is incomplete.</p>
                )}
                {formData.APDNumberOfClaims1YearPrior === "" && (
                  <p>APD Number of Claims 1 Year Prior field is incomplete.</p>
                )}
                {formData.APDNumberOfClaims2YearsPrior === "" && (
                  <p>APD Number of Claims 2 Years Prior field is incomplete.</p>
                )}
                {formData.APDNumberOfClaims3YearsPrior === "" && (
                  <p>APD Number of Claims 3 Years Prior field is incomplete.</p>
                )}
                {formData.TIV1YearPrior === "" && (
                  <p>TIV 1 Year Prior field is incomplete.</p>
                )}
                {formData.TIV2YearsPrior === "" && (
                  <p>TIV 2 Years Prior field is incomplete.</p>
                )}
                {formData.TIV3YearsPrior === "" && (
                  <p>TIV 3 Years Prior field is incomplete.</p>
                )}
                {formData.APDDollarAmountOfLosses1YearPrior === "" && (
                  <p>
                    APD Dollar Amount of Losses 1 Year Prior field is
                    incomplete.
                  </p>
                )}
                {formData.APDDollarAmountOfLosses2YearsPrior === "" && (
                  <p>
                    APD Dollar Amount of Losses 2 Years Prior field is
                    incomplete.
                  </p>
                )}
                {formData.APDDollarAmountOfLosses3YearsPrior === "" && (
                  <p>
                    APD Dollar Amount of Losses3 Years Prior field is
                    incomplete.
                  </p>
                )}
                {formData.APDLossRuns1YearPrior === "" && (
                  <p>APD Loss Runs 1 Year Prior field is incomplete.</p>
                )}
                {formData.APDLossRuns2YearsPrior === "" && (
                  <p>APD Loss Runs 2 Years Prior field is incomplete.</p>
                )}
                {formData.APDLossRuns3YearsPrior === "" && (
                  <p>APD Loss Runs 3 Years Prior field is incomplete.</p>
                )}
                                {formData.activeDriverList === "" && (
                  <p>APD Loss Runs 1 Year Prior field is incomplete.</p>
                )}
                {formData.activeTrailerAndUnitList === "" && (
                  <p>APD Loss Runs 2 Years Prior field is incomplete.</p>
                )}
                {formData.driverDocuments === "" && (
                  <p>APD Loss Runs 3 Years Prior field is incomplete.</p>
                )}
              </div>
            )}
            {!formData.APD && !formData.MTC ? (<p>No APD or MTC Coverage selected.</p>) : (<></>) }
          </div>
          {/* Save/Submit Buttons */}
          <article className="back-next-container text-center">
            {formData.status === "Incomplete" || formData.status === "" ? (
              <>
                <button className="button-2" onClick={saveSubmission}>
                  Save Submission
                </button>
                <button className="button-2" onClick={submitSubmission}>
                  Submit Submission
                </button>
                <button
                  onClick={() => setValue(value - 1)}
                  className="button-1"
                  disabled={value === 0}
                >
                  Back
                </button>
              </>
            ) : (
              <></>
            )}
          </article>
        </TabPanel>
      </Box>
    </fieldset>
  );
}

export default SubmitBusiness;
