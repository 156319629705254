import { useState } from "react"
import useWaiverOfSubrogation from "../../Context/actions/useWaiverOfSubrogationState"
import useGeneralState from "../../Context/actions/useGeneralState"
import ApiService from "../../services/southern-api/apiService"
import { createWaiverEndorsementRequest } from "../../services/firebase-api/waiversOfSubrogation"
import {
  convertDateToFirestoreFormat,
  convertDateToTimestamp
} from "../../utils/helpers"
import { db } from "../../firebase"
import { doc, setDoc } from "firebase/firestore"
import endoStatusChangeNotification from "../../Components/utils/notifications/endoStatusChangeNotification"
import { getAuth } from "firebase/auth"
import updateSystemLog from "../../Components/utils/updateSystemLog"
import useUsersState from "../../Context/actions/useUsersState"

const useWaivers = (props) => {
    const {
        getEndorsements,
        insuredInfo,
        formData
    } = props

    const {
      alPolicyNo,
      glPolicyNo
    } = formData

    const {
      company: namedInsured
    } = insuredInfo

    const apiService = new ApiService('southern')
    const {
        waiversOfSubrogation,
    } = useWaiverOfSubrogation()

    const auth = getAuth();

    const { user } = useUsersState()

    const [ editMode, setEditMode ] = useState(false)
    const [ viewData, setViewData ] = useState(null)
    const { controlToast } = useGeneralState()
    const [loading, setLoading] = useState(false)

    const handleWaiversOfSubrogation = async (e, data) => {
        e.preventDefault();
        const { effectiveDate } = data
        try {
          setLoading(true)
          const payloadPdf = {
            ...data,
            effectiveDate: convertDateToTimestamp(effectiveDate),
            namedInsured
          }
          if (alPolicyNo) {
            payloadPdf['alPolicyNo'] = alPolicyNo
          }
          if (glPolicyNo) {
            payloadPdf['glPolicyNo'] = glPolicyNo
          }
          const payload = {
            ...payloadPdf,
            company: data.company !== 'Other' ? data.company : data.companyName,
            effectiveDate: convertDateToFirestoreFormat(effectiveDate)
          }
          delete payload['companyName']
          const { counter, addId } = await createWaiverEndorsementRequest(
            payload,
            props,
            Boolean(data.company !== 'Other')
          )
          await getEndorsements()
          controlToast(true, 'You created a waiver of subrogation endorsement', 'success')

          await endoStatusChangeNotification(
            "Add Waiver Of Subrogation",
            String(counter),
            "Submitted",
            addId,
            formData?.id,
            user.agencyID
          );

          await updateSystemLog(
            auth,
            formData?.id,
            `Add Waiver Of Subrogation Endorsements Submitted For ${
              insuredInfo.company
            } by ${auth.currentUser.displayName}`,
            "Add Waiver Of Subrogation Endorsement",
            payload
          );

          setLoading(false)
          setEditMode(false)
        } catch (error) {
          console.log('error', error)
          controlToast(true, 'There was an error in the service', 'error')
          setLoading(false)
          return error
        }
    }

    const handleEditMode = (body) => {
      setEditMode(!editMode)
      if (body) {
        delete body["iconLeft"];
        delete body["iconRight"];
        delete body["tableInfo"];
        delete body["dataIndex"];
        setViewData(body)
      } else {
        setViewData(null)
      }
    }

    const [generateFileLoader, setGenerateFileLoader] = useState(false)

    const generateWaiverFile = async () => {
      try {
        setGenerateFileLoader(true)
        const payload = { ...viewData }
        payload['effectiveDate'] = payload['effectiveDate'].seconds
        payload['waiverId'] = viewData['id']
        delete payload.id
        const newPayload = {
          waiverId: payload['waiverId']
        }
        
        const date = new Date(payload['effectiveDate'] * 1000);
        const parsedDate = date.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
        });

        if (payload?.['alPolicyNo']) {
          newPayload['dataAL'] = {
            policyNo: payload['alPolicyNo'],
            namedInsured: payload['namedInsured'],
            effectiveDate: parsedDate,
            company: payload['company'],
            address: payload['address'],
            city: payload['city'],
            state: payload['state'],
            zip: payload['zip']
          }
        }
        if (payload?.['glPolicyNo']) {
          newPayload['dataGL'] = {
            policyNo: payload['glPolicyNo'],
            namedInsured: payload['namedInsured'],
            effectiveDate: parsedDate,
            company: payload['company'],
            address: payload['address'],
            city: payload['city'],
            state: payload['state'],
            zip: payload['zip']
          }
        }
        const result = await apiService.createLiabilityPdf(newPayload)
        const { agencyID, insuredID } = formData
        const collectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          'waivers-of-subrogation',
          viewData.id
        )
        await setDoc(
          collectionRef,
          {
            file: result.url
          },
          { merge: true }
        );
        setGenerateFileLoader(false)
        setViewData({
          ...viewData,
          file: result.url
        })
      } catch (error) {
        setGenerateFileLoader(false)
        throw error
      }
    }

    return {
        editMode,
        waiversOfSubrogation,
        loading,
        handleWaiversOfSubrogation,
        handleEditMode,
        generateWaiverFile,
        viewData,
        setViewData,
        generateFileLoader
    }
}

export default useWaivers