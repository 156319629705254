import BlueButton from "../../assets/forms/BlueButton";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import Popup from "../Popup";

const SeeAction = ({file, onClick}) => {
  return (
    <>
      {onClick ?
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault()
            onClick()
          }}
          className="text-sky-900 hover:text-[#072a48]"
        >
          <VisibilityIcon /> View
        </button> :
        <a
          href={file}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sky-900 hover:text-[#072a48]"
        >
          <VisibilityIcon /> View
        </a>
      }
    </>
  )
}

const ImgRendered = ({
  isPdf,
  file,
  width,
  height,
  loading,
  handleLoad,
  alt,
  className,
  isFullScreen
}) => {
  return (
    isPdf ? (
      <iframe
        src={`${file}#navpanes=0`}
        title="PDF Viewer"
        width="100%"
        height={`${height}px`}
        style={{
          border: "1px solid #072A48",
          overflow: "hidden",
          margin: "8px 0",
          display: loading ? 'none' : 'block',
          height: isFullScreen ? "90vh" : `${height}px`,
        }}
        allowFullScreen
        onLoad={handleLoad}
      />
    ) : (
      <img
        src={file}
        alt={alt}
        className={className ? className : "max-h-[300px] min-h-[200px]"}
        width={width}
        height={height}
        onLoad={handleLoad}
      />
    )
  )
}

const PDFPreviewer = ({
  file,
  width,
  height = 400,
  className,
  alt,
  fileInButton,
  dissapearElem = false,
  showInAPopup = false,
  forceLoading
}) => {
  const isPdf = file?.includes("pdf");

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!forceLoading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000)
    }
  }, [file, isPdf, forceLoading]);

  const handleLoad = () => {
    setLoading(false);
  };

  const [isFullScreen, setIsFullScreen] = useState(true);

  return (
    <>
      <Popup
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        setIsFullScreenActive={setIsFullScreen}
        showExpandButton={true}
        expandByDefault={true}
      >
        {!forceLoading ?
          <div>
            <ImgRendered
              isPdf={isPdf}
              file={file}
              width={'100%'}
              height={height}
              loading={loading}
              handleLoad={handleLoad}
              className={className}
              alt={alt}
              isFullScreen={isFullScreen}
            />
          </div> :
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}>
            <CircularProgress />
          </Box>
        }
      </Popup>  
      <SeeAction
        file={file}
        onClick={showInAPopup ? () => setOpen(true) : null}
      />
    </>
  );
};

export default PDFPreviewer;