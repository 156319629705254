import getTaxSchedule from "./getTaxSchedule";
import getStampFee from "./premiumCalculation/getStampFee";
import getSurplusLinesRate from "./premiumCalculation/getSurplusLinesRate";
import getDailyPremium from "./rateUnits/getDailyPremium";
import getLeapDays from "./rateUnits/getLeapDays";
import humanDate from "./rateUnits/humanDate";

const getCancelledPolicyReturnPremium = async (
  effDate,
  endoEffDate,
  formData
) => {
  const endoEffectiveDate = humanDate(endoEffDate);
  const effectiveDate = effDate.toDate();
  const daysRemainingInPolicy = Math.floor(
    (endoEffectiveDate - effectiveDate) / (1000 * 60 * 60 * 24)
  );
  const leapDays = getLeapDays(effectiveDate, endoEffectiveDate);
  let totalPremiumAfterFleetCredit = formData.alPremiums.GWP;
  const dailyPremium = getDailyPremium(
    null,
    formData.alPremiums.GWP,
    leapDays,
    null,
    effectiveDate,
    null,
    endoEffectiveDate
  );

  totalPremiumAfterFleetCredit -=
    parseFloat(dailyPremium.toFixed(2)) * daysRemainingInPolicy;

  // Calculate the taxes and fees
  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };
  const stampFeePercent = getStampFee(formData.stateOfEntry, taxesAndFees);
  const surplusLinesTaxPercent = getSurplusLinesRate(
    formData.stateOfEntry,
    taxesAndFees
  );
  const otherFees = taxesAndFees.otherFees;
  const processingFee =
    taxesAndFees.processingFee * totalPremiumAfterFleetCredit;
  const stampFee = stampFeePercent * totalPremiumAfterFleetCredit;
  const surplusLinesTax =
    surplusLinesTaxPercent *
    (totalPremiumAfterFleetCredit + otherFees + processingFee);
  const taxes = {
    surplusLinesTax,
    stampFee,
    processingFee,
    otherFees: otherFees,
  };
  const ratingData = {
    GWP: totalPremiumAfterFleetCredit * -1,
    stampTaxAmount: stampFee * -1,
    surplusLinesTaxAmount: surplusLinesTax * -1,
    otherFees: otherFees * -1,
    processingFeeAmount: processingFee * -1,
    totalPremium:
      (totalPremiumAfterFleetCredit +
        stampFee +
        otherFees +
        taxes.surplusLinesTax +
        processingFee) *
      -1,
  };

  return ratingData;
};

export default getCancelledPolicyReturnPremium;
