import React from "react";
import { Button } from "@mui/material";
import numberWithCommas from "../../utils/numberWithCommas";
import Checkbox from "./Checkbox";

export default function NonStandardClauses({
  disabledRule,
  formData,
  setFormData,
  policyID,
  dropdownOptions,
  handleEditClick,
  handleDropdownChange,
  isAdminOrUnderwriter,
  t,
}) {
  return (
    <section>
      <h1 className="text-lg mt-3">{t("plm.apd.non-standard-clauses")}</h1>
      <Checkbox
        disabledRule={disabledRule}
        label={t("plm.apd.towing")}
        field={"towingStorageAndCleanUp"}
        policyID={policyID}
        setFormData={setFormData}
        formData={formData}
        defaultToTrue={true}
      />
      {formData?.apdDetails?.towingStorageAndCleanUp && (
        <div className="mb-3">
          <label htmlFor={`towingStorageAndCleanUp-select`} className="ml-2">
            {t("plm.apd.select-target-limit")}:
          </label>
          <select
            disabled={disabledRule}
            id={`towingStorageAndCleanUp-select`}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
            value={formData?.apdDetails?.towingStorageAndCleanUpTargetLimit || ""}
            onChange={(e) =>
              handleDropdownChange(
                "towingStorageAndCleanUpTargetLimit",
                e.target.value
              )
            }
          >
            <option value="">{t("plm.apd.choose-one")}</option>
            {dropdownOptions?.towingStorageAndCleanUpTargetLimits?.slice()
              .sort((a, b) => a - b)
              .map((limit) => (
                <option key={limit} value={limit}>
                  ${numberWithCommas(limit)}
                </option>
              ))}
          </select>
          {isAdminOrUnderwriter && formData.documentType === 'Application' && (
            <Button
              sx={{ fontSize: "10px" }}
              onClick={() => handleEditClick("towingStorageAndCleanUpTargetLimits")}
            >
              {t("plm.apd.edit-options")}
            </Button>
          )}
        </div>
      )}
      <div className="mt-3">
        <Checkbox
          disabledRule={disabledRule}
          label={t("plm.apd.non-owned")}
          field={"nonOwnedHiredAuto"}
          policyID={policyID}
          setFormData={setFormData}
          formData={formData}
          defaultToTrue={true}
        />
        {formData?.apdDetails?.nonOwnedHiredAuto && (
          <div className="mb-3">
            <label htmlFor={`nonOwnedHiredAuto-select`} className="ml-2">
              {t("plm.apd.select-target-limit")}
            </label>
            <select
              disabled={disabledRule}
              id={`nonOwnedHiredAuto-select`}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              value={formData?.apdDetails?.nonOwnedHiredAutoTargetLimit || ""}
              onChange={(e) =>
                handleDropdownChange("nonOwnedHiredAutoTargetLimit", e.target.value)
              }
            >
              <option value="">{t("plm.apd.choose-one")}</option>
              {dropdownOptions?.nonOwnedHiredAutoTargetLimits?.slice()
                .sort((a, b) => a - b)
                .map((limit) => (
                  <option key={limit} value={limit}>
                    ${numberWithCommas(limit)}
                  </option>
                ))}
            </select>
            {isAdminOrUnderwriter && formData.documentType === 'Application' && (
              <Button
                sx={{ fontSize: "10px" }}
                onClick={() => handleEditClick("nonOwnedHiredAutoTargetLimits")}
              >
                {t("plm.apd.edit-options")}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="mt-3">
        <Checkbox
          disabledRule={disabledRule}
          label={t("plm.apd.attached")}
          field={"trailerInterchangeTarget"}
          policyID={policyID}
          setFormData={setFormData}
          formData={formData}
          defaultToTrue={true}
        />
        {formData?.apdDetails?.trailerInterchangeTarget && (
          <div className="mb-3">
            <label htmlFor={`trailerInterchangeTarget-select`} className="ml-2">
              {t("plm.apd.select-target-limit")}
            </label>
            <select
              disabled={disabledRule}
              id={`trailerInterchangeTarget-select`}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              value={formData?.apdDetails?.trailerInterchangeTargetLimit || ""}
              onChange={(e) =>
                handleDropdownChange("trailerInterchangeTargetLimit", e.target.value)
              }
            >
              <option value="">{t("plm.apd.choose-one")}</option>
              {dropdownOptions.trailerInterchangeTargetLimits?.slice()
                .sort((a, b) => a - b)
                .map((limit) => (
                  <option key={limit} value={limit}>
                    ${numberWithCommas(limit)}
                  </option>
                ))}
            </select>
            {isAdminOrUnderwriter && formData.documentType === 'Application' && (
              <Button
                sx={{ fontSize: "10px" }}
                onClick={() => handleEditClick("trailerInterchangeTargetLimits")}
              >
                {t("plm.apd.edit-options")}
              </Button>
            )}

            <label
              htmlFor={`trailerInterchangeTarget-select`}
              className="d-block mt-3 ml-2"
            >
              {t("plm.apd.briefly-describe-exposure")}:
            </label>
            <input
              id={`tiExposure`}
              type="text"
              placeholder={t("plm.apd.example-15")}
              disabled={disabledRule}
              value={formData?.apdDetails?.tiExposure || ""}
              onChange={(e) =>
                handleDropdownChange("tiExposure", e.target.value)
              }
              className="mt-1 w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        )}
      </div>
    </section>
  );
}
