import {
  collection,
  doc,
  getDoc,
  getDocs,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../../../../firebase";
import { formatDateToYYYYMMDD } from "../../../../Finances/Tables/utils";
import updateSystemLog from "../../../../utils/updateSystemLog";
import calculatePremiums from "../../../../utils/calculatePremiums";
import getTaxSchedule from "../../../../utils/getTaxSchedule";

const updateEndorsementStatusAndCreatePowerUnit = async (
  endorsementId,
  status,
  powerUnitData,
  policyData,
  setSuccess,
  paramPolicyID,
  agencyID,
  insuredID,
  requestExceptionData,
  endorsement,
  setPolicyInfo,
  insuredInfo,
  setTaxSchedule,
  paramRefID
) => {
  const endorsementRef = doc(
    db,
    "policy-applications",
    paramPolicyID,
    "endorsements",
    endorsementId
  );
  const existingTrucksSnapshot = await getDocs(
    collection(db, `agencies/${agencyID}/insureds/${insuredID}/power-units`)
  );
  const existingTrucks = existingTrucksSnapshot.docs.map((doc) => ({
    status: doc.data().status,
    VIN: doc.data().truckVIN,
    id: doc.id,
  }));

  try {
    await runTransaction(db, async (transaction) => {
      await transaction.get(endorsementRef);
      const issuedDate = formatDateToYYYYMMDD(new Date());
      const endoPayload = {
        status,
        wasARequestException: false,
        issuedDate,
      };
      if (requestExceptionData) {
        endoPayload["wasARequestException"] = true;
        endoPayload["requestException"] = requestExceptionData;
      }
      transaction.update(endorsementRef, endoPayload);
      const promises = powerUnitData.map(async (powerUnit) => {
        const existingTruck = existingTrucks.find(
          (truck) => truck.VIN === powerUnit.truckVIN
        );

        const payload = setPolicyInfo(
          {
            ...powerUnit,
            dateCreated: new Date(),
          },
          {
            policyID: paramPolicyID,
            status: "Active",
          },
          "endo-addition"
        );

        if (existingTruck) {
          const subcollectionRef = doc(
            db,
            "agencies",
            agencyID,
            "insureds",
            insuredID,
            "power-units",
            powerUnit.id
          );

          await setDoc(subcollectionRef, payload, { merge: true });

          updateSystemLog(
            auth,
            paramPolicyID,
            "Add Power Units Endorsement (" +
              payload.truckVIN +
              ") Approved For " +
              insuredInfo.company +
              " by " +
              auth.currentUser.displayName,
            "Add Power Unit Endo Approved",
            payload,
            true
          );

          const renewalApplicationID = policyData?.renewalApplicationID;
          if (renewalApplicationID) {
            const mirroredSubcollectionRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              "power-units",
              powerUnit?.id
            );
            const mirroredPayload = setPolicyInfo(
              {
                ...payload,
                dateCreated: new Date(),
              },
              {
                policyID: renewalApplicationID,
                status: "Active",
              },
              "endo-addition"
            );

            await setDoc(mirroredSubcollectionRef, mirroredPayload, {
              merge: true,
            });

            updateSystemLog(
              auth,
              renewalApplicationID,
              "Add Power Units Endorsement (" +
                mirroredPayload.truckVIN +
                ") Approved For " +
                insuredInfo.company +
                " by " +
                auth.currentUser.displayName,
              "Add Power Unit Endo Approved",
              mirroredPayload,
              true
            );
          }

          return subcollectionRef.id;
        }
      });
      await Promise.all(promises);
      const policySnapshot = await getDoc(
        doc(db, "policy-applications", paramPolicyID)
      );
      // Don't rerate power units, I removed this, can probably be deleted all together. -Colt
      // await calculatePremiums(
      //   policySnapshot?.id,
      //   policySnapshot?.data(),
      //   await getTaxSchedule(setTaxSchedule),
      //   "#",
      //   policySnapshot?.data()?.alPremiums?.adjustment || 0,
      //   true,
      //   true,
      //   paramRefID
      // );
      setSuccess(true);
    });
  } catch (error) {
    console.error(
      "Error updating endorsement status and creating power unit:",
      error
    );
  }
};

export default updateEndorsementStatusAndCreatePowerUnit;
