import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CrashIcon from "@mui/icons-material/CarCrash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PieChartIcon from "@mui/icons-material/PieChart";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"; // Add this import for the VIN Master List icon

export default function LosscontrolSidebar({ setActiveComponent }) {
  return (
    <Sidebar>
      <header
        style={{
          textAlign: "left",
          marginLeft: "10px",
          marginTop: "20px",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        Loss Control Menu
      </header>
      <Menu iconShape="circle">
        {/* <MenuItem icon={<HomeIcon />} onClick={() => setActiveComponent('Home')}>
            Home
          </MenuItem> */}
        <MenuItem
          icon={<AssessmentIcon />}
          onClick={() => setActiveComponent("Driver Scoring")}
        >
          Driver Scoring
        </MenuItem>
        {/* <MenuItem
          icon={<DescriptionIcon />}
          onClick={() => setActiveComponent("Factfinders")}
        >
          Factfinders
        </MenuItem> */}
        {/* <MenuItem
          icon={<PieChartIcon />}
          onClick={() => setActiveComponent("LC Reports")}
        >
          LC Reports
        </MenuItem> */}
        <MenuItem
          icon={<DirectionsCarIcon />}
          onClick={() => setActiveComponent("VIN Master List")}
        >
          VIN Master List
        </MenuItem>
        {/* <MenuItem
          icon={<DirectionsCarIcon />}
          onClick={() => {
            // in a new tab open /drivers-trainings
            window.open("/drivers-trainings", "_blank");
          }}
        >
          Driver Trainings
        </MenuItem> */}
        <MenuItem
          icon={<DirectionsCarIcon />}
          onClick={() => {
            // in a new tab open /drivers-trainings
            window.open("/blacklist", "_blank");
          }}
        >
          Driver Blacklist
        </MenuItem>
      </Menu>
      {/* <header
        style={{
          textAlign: "left",
          marginLeft: "10px",
          marginTop: "20px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Add Data
      </header> */}
      {/* <Menu iconShape="circle">
        <MenuItem
          icon={<AddCircleOutlineIcon />}
          onClick={() => setActiveComponent("New LC Report")}
        >
          New LC Report
        </MenuItem>
        <MenuItem
          icon={<AddCircleOutlineIcon />}
          onClick={() => setActiveComponent("New Factfinder")}
        >
          New Factfinder
        </MenuItem>
      </Menu> */}
    </Sidebar>
  );
}
