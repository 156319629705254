import React, { useState } from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import updatePolicyField from "../../utils/updatePolicyField";
import { TextField, Typography } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";

export default function ReferralMessage({
  formData,
  className,
  policyID,
  userRole,
}) {
  const [show, setShow] = useState(true);
  const [showDeclineReasons, setShowDeclineReasons] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const policyRef = doc(db, "policy-applications", policyID);
  const color = "red";

  const onDecline = () => {
    if (!showDeclineReasons) return setShowDeclineReasons(true);
    if (declineReason === "") return alert("Please enter a decline reason.");
    showDeclineReasons &&
      declineReason !== "" &&
      updateDoc(policyRef, {
        referralDeclined: true,
        referralDeclinedReason: declineReason,
        referralDeclinedDate: new Date(),
        referralDeclinedBy: auth.currentUser.displayName,
        referralDeclinedByID: auth.currentUser.uid,
      });
  };

  const onApprove = () => {
    updateDoc(policyRef, {
      referralApproved: true,
      referralApprovedDate: new Date(),
      referralApprovedBy: auth.currentUser.displayName,
      referralApprovedByID: auth.currentUser.uid,
    });
  };

  return (
    <>
      {formData.isReferral && (
        <>
          {/* If not approved or declined show this: */}
          {!formData?.referralApproved && !formData?.referralDeclined && (
            <main
              className={`card mt-3 bg-${color}-100 text-${color}-700 ${
                className || ""
              }`}
            >
              <div className="card-header" onClick={(e) => setShow(!show)}>
                <h1 className={`text-lg font-bold text-${color}-700`}>
                  <button>Account Must Be Referred</button>
                  <ExpandCollapseArrow show={show} setShow={setShow} />
                </h1>
              </div>
              {show && (
                <div className="card-body">
                  <p className="mb-2">
                    This application must be referred for the following reasons:
                  </p>
                  <ul className="list-disc ml-6">
                    <li className="mb-2">Total premiums are over $500,000.</li>
                  </ul>
                  {showDeclineReasons && (
                    <TextField
                      className="mt-2"
                      fullWidth
                      label="Decline Reason"
                      value={declineReason}
                      onChange={(e) => setDeclineReason(e.target.value)}
                    />
                  )}
                  {userRole === "Admin" ? (
                    <ApprovalButtons
                      onApprove={onApprove}
                      onDecline={onDecline}
                      titleAccept="Approve"
                      titleDecline="Decline"
                    />
                  ) : (
                    <p className="text-sm text-gray-500">
                      Only an admin can approve or decline this referral.
                    </p>
                  )}
                </div>
              )}
            </main>
          )}
          {/* If Approved, show this: */}
          {formData?.referralApproved && (
            <Typography className="mt-3" variant="h6" color="primary">
              This referral has been approved by {formData?.referralApprovedBy}{" "}
              on {formData?.referralApprovedDate?.toDate().toLocaleDateString()}
              .
            </Typography>
          )}
          {/* If Declined, show this: */}
          {formData?.referralDeclined && (
            <Typography className="mt-3" variant="h6" color="error">
              This referral has been declined by {formData?.referralDeclinedBy}{" "}
              on {formData?.referralDeclinedDate?.toDate().toLocaleDateString()}
              .
            </Typography>
          )}
        </>
      )}
    </>
  );
}
