import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function Checkbox(props) {
  const apdDetails = {
    ...(props.formData.apdDetails || {}),
    [props?.field]: !props?.formData?.apdDetails?.[props?.field],
  };
  return (
    <div className="flex items-center my-2">
      <input
        id={props?.field}
        type="checkbox"
        className="large-checkbox"
        checked={props?.formData?.apdDetails?.[props?.field]}
        onChange={async () => {
          props?.setFormData({
            ...props?.formData,
            apdDetails,
          });
          const policyRef = doc(db, "policy-applications", props.policyID);
          await updateDoc(policyRef, {
            apdDetails,
          });
        }}
      />

      <label htmlFor={props?.field} className="ml-2">
        {props?.label}
      </label>
    </div>
  );
}
