import {
  addDoc,
  collection,
  doc,
  getDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

let getType = (isAL, isGL, isEndo, isCancellation) => {
  if (isAL) {
    if (isCancellation) return "1010000022";
    if (isEndo) {
      return "1010000053";
    } else return "1010000061";
  } else if (isGL) {
    if (isCancellation) return "1010000064";
    if (isEndo) {
      return "1010000111";
    } else return "1010000051";
  }
};

let getDescription = (isAL, isGL, isEndo, isCancellation) => {
  if (isAL) {
    if (isCancellation) return "Auto Liability Cancellation";
    if (isEndo) {
      return "Auto Liability Endorsement";
    } else return "Auto Liability New Policy";
  } else if (isGL) {
    if (isCancellation) return "General Liability Cancellation";
    if (isEndo) {
      return "General Liability Endorsement";
    }
    return "General Liability New Policy";
  }
};

let getCommissionType = (isAL, isGL, isEndo, isCancellation) => {
  if (isAL) {
    if (isCancellation) return "10100000057";
    if (isEndo) {
      return "1010000062";
    } else {
      return "1010000012";
    }
  } else if (isGL) {
    if (isCancellation) return "1010000023";
    if (isEndo) {
      return "1010000083";
    } else {
      return "1010000074";
    }
  }
};

let getCommissionDescription = (isAL, isGL, isEndo, isCancellation) => {
  if (isAL) {
    if (isCancellation) return "Auto Liability Cancellation - Agent Commission";
    if (isEndo) {
      return "Auto Liability Endorsement - Agent Commission";
    } else {
      return "Auto Liability New Policy - Agent Commission";
    }
  } else if (isGL) {
    if (isCancellation)
      return "General Liability Cancellation - Agent Commission";
    if (isEndo) {
      return "General Liability Endorsement - Agent Commission";
    } else {
      return "General Liability New Policy - Agent Commission";
    }
  }
};

let getLineItems = (
  invoiceObject,
  isAL,
  isGL,
  isEndo,
  isAddEndo,
  isCancellation
) => {
  let currentYear = new Date().getFullYear();
  let stateOfEntry = invoiceObject?.State;
  let returnData = {
    LineItemsName: [
      getType(isAL, isGL, isEndo, isCancellation),
      "1010000035",
      "1010000081",
      getCommissionType(isAL, isGL, isEndo, isCancellation),
      stateOfEntry === "TX"
        ? `1010000151`
        : stateOfEntry === "CA"
        ? `1010000171`
        : stateOfEntry === "AZ"
        ? "1010000181"
        : "",
      stateOfEntry === "TX"
        ? "1010000152"
        : stateOfEntry === "CA"
        ? "1010000162"
        : stateOfEntry === "AZ"
        ? "1010000191"
        : "",
    ],
    LineItemsDescription: [
      getDescription(isAL, isGL, isEndo, isCancellation),
      "Processing Fees (AL/GL)",
      "Other Fees",
      getCommissionDescription(isAL, isGL, isEndo),
      `${invoiceObject?.State} Surplus Lines Tax ${currentYear}`,
      `${invoiceObject?.State} Stamping Fees ${currentYear}`,
    ],
    LineItemsQuantity: [
      "1",
      "1",
      "1",
      isAddEndo
        ? invoiceObject?.Premium
        : (parseFloat(invoiceObject?.Premium) * -1).toString(),
      "1",
      "1",
    ],
    LineItemRate: [
      invoiceObject?.Premium || "0",
      invoiceObject?.ProcessingFees || "0",
      invoiceObject?.OtherFees || "0",
      isAddEndo ? "-0.1" : "0.1",
      invoiceObject?.SurplusLinesTax || "0",
      invoiceObject?.StampingFee || "0",
    ],
    LineItemsUnitAmount: [
      invoiceObject?.Premium || "0",
      invoiceObject?.ProcessingFees || "0",
      invoiceObject?.OtherFees || "0",
      invoiceObject?.LiconaDollars || "0",
      invoiceObject?.SurplusLinesTax || "0",
      invoiceObject?.StampingFee || "0",
    ],
  };
  if (isEndo) {
    // remove the
  }
  return returnData;
};

let generatePolicyInvoice = async (
  namedInsured,
  effectiveDate,
  effectiveDateGL,
  stateOfEntry,
  formData,
  formDataGL,
  agencyID,
  coverageType,
  isEndo,
  vinArray,
  isAddEndo
) => {
  try {
  let invoicesCollectionRef = collection(db, "invoices");
  let creditMemosRef = collection(db, "credit-memos");
  let isAL = coverageType === "AL";
  let isGL = coverageType === "GL";
  let isCreditMemo = !isAddEndo;
  let isCancellation = formData?.type && formData?.type === "Cancellation";

  let isLIG = agencyID === "JiAfXAkc0Bjr4OXfqSv9";
  let agencyName = null;
  let premiums = isAL
    ? formData.alPremiums
    : isGL
    ? formDataGL.glPremiums
    : null;

  let Insured = namedInsured;
  let PolicyNumber = isAL
    ? formData.alPolicyNo
    : isGL
    ? formDataGL.glPolicyNo
    : "";
  let Type = getType(isAL, isGL);
  let Company = isLIG ? "Licona Insurance Group" : agencyName;
  let State = stateOfEntry;

  let EffectiveDate = isAL
    ? effectiveDate instanceof Timestamp
      ? effectiveDate.toDate()
      : new Date(effectiveDate.seconds * 1000)
    : isGL
    ? effectiveDateGL instanceof Timestamp
      ? effectiveDateGL.toDate()
      : new Date(effectiveDateGL.seconds * 1000)
    : new Date();

  let ExpDate = new Date(EffectiveDate);
  ExpDate.setFullYear(ExpDate.getFullYear() + 1);

  let DueDate = new Date(EffectiveDate);
  DueDate.setDate(DueDate.getDate() + 30);

  let Premium = premiums.GWP;
  let ProcessingFees = premiums.processingFeeAmount;
  let OtherFees = premiums.otherFees || 0;
  let SurplusLinesTax = premiums.surplusLinesTaxAmount;
  let StampingFee = premiums.stampTaxAmount;
  let LiconaPercent = -0.1;
  let LiconaDollars = LiconaPercent * premiums.GWP;

  let NetTotal =
    Premium +
    ProcessingFees +
    OtherFees +
    LiconaDollars +
    SurplusLinesTax +
    StampingFee;

  let vinArrayAsString = vinArray.join(", ");
  let BeforeNote = isEndo ? "Endorsement " : "";
  let AfterNote = isEndo
    ? (isAddEndo ? "Add Units: " : "Remove Units: ") + vinArrayAsString
    : "";

  // get invoice number
  let uniqueNumbersRef = doc(db, "unique-numbers", "invoices");
  let uniqueNumbersDoc = await getDoc(uniqueNumbersRef);
  let uniqueNumbersData = uniqueNumbersDoc.data();
  let InvoiceNumber = uniqueNumbersData.lastUsed + 1;
  // update unique numbers ref
  await updateDoc(uniqueNumbersRef, {
    lastUsed: InvoiceNumber,
  });
  // if it's a credit memo, then multiply all the values used in the invoice object by -1
  if (isCreditMemo) {
    Premium *= -1;
    ProcessingFees *= -1;
    OtherFees *= -1;
    LiconaDollars *= -1;
    SurplusLinesTax *= -1;
    StampingFee *= -1;
    NetTotal *= -1;
  }

  let invoice = {
    Company,
    DueDate,
    EffectiveDate: EffectiveDate.toString().slice(4, 15),
    ExpDate: ExpDate.toString().slice(4, 15),
    Insured,
    LiconaDollars: LiconaDollars.toFixed(2).toString(),
    LiconaPercent: LiconaPercent.toString(),
    NetTotal: NetTotal.toFixed(2).toString(),
    OtherFees: OtherFees.toFixed(2).toString(),
    PolicyNumber,
    Premium: Premium.toFixed(2).toString(),
    ProcessingFees: ProcessingFees.toFixed(2).toString(),
    StampingFee: StampingFee.toFixed(2).toString(),
    State,
    SurplusLinesTax: SurplusLinesTax.toFixed(2).toString(),
    Type,
    BeforeNote,
    AfterNote,
    InvoiceNumber,
  };

  let lineItems = getLineItems(invoice, isAL, isGL, isEndo, isCancellation);

  invoice = {
    ...invoice,
    ...lineItems,
  };
  
  let refToUse = isCreditMemo ? creditMemosRef : invoicesCollectionRef;
  
  await addDoc(refToUse, invoice);
} catch {
  console.log("error generating invoice");
}
};

export default generatePolicyInvoice;
