import React from "react";
import { Tbody, Td, Thead, Tr, Table } from "react-super-responsive-table";
import { Col, Row } from "react-bootstrap";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import QuoteSelector from "./QuoteSelector";
import RedButton from "../../../assets/forms/RedButton";
import { Container, Typography, useMediaQuery } from "@mui/material";
import BlueButton from "../../../assets/forms/BlueButton";
import AgentColumns from "../QuotesTable/AgentColumns";
import UnderwriterColumns from "../QuotesTable/UnderwriterColumns";
import AgentHeaders from "../QuotesTable/AgentHeaders";
import UnderwriterHeaders from "../QuotesTable/UnderwriterHeaders";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";
import useTranslations from "../../../../Context/actions/useTranslations";
import updatePolicyField from "../../../utils/updatePolicyField";
import getQuoteHistory from "../utils/getQuoteHistory";
import clearQuoteList from "../utils/clearQuoteList";

export default function QuotesTable({
  hasALOrGL,
  formData,
  policyID,
  setFormData,
  userInfo,
  quoteHistory,
  applyAdjustments,
  setQuoteHistory,
  setGotQuoteHistory,
  isNewQuote,
  setIsNewQuote,
  createNewQuote,
}) {
  const { t } = useTranslations();

  const deleteThisQuote = async (thisID) => {
    const selectedQuote = formData.selectedQuote;
    // TODO: Make function delete file too, if we are storing the quotes as files
    let verify = await confirmDeletePromptGlobal(
      t("plm.quotes.quotes-table.about-to-delete")
    );
    if (verify === "DELETE") {
      if (selectedQuote === thisID) {
        const policyDocRef = doc(db, "policy-applications", policyID);
        await updateDoc(policyDocRef, {
          selectedQuote: "#",
          alPremiums: {},
          glPremiums: {},
          apdPremiums: {},
          mtcPremiums: {},
        });
      } else {
        await updatePolicyField(policyID, "selectedQuote", "#");
        await deleteDoc(
          doc(db, "policy-applications", policyID, "quotes", thisID)
        );
      }
      getQuoteHistory(policyID, setQuoteHistory, setGotQuoteHistory);
    }
  };
  const role = userInfo.role;
  const userIsUnderwriter = role === "Underwriter" || role === "Admin";
  const selectedQuote = formData.selectedQuote;
  const isQuoteRequest = formData.documentType === "Quote Request";
  const userIsAgent = role === "Agent";

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {!formData?.signedProposalData?.envelopeId ? (
        <>
          {(formData.documentType === "Quote Request" ||
            formData.documentType === "Bind Order") &&
            hasALOrGL && (
              <div className="my-3">
                <Container>
                  <Row className="mb-3">
                    <Col sm={12}>
                      {/* Quote List */}
                      <hr />
                      <Typography variant="h6" className="mb-2 mt-3">
                        Quote List
                      </Typography>
                      <p className="mb-3">
                        Below is a list of all quotes that have been generated
                        for this policy. The currently selected quote shows in
                        green. To select a new quote, click the checkmark next
                        to it's description.
                      </p>
                      <article className="mt-2">
                        {/* TODO: Refactor table to fit new standards */}
                        <Table>
                          <Thead>
                            <Tr>
                              <Td className="bg-[#072a48] text-white w-[10px] rounded-tl-md">
                                {t("plm.quotes.quotes-table.apply")}
                              </Td>
                              <Td className="bg-[#072a48] min-w-[200px] text-white">
                                {t("plm.quotes.quotes-table.description")}
                              </Td>
                              {userIsAgent && <AgentHeaders />}
                              {userIsUnderwriter && <UnderwriterHeaders />}
                              <Td className="bg-[#072a48] text-white rounded-tr-md"></Td>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {quoteHistory.length === 0 && (
                              <Tr>
                                <Td colspan={7} className="text-center">
                                  {userIsUnderwriter ? (
                                    <p>
                                      {t(
                                        "plm.quotes.quotes-table.no-existing-underwriter"
                                      )}
                                    </p>
                                  ) : (
                                    <p>
                                      {t(
                                        "plm.quotes.quotes-table.no-existing-normal"
                                      )}
                                    </p>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {quoteHistory.map((quote) => {
                              return (
                                <Tr key={quote.id}>
                                  <Td
                                    className={`${
                                      selectedQuote === quote.id &&
                                      "bg-green-200"
                                    }`}
                                  >
                                    <QuoteSelector
                                      quoteID={quote.id}
                                      quoteData={quote}
                                      policyID={policyID}
                                      setFormData={setFormData}
                                      formData={formData}
                                      applyAdjustments={applyAdjustments}
                                    />
                                  </Td>
                                  <Td
                                    className={`${
                                      selectedQuote === quote.id &&
                                      "bg-green-200"
                                    }`}
                                  >
                                    <button
                                      onClick={(e) => {
                                        createNewQuote(
                                          e,
                                          true,
                                          quote.adjustmentFormData,
                                          quote.generatedData
                                        );
                                      }}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="hover:text-sky-900 text-[#072a48]"
                                    >
                                      {quote?.adjustmentFormData?.description}
                                    </button>
                                  </Td>
                                  {userIsAgent && (
                                    <AgentColumns
                                      quote={quote}
                                      selectedQuote={selectedQuote}
                                    />
                                  )}
                                  {userIsUnderwriter && (
                                    <UnderwriterColumns
                                      quote={quote}
                                      selectedQuote={selectedQuote}
                                    />
                                  )}
                                  <Td
                                    className={`${
                                      selectedQuote === quote.id &&
                                      "bg-green-200"
                                    }`}
                                  >
                                    <button
                                      title={t("plm.quotes.quotes-table.print")}
                                      onClick={(e) => {
                                        createNewQuote(
                                          e,
                                          true,
                                          quote.adjustmentFormData,
                                          quote.generatedData
                                        );
                                      }}
                                      className="d-inline mx-1 text-[#072a48]"
                                    >
                                      <PrintIcon fontSize={"large"} />
                                    </button>

                                    {isQuoteRequest && userIsUnderwriter && (
                                      <>
                                        <button
                                          title={t(
                                            "plm.quotes.quotes-table.delete"
                                          )}
                                          onClick={(e) =>
                                            deleteThisQuote(quote.id)
                                          }
                                          className="d-inline mx-1 text-[#8B0000]"
                                        >
                                          <DeleteIcon fontSize={"large"} />
                                        </button>
                                      </>
                                    )}
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </article>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <BlueButton
                    text={t("plm.quotes.create-new.submit")}
                    hasContentToBottom={true}
                    hasContentToRight={true}
                    onClickFunction={(e) => {
                      window.scrollTo(0, 0);
                      setIsNewQuote(!isNewQuote);
                    }}
                  />
                  <RedButton
                    text={t("plm.quotes.underwriting-details.clear-list")}
                    onClickFunction={(e) => {
                      clearQuoteList(
                        e,
                        quoteHistory,
                        setQuoteHistory,
                        policyID
                      );
                    }}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                  />
                </Container>
              </div>
            )}
        </>
      ) : (
        <>
          <div className="mt-2 text-center">
            <Typography variant="h6">
              Quotes cannot be modified once signatures are requested.
            </Typography>
            {formData?.signedProposalData?.status === "complete" ? (
              <Typography variant="body1">
                The quote request was signed by{" "}
                {formData?.signedProposalData?.signerName} at{" "}
                {formData?.signedProposalData?.signerEmail} <br /> on{" "}
                {formData?.signedProposalData?.completeDate
                  .toDate()
                  .toString()
                  .slice(4, 15)}
                .
              </Typography>
            ) : (
              <Typography variant="body1">
                A signature was requested from{" "}
                {formData?.signedProposalData?.signerName} at{" "}
                {formData?.signedProposalData?.signerEmail} <br /> on{" "}
                {formData?.signedProposalData?.dateSent
                  .toDate()
                  .toString()
                  .slice(4, 15)}
                .
              </Typography>
            )}
            <button
              title={t("plm.quotes.quotes-table.print")}
              onClick={(e) => {
                if (formData?.signedProposalData?.status === "complete") {
                  const publicUrl = formData?.signedProposalData?.publicUrl;
                  window.open(publicUrl, "_blank");
                } else {
                  const selectedQuoteID = formData.selectedQuote;
                  const selectedQuoteData = quoteHistory.find(
                    (quote) => quote.id === selectedQuoteID
                  );
                  createNewQuote(
                    e,
                    true,
                    selectedQuoteData.adjustmentFormData,
                    selectedQuoteData.generatedData
                  );
                }
              }}
              className="d-inline mx-1 text-[#072a48] my-4"
            >
              <Typography variant="body1">
                {formData?.signedProposalData?.status === "complete" ? (
                  <>{t("Print Signed Quote")}</>
                ) : (
                  <>{t("Print Unsigned Quote")}</>
                )}
              </Typography>
              <PrintIcon fontSize={"large"} />
            </button>
          </div>
        </>
      )}
    </>
  );
}
