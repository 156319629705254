import React, { useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import assignFrequency from "../utils/assignFrequency";
import assignSeverity from "../utils/assignSeverity";
import assignCombined from "../utils/assignCombined";
import { Tooltip } from "@mui/material";
import getAge from "../../utils/getAge";
import DriverDetailsModal from "./modals/DriverDetailsModal";

export default function FitnessTable({
  aggregatedDriverInfo,
  frequencyMatrix,
  severityMatrix,
  combinedMatrix,
  dataReady,
  userInfo,
  setDriverModalIsOpen,
  driverModalIsOpen,
  closeDriverModal,
  openDriverModal,
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <h1 className={`${"text-2xl mt-2.5 font-bold md:mb-0"}`}>
        Fitness Table
      </h1>
      Count: {aggregatedDriverInfo?.length}
      <button
        className="bg-[#072a48] text-white d-block rounded-md px-3 py-2 my-2 text-sm"
        onClick={(e) => setShow(!show)}
      >
        {show ? "Collapse" : "Expand"} Table
      </button>
      <Table>
        <Thead>
          <Tr>
            <Td className="bg-[#072a48] text-center text-white rounded-tl-md cursor-pointer hover:bg-[#072a48]">
              Full Name
            </Td>
            {show && (
              <>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  DOB
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Age
                </Td>
              </>
            )}

            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              On Policy
            </Td>
            {show && (
              <>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Major Violations
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Minor Violations
                </Td>
              </>
            )}

            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Risk Factor Frequency
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Risk Factor Severity
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Risk Factor Combined
            </Td>
            {show && (
              <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                License Number
              </Td>
            )}

            {show && (
              <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                Notes
              </Td>
            )}

            {show && (
              <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                Warning Data
              </Td>
            )}

            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Age Status
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Experience Status
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Major Violation Status
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Minor Violation Status
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Crashes Status
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Case by Case Review
            </Td>
            <Td className="bg-[#072a48] text-center text-white rounded-tr-md cursor-pointer hover:bg-[#072a48]">
              Training Status
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {aggregatedDriverInfo.map((driver, i) => (
            <Tr key={driver.licenseNumber}>
              <Td className="text-center">
                <button onClick={openDriverModal} title={driver?.licenseNumber}>
                  {driver?.driverFirst + " " + driver?.driverLast}
                </button>

                <DriverDetailsModal
                  driver={driver}
                  setDriverModalIsOpen={setDriverModalIsOpen}
                  userInfo={userInfo}
                  driverModalIsOpen={driverModalIsOpen}
                  closeDriverModal={closeDriverModal}
                />
              </Td>
              {show && (
                <>
                  <Td className="text-center">
                    {driver.dob && driver.dob.toDate().toString().slice(4, 15)}
                  </Td>
                  <Td className="text-center">
                    {getAge(
                      driver.dob && driver.dob.toDate().toString().slice(4, 15)
                    ) || 0.0}
                  </Td>
                </>
              )}
              <Td className="text-center">{driver.onPoliciesCount}</Td>
              {show && (
                <>
                  <Td className="text-center">{driver.majorViolationsCount}</Td>
                  <Td className="text-center">{driver.minorViolationsCount}</Td>
                </>
              )}

              <Td className="text-center" title={driver.claimsCount}>
                {dataReady &&
                  frequencyMatrix &&
                  frequencyMatrix.length !== 0 &&
                  assignFrequency(driver, dataReady, frequencyMatrix)}
              </Td>
              <Td className="text-center">
                {dataReady &&
                  severityMatrix &&
                  severityMatrix.length !== 0 &&
                  assignSeverity(driver, dataReady, severityMatrix)}
              </Td>
              <Td className="text-center">
                {dataReady &&
                  combinedMatrix &&
                  combinedMatrix.length !== 0 &&
                  assignCombined(driver, dataReady, combinedMatrix)}
              </Td>
              {show && <Td className="text-center">{driver.licenseNumber}</Td>}

              {show && <Td className="text-center">{driver.notes}</Td>}

              {show && <Td className="text-center">{driver.warningData}</Td>}

              <Td className="text-center">
                <Tooltip
                  placement="top"
                  title={
                    "Age: " +
                    getAge(
                      driver.dob && driver.dob.toDate().toString().slice(4, 15)
                    ) + // Wrap getAge() call in parentheses
                    ", DOB: " + // Add comma to separate Age and DOB
                    (driver.dob && driver.dob.toDate().toString().slice(4, 15)) // Wrap driver.dob condition in parentheses
                  }
                >
                  {driver.ageStatus}
                </Tooltip>
              </Td>
              <Td className="text-center">
                <Tooltip placement="top" title={"YOE: " + driver.yoe}>
                  {driver.expStatus}
                </Tooltip>
              </Td>
              <Td className="text-center">
                <Tooltip
                  placement="top"
                  title={"Violations: " + driver.majorViolationsCount}
                >
                  {driver.majorViolationsCount > 0
                    ? "Exclusion by Type A violation"
                    : "Insurable"}
                </Tooltip>
              </Td>
              <Td className="text-center">
                <Tooltip
                  placement="top"
                  title={"Violations: " + driver.minorViolationsCount}
                >
                  {driver.minorViolationsCount > 1
                    ? "Exclusion by Type B violation"
                    : "Insurable"}
                </Tooltip>
              </Td>
              <Td className="text-center">
                {driver.claimsCount > 0 ? "Exclusion by Crashes" : "Insurable"}
              </Td>
              <Td className="text-center">{driver.caseByCaseCount}</Td>
              <Td className="text-center">{driver.result}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
