import React, { useEffect, useState } from "react";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import { Save as SaveIcon, ModeEdit as PencilIcon } from "@mui/icons-material";
import { db } from "../../../firebase";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { POLICY_ROUTE } from "../../../utils/routes";

const PowerUnitDetailsModal = ({
  truck,
  isOpen,
  onClose,
  contentLabel,
  policyID,
}) => {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations();

  const policyDetailToShow = truck?.policiesDetails?.find(
    (policy) => policy?.id === policyID
  );

  const breadcrumbs = [
    { label: t("plm.units.modal.breadcrumb.title") },
    { label: t("plm.units.modal.breadcrumb.details") },
  ];

  const selectedTruckVIN = truck?.truckVIN;

  const [discoveredPowerUnits, setDiscoveredPowerUnits] = useState([]);
  const searchOtherInsuredsForTruck = async () => {
    if (!truck) return;

    const powerUnitsSubcollectionRef = collectionGroup(db, "power-units");
    const q = query(
      powerUnitsSubcollectionRef,
      where("truckVIN", "==", selectedTruckVIN)
    );
    const querySnapshot = await getDocs(q);

    const values = await Promise.all(
      querySnapshot.docs.map(async (document) => {
        const insuredDocRef = document.ref.parent.parent;

        let insuredData = {};
        if (insuredDocRef) {
          const insuredDoc = await getDoc(insuredDocRef);
          if (insuredDoc.exists()) {
            insuredData = insuredDoc.data();
          }
        }

        // Fetch policy details
        const policiesDetails = await Promise.all(
          (document.data()?.policiesDetails || []).map(async (policy) => {
            // if it has no id, remove it from the array completely
            if (!policy.id) return null;
            const policyDocRef = doc(db, "policy-applications", policy.id);
            const policyDoc = await getDoc(policyDocRef);

            return {
              ...policy,
              policyNumber: policyDoc.exists()
                ? policyDoc.data()?.alPolicyNo
                : "N/A",
              policyStatus: policyDoc.exists()
                ? policyDoc.data()?.status
                : "N/A",
            };
          })
        );

        return { ...document.data(), id: doc.id, insuredData, policiesDetails };
      })
    );

    console.log("Discovered units with policies: ", values);
    setDiscoveredPowerUnits(values);
  };

  useEffect(() => {
    searchOtherInsuredsForTruck();
  }, [selectedTruckVIN]);

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={onClose}
      onClose={onClose}
      expandByDefault={true}
      showExpandButton={true}
      contentLabel={contentLabel}
      title="Power Unit Information"
    >
      <div className="modal-content">
        <Breadcrumbs items={breadcrumbs} />
        <p className="text-2xl font-semibold mb-3 mt-2">
          {t("plm.units.modal.truck-information")}
        </p>
        <section>
          <table className="table">
            <tbody>
              <tr>
                <td>VIN:</td>
                <td>
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(truck?.truckVIN);
                        controlToast(
                          true,
                          "The VIN was copied to clipboard",
                          "success"
                        );
                      }}
                      className="copy-button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-copy mr-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                        />
                      </svg>
                    </button>
                    {truck?.truckVIN}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.year")}:</td>
                <td>{truck?.truckYear}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.make")}:</td>
                <td>{truck?.truckMake}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.model")}:</td>
                <td>{truck?.truckModel}</td>
              </tr>
              <tr>
                <td>{t("plm.units.modal.class")}:</td>
                <td>{truck?.truckClass}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <p className="text-2xl font-semibold mb-3 mt-2">
            Policies Information
          </p>
          <table className="table">
            <tbody>
              {discoveredPowerUnits.map((unit, index) => (
                <tr key={unit.id || index}>
                  <td>
                    <p className="text-lg font-semibold mb-2">
                      {unit?.insuredData?.company || "N/A"}
                    </p>
                    <table className="table">
                      <thead>
                        <th>Policy Number</th>
                        <th>Unit Status</th>
                        <th>Policy Status</th>
                      </thead>
                      <tbody>
                        {unit?.policiesDetails?.map(
                          (policy, index) =>
                            policy?.id && (
                              <tr key={policy?.id || index}>
                                <td>
                                  <a
                                    target="_blank"
                                    href={`${POLICY_ROUTE}/${policy.id}`}
                                  >
                                    
                                  {policy?.policyNumber}
                                    <Tooltip
                                      title="Open This Policy"
                                      placement="top"
                                    >
                                      <OpenInNewIcon
                                        fontSize={"smaller"}
                                        className="ml-2 mb-1"
                                      />
                                    </Tooltip>
                                  </a>{" "}
                                  {policy?.id === policyID &&
                                    "(Currently Viewing)"}
                                </td>
                                <td>{policy?.status}</td>
                                <td>{policy?.policyStatus}</td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </Popup>
  );
};

export default PowerUnitDetailsModal;
