import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { db } from "../../../../firebase";
import Popup from "../../../generals/Popup";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";
import useTranslations from "../../../../Context/actions/useTranslations";

const PreviousYearsAdjustments = ({ formData, show, setShow }) => {
  const [adjustmentsData, setAdjustmentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const agencyID = formData.agencyID;
  const insuredID = formData.insuredID;

  const { t } = useTranslations();

  useEffect(() => {
    async function getPreviousYearsAdjustments() {
      const policiesCollectionRef = collection(db, "policy-applications");
      const q = query(
        policiesCollectionRef,
        where("agencyID", "==", agencyID),
        where("insuredID", "==", insuredID)
        // where("documentType", "==", "Policy")
      );

      try {
        const previousYearsPoliciesSnapshot = await getDocs(q);
        // Extract adjustments from fetched policies
        const allAdjustments = previousYearsPoliciesSnapshot.docs
          .map((doc) => {
            if (doc.data().documentType !== "Policy") return null;
            const { bindData, alPolicyNo, effectiveDate } = doc.data();
            if (bindData && bindData.adjustments) {
              const data = {
                ...bindData.adjustments, // Include all fields from adjustments
                alPolicyNo, // Add the policy number
                effectiveDate, // Add the effective date
                surcharges: {},
                credits: {},
              };

              if (bindData.adjustments.lossRatioAdjustmentPercentage) {
                if (
                  bindData.adjustments.lossRatioAdjustmentDebitOrCredit ===
                  "Debit"
                ) {
                  data.surcharges.lossRatioAdjustmentPercentage =
                    bindData.adjustments.lossRatioAdjustmentPercentage / 100;
                } else {
                  data.credits.lossRatioAdjustmentPercentage =
                    bindData.adjustments.lossRatioAdjustmentPercentage / 100;
                }
              }

              if (bindData.adjustments.safetyAdjustmentPercentage) {
                if (
                  bindData.adjustments.safetyAdjustmentDebitOrCredit === "Debit"
                ) {
                  data.surcharges.safetyAdjustmentPercentage =
                    bindData.adjustments.safetyAdjustmentPercentage / 100;
                } else {
                  data.credits.safetyAdjustmentPercentage =
                    bindData.adjustments.safetyAdjustmentPercentage / 100;
                }
              }

              if (bindData.adjustments.percentage) {
                if (bindData.adjustments.debitOrCredit === "Debit") {
                  data.surcharges.manualSurcharge =
                    bindData.adjustments.percentage / 100;
                } else {
                  data.credits.manualSurcharge =
                    bindData.adjustments.percentage / 100;
                }
              }

              return data;
            } else {
              return null;
            }
          })
          .filter((adjustment) => adjustment !== null); // Remove null values

        // Sort adjustments by the last two characters of alPolicyNo (descending)
        const sortedAdjustments = allAdjustments.sort((a, b) => {
          const aLastTwo = a.alPolicyNo.slice(-2);
          const bLastTwo = b.alPolicyNo.slice(-2);
          return bLastTwo - aLastTwo;
        });

        // Update state with sorted adjustments
        console.log("sorted adjustments: ", sortedAdjustments);
        setAdjustmentsData(sortedAdjustments);
      } catch (error) {
        console.error("Error fetching previous years adjustments:", error);
      } finally {
        setLoading(false);
      }
    }

    // Only fetch data if required fields are available
    if (insuredID && agencyID) {
      setLoading(true);
      getPreviousYearsAdjustments();
    }
  }, [agencyID, insuredID]);

  return (
    <Popup
      isOpen={show}
      onRequestClose={() => setShow(false)}
      maxWidth={"600px"}
    >
      {loading ? (
        <CircularProgress />
      ) : adjustmentsData.length === 0 ? (
        <Typography>
          {t("plm.quotes.previous-adjustments.no-adjustments")}
        </Typography>
      ) : (
        adjustmentsData.map((adjustments, index) => (
          <TableContainer
            key={index}
            component={Paper}
            style={{ marginBottom: "16px" }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              {adjustments.alPolicyNo}
              <br />
              {adjustments.effectiveDate.toDate().toString().slice(0, 15)}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>
                      {t("plm.quotes.previous-adjustments.category")}
                    </strong>
                  </TableCell>
                  <TableCell>
                    <strong>
                      {t("plm.quotes.previous-adjustments.description")}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {t("plm.quotes.previous-adjustments.percentage")}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Credits Section */}
                {Object.entries(adjustments.credits || {}).map(
                  ([key, value], i) =>
                    value > 0 && (
                      <TableRow key={i}>
                        <TableCell>
                          {t("plm.quotes.previous-adjustments.credits")}
                        </TableCell>
                        <TableCell>
                          {camelCaseToTitleCase(key.replace(/([A-Z])/g, " $1"))}
                        </TableCell>
                        <TableCell align="right">
                          {(value * 100).toFixed(2)}%
                        </TableCell>
                      </TableRow>
                    )
                )}

                {/* Surcharges Section */}
                {Object.entries(adjustments.surcharges || {}).map(
                  ([key, value], i) =>
                    value > 0 && (
                      <TableRow key={i}>
                        <TableCell>
                          {t("plm.quotes.previous-adjustments.surcharges")}
                        </TableCell>
                        <TableCell>
                          {camelCaseToTitleCase(key.replace(/([A-Z])/g, " $1"))}
                        </TableCell>
                        <TableCell align="right">
                          {(value * 100).toFixed(2)}%
                        </TableCell>
                      </TableRow>
                    )
                )}

                {/* Total Adjustment */}
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>
                      {t("plm.quotes.previous-adjustments.total-adjustment")}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {(Math.abs(adjustments.totalAdjustment) * 100).toFixed(2)}
                      %{" "}
                      {adjustments.totalAdjustment < 0 ? "Surcharge" : "Debit"}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))
      )}
    </Popup>
  );
};

export default PreviousYearsAdjustments;
