import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import Popup from "../../../generals/Popup";
import { db } from "../../../../firebase";
import DynamicTable from "../../../assets/lists/DynamicTable";
import {
  prepareLogsAndSubmission,
} from "./handleSubmission";

const CommoditiesLogs = ({
  open,
  setOpen,
  formData,
  modal,
  parseLogs,
  endorsementData,
}) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // TO PARSE LOGS THROUGH DATA ENDORSEMENT
  useEffect(() => {
    if (!parseLogs) return () => {};
    setLoading(true);
    console.log(
      "endorsementData.oldInsuredInfo",
      endorsementData.oldInsuredInfo
    );
    console.log(
      "endorsementData.newInsuredInfo",
      endorsementData.newInsuredInfo
    );
    const { logValues } = prepareLogsAndSubmission({
      insuredInfo: endorsementData.oldInsuredInfo,
      newInsuredInfo: endorsementData.newInsuredInfo,
    });
    console.log("logValues", logValues);
    setLogs(logValues);
    setLoading(true);
  }, []);

  // WITHOUT PARSE LOGS TO REQUEST DIRECT LOGS
  useEffect(() => {
    if (parseLogs) return () => {};
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          "agencies",
          formData?.agencyID,
          "insureds",
          formData?.insuredID,
          "commodities-logs"
        )
      ),
      (snapshot) => {
        const fetchedDocuments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("fetchedDocuments", fetchedDocuments);
        setLogs(fetchedDocuments);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);

  const [tableData, setTableData] = useState([]);

  const filterKeys = ["date-of-change", "commodity", "old-value", "new-value"];

  const columnsForFilings = [
    // {
    //   key: "date-of-change",
    //   label: "Date of Change",
    //   sortable: true,
    // },
    // {
    //   key: "commodity",
    //   label: "Commodity Name",
    //   sortable: true,
    // },
    // {
    //   key: "old-value",
    //   label: "Old Value %",
    //   sortable: true,
    // },
    // {
    //   key: "new-value",
    //   label: "New Value %",
    //   sortable: true,
    // },
    // {
    //   key: "old-value",
    //   label: "Old Value $",
    //   sortable: true,
    // },
    // {
    //   key: "new-value",
    //   label: "New Value $",
    //   sortable: true,
    // },
    {
      key: "key",
      label: "Commodity Name",
      sortable: true,
    },
    {
      key: "type",
      label: "Type",
      sortable: true,
    },
    {
      key: "oldValue",
      label: "Old Value",
      sortable: true,
    },
    {
      key: "newValue",
      label: "New Value",
      sortable: true,
    },
  ];

  function getLastPart(value) {
    if (typeof value === "string") {
      return value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    }
    return value;
  }

  const formingTableData = async () => {
    // function ensureToDate(date) {
    //   if (date && typeof date.toDate === "function") {
    //     return date;
    //   } else if (
    //     date &&
    //     typeof date.seconds === "number" &&
    //     typeof date.nanoseconds === "number"
    //   ) {
    //     const milliseconds =
    //       date.seconds * 1000 + Math.floor(date.nanoseconds / 1000000);
    //     return {
    //       toDate: () => new Date(milliseconds),
    //       milliseconds,
    //     };
    //   }
    //   return null;
    // }

    const tableData = logs?.map(async (log, dataIndex) => {
      //   const dateOfChangeParsed = ensureToDate(log["change-date"])
      //     ? ensureToDate(log["change-date"])
      //         .toDate()
      //         .toLocaleDateString("en-US", {
      //           month: "short",
      //           day: "2-digit",
      //           year: "numeric",
      //         })
      //     : "-";
      const type = log["type"];
      const commodity = log["key"];
      const oldValue = log["oldValue"];
      const newValue = log["newValue"];
      const tableObject = {
        ...log,
        tableInfo: {
          type: {
            text: type,
          },
          key: {
            text: getLastPart(commodity),
          },
          oldValuePer: {
            text: oldValue ? oldValue : "-",
          },
          newValuePer: {
            text: newValue ? newValue : "-",
          },
        },
        dataIndex,
      };
      return tableObject;
    });
    const tableDataSolved = await Promise.all(tableData);
    return tableDataSolved;
  };

  useEffect(() => {
    if (logs.length > 0) {
      formingTableData().then((tableData) => {
        console.log("tableData", tableData);
        setTableData(tableData);
      });
    }
  }, [logs]);

  return (
    <>
      {modal ? (
        <Popup
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          maxWidth="100%"
        >
          <DynamicTable
            skeletonLoader={loading}
            columns={columnsForFilings}
            tableData={tableData}
            filterKeys={filterKeys}
            formData={formData}
            sortDefault={{ key: "date-of-change", direction: "desc" }}
          />
        </Popup>
      ) : (
        <DynamicTable
          skeletonLoader={loading}
          columns={columnsForFilings}
          tableData={tableData}
          filterKeys={filterKeys}
          formData={formData}
          sortDefault={{ key: "date-of-change", direction: "desc" }}
        />
      )}
    </>
  );
};

export default CommoditiesLogs;
