import axios from "axios";
import React, { useEffect, useState } from "react";
import readXlsxFile from "read-excel-file";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import qs from "qs";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { UserAuth } from "../../Context/AuthContent";
import config from "../../config/env";

export default function SCT() {
  const { user } = UserAuth();
  const userID = user.uid;
  const { REACT_APP_SOUTHERN_BACKEND } = config
  const [currentSearchID, setCurrentSearchID] = useState("");
  // Check if user has active search
  const [loading, setLoading] = useState(true);
  const [userHasActiveSearch, setUserHasActiveSearch] = useState(false);
  const [activeSearchID, setActiveSearchID] = useState("");
  const [stagedData, setStagedData] = useState();
  const [checkedForSearches, setCheckedForSearches] = useState(false);
  const checkForActiveSearches = async () => {
    setCheckedForSearches(false);
    const q = query(
      collection(db, "temp-driver-lookups"),
      where("authorID", "==", userID)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setUserHasActiveSearch(true);
      setActiveSearchID(doc.id);
      setStagedData(doc.data().fileData);
      setCheckedForSearches(true);
      setShowImportPanel(false);
    });
    setLoading(false);
  };

  const [showImportPanel, setShowImportPanel] = useState(true);
  const [importedFileData, setImportedFileData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const handleUploadFile = (e) => {
    e.preventDefault();
    setGotData(false);
    const file = e.target[0]?.files[0];

    if (!file) return;
    readXlsxFile(file).then(async (rows) => {
      const values = rows;
      values.splice(0, 1);
      const newArray = [];
      values.forEach((driver) => {
        if (!driver[11]) return;
        newArray.push({
          driverLookupStatus: "Incomplete",
          driverFullName: driver[1],
          driverLicenseNumber: driver[11],
          driverSCTNumber: driver[13],
        });
      });
      setImportedFileData(newArray);
      const docRef = await addDoc(collection(db, "temp-driver-lookups"), {
        status: "Incomplete",
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        fileData: newArray,
      });
      setGotData(true);
      setCurrentSearchID(docRef.id);
      setCheckedForSearches(true);
      setUserHasActiveSearch(false);
      setShowImportPanel(false);
      // Log System Activity to System Log
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A new temporary file has been uploaded for an SCT Search by " +
          auth.currentUser.displayName,
      });
      // `rows` is an array of rows
      // each row being an array of cells.
    });
  };

  const [driverFields, setDriverFields] = useState([""]);

  const handleChangeDriverField = (e, i) => {
    const values = [...driverFields];
    values[i] = e.target.value;
    setDriverFields(values);
  };

  const resumeLastSearch = async () => {
    setCurrentSearchID(activeSearchID);
    setImportedFileData(stagedData);
    setGotData(true);
    setUserHasActiveSearch(false);
  };

  const deleteLastSearch = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, "temp-driver-lookups", activeSearchID));
    setImportedFileData([]);
    setCurrentSearchID("");
    setActiveSearchID("");
    setUserHasActiveSearch(false);
    setCheckedForSearches(false);
    setShowImportPanel(true);
  };

  const deleteCurrentSearch = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, "temp-driver-lookups", currentSearchID));
    setImportedFileData([]);
    setGotData(false);
    setCurrentSearchID("");
    setActiveSearchID("");
    setUserHasActiveSearch(false);
    setCheckedForSearches(false);
    setShowImportPanel(true);
  };

  const updateCurrentSearch = async (data) => {
    const searchRef = doc(db, "temp-driver-lookups", currentSearchID);

    await updateDoc(searchRef, {
      fileData: data,
    });
  };

  const updateSearchResults = async () => {
    const searchRef = doc(db, "temp-driver-lookups", currentSearchID);
    const docSnap = await getDoc(searchRef);

    if (docSnap.exists()) {
      setImportedFileData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const [dataUpdated, setDataUpdated] = useState(0);
  const validateDriver = (i, license, sct) => {
    // validates a single driver
    axios
      .post(`${REACT_APP_SOUTHERN_BACKEND}/sct`, {
        driverLicenseNumber: license,
        driverSCTNumber: sct,
      })
      .then(function (response) {
                const responseData = qs.parse(response.data);


        const values = importedFileData;
        if (responseData.error === "No se encontro información") {
          values[i].driverLookupStatus = "No information found";
        } else {
          values[i].driverLookupStatus = "Complete";
          values[i].driverLookupData = responseData;
        }
        setImportedFileData(values);
        updateCurrentSearch(values);
        return response;
      })
      .then((response) => {
        console.log(response);
        setDataUpdated(dataUpdated + 1);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const sliceDate = (date) => {
    const d = date.slice(0, 2);
    const m = date.slice(3, 5);
    const y = date.slice(6, 10);
    const newDate = y + "-" + m + "-" + d;
    return new Date(newDate + " CST");
  };

  const [validationComplete, setValidationComplete] = useState(false);
  const validateAllDrivers = () => {
    setValidationComplete(false);

    // Old Script
    importedFileData.forEach((driver, i) => {
      if (driver.driverSCTNumber === 0 || driver.driverSCTNumber === "") {
        importedFileData[i].driverLookupStatus = "No SCT Provided"
        return;
      }
      if (
        i !== 0 &&
        importedFileData[i - 1].driverLookupStatus === "Incomplete"
      )
        return;
      driver.driverLookupStatus === "Incomplete" &&
        driver.driverLookupStatus === "Incomplete" &&
        validateDriver(
          [i],
          importedFileData[i].driverLicenseNumber,
          importedFileData[i].driverSCTNumber
        );
    });
  };
  useEffect(() => {
    userID && !checkedForSearches && checkForActiveSearches();
    importedFileData.filter((e) => e.driverLookupStatus === "Incomplete")
      .length !== 0 && validateAllDrivers();
  }, [userID, checkedForSearches, userHasActiveSearch, dataUpdated, gotData]);

  return (
    <main>
      {loading ? (
        <p>Loading... Please wait</p>
      ) : (
        <div>
          {userHasActiveSearch ? (
            <section>
              <h1 className="text-[#072a48] font-[600] text-xl mt-3 ">
                Previous Search Found
              </h1>
              <p className="mt-3">
                You currently have an active driver search, would you like to
                resume your previous search or clear the data and start a new
                search?
              </p>
              <button
                onClick={(e) => resumeLastSearch(e)}
                className="text-[#072a48] d-block m-3"
              >
                Resume Search
              </button>
              <button
                onClick={(e) => deleteLastSearch(e)}
                className="text-[#8B0000] d-block m-3"
              >
                Start Over
              </button>
            </section>
          ) : (
            <section>
              {/* File Import Form */}
              {showImportPanel && (
                <form onSubmit={(e) => handleUploadFile(e)}>
                  <h1 className="text-[#072a48] font-[600] text-xl mt-3 ">
                    Driver SCT Search
                  </h1>
                  <p className="my-3">
                    To begin, upload an unedited drivers list exported from
                    INS-PAS.
                  </p>
                  <input type="file" />
                  <div className="max-w-[200px]">
                    <button className="d-block m-3 p-2 rounded w-full bg-[#072a48] hover:bg-sky-900 hover:text-white font-[600]">
                      Upload File
                    </button>
                  </div>
                </form>
              )}
              {!showImportPanel && (
                <h1 className="text-[#072a48] font-[600] text-xl mt-3 ">
                  Driver SCT Search
                </h1>
              )}
              {/* Drivers List Form */}
              <article>
                {!showImportPanel && (
                  <button
                    onClick={(e) => deleteCurrentSearch(e)}
                    className="text-[#8B0000] mt-2"
                  >
                    Cancel & Clear Data
                  </button>
                )}
                {!showImportPanel &&
                  importedFileData.filter(
                    (e) => e.driverLookupStatus === "Incomplete"
                  ).length === 0 && (
                    <div className="mt-2">
                      Search complete. If any drivers still show a status of
                      incomplete, try refreshing the page.
                    </div>
                  )}
                {!showImportPanel && (
                  <div>
                    <p className="font-[600] mt-2">
                      Progress:{" "}
                      {importedFileData.length -
                        importedFileData.filter(
                          (e) => e.driverLookupStatus === "Incomplete"
                        ).length}{" "}
                      {" out of"} {importedFileData.length}
                    </p>
                    <Table className="data-table my-3">
                      <Thead>
                        <Tr>
                          <Td className="bg-[#072a48] text-white md:min-w-[300px]">
                            Full Name
                          </Td>
                          <Td className="bg-[#072a48] text-white max-w-[75px]">
                            License No.
                          </Td>
                          <Td className="bg-[#072a48] text-white max-w-[75px]">
                            SCT No.
                          </Td>
                          <Td className="bg-[#072a48] text-white md:min-w-[355px]">
                            Status
                          </Td>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {importedFileData.map((field, i) => {
                          return (
                            <Tr key={"driver" + i}>
                              <Td className="align-top">
                                <input
                                  disabled
                                  onChange={(e) =>
                                    handleChangeDriverField(e, i)
                                  }
                                  className="w-full rounded"
                                  name="driverFullName"
                                  type="text"
                                  value={field.driverFullName}
                                />
                              </Td>
                              <Td className="align-top">
                                <input
                                  disabled
                                  onChange={(e) =>
                                    handleChangeDriverField(e, i)
                                  }
                                  className="max-w-[125px] rounded"
                                  name="driverLicenseNumber"
                                  type="text"
                                  value={field.driverLicenseNumber}
                                />
                              </Td>
                              <Td className="align-top">
                                <input
                                  disabled
                                  onChange={(e) =>
                                    handleChangeDriverField(e, i)
                                  }
                                  className="max-w-[85px] rounded"
                                  name="driverSCTNumber"
                                  type="text"
                                  value={field.driverSCTNumber}
                                />
                              </Td>
                              <Td className="align-top">
                                {field.driverLookupStatus !== "Complete" && (
                                  <div>
                                    {field.driverLookupStatus ===
                                    "No information found" ? (
                                      <p className="text-[#8B0000] font-bold">
                                        {field.driverLookupStatus}
                                      </p>
                                    ) : (
                                      <p>{field.driverLookupStatus}</p>
                                    )}
                                  </div>
                                )}

                                {field.driverLookupData && (
                                  <div>
                                    <p className="mb-0">Results:</p>
                                    <ul className="list-disc mt-0 p-2">
                                      {field.driverLookupData.determination ===
                                      "El operador NO cumple con los requisitos para poder conducir" ? (
                                        <li className="text-[#8B0000]">
                                          Driver does <b>NOT</b> meet the
                                          requirements to drive
                                        </li>
                                      ) : (
                                        <li
                                          className={
                                            field.driverLookupData
                                              .determination ===
                                              "El operador cumple con los requisitos para poder conducir" &&
                                            `text-green-600`
                                          }
                                        >
                                          {field.driverLookupData
                                            .determination ===
                                          "El operador cumple con los requisitos para poder conducir" ? (
                                            <>
                                              Driver{" "}
                                              <b>meets the requirements</b> to
                                              drive.
                                            </>
                                          ) : (
                                            <>
                                              {
                                                field.driverLookupData
                                                  .determination
                                              }
                                            </>
                                          )}
                                        </li>
                                      )}
                                      {field.driverLookupData.observations ===
                                      "ESTA LICENCIA ES VÁLIDA EN EUA Y CANADA" ? (
                                        <li className="text-green-600">
                                          License <b>valid</b> in USA & Canada.
                                        </li>
                                      ) : (
                                        <li className="text-[#8B0000]">
                                          {field.driverLookupData.observations}
                                        </li>
                                      )}
                                      {/* Check if Driver's License Expired */}
                                      {new Date(
                                        sliceDate(
                                          field.driverLookupData.expires
                                        )
                                      ) < new Date() ? (
                                        <li className="text-[#8B0000]">
                                          License <b>Expired</b>
                                          {" ("}
                                          {new Date(
                                            sliceDate(
                                              field.driverLookupData.expires
                                            )
                                          )
                                            .toDateString()
                                            .slice(4)}
                                          {") "}
                                        </li>
                                      ) : (
                                        <li className="text-green-600">
                                          License <b>NOT</b> Expired
                                          {" ("}
                                          {new Date(
                                            sliceDate(
                                              field.driverLookupData.expires
                                            )
                                          )
                                            .toDateString()
                                            .slice(4)}
                                          {") "}
                                        </li>
                                      )}
                                      {/* Check if Driver Passed Physical */}
                                      {field.driverLookupData.driverFit !==
                                      "SI" ? (
                                        <li className="text-[#8B0000]">
                                          <b>Failed</b> Medical Exam
                                        </li>
                                      ) : (
                                        <li className="text-green-600">
                                          <b>Passed</b> Medical Exam
                                        </li>
                                      )}
                                      {/* Check if Driver Physical Exam Expired */}
                                      {new Date(
                                        sliceDate(
                                          field.driverLookupData.examExpiration
                                        )
                                      ) < new Date() ? (
                                        <li className="text-[#8B0000]">
                                          Medical Exam <b>Expired</b>
                                          {" ("}
                                          {new Date(
                                            sliceDate(
                                              field.driverLookupData
                                                .examExpiration
                                            )
                                          )
                                            .toDateString()
                                            .slice(4)}
                                          {") "}
                                        </li>
                                      ) : (
                                        <li className="text-green-600">
                                          Medical Exam <b>Valid</b>
                                          {" ("}
                                          {new Date(
                                            sliceDate(
                                              field.driverLookupData
                                                .examExpiration
                                            )
                                          )
                                            .toDateString()
                                            .slice(4)}
                                          {") "}
                                        </li>
                                      )}
                                      {field.driverFullName !==
                                      field.driverLookupData.driverName ? (
                                        <li
                                          title={
                                            "Name In SCT: " +
                                            field?.driverLookupData.driverName
                                          }
                                          className="text-[#8B0000]"
                                        >
                                          Name does <b>NOT</b> match SCT records
                                        </li>
                                      ) : (
                                        <li className="text-green-600">
                                          Name <b>matches</b> SCT records
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                )}
              </article>
            </section>
          )}
        </div>
      )}
    </main>
  );
}
