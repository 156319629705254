import LoadingScreen from "react-loading-screen";
import {
  Row,
  Col,
  Container,
} from "../../../../Components/generals/GridSystem";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useTranslations from "../../../../Context/actions/useTranslations";
import DriverPanel from "../../../../Components/PolicyLifecycleManager/Drivers";
import PowerUnitPanel from "../../../../Components/PolicyLifecycleManager/Units";
import TrailerPanel from "../../../../Components/PolicyLifecycleManager/Trailers";
import QuestionnairePanel from "../../../../Components/PolicyLifecycleManager/Questionnaire";
import CommoditiesPanel from "../../../../Components/PolicyLifecycleManager/Commodities";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";
import BigAlert from "../../../../Components/generals/Toast/BigAlert";
import PrintOptionsModal from "../../../../Components/PolicyLifecycleManager/PrintOptionsModal";
import usePoliciesPdfState from "../../../../Context/actions/usePoliciesPdfState";
import { setProgressToZero } from "../../../../services/firebase-api/websocketSimulator";
import { filePolicyStructuring } from "../../../../utils/constants";
import useWebSocketSimulator from "../../../../services/southern-api/useWebSocketSimulator";
import {
  generateRandomUid,
  isPastSomeMinutes,
  parseStaticDate,
} from "../../../../utils/helpers";
import {
  domesticAlPolicyPayload,
  domesticGlPolicyPayload,
  naftaAlPolicyPayload,
  naftaGlPolicyPayload
} from "../../../../utils/policiesPdfPayloads";
import { ACCOUNT_ROUTE } from "../../../../utils/routes";
import useFormDataState from "../../../../Context/actions/useFormData";
import rateAllUnits from "../../../../Components/utils/rateUnits/rateAllUnits";

export default function NotLoggedInModules() {
  const { t } = useTranslations();

  let { paramRefID } = useParams();
  const policyID = paramRefID;
  const [loading, setLoading] = useState(false);
  const loadingText = t("manage-policies.loading");
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [driverList, setDriverList] = useState([]);
  const [truckList, setTruckList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [formSaved, setFormSaved] = useState(true);
  const [insuredInfo, setInsuredInfo] = useState({});
  const [agencyInfo, setAgencyInfo] = useState({});
  const policyRef = doc(db, "policy-applications", policyID);

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };

  const getEmailFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("email");
  };

  const moduleValue = getModuleValueFromCurrentUrl();
  const emailValue = getEmailFromCurrentUrl();

  const [formData, setFormData] = useState({
    alPremiums: {
      GWP: 0,
      totalPremium: 0,
      stampFee: 0,
      stampTaxAmount: 0,
      surplusLinesTax: 0,
      surplusLinesTaxAmount: 0,
      otherFees: 0,
      processingFee: 0,
      processingFeeAmount: 0,
    },
    effectiveDate: "",
  });

  const apiService = new ApiService();
  const fetchData = async () => {
    try {
      const data = await apiService.renewalProcessPolicyData({ policyID });
      setFormData(data?.policyData);
      setAgencyInfo(data?.agencyData);
      setInsuredInfo(data?.insuredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, [policyID]);

  const saveForm = async () => {
    try {
      const isTemporalUrl =
        typeof window !== "undefined"
          ? window?.location?.pathname?.includes("temporarily")
          : false;
      if (!isTemporalUrl) {
        await setDoc(policyRef, formData, { merge: true });
      } else {
        await apiService.renewalProcessPolicyData({
          policyID,
          payload: formData,
        });
      }
      setFormSaved(true);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const [didSuccess, setDidSuccess] = useState(false);

  const driverPanel = {
    formData,
    setDriverList,
    insuredInfo,
    policyID,
    userInfo: null,
    userID: null,
    saveForm,
    setFormSaved,
    driverList,
    formSaved,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
    driverCount: driverList.length,
    truckList,
    trailerList,
    powerUnitCount: truckList.length,
    setTruckList,
    rateAllUnits,
    getApplication: () => {},
    setPowerUnitCount: () => {},
    setFormData,
  };

  const powerUnitPanel = {
    formData,
    setTruckList,
    insuredInfo,
    policyID,
    userInfo: null,
    userID: null,
    saveForm,
    setFormSaved,
    truckList,
    formSaved,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
  };

  const trailerPanel = {
    formData,
    setTrailerList,
    insuredInfo,
    policyID,
    userInfo: null,
    userID: null,
    saveForm,
    setFormSaved,
    trailerList,
    formSaved,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
  };

  const questionnairePanel = {
    formData,
    insuredInfo,
    policyID,
    userInfo: null,
    saveForm,
    setFormSaved,
    formSaved,
    setFormData,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
    setInsuredInfo,
  };

  const commoditiesPanel = {
    formData,
    insuredInfo,
    policyID,
    userInfo: null,
    saveForm,
    setFormSaved,
    formSaved,
    setFormData,
    emailValue,
    setDidSuccess,
    setInsuredInfo,
    getEndorsements: () => {},
  };

  const { progress, initialSetup } = useWebSocketSimulator();
  const { policiesPdf, setPoliciesPdf } = usePoliciesPdfState();

  const { formDataGL, quoteSelected } = useFormDataState()

  const navigate = useNavigate()

  let isCancelled = false;

  const cancelPolicyPrinting = async () => {
    try {
      setPoliciesPdf({
        loadingCancel: true,
        dateWasCancel: parseStaticDate(new Date()),
      });
      const realType = filePolicyStructuring(policiesPdf?.type);
      await setProgressToZero(`${realType}-${policiesPdf?.userId}`);
      await apiService.abortPolicyPrinting();
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
      navigate(ACCOUNT_ROUTE)
    } catch (error) {
      setPoliciesPdf({
        loadingCancel: false,
        loading: false,
      });
      throw error;
    }
  };

  useEffect(() => {
    if (initialSetup) {
      setPoliciesPdf({
        error: false,
        cancelRequest: cancelPolicyPrinting,
      });
    }
  }, [initialSetup]);

  const downloadFile = async (url) => {
    if (isPastSomeMinutes(policiesPdf?.dateWasCancel)) {
      try {
        window.open(url);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    }
  };

  const printPolicy = async (e, option) => {
    const realType =
      option.includes("NaftaAL") ?
      "NAFTA AL" :
      option.includes("DomesticAL") ?
      "Domestic AL" :
      option.includes("NaftaGL") ?
      "NAFTA GL" :
      "Domestic GL";
    const fnPayload =
      option.includes("NaftaAL") ?
      naftaAlPolicyPayload :
      option.includes("DomesticAL") ?
      domesticAlPolicyPayload :
      option.includes("NaftaGL") ?
      naftaGlPolicyPayload :
      domesticGlPolicyPayload
    const dashedType = filePolicyStructuring(realType)
    const fnPayloadResult = fnPayload(
      formData,
      insuredInfo,
      driverList,
      truckList,
      trailerList,
      agencyInfo,
      option,
      formDataGL,
      quoteSelected
    )
    const randomUid = generateRandomUid("")
    setPoliciesPdf({
      type: realType,
      loading: true,
      error: false,
      userId: randomUid,
    });
    try {
      e.preventDefault();
      if (isCancelled) {
        throw new Error("Operation cancelled");
      }
      const result = await apiService.printPolicy({
        type: realType,
        payload: fnPayloadResult,
        id: formData?.id,
        option,
        userId: randomUid,
      });
      if (isCancelled) {
        throw new Error("Operation cancelled");
      }
      if (result) {
        setPoliciesPdf({
          ...result,
          loading: false,
          error: false,
        });
        if (isCancelled) {
          throw new Error("Operation cancelled");
        } else {
          const name = `${realType} Policy.pdf`;
          downloadFile(result.url, name);
        }
      }
    } catch (error) {
      const abortedError =
        error?.response?.data?.error === "Operation aborted" ||
        error?.message === "Operation cancelled";
      try {
        await setProgressToZero(dashedType);
      } catch (suberror) {
        console.error(suberror);
      } finally {
        console.log(
          "abortedError ? false : error",
          abortedError ? false : error
        );
        setPoliciesPdf({
          loading: false,
          error: abortedError ? false : error,
        });
      }
      return error;
    }
  };

  const { percentage } = progress;

  const printingPolicyPanel = {
    formData,
    insuredInfo,
    policyID,
    userInfo: null,
    saveForm,
    setFormSaved,
    formSaved,
    emailValue,
    setDidSuccess,
    getEndorsements: () => {},
    isNotModal: true,
    policiesPdf,
    percentage,
    printPolicy,
  };

  return (
    <LoadingScreen
      loading={loading && !Boolean(formData?.documentType)}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={loadingText}
    >
      {!loading && Boolean(formData?.documentType) ? (
        <Container className="p-0 px-4 mt-4 mb-4 w-full bg-white rounded" fluid>
          <Row>
            <Col lg={12}>
              <Row>
                <Col
                  spacing={isMobileScreen ? 0 : 12}
                  className={isMobileScreen ? "mt-2" : ""}
                >
                  {(!insuredInfo?.publicEditionModule?.["drivers"] &&
                    !insuredInfo?.publicEditionModule?.["power-units"] &&
                    !insuredInfo?.publicEditionModule?.["trailers"] &&
                    !insuredInfo?.publicEditionModule?.["commodities"] &&
                    !insuredInfo?.publicEditionModule?.["questionnaire"] &&
                    !insuredInfo?.publicEditionModule?.["print-policy"]) ||
                  didSuccess ? (
                    <BigAlert
                      title={
                        formData?.documentType === "Policy"
                          ? "Your changes were submitted.."
                          : "Your changes were submitted."
                      }
                      subtitle={
                        formData?.documentType === "Policy"
                          ? "An endorsement was created and your changes will not be active until the endorsement is approved. Your agent has been notified."
                          : "The changes will become active on the application immediately. Your agent has been notified."
                      }
                      className="mt-2"
                      status="successful"
                    />
                  ) : insuredInfo?.publicEditionModule?.["drivers"] &&
                    moduleValue === "drivers" ? (
                    <DriverPanel {...driverPanel} />
                  ) : insuredInfo?.publicEditionModule?.["power-units"] &&
                    moduleValue === "power-units" ? (
                    <PowerUnitPanel {...powerUnitPanel} />
                  ) : insuredInfo?.publicEditionModule?.["trailers"] &&
                    moduleValue === "trailers" ? (
                    <TrailerPanel {...trailerPanel} />
                  ) : insuredInfo?.publicEditionModule?.["commodities"] &&
                    moduleValue === "commodities" ? (
                    <CommoditiesPanel {...commoditiesPanel} />
                  ) : insuredInfo?.publicEditionModule?.["questionnaire"] &&
                    moduleValue === "questionnaire" ? (
                    <QuestionnairePanel {...questionnairePanel} />
                  ) : insuredInfo?.publicEditionModule?.["print-policy"] &&
                    moduleValue === "print-policy" ? (
                    <PrintOptionsModal {...printingPolicyPanel} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}
    </LoadingScreen>
  );
}
