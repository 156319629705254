import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../../../../firebase";
import {
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useTranslations from "../../../../Context/actions/useTranslations";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";
import LoadingScreen from "../../../assets/LoadingScreen";
import PreviousYearsAdjustmentsModal from "../PreviousYearsAdjustmentsModal";

export default function CreateNewQuote({
  formData,
  showQuoteSuccess,
  loading,
  setLoading,
  createNewQuote,
  adjustmentFormData,
  setAdjustmentFormData,
  show,
  setShow,
}) {
  const { t } = useTranslations();

  const adjustmentFields = [
    {
      label: t("plm.quotes.create-new.manual-adjustment"),
      fieldKey: "percentage",
      selectKey: "debitOrCredit",
      options: ["Debit", "Credit"],
    },
    {
      label: t("plm.quotes.create-new.loss-ratio-adjustment"),
      fieldKey: "lossRatioAdjustmentPercentage",
      selectKey: "lossRatioAdjustmentDebitOrCredit",
      options: ["Debit", "Credit"],
    },
    {
      label: t("plm.quotes.create-new.safety-adjustment"),
      fieldKey: "safetyAdjustmentPercentage",
      selectKey: "safetyAdjustmentDebitOrCredit",
      options: ["Debit", "Credit"],
    },
  ];

  const staging = formData?.stagingArea?.adjustmentFormData;

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader
        sx={{ backgroundColor: "#072a48", color: "white" }}
        title={<Typography variant="h6">{t("Adjustments")}</Typography>}
        action={<ExpandCollapseArrow show={show} setShow={setShow} />}
      />
      {show && (
        <>
          {loading ? (
            <LoadingScreen text={"Please wait..."} />
          ) : (
            <Box>
              {
                <Box sx={{ p: 3 }}>
                  <PreviousYearsAdjustmentsModal formData={formData} />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (
                        !adjustmentFormData.description ||
                        adjustmentFormData.description === ""
                      ) {
                        document.getElementById("title").scrollIntoView();

                        // TODO: translate this
                        return alert("Please enter a title for the quote.");
                      }
                      createNewQuote(e, false, adjustmentFormData);
                    }}
                  >
                    {adjustmentFields.map((field) => (
                      <Box
                        key={field.fieldKey}
                        sx={{ my: 2, p: 1, borderTop: "1px solid #ccc" }}
                      >
                        <Typography sx={{ mb: 1 }}>{field.label}:</Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <TextField
                            required
                            type="number"
                            variant="outlined"
                            size="small"
                            value={adjustmentFormData[field.fieldKey]}
                            onChange={(e) => {
                              setAdjustmentFormData({
                                ...adjustmentFormData,
                                [field.fieldKey]: parseFloat(e.target.value),
                              });
                            }}
                            sx={{ flex: 1 }}
                          />
                          <Typography>%</Typography>
                          <FormControl sx={{ flex: 1 }} size="small">
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                              required
                              value={adjustmentFormData[field.selectKey]}
                              onChange={(e) => {
                                setAdjustmentFormData({
                                  ...adjustmentFormData,
                                  [field.selectKey]: e.target.value,
                                });
                              }}
                            >
                              {field.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    ))}

                    <Tooltip
                      title={t("plm.quotes.create-new.this-value")}
                      placement="top"
                    >
                      <Box sx={{ my: 2, p: 2, borderTop: "1px solid #ccc" }}>
                        <Typography sx={{ mb: 1 }}>
                          {t("plm.quotes.create-new.renewal-credit")}:
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <FormControl sx={{ flex: 1 }} size="small">
                            <InputLabel>
                              {t("plm.quotes.create-new.renewal-credit")}
                            </InputLabel>
                            <Select
                              required
                              value={adjustmentFormData.renewalCredit}
                              onChange={(e) => {
                                setAdjustmentFormData({
                                  ...adjustmentFormData,
                                  renewalCredit: e.target.value,
                                });
                              }}
                            >
                              <MenuItem value={0}>0</MenuItem>
                              <MenuItem value={7.5}>7.5%</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Tooltip>

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width: "100%",
                        mt: 3,
                        bgcolor: "#072a48",
                        "&:hover": { bgcolor: "#116582" },
                      }}
                    >
                      {t("plm.quotes.create-new.submit")}
                    </Button>
                  </form>
                </Box>
              }
            </Box>
          )}
        </>
      )}
    </Card>
  );
}
