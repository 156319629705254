import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_CLIENT_ROUTE } from "../types"

const useClientState = () => {
    const { state, dispatch } = useContext(AppContext)
    const { client } = state || initialState

    const setClientRouteData = routeData => {
        dispatch({ type: SET_CLIENT_ROUTE, payload: routeData })
    }

    return {
        setClientRouteData,
        client
    }
}

export default useClientState