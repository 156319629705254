import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { doc, getDoc, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { setPolicyDetailUpdate, setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import Popup from "../../generals/Popup";
import { getLastId } from "../../../utils/endorsementDetails";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import updateSystemLog from "../../utils/updateSystemLog";

export default function AddTrailers(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;
  const insuredInfo = props.insuredInfo;

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [endorsementData, setEndorsementData] = useState([]);

  const handleGetTrailersById = async () => {
    let drivers = [];
    for (const trailerId of endorsement.data) {
      const docRef = doc(
        db,
          "agencies",
          endorsement.agencyID,
          "insureds",
          endorsement.insuredID,
          "trailers",
          getLastId(trailerId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        drivers.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    setEndorsementData(drivers);
  }

  useEffect(() => {
    handleGetTrailersById();
  }, [endorsement]);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false)

  const approveAddTrailersEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreateTrailer = async (
      endorsementId,
      status,
      trailerData,
      policyData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date())
          const endoPayload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, endoPayload);
          const promises = trailerData.map(async (trailer) => {
            
            const subcollectionRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              "trailers",
              trailer.id
            );

            const payload = setPolicyInfo(
              {
                ...trailer,
                dateCreated: new Date(),
              },
              {
                policyID: paramPolicyID,
                status: "Active",
              },
              'endo-addition'
            );
  
            await setDoc(subcollectionRef, payload, { merge: true });

            updateSystemLog(
              auth,
              paramPolicyID,
              "Add Trailers Endorsement (" +
                payload.trailerVIN +
                ") Approved For " +
                insuredInfo.company +
                " by " +
                auth.currentUser.displayName,
              "Add Trailer Endo Approved",
              payload,
              true
            );

            const renewalApplicationID = policyData?.renewalApplicationID

            if (renewalApplicationID) {
              const mirroredSubcollectionRef = doc(
                db,
                "agencies",
                agencyID,
                "insureds",
                insuredID,
                "trailers",
                trailer?.id
              );
              
              const mirroredPayload = setPolicyInfo(
                {
                  ...payload,
                  dateCreated: new Date(),
                },
                {
                  policyID: renewalApplicationID,
                  status: "Active",
                },
                'endo-addition'
              );

              await setDoc(mirroredSubcollectionRef, mirroredPayload, {
                merge: true,
              });

              updateSystemLog(
                auth,
                renewalApplicationID,
                "Add Trailers Endorsement (" +
                  mirroredPayload.trailerVIN +
                  ") Approved For " +
                  insuredInfo.company +
                  " by " +
                  auth.currentUser.displayName,
                "Add Trailer Endo Approved",
                mirroredPayload,
                true
              );
            }

            return subcollectionRef.id;
          });
          await Promise.all(promises)
          setSuccess(true);
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating trailer:",
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true)
      return
    }

    updateEndorsementStatusAndCreateTrailer(
      paramRefID,
      "Approved",
      endorsementData,
      policyData
    );
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAddTrailersEndorsement = async (
    e,
    _,
    reasons
  ) => {
    if (e) {
      e.preventDefault();
    }
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();

    await updateDoc(endoRef, payload);

    const promises = endorsementData?.map(async (subcollection) => {
      const { agencyID, insuredID } = endorsement;
      const subcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "trailers",
        subcollection?.id
      );

      const subCollectionPayload = setPolicyInfo(
        {
          ...subcollection,
          dateCreated: new Date(),
        },
        {
          policyID: paramPolicyID,
          status: "Declined",
        },
        'endo-addition'
      );

      const entities = await getDoc(subcollectionRef)
      const entityData = entities.data()
      const updatedPayload = setPolicyDetailUpdate(subCollectionPayload, entityData, subCollectionPayload?.['policiesDetails']?.[0])

      await setDoc(subcollectionRef, updatedPayload, { merge: true });

      
      updateSystemLog(
        auth,
        paramPolicyID,
        "Add Trailers Endorsement (" +
          updatedPayload.trailerVIN +
          ") Rejected For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Trailer Endo Rejected",
        updatedPayload,
        true
      );

      const renewalApplicationID = policyData?.renewalApplicationID

      if (renewalApplicationID) {
        const mirroredSubcollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "trailers",
          subcollection?.id
        );
        
        const mirroredPayload = setPolicyInfo(
          {
            ...payload,
            dateCreated: new Date(),
          },
          {
            policyID: renewalApplicationID,
            status: "Declined",
          },
          'endo-addition'
        );

        const updatedPayload = setPolicyDetailUpdate(mirroredPayload, entityData, mirroredPayload?.['policiesDetails']?.[0])

        await setDoc(mirroredSubcollectionRef, updatedPayload, {
          merge: true,
        });

        updateSystemLog(
          auth,
          paramPolicyID,
          "Add Trailers Endorsement (" +
            updatedPayload.trailerVIN +
            ") Rejected For " +
            insuredInfo.company +
            " by " +
            auth.currentUser.displayName,
          "Add Trailer Endo Rejected",
          updatedPayload,
          true
        );
      }
    });
    
    await Promise.all(promises);

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false)
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  return (
    <>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth='50%'
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved
          because the policy is In Cancellation
        </p>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineAddTrailersEndorsement(e, null, reasons)
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div>
        {endorsementData.map((trailer) => {
          return (
            <div className="mx-auto p-6 bg-white shadow-lg rounded-lg mb-2">
              <Container className="p-0">
                <Row>
                  <Col sm={12}>
                    <span className="font-semibold">Effective:</span>{" "}
                    {trailer.effectivDate?.toDate().toString() ||
                      "At Inception"}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">VIN:</span>{" "}
                    {trailer.trailerVIN}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Year:</span>{" "}
                    {trailer.trailerYear}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Make:</span>{" "}
                    {trailer.trailerMake}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">ACV:</span>{" "}
                    {trailer.trailerACV}
                  </Col>
                </Row>
              </Container>
            </div>
          );
        })}
      </div>

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(
        endorsement.status === "Submitted" ||
        isRequestException
      ) &&
      (
        user.role === "Underwriter" ||
        user.role === "Admin"
      ) ? (
        <>
          <article className="mt-3">
            <p className={isRequestException ? 'text-red-700 text-center !max-w-[345px] mx-auto' : ''}>
              {isRequestException ?
                `This endorsement has already been declined. 
                You are accepting/decling an exception request` :
                `Approving this endorsement will add the above trailer to the
                policy and notify the insured of the change.`
              }
            </p>
          </article>
          {isRequestException ?
            <RequestExceptions endorsement={endorsement} color='red' center={true} /> : null
          }
          <ApprovalButtons
            onApprove={approveAddTrailersEndorsement}
            onDecline={() => setRejectionModal(true)}
          />
        </>
      ) : endorsement.status === "Declined" &&
      !isRequestException &&
      !wasARequestException &&
      user.role === "Agent" &&
      !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </>
  );
}
