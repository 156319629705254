import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { getLastId } from "../../../../../utils/endorsementDetails";

const handleGetPowerUnitsById = async (endorsement) => {
  if (!endorsement?.data || !endorsement?.agencyID || !endorsement?.insuredID) {
    console.error("Invalid endorsement data provided.");
    return null;
  }

  const powerUnits = [];
  for (const powerUnitId of endorsement.data) {
    try {
      const docRef = doc(
        db,
        "agencies",
        endorsement.agencyID,
        "insureds",
        endorsement.insuredID,
        "power-units",
        getLastId(powerUnitId.path)
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        powerUnits.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.warn(`No document found for path: ${powerUnitId.path}`);
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  }

  return powerUnits;
};

export default handleGetPowerUnitsById;
