import React, { useEffect, useMemo, useState } from "react";
import {
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import Popup from "../../generals/Popup";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import updateSystemLog from "../../utils/updateSystemLog";
import CommoditiesLogs from "../../PolicyLifecycleManager/Commodities/utils/CommodityLogs";
import { commoditiesSubmission } from "../../PolicyLifecycleManager/Commodities/utils/handleSubmission";

export default function ModifyCommodities(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;
  const insuredInfo = props.insuredInfo;

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [policyData, setPolicyData] = useState({});

  useEffect(() => {
    const handleGetPolicyData = async () => {
      const policyRef = doc(db, "policy-applications", paramPolicyID);
      const policyDoc = await getDoc(policyRef);
      const policyData = {
        ...policyDoc.data(),
        id: policyDoc.id,
      };
      setPolicyData(policyData);
    };
    handleGetPolicyData();
  }, []);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);

  const approveModifyCommoditiesEndorsement = async (
    e,
    requestExceptionData
  ) => {
    e.preventDefault();
    const updateEndorsementStatusAndCreateCommodities = async () => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        paramRefID
      );
      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date());
          const endoPayload = {
            status: "Approved",
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, endoPayload);
          await commoditiesSubmission({
            insuredInfo: endorsement?.data.oldInsuredInfo,
            newInsuredInfo: endorsement?.data?.newInsuredInfo,
            formData: policyData,
            documentType: "Application",
          });
          updateSystemLog(
            auth,
            paramPolicyID,
            "Modify Commodity Endorsement Approved For " +
              insuredInfo.company +
              " by " +
              auth.currentUser.displayName,
            "Modify Commodity Endo Approved",
            null,
            true
          );
          setSuccess(true);
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating trailer:",
          error
        );
      }
    };

    const policyStatus = policyData.status;
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true);
      return;
    }

    updateEndorsementStatusAndCreateCommodities();
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAddCommodityEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(endoRef, payload);

    updateSystemLog(
      auth,
      paramPolicyID,
      "Modify Commodity Endorsement Rejected For " +
        insuredInfo.company +
        " by " +
        auth.currentUser.displayName,
      "Modify Commodity Endo Rejected",
      null,
      true
    );

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  return (
    <>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineAddCommodityEndorsement(e, null, reasons)
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />

      <CommoditiesLogs
        open={false}
        setOpen={() => {}}
        formData={policyData}
        modal={false}
        parseLogs={true}
        endorsementData={endorsement.data}
      />

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      (user.role === "Underwriter" || user.role === "Admin") ? (
        <>
          <article className="mt-3">
            <p
              className={
                isRequestException
                  ? "text-red-700 text-center !max-w-[345px] mx-auto"
                  : ""
              }
            >
              {isRequestException
                ? `This endorsement has already been declined. 
                You are accepting/decling an exception request`
                : `Approving this endorsement will add the above trailer to the
                policy and notify the insured of the change.`}
            </p>
          </article>
          {isRequestException ? (
            <RequestExceptions
              endorsement={endorsement}
              color="red"
              center={true}
            />
          ) : null}
          <ApprovalButtons
            onApprove={approveModifyCommoditiesEndorsement}
            onDecline={() => setRejectionModal(true)}
          />
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </>
  );
}
