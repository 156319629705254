import React, { useEffect, useState } from "react";
import Popup from "../../../generals/Popup";
import {
  useMediaQuery,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import DatePicker from "react-datepicker";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import LoadingScreen from "../../../assets/LoadingScreen";

export default function AddInspectionModal({ driver, formData }) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const insuredID = formData.insuredID;
  const agencyID = formData.agencyID;
  const getCustomerName = async () => {
    const customerRef = doc(db, "agencies", agencyID, "insureds", insuredID);
    const customerSnap = await getDoc(customerRef);
    if (customerSnap.exists()) {
      return customerSnap.data().company;
    } else {
      console.log("No such document!");
    }
  };
  const Customer = getCustomerName();
  const initialFormData = {
    code: "",
    inspectionDate: new Date(),
    reportNumber: "",
    claimAmount: 0,
    atFault: false,
    Customer,
    drvFirstName: driver.driverFirst,
    drvLastName: driver.driverLast,
  };

  const [inspectionFormData, setInspectionFormData] = useState(initialFormData);

  const resetForm = () => {
    setInspectionFormData(initialFormData);
  };

  const handleChange = (field, value) => {
    setInspectionFormData((prev) => ({ ...prev, [field]: value }));
    if (field === "code") {
      const selectedCode = codes.find((code) => code.code === value);
      setInspectionFormData((prev) => ({
        ...prev,
        description: selectedCode.description,
        bASIC: selectedCode.classification,
      }));
    }
  };

  const onClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(
        collection(db, "inspections"),
        inspectionFormData
      );
      console.log("Inspection added with ID: ", docRef.id);
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error adding inspection: ", error);
    }
  };

  const [codes, setCodes] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "loss-control", "tables", "eventualities"),
      (snapshot) => {
        const values = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCodes(values);
        setLoading(false);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen text={"Loading Code List..."} />
      ) : (
        <div>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            style={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "#072a48",
              color: "white",
            }}
          >
            Add Inspection
          </Button>
          <Popup
            isOpen={open}
            onRequestClose={onClose}
            maxWidth={isMobileScreen ? "100%" : "400px"}
            zIndex={1000}
            contentLabel={"Add Inspection"}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Add Inspection
              </Typography>

              {/* Code Dropdown And Subsequent Items*/}
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>Code</InputLabel>
                <Select
                  value={inspectionFormData.code}
                  onChange={(e) => handleChange("code", e.target.value)}
                  label="Code"
                >
                  {codes.map((eventuality) => (
                    <MenuItem value={eventuality.code}>
                      {eventuality.code} - {eventuality.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {inspectionFormData.code && (
                <>
                  <FormControl variant="outlined" fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      value={inspectionFormData.description}
                      required
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth required>
                    <TextField
                      disabled
                      variant="outlined"
                      onChange={(e) => handleChange("bASIC", e.target.value)}
                      value={inspectionFormData.bASIC}
                      required
                    />
                  </FormControl>
                </>
              )}

              {/* Inspection Date */}
              <Typography variant="body2">Inspection Date</Typography>
              <DatePicker
                wrapperClassName="datePicker"
                selected={inspectionFormData.inspectionDate}
                onChange={(date) => handleChange("inspectionDate", date)}
                className={
                  "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                }
              />

              {/* Report Number */}
              <TextField
                label="Report Number"
                placeholder="Enter report number"
                variant="outlined"
                onChange={(e) => handleChange("reportNumber", e.target.value)}
                value={inspectionFormData.reportNumber}
                required
              />

              {/* Claim Amount */}
              <TextField
                label="Claim Amount"
                placeholder="Enter Claim Amount"
                variant="outlined"
                type="number"
                onChange={(e) => handleChange("claimAmount", e.target.value)}
                value={inspectionFormData.claimAmount}
                required
              />

              {/* At Fault Toggle */}
              <FormControlLabel
                control={
                  <Switch
                    checked={inspectionFormData.atFault}
                    onChange={(e) => handleChange("atFault", e.target.checked)}
                    color="primary"
                  />
                }
                label="At Fault"
              />

              {/* Submit Button */}
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Popup>
        </div>
      )}
    </>
  );
}
