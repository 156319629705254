import React, { useEffect, useState } from "react";
import DocumentsTable from "../../DocumentsTable";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";

export default function Documents({ policyID, formData, insuredInfo }) {
  const { t } = useTranslations();

  const [cabReportFiles, setCabReportFiles] = useState([]);
  const [signedApplicationFiles, setSignedApplicationFiles] = useState([]);
  const [ssmImportListFiles, setSsmImportListFiles] = useState([]);
  const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState([]);

  const handlePolicySnapshot = async () => {
    const policySnapshot = await getDoc(
      doc(db, "policy-applications", policyID)
    );
    return {
      ...policySnapshot.data(),
      id: policySnapshot.id,
    };
  };

  useEffect(() => {
    handlePolicySnapshot().then((policyData) => {
      setCabReportFiles(policyData.cabReport ? [policyData.cabReport] : []);
      setSignedApplicationFiles(
        policyData.signedApplication ? [policyData.signedApplication] : []
      );
      setAdditionalDocumentsFiles(
        policyData.additionalDocuments ? policyData.additionalDocuments : []
      );
    });
  }, []);

  const handleSubmit = async (e) => {
    try {
      if (e) e?.preventDefault();
      const payload = {};
      payload["cabReport"] = cabReportFiles[0] ? cabReportFiles[0] : "";
      payload["signedApplication"] = signedApplicationFiles[0]
        ? signedApplicationFiles[0]
        : "";
      payload["additionalDocuments"] = additionalDocumentsFiles[0]
        ? additionalDocumentsFiles
        : [];
      await setDoc(doc(db, "policy-applications", policyID), payload, {
        merge: true,
      });
    } catch (error) {
      throw error;
    }
  };

  const [didRender, setDidRender] = useState(false);

  useEffect(() => {
    if (!didRender) {
      setDidRender(true);
      return;
    }
    handleSubmit();
  }, [
    cabReportFiles,
    signedApplicationFiles,
    ssmImportListFiles,
    additionalDocumentsFiles,
  ]);

  const documentUploadInfo = [
    {
      name: t("plm.documents.cab-report"),
      URL:
        "https://subscriber.cabadvantage.com/CABreport.cfm?dot=" +
        insuredInfo?.dot,
      key: "cabReport",
      files: cabReportFiles,
      setFiles: setCabReportFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
    },
    {
      name: t("plm.documents.signed-application"),
      key: "signedApplication",
      files: signedApplicationFiles,
      setFiles: setSignedApplicationFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
    },
    {
      name: t("plm.documents.additional-documents"),
      key: "additionalDocuments",
      files: additionalDocumentsFiles,
      setFiles: setAdditionalDocumentsFiles,
      storageFile: `files/policies/${policyID}/application-docs`,
      multiple: true,
    },
  ];

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.documents.title") },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold md:mb-0">
        {t("plm.documents.title")}
      </h1>
      <p className="my-3">
        {formData.documentType === "Application" && (
          <>{t("plm.documents.description1")}</>
        )}
        {formData.documentType !== "Application" && (
          <>{t("plm.documents.description2")}</>
        )}
      </p>
      <div className="mb-4 max-w-[850px] mx-auto">
        <DocumentsTable
          formData={formData}
          documentUploadInfo={documentUploadInfo}
        />
      </div>
    </div>
  );
}
