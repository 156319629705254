import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";
import { Button } from "@mui/material";
import useTranslations from "../../../Context/actions/useTranslations";

export default function Deductible({
  formData,
  setFormData,
  dropdownOptions,
  policyID,
  disabledRule,
  isAdminOrUnderwriter,
  handleOpenModal,
}) {

  const { t } = useTranslations()

  return (
    <section>
      <h1 className="text-lg">{t("plm.mtc.deductible.title")}</h1>
      <label
        htmlFor={`select-target-deductible`}
        className="block text-sm font-medium text-gray-700"
      >
        {t("plm.mtc.deductible.target-deductible")}
      </label>
      <select
        disabled={disabledRule}
        id={`select-target-deductible`}
        onChange={async (e) => {
          const mtcDetails = {
            ...(formData.mtcDetails || {}),
            targetDeductible: e.target.value,
          };
          const policyRef = doc(db, "policy-applications", policyID);
          await updateDoc(policyRef, {
            mtcDetails,
          });
        }}
        value={formData?.mtcDetails?.targetDeductible}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
      >
        <option value="">{t("plm.mtc.deductible.choose-deductible")}</option>
        {dropdownOptions?.targetDeductibles
          ?.slice()
          .sort((a, b) => a - b)
          .map((deductible) => (
            <option key={deductible} value={deductible}>
              ${numberWithCommas(deductible)}
            </option>
          ))}
      </select>
      {isAdminOrUnderwriter && !disabledRule && (
        <Button
          sx={{ fontSize: "10px" }}
          onClick={() => handleOpenModal("targetDeductibles", "mtcDropdowns")}
        >
          {t("plm.mtc.deductible.edit-options")}
        </Button>
      )}
    </section>
  );
}
