import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import {
  ModeEdit as PencilIcon
} from "@mui/icons-material";
import numberWithCommas from "../../../utils/numberWithCommas";
import DropdownPowerUnitMultiUpload from "../../forms/DynamicPropertiesInputs/DropdownPowerUnitMultiUpload";
import { handleDropdownPowerUnit } from "../DynamicTable/utils";
import useTranslations from "../../../../Context/actions/useTranslations";

const PowerUnitCard = ({
  item,
  itemIndex,
  formData,
  hasALCoverage,
  onClickItem,
  userInfo,
  handleOpenEditMode
}) => {

  const isApplicationOrPolicy =
    formData?.documentType === "Application" ||
    formData?.documentType === "Policy" ||
    typeof formData === "undefined";

  const allPolicies = item.policiesDetails;
  const matchingPolicies = allPolicies.filter(
    (policy) => policy.id === formData.id
  );
  const policy = matchingPolicies[0];
  const truckACV = policy.truckACV;
  const adjustedRate = policy.adjustedRate;
  const truckRadius = policy.truckRadius;
  const formattedACV = !truckACV
    ? "$0.00"
    : `$${numberWithCommas(truckACV || 0)}`;
  const formattedRate = !adjustedRate
    ? "$0.00"
    : `$${numberWithCommas(adjustedRate)}`;

  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslations();

  return (
    <Card
      sx={{
        mb: 2,
        borderRadius: 3,
        boxShadow: 4,
        backgroundColor: "#f9fafa",
        "&:hover": {
          boxShadow: 8,
        },
      }}
    >
      <CardContent>
        <Stack spacing={1.5}>
          {/* VIN Field */}
          <Box>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              fontWeight="bold"
            >
              VIN
            </Typography>
            <Typography
              variant="body1"
              onClick={(e) => {
                onClickItem(e, item?.id, itemIndex)
              }}
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
                "&:hover": { color: "primary.dark" },
              }}
            >
              {item.truckVIN}
            </Typography>
          </Box>

          {/* Truck Details */}
          <Box>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              fontWeight="bold"
            >
              Year Make Model
            </Typography>
            <Typography variant="body1">
              {item.truckYear} {item.truckMake} {item.truckModel}
            </Typography>
          </Box>
              <button onClick={e => setShowMore(!showMore)} className="text-[#072a48]">
                Show {!showMore ? "more" : "less"}...
              </button>
          {showMore && (
            <>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Radius
                </Typography>
                <Typography variant="body1">{truckRadius} miles</Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Class
                </Typography>
                <Typography variant="body1">{item.truckClass}</Typography>
              </Box>
              {(userInfo.role === "Admin" ||
                userInfo.role === "Underwriter") && (
                <>
                  {/* Adjusted Rate */}
                  {hasALCoverage && (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        fontWeight="bold"
                      >
                        Adjusted Rate
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {formattedRate}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {/* ACV */}
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  ACV
                </Typography>
                <Typography variant="body1">{formattedACV}</Typography>
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <button
          onClick={(e) => {
            if (isApplicationOrPolicy) {
              handleOpenEditMode(e, item);
            }
          }}
          className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
            ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
          `}
        >
          <PencilIcon
            className={!isApplicationOrPolicy ? 'opacity-20' : ''}
          />
        </button>
        <DropdownPowerUnitMultiUpload
          containerStyle="bg-transparent border-none p-0"
          wrapperStyle={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
            ${!isApplicationOrPolicy ? 'cursor-not-allowed' : ''}
          `}
          iconStyle={!isApplicationOrPolicy ? 'opacity-20' : ''}
          handleDropdown={() => handleDropdownPowerUnit(
            item,
            null,
            "power-units",
            null,
            null,
            null,
            t
          )}
          formData={formData}
          item={item}
        />
      </CardActions>
    </Card>
  );
};

export default PowerUnitCard;
