import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import FileUploadModal from "../../../FileUploadModal";
import uploadFile from "./uploadFile";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import SelectDropdown from "./SelectDropdown";
import updatePolicyField from "../../../utils/updatePolicyField";
import BlueButton from "../../../assets/forms/BlueButton";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import LossRunItem from "../LossRunItem";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function OtherLosses({
  formData,
  setFormData,
  policyID,
  currentYear,
}) {
  const [show, setShow] = useState(false);
  const [fileList, setFileList] = useState([
    { coverageType: "", coverageTerm: "", files: [] },
  ]);

  const { t } = useTranslations();

  const dropdownOptions = {
    coverages: [
      { value: "AL", label: "AL" },
      { value: "GL", label: "GL" },
      { value: "APD", label: "APD" },
      { value: "MTC", label: "MTC" },
      { value: "Other", label: "Other" },
    ],
    term: [
      {
        value: currentYear - 0 + " - " + (currentYear - 1),
        label: currentYear - 0 + " - " + (currentYear - 1),
      },
      {
        value: currentYear - 1 + " - " + (currentYear - 2),
        label: currentYear - 1 + " - " + (currentYear - 2),
      },
      {
        value: currentYear - 2 + " - " + (currentYear - 3),
        label: currentYear - 2 + " - " + (currentYear - 3),
      },
      {
        value: currentYear - 3 + " - " + (currentYear - 4),
        label: currentYear - 3 + " - " + (currentYear - 4),
      },
      {
        value: currentYear - 4 + " - " + (currentYear - 5),
        label: currentYear - 4 + " - " + (currentYear - 5),
      },
    ],
  };

  const addRow = () => {
    setFileList((prevList) => [
      ...prevList,
      { coverageType: "", coverageTerm: "", files: [] },
    ]);
  };

  const getFileList = async () => {
    const policyRef = doc(db, "policy-applications", policyID);
    try {
      const unsubscribe = onSnapshot(
        policyRef,
        (doc) => {
          if (doc.exists()) {
            const listOfFiles = doc.data().OtherLossRuns || [
              { coverageType: "", coverageTerm: "", files: [] },
            ];
            setFileList(listOfFiles);
          } else {
            console.log("No such document!");
            // Handle the case where the document doesn't exist
          }
        },
        (error) => {
          console.error("Error fetching document: ", error);
          // Handle errors
        }
      );
      return unsubscribe; // Return the unsubscribe function
    } catch (error) {
      console.error("Error subscribing to snapshot: ", error);
      // Handle subscription error
      return () => {}; // Return a dummy unsubscribe function
    }
  };

  // Subscribe to changes in file list
  useEffect(() => {
    let unsubscribe; // Declare unsubscribe variable

    const getFileList = async () => {
      const policyRef = doc(db, "policy-applications", policyID);
      try {
        unsubscribe = onSnapshot(
          // Assign to the outer scope variable
          policyRef,
          (doc) => {
            if (doc.exists()) {
              const listOfFiles = doc.data().OtherLossRuns || [
                { coverageType: "", coverageTerm: "", files: [] },
              ];
              setFileList(listOfFiles);
            } else {
              console.log("No such document!");
              // Handle the case where the document doesn't exist
            }
          },
          (error) => {
            console.error("Error fetching document: ", error);
            // Handle errors
          }
        );
      } catch (error) {
        console.error("Error subscribing to snapshot: ", error);
        // Handle subscription error
      }
    };

    getFileList(); // Call getFileList to initiate subscription

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Ensure unsubscribe is called on component unmount
      }
    };
  }, []); // Empty dependency array, runs once on mount

  return (
    <div className="card mb-2">
      <div className="card-header">
        <button onClick={(e) => setShow(!show)}>
          {t("plm.losses.other.upload")}
        </button>
        <ExpandCollapseArrow show={show} setShow={setShow} />
      </div>
      {show && (
        <fieldset disabled={formData.documentType !== "Application"}>
          <div className="card-body">
            <Table>
              <Thead>
                <Tr>
                  <Td className="bg-[#072a48] w-[150px] text-white rounded-tl-md">
                    {t("plm.losses.other.coverage")}
                  </Td>
                  <Td className="bg-[#072a48] text-white">
                    {t("plm.losses.other.years")}
                  </Td>
                  <Td className="bg-[#072a48] text-white rounded-tr-md">
                    {t("plm.losses.other.loss-runs")}
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {fileList.map((files, index) => {
                  return (
                    <LossRunItem
                      key={"otherLossRun" + index}
                      files={files}
                      index={index}
                      policyID={policyID}
                      formData={formData}
                      dropdownOptions={dropdownOptions}
                      labelCoverageType={"Coverage Type"}
                      labelCoverageTerm={"Coverage Term"}
                      labelMultiupload={t("plm.losses.other.upload")}
                      fieldNameCoverageType={"coverageType"}
                      fieldNameCoverageTerm={"coverageTerm"}
                      fieldNameMultiupload={"files"}
                    />
                  );
                })}
              </Tbody>
            </Table>
            <div className="mt-3">
              <BlueButton
                text={t("plm.losses.other.add-row")}
                onClickFunction={addRow}
                hasContentToBottom={true}
              />
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
}
