import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination, // Import TablePagination
} from '@mui/material';
import { db } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const Inspections = () => {
  const [inspections, setInspections] = useState([]); // Initialize state as an array
  const [page, setPage] = useState(0); // Page state
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page state

  const inspectionsRef = collection(db, 'loss-control', 'driverData', 'inspections');

  const getInspections = async () => {
    const snapshot = await getDocs(inspectionsRef);
    const inspectionsData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('inspections data: ', inspectionsData);
    setInspections(inspectionsData); // Set inspections as an array
  };

  useEffect(() => {
    getInspections();
  }, []);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  // Slice the data to show only the current page's data
  const paginatedInspections = inspections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center" sx={{ margin: 2 }}>
        Driver Violations
      </Typography>
      <Table aria-label="driver violations table">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Driver Name</TableCell>
            <TableCell>Driver License Number</TableCell>
            <TableCell>Driver DOB</TableCell>
            <TableCell>Inspection Date</TableCell>
            <TableCell>Inspection Number</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Violation Code</TableCell>
            <TableCell>Violation Group</TableCell>
            <TableCell>BASIC</TableCell>
            <TableCell>At Fault</TableCell>
            <TableCell>Crash Status</TableCell>
            <TableCell>Upload Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedInspections.map((inspection) => (
            <TableRow key={inspection.id}>
              <TableCell>{inspection.customer}</TableCell>
              <TableCell>{`${inspection.drvFirstName} ${inspection.drvLastName}`}</TableCell>
              <TableCell>{inspection.drvLicNbr}</TableCell>
              <TableCell>
                {inspection.drvDOB?.toDate
                  ? new Date(inspection.drvDOB.toDate()).toLocaleDateString()
                  : 'N/A'}
              </TableCell>
              <TableCell>
                {inspection.inspectionDate?.toDate
                  ? new Date(inspection.inspectionDate.toDate()).toLocaleDateString()
                  : 'N/A'}
              </TableCell>
              <TableCell>{inspection.number}</TableCell>
              <TableCell>{inspection.description}</TableCell>
              <TableCell>{inspection.code}</TableCell>
              <TableCell>{inspection.violationGroupDescription}</TableCell>
              <TableCell>{inspection.bASIC}</TableCell>
              <TableCell>{inspection.atFault}</TableCell>
              <TableCell>{inspection.crashStatus}</TableCell>
              <TableCell>
                {inspection.uploadDate?.toDate
                  ? new Date(inspection.uploadDate.toDate()).toLocaleDateString()
                  : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
        component="div"
        count={inspections.length} // Total count of inspections
        rowsPerPage={rowsPerPage} // Current rows per page
        page={page} // Current page
        onPageChange={handleChangePage} // Handle page change
        onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
      />
    </TableContainer>
  );
};

export default Inspections;
