import { addDoc, collection, doc, runTransaction } from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import updateSystemLog from "../../Components/utils/updateSystemLog";

export const additionalInsuredEndorsementRequest = async (
  additionalInsuredData,
  props
) => {
  const { policyID, insuredInfo, formData } = props;

  const alPremiums = formData?.alPremiums || null;
  const glPremiums = formData?.glPremiums || null;

  try {
    const endoRef = collection(
      db,
      "policy-applications",
      policyID,
      "endorsements"
    );
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const auth = getAuth();
    const result = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });
      let payload = {
        endorsementNumber: String(newCounter),
        type: "Add Additional Insureds",
        data: {
          ...additionalInsuredData,
          alPremiums,
          glPremiums,
        },
        dateCreated: new Date(),
        policyID,
        status: "Submitted",
        documentSource: "Endorsement",
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        endoEffectiveDate: additionalInsuredData.effectiveDate,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
      };
      if (Boolean(formData?.alPolicyNo)) {
        payload["alPolicyNo"] = formData.alPolicyNo;
      }
      if (Boolean(formData?.glPolicyNo)) {
        payload["glPolicyNo"] = formData.glPolicyNo;
      }
      await addDoc(endoRef, payload);
      await updateSystemLog(
        auth,
        policyID,
        "Add Additional Insured Endorsement Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        `Add Additional Insured Endorsement`,
        additionalInsuredData
      );
      return payload;
    });
    return result;
  } catch (error) {
    throw error;
  }
};

export const blanketAdditionalInsuredEndorsementRequest = async (
  policyID,
  insuredInfo,
  formData
) => {
  try {
    const endoRef = collection(
      db,
      "policy-applications",
      policyID,
      "endorsements"
    );
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const auth = getAuth();
    const result = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });
      let payload = {
        alPremiums: {
          GWP: 0,
          otherFees: 0,
          processingFeeAmount: 0,
          stampTaxAmount: 0,
          surplusLinesTaxAmount: 0,
          totalPremium: 0,
        },
        endorsementNumber: String(newCounter),
        type: "Blanket Additional Insured",
        dateCreated: new Date(),
        issuedDate: new Date(),
        status: "Approved",
        documentSource: "Endorsement",
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        endoEffectiveDate: formData.effectiveDate,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        policyID,
      };
      if (Boolean(formData?.alPolicyNo)) {
        payload["alPolicyNo"] = formData.alPolicyNo;
      }
      if (Boolean(formData?.glPolicyNo)) {
        payload["glPolicyNo"] = formData.glPolicyNo;
      }
      await addDoc(endoRef, payload);
      return payload;
    });
    return result;
  } catch (error) {
    throw error;
  }
};
