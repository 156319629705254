import DynamicTable from "../../assets/lists/DynamicTable";
import FormPopup from "../../generals/Popup/FormPopup";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { manageDriverOptions } from "../../../utils/searchFilters";
import ListFilters from "../../agent/policies/ListFilters";
import { useLocation } from "react-router-dom";
import ModalExclusion from "../../generals/Popup/ModalExclusion";
import { useMediaQuery } from "@mui/material";
import DriverAndTruckDynamicTable from "../../assets/lists/DynamicTable/DriverAndTruckDynamicTable";

export default function DriversList(props) {
  const {
    openModal,
    handleCloseModal,
    deletionMode,
    setDeletionMode,
    formSettings,
    modalSubmitting,
    columns,
    tableData,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    handleSubmit,
    showItem,
    filterKeys,
    formData,
    isAtLeastOneSelectedInDeletedMode,
    skeletonTableLoader,
    handleOpenEditMode,
    currentFilters,
    setCurrentFilters,
    insuredInfo,
    deletionType,
    mirrorLogic
  } = props

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <div>
      {deletionType !== 'exclusion' ?
        <FormPopup
          isOpen={openModal}
          onRequestClose={handleCloseModal}
          title='Select the expiration date of the driver'
          formSettings={formSettings}
          loading={modalSubmitting}
        /> :
        <ModalExclusion
          open={openModal}
          onClose={handleCloseModal}
          handleSubmit={mirrorLogic}
          isMobileScreen={isMobileScreen}
          loading={modalSubmitting}
          insuredInfo={insuredInfo}
        />
      }

      {!isTemporalUrl ?
        <ListFilters
          options={manageDriverOptions}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
        /> : null
      }
      <DriverAndTruckDynamicTable
        skeletonLoader={skeletonTableLoader}
        deletionMode={deletionMode}
        formData={formData}
        columns={columns}
        tableData={
          !deletionMode ?
            tableData : tableDataShowingDeletion
        }
        setTableData={
          !deletionMode ? () => {} :
            setTableDataShowingDeletion
        }
        alternativeTableData={
          !deletionMode ? [] :
            tableDataInDeletionMode
        }
        setAlternativeTableData={
          !deletionMode ? () => {} :
            setTableDataInDeletionMode
        }
        deleteThisItem={handleSubmit}
        onClickItem={showItem}
        filterKeys={filterKeys}
        handleOpenEditMode={handleOpenEditMode}
        name="drivers"
      />
      {deletionMode ?
        <ApprovalButtons
          onApprove={(e) => {
            handleSubmit(e, 'multiple')
          }}
          onDecline={(e) => {
            e.preventDefault()
            setTableDataInDeletionMode([])
            setTableDataShowingDeletion([])
            setDeletionMode(!Boolean(deletionMode))
          }}
          titleAccept="Submit"
          titleDecline="Cancel"
          disabledAccept={!isAtLeastOneSelectedInDeletedMode}
        /> : null
      }
      {deletionMode ?
        <DynamicTable
          deletionMode={deletionMode}
          formData={formData}
          deleteMode={true}
          columns={columns}
          tableData={tableDataInDeletionMode}
          setTableData={setTableDataInDeletionMode}
          alternativeTableData={tableDataShowingDeletion}
          setAlternativeTableData={setTableDataShowingDeletion}
          deleteThisItem={handleSubmit}
          onClickItem={showItem}
          filterKeys={filterKeys}
          // name="driversDelete"
        /> : null
      }
    </div>
  );
}
