import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";
import useTranslations from "../../../../Context/actions/useTranslations";
import updateCommodityTotal from "../utils/updateCommodityTotal";

export default function StandardCommodityName({
  label,
  property,
  formData,
  setFormData,
  policyID,
  disabledSlider,
  defaultValue,
  editMode,
  insuredInfo,
  setInsuredInfo
}) {

  const { t } = useTranslations();

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();

  const handleChangeStandardCommodityPercentage = async (e) => {
    let newValue = e.target.value.replace(/^0+(?=\d)/, "");
    const updatedPercentages = { ...insuredInfo.standardCommodityPercentages };
    updatedPercentages[e.target.name] = newValue;
    if (disabledSlider && newValue === 0) {
      const updatedValues = {
        ...formData.standardCommodityValues,
        [property]: 0,
      };
      if (Boolean(moduleValue)) {
        setInsuredInfo({
          ...insuredInfo,
          standardCommodityValues: updatedPercentages,
        })
      } else {
        setInsuredInfo({
          ...insuredInfo,
          standardCommodityValues: updatedValues,
        })
      }
    }

    const total = updateCommodityTotal(
      null,
      formData,
      {
        ...insuredInfo,
        standardCommodityPercentages: updatedPercentages,
      },
      setInsuredInfo
    );

    setInsuredInfo({
      ...insuredInfo,
      standardCommodityPercentages: updatedPercentages,
      commodityTotal: total,
    })
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm font-semibold">
        {label} {t("plm.commodities.custom.percentage")}:
      </label>
      <input
        name={property}
        type="number"
        onChange={handleChangeStandardCommodityPercentage}
        className="p-2 border rounded focus:outline-none focus:border-blue-500"
        value={insuredInfo?.standardCommodityPercentages?.[property] || 0}
        disabled={!editMode}
      />
    </div>
  );
}
