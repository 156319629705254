import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "../../assets/forms/Checkbox";
import updatePolicyField from "../../utils/updatePolicyField";
import AgentSelection from "./CustomFields/AgentSelection";
import InsuredInfo from "./InsuredInfo";
import TableSkeleton from "../../generals/TableSkeleton";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import SelectDropdown from "../../assets/forms/SelectDropdown";
import BlueButton from "../../assets/forms/BlueButton";
import {
  Summarize as SummarizeIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Popup from "../../generals/Popup";
import DynamicTable from "../../assets/lists/DynamicTable";
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import FormPopup from "../../generals/Popup/FormPopup";
import {
  citiesAZOptions,
  citiesCAOptions,
  citiesNMOptions,
  citiesTXOptions,
} from "../../../utils/constants";
import useGeneralState from "../../../Context/actions/useGeneralState";
import BindInfo from "./BindInfo";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import useFormDataState from "../../../Context/actions/useFormData";
import {
  calculateExpirationDateTimestamp,
  isLeapYear,
} from "../../Finances/Tables/utils";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useTranslations from "../../../Context/actions/useTranslations";
import { setTimeToNoonOne } from "../../../utils/helpers";
import CoverageCheckboxes from "./CoverageCheckboxes";
// import { useTranslation } from "react-i18next";

export default function Coverages({
  userInfo,
  formData,
  setFormData,
  policyID,
  insuredInfo,
  language,
  setDisplayCompanyDetails,
  setDisplayBindDetailsInfo,
}) {
  const isAdminOrUnderwriter =
    userInfo.role === "Admin" || userInfo.role === "Underwriter";

  // const { t } = useTranslation();
  const { t } = useTranslations();
  const { controlToast } = useGeneralState();
  const [showCoverages, setShowCoverages] = useState(false);

  // Check if the screen is mobile
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const hasStateOfEntry =
      formData.stateOfEntry && formData.stateOfEntry !== "";
    const insuredPhysicalState = insuredInfo.state;

    if (insuredInfo.state && !hasStateOfEntry) {
      updatePolicyField(policyID, "stateOfEntry", insuredPhysicalState);
    }
  }, [formData, insuredInfo, policyID]);

  // Update state based on screen size
  useEffect(() => {
    setShowCoverages(!isMobileScreen);
  }, [isMobileScreen]);

  const dropdownOptions = {
    product: [
      { value: "MCA", label: "MCA" },
      { value: "MCE", label: "MCE" },
      { value: "MX", label: "MX" },
    ],
    stateOfEntry: [
      { value: "AZ", label: "Arizona" },
      { value: "CA", label: "California" },
      { value: "NM", label: "New Mexico" },
      { value: "TX", label: "Texas" },
    ],
    coverageSingleLimit: [
      { value: "$1,000,000", label: "$1,000,000" },
      { value: "$750,000", label: "$750,000" },
      { value: "$300,000", label: "$300,000" },
    ],
    program: [
      { value: "NAFTA", label: t("plm.coverage-side-panel.program.option1") },
      {
        value: "Domestic",
        label: t("plm.coverage-side-panel.program.option2"),
      },
    ],
    newOrRenewal: [
      // { value: "New Business", label: t("New Business") },
      {
        value: "New Business",
        label: t("plm.coverage-side-panel.new-renewal.option1"),
      },
      {
        value: "Renewal",
        label: t("plm.coverage-side-panel.new-renewal.option2"),
      },
    ],
    paymentPlan: [
      {
        value: "External Finance",
        label: t("plm.coverage-side-panel.payment-plan.option1"),
      },
      {
        value: "Full Payment",
        label: t("plm.coverage-side-panel.payment-plan.option2"),
      },
    ],
    cargoType: [
      {
        value: "Non-Hazmat",
        label: t("plm.coverage-side-panel.cargo-type.option1"),
      },
      {
        value: "Hazmat",
        label: t("plm.coverage-side-panel.cargo-type.option2"),
        disabled: true,
      },
    ],
  };

  const [openFilingsModal, setOpenFilingsModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [proofOfFilingsData, setProofOfFilingsData] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false);

  const handleOpenFilingsModal = () => {
    setOpenFilingsModal(!openFilingsModal);
  };

  const handleOpenEditMode = (e, body) => {
    e.preventDefault();
    window.open(body.url, "_blank");
  };

  useEffect(() => {
    if (openFilingsModal) {
      const proofOfFilingsRef = collection(db, "proof-of-filings");
      const q = query(
        proofOfFilingsRef,
        where("insuredDOT", "==", insuredInfo?.dot)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const proofOfFilingsResult = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProofOfFilingsData(proofOfFilingsResult);
      });
      return () => unsubscribe();
    }
    return () => {};
  }, [openFilingsModal]);

  const filterKeysForFilings = [
    "creationDate",
    "filingType",
    "submittingUserName",
    "url",
  ];

  const columnsForFilings = [
    {
      name: "creationDate",
      label: t("plm.coverage-side-panel.filing-table.creationDate"),
    },
    {
      name: "filingType",
      label: t("plm.coverage-side-panel.filing-table.type"),
    },
    {
      name: "submittingUserName",
      label: t("plm.coverage-side-panel.filing-table.submittingUserName"),
    },
    {
      name: "action",
      label: t("plm.coverage-side-panel.filing-table.view"),
    },
  ];

  const getAgencyAndInsured = async (agencyID, insuredDOT) => {
    const names = {};
    try {
      const agencyRef = doc(db, "agencies", agencyID);
      const agencySnap = await getDoc(agencyRef);
      if (agencySnap.exists()) {
        names["agencyName"] = agencySnap.data().name;
      }
      const insuredRef = query(
        collectionGroup(db, "insureds"),
        where("dot", "==", insuredDOT)
      );
      const querySnapshot = await getDocs(insuredRef);
      querySnapshot.forEach((doc) => {
        if (doc.data().company) {
          names["insuredName"] = doc.data().company;
        }
      });
      return names;
    } catch (error) {
      console.error(error);
      return {
        agencyName: "",
        insuredName: "",
      };
    }
  };

  const formingTableData = () => {
    const tableData = proofOfFilingsData?.map(async (data, dataIndex) => {
      const {
        creationDate,
        insuredDOT,
        submittingUserName,
        agencyID,
        filingType,
      } = data || {};
      const iconLeft = [];

      const actions = ["view"];

      iconLeft.push({
        name: "redFlag-filings",
        toField: "creationDate",
        className: "text-[#8B0000]",
        onClick: null,
      });

      actions.push("redFlag-filings");

      const iconRight = [
        {
          icon: "delete",
          action: true,
          toField: "action",
        },
      ];

      const { agencyName, insuredName } = await getAgencyAndInsured(
        agencyID,
        insuredDOT
      );

      const parsedCreationDate = creationDate
        ? creationDate.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : null;

      const tableObject = {
        ...data,
        tableInfo: {
          creationDate: {
            text: parsedCreationDate,
          },
          filingType: {
            text: filingType,
          },
          submittingUserName: {
            text: submittingUserName,
          },
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
      tableObject["tableInfo"]["actions"] = {
        actions,
      };
      return tableObject;
    });
    const tableDataSolved = Promise.all(tableData);
    return tableDataSolved;
  };

  useEffect(() => {
    if (proofOfFilingsData.length > 0) {
      formingTableData().then((tableData) => {
        setTableData(tableData);
      });
    }
  }, [formData, openFilingsModal, proofOfFilingsData]);

  const filterOptions = (options) => {
    const cities = options.map((option) => option.key);
    return cities;
  };

  const [openPortOfEntryModal, setOpenPortOfEntryModal] = useState(false);
  const [openPortOfEntryModalLoading, setOpenPortOfEntryModalLoading] =
    useState(false);
  const [portOfEntryCA, setPortOfEntryCA] = useState(
    filterOptions(citiesCAOptions)
  );
  const [portOfEntryAZ, setPortOfEntryAZ] = useState(
    filterOptions(citiesAZOptions)
  );
  const [portOfEntryNM, setPortOfEntryNM] = useState(
    filterOptions(citiesNMOptions)
  );
  const [portOfEntryTX, setPortOfEntryTX] = useState(
    filterOptions(citiesTXOptions)
  );

  useEffect(() => {
    const stateOfEntry = formData?.stateOfEntry || "CA";
    const portOfEntryRef = collection(
      db,
      "ports-of-entry",
      stateOfEntry,
      "ports"
    );
    const q = query(portOfEntryRef);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ids = snapshot.docs.map((doc) => doc.id) || [];
      switch (formData?.stateOfEntry) {
        case "CA":
          setPortOfEntryCA([...filterOptions(citiesCAOptions), ...ids]);
          break;
        case "AZ":
          setPortOfEntryAZ([...filterOptions(citiesAZOptions), ...ids]);
          break;
        case "NM":
          setPortOfEntryNM([...filterOptions(citiesNMOptions), ...ids]);
          break;
        case "TX":
          setPortOfEntryTX([...filterOptions(citiesTXOptions), ...ids]);
          break;
        default:
          break;
      }
    });
    return () => unsubscribe();
  }, [formData?.stateOfEntry, openPortOfEntryModalLoading]);

  const handlePortOfEntrySubmit = async (e, payload) => {
    if (e) e?.preventDefault();
    setOpenPortOfEntryModalLoading(true);
    try {
      const value = payload["new-port-of-entry"];
      const portOfEntryRef = doc(
        db,
        "ports-of-entry",
        formData?.stateOfEntry,
        "ports",
        value
      );
      await setDoc(portOfEntryRef, {}, { merge: true });
      controlToast(
        true,
        `The port of entry ${value} was successfully created in the state of ${formData?.stateOfEntry}`,
        "success"
      );
      handlePortOfEntryModal();
      setOpenPortOfEntryModalLoading(false);
    } catch (error) {
      setOpenPortOfEntryModalLoading(false);
      console.error(error);
      controlToast(
        true,
        "There was an error submitting the port of entry",
        "error"
      );
    }
  };

  const glFormSettingsAddDriver = {
    onSubmit: handlePortOfEntrySubmit,
    inputs: [
      {
        label: t("plm.coverage-side-panel.add-port-of-entry.field1"),
        name: "new-port-of-entry",
        required: true,
        type: "text",
      },
    ],
    buttonLabel: t("plm.coverage-side-panel.add-port-of-entry.button"),
  };

  const handlePortOfEntryModal = () => {
    setOpenPortOfEntryModal(!openPortOfEntryModal);
  };

  const handleDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const { formDataGL, formDataAPD, formDataMTC } = useFormDataState();

  const [createGLPolicyOpen, setCreateGLPolicyOpen] = useState(false);
  const [createGLPolicyLoading, setCreateGLPolicyLoading] = useState(false);
  const [createMTCPolicyOpen, setCreateMTCPolicyOpen] = useState(false);
  const [createMTCPolicyLoading, setCreateMTCPolicyLoading] = useState(false);
  const [createAPDPolicyOpen, setCreateAPDPolicyOpen] = useState(false);
  const [createAPDPolicyLoading, setCreateAPDPolicyLoading] = useState(false);
  const [coverageModalGLValue, setCoverageModalGLValue] = useState({
    hasGLCoverage: null,
  });
  const [coverageModalAPDValue, setCoverageModalAPDValue] = useState({
    hasAPDCoverage: null,
  });
  const [coverageModalMTCValue, setCoverageModalMTCValue] = useState({
    hasMTCCoverage: null,
  });

  const handleOpenGLModalValue = (e) => {
    const { checked, id } = e.target;
    setCoverageModalGLValue({
      ...coverageModalGLValue,
      [id]: checked,
    });
  };

  const handleOpenAPDModalValue = (e) => {
    const { checked, id } = e.target;
    setCoverageModalAPDValue({
      ...coverageModalAPDValue,
      [id]: checked,
    });
  };

  const handleOpenMTCModalValue = (e) => {
    const { checked, id } = e.target;
    setCoverageModalMTCValue({
      ...coverageModalMTCValue,
      [id]: checked,
    });
  };

  useEffect(() => {
    if (
      coverageModalGLValue.hasGLCoverage === true ||
      coverageModalGLValue.hasGLCoverage === false
    ) {
      setCreateGLPolicyOpen(true);
    }
    if (
      coverageModalAPDValue.hasAPDCoverage === true ||
      coverageModalAPDValue.hasAPDCoverage === false
    ) {
      setCreateAPDPolicyOpen(true);
    }
    if (
      coverageModalMTCValue.hasMTCCoverage === true ||
      coverageModalMTCValue.hasMTCCoverage === false
    ) {
      setCreateMTCPolicyOpen(true);
    }
  }, [
    coverageModalGLValue.hasGLCoverage,
    coverageModalAPDValue.hasAPDCoverage,
    coverageModalMTCValue.hasMTCCoverage,
  ]);

  const handleCloseCreateGLPolicy = () => {
    setCreateGLPolicyOpen(false);
    setCoverageModalGLValue({
      ...coverageModalGLValue,
      hasGLCoverage: null,
    });
  };

  const handleCloseCreateMTCPolicy = () => {
    setCreateMTCPolicyOpen(false);
    setCoverageModalMTCValue({
      ...coverageModalMTCValue,
      hasMTCCoverage: null,
    });
  };

  const handleCloseCreateAPDPolicy = () => {
    setCreateAPDPolicyOpen(false);
    setCoverageModalAPDValue({
      ...coverageModalAPDValue,
      hasAPDCoverage: null,
    });
  };

  const glPolicyExists = Boolean(formData?.glPolicyID);
  const apdPolicyExists = Boolean(formData?.apdPolicyID);
  const mtcPolicyExists = Boolean(formData?.mtcPolicyID);
  const glPolicyIsActive = Boolean(formDataGL?.hasGLCoverage);
  const mtcPolicyIsActive = Boolean(formDataMTC?.hasMTCCoverage);
  const apdPolicyIsActive = Boolean(formDataAPD?.hasAPDCoverage);
  const thereIsEffectiveDate = Boolean(formData?.effectiveDate);
  const isAPolicy = formData?.documentType === "Policy";

  const handleUpdateGLData = async (value) => {
    const policyRef = doc(db, "gl-policies", formDataGL.id);
    await setDoc(policyRef, value, { merge: true });
  };

  const handleUpdateMTCData = async (value) => {
    const policyRef = doc(db, "mtc-policies", formDataMTC.id);
    await setDoc(policyRef, value, { merge: true });
  };

  const handleUpdateAPDData = async (value) => {
    const policyRef = doc(db, "apd-policies", formDataAPD.id);
    await setDoc(policyRef, value, { merge: true });
  };

  const handleClickDate = (event) => {
    event.stopPropagation();
  };

  const createGLPolicySubmit = async (e, value) => {
    e.preventDefault();
    setCreateGLPolicyLoading(true);
    const effectiveDate = value?.["effective-date"];
    const expirationDate = calculateExpirationDateTimestamp(
      effectiveDate,
      isLeapYear(effectiveDate) ? 366 : 365
    );
    try {
      if (!glPolicyExists) {
        const policyData = {
          hasGLCoverage: true,
          coverageRequested: true,
          alPolicyID: formData.id,
          glPolicy: formData?.glPolicy || "",
          glPolicyNo: formData?.glPolicyNo || "",
          createdAt: new Date(),
          authorID: auth.currentUser.uid,
          author: auth.currentUser.displayName,
          documentType: formData?.documentType,
          status: "Unsubmitted",
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
          program: formData?.program || "",
        };
        if (formData?.documentType === "Policy") {
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        } else if (
          formData?.documentType !== "Policy" &&
          !thereIsEffectiveDate
        ) {
          setCreateGLPolicyLoading(false);
          controlToast(
            true,
            "Activating a GL policy requires to have an effective date on the AL Coverage",
            "error"
          );
          handleCloseCreateGLPolicy();
          return;
        } else {
          const effectiveDate = new Date(formData?.effectiveDate?.toDate?.());
          const expirationDate = new Date(effectiveDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        }
        const glPolicyRef = await addDoc(
          collection(db, "gl-policies"),
          policyData
        );
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasGLCoverage: true,
            glPolicyID: glPolicyRef.id,
          },
          { merge: true }
        );
      } else {
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasGLCoverage: !Boolean(formDataGL.hasGLCoverage),
          },
          { merge: true }
        );
        const policyDataGL = {
          hasGLCoverage: !Boolean(formDataGL.hasGLCoverage),
        };
        if (Boolean(formDataGL.hasGLCoverage)) {
          policyDataGL["effectiveDate"] = effectiveDate;
          policyDataGL["expirationDate"] = expirationDate;
        }
        const glPolicyRef = doc(db, "gl-policies", formDataGL.id);
        await setDoc(
          glPolicyRef,
          {
            hasGLCoverage: !Boolean(formDataGL.hasGLCoverage),
          },
          { merge: true }
        );
      }
      setCreateGLPolicyLoading(false);
      controlToast(
        true,
        !glPolicyExists
          ? "The policy was created successfully"
          : Boolean(formDataGL?.hasGLCoverage)
          ? "The policy was deleted successfully"
          : "The policy was updated successfully",
        "success"
      );
      handleCloseCreateGLPolicy();
    } catch (error) {
      console.error(error);
      setCreateGLPolicyLoading(false);
      controlToast(true, "There was an error creating the policy", "error");
    }
  };

  const createAPDPolicySubmit = async (e, value) => {
    console.log("value", value);
    e.preventDefault();
    setCreateAPDPolicyLoading(true);
    const effectiveDate = value?.["effective-date"];
    const expirationDate = calculateExpirationDateTimestamp(
      effectiveDate,
      isLeapYear(effectiveDate) ? 366 : 365
    );
    try {
      if (!apdPolicyExists) {
        const policyData = {
          hasAPDCoverage: true,
          coverageRequested: true,
          alPolicyID: formData.id,
          apdPolicy: formData?.apdPolicy || "",
          apdPolicyNo: "Unknown",
          createdAt: new Date(),
          authorID: auth.currentUser.uid,
          author: auth.currentUser.displayName,
          documentType: formData?.documentType,
          status: "Unsubmitted",
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
          program: formData?.program || "",
        };
        if (formData?.documentType === "Policy") {
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        } else if (
          formData?.documentType !== "Policy" &&
          !thereIsEffectiveDate
        ) {
          setCreateAPDPolicyLoading(false);
          controlToast(
            true,
            "Activating a APD policy requires to have an effective date on the AL Coverage",
            "error"
          );
          handleCloseCreateAPDPolicy();
          return;
        } else {
          const effectiveDate = new Date(formData?.effectiveDate?.toDate?.());
          const expirationDate = new Date(effectiveDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        }
        const apdPolicyRef = await addDoc(
          collection(db, "apd-policies"),
          policyData
        );
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasAPDCoverage: true,
            apdPolicyID: apdPolicyRef.id,
          },
          { merge: true }
        );
      } else {
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasAPDCoverage: !Boolean(formDataAPD.hasAPDCoverage),
          },
          { merge: true }
        );
        const policyDataAPD = {
          hasAPDCoverage: !Boolean(formDataAPD.hasAPDCoverage),
        };
        if (Boolean(formDataAPD.hasAPDCoverage)) {
          policyDataAPD["effectiveDate"] = effectiveDate;
          policyDataAPD["expirationDate"] = expirationDate;
        }
        const apdPolicyRef = doc(db, "apd-policies", formDataAPD.id);
        await setDoc(
          apdPolicyRef,
          {
            hasAPDCoverage: !Boolean(formDataAPD.hasAPDCoverage),
          },
          { merge: true }
        );
      }
      setCreateAPDPolicyLoading(false);
      controlToast(
        true,
        !apdPolicyExists
          ? "The policy was created successfully"
          : Boolean(formDataAPD?.hasAPDCoverage)
          ? "The policy was deleted successfully"
          : "The policy was updated successfully",
        "success"
      );
      handleCloseCreateAPDPolicy();
    } catch (error) {
      console.error(error);
      setCreateAPDPolicyLoading(false);
      controlToast(true, "There was an error creating the policy", "error");
    }
  };

  const createMTCPolicySubmit = async (e, value) => {
    e.preventDefault();
    setCreateMTCPolicyLoading(true);
    const effectiveDate = value?.["effective-date"];
    const expirationDate = calculateExpirationDateTimestamp(
      effectiveDate,
      isLeapYear(effectiveDate) ? 366 : 365
    );
    try {
      if (!mtcPolicyExists) {
        const policyData = {
          hasMTCCoverage: true,
          coverageRequested: true,
          alPolicyID: formData.id,
          mtcPolicy: formData?.mtcPolicy || "",
          mtcPolicyNo: "Unknown",
          createdAt: new Date(),
          authorID: auth.currentUser.uid,
          author: auth.currentUser.displayName,
          documentType: formData?.documentType,
          status: "Unsubmitted",
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
          program: formData?.program || "",
        };
        if (formData?.documentType === "Policy") {
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        } else if (
          formData?.documentType !== "Policy" &&
          !thereIsEffectiveDate
        ) {
          setCreateMTCPolicyLoading(false);
          controlToast(
            true,
            "Activating a MTC policy requires to have an effective date on the AL Coverage",
            "error"
          );
          handleCloseCreateMTCPolicy();
          return;
        } else {
          const effectiveDate = new Date(formData?.effectiveDate?.toDate?.());
          const expirationDate = new Date(effectiveDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          policyData["effectiveDate"] = effectiveDate;
          policyData["expirationDate"] = expirationDate;
        }
        const mtcPolicyRef = await addDoc(
          collection(db, "mtc-policies"),
          policyData
        );
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasMTCCoverage: true,
            mtcPolicyID: mtcPolicyRef.id,
          },
          { merge: true }
        );
      } else {
        const policyRef = doc(db, "policy-applications", formData?.id);
        await setDoc(
          policyRef,
          {
            hasMTCCoverage: !Boolean(formDataMTC.hasMTCCoverage),
          },
          { merge: true }
        );
        const policyDataMTC = {
          hasMTCCoverage: !Boolean(formDataMTC.hasMTCCoverage),
        };
        if (Boolean(formDataMTC.hasMTCCoverage)) {
          policyDataMTC["effectiveDate"] = effectiveDate;
          policyDataMTC["expirationDate"] = expirationDate;
        }
        const mtcPolicyRef = doc(db, "mtc-policies", formDataMTC.id);
        await setDoc(
          mtcPolicyRef,
          {
            hasMTCCoverage: !Boolean(formDataMTC.hasMTCCoverage),
          },
          { merge: true }
        );
      }
      setCreateMTCPolicyLoading(false);
      controlToast(
        true,
        !mtcPolicyExists
          ? "The policy was created successfully"
          : Boolean(formDataMTC?.hasMTCCoverage)
          ? "The policy was deleted successfully"
          : "The policy was updated successfully",
        "success"
      );
      handleCloseCreateMTCPolicy();
    } catch (error) {
      console.error(error);
      setCreateMTCPolicyLoading(false);
      controlToast(true, "There was an error creating the policy", "error");
    }
  };

  const initialEffectiveDate = useMemo(() => {
    if (formData?.hasALCoverage === undefined) {
      return null;
    }
    return formData?.hasALCoverage
      ? new Date(formData?.effectiveDate?.toDate?.())
      : new Date();
  }, [formData?.hasALCoverage, formData?.effectiveDate]);

  const inputs = useMemo(() => {
    if (!initialEffectiveDate) return [];
    return [
      {
        label: t("plm.coverage-side-panel.effective-date"),
        type: "date",
        name: "effective-date",
        minDate: initialEffectiveDate,
      },
    ];
  }, [initialEffectiveDate]);

  const glFormSettings = {
    onSubmit: createGLPolicySubmit,
    inputs,
    buttonLabel: t("plm.coverage-side-panel.add-new-gl-policy"),
  };

  const apdFormSettings = {
    onSubmit: createAPDPolicySubmit,
    inputs,
    buttonLabel: t("plm.coverage-side-panel.add-new-apd-policy"),
  };

  const mtcFormSettings = {
    onSubmit: createMTCPolicySubmit,
    inputs,
    buttonLabel: t("plm.coverage-side-panel.add-new-mtc-policy"),
  };

  const filingsBreadcrumbs = [
    { label: t("plm.coverage-side-panel.filing-table.filings") },
    { label: t("plm.coverage-side-panel.filing-table.historical") },
  ];

  return (
    <main>
      {glPolicyIsActive || !isAPolicy ? (
        <>
          <Popup
            maxWidth="50%"
            isOpen={createGLPolicyOpen}
            onRequestClose={handleCloseCreateGLPolicy}
          >
            <h2 className="text-xl font-bold mt-2 mb-4">
              Are you sure you would like to{" "}
              {glPolicyIsActive ? "Remove" : "Add"} General Liability coverage?
            </h2>
            <div>
              <BlueButton
                text={`${glPolicyIsActive ? "Delete" : "Add"} GL Policy`}
                onClickFunction={createGLPolicySubmit}
                className="w-[100%]"
                icon="no-icon"
                disabled={createGLPolicyLoading}
              />
            </div>
          </Popup>
          <Popup
            maxWidth="50%"
            isOpen={createMTCPolicyOpen}
            onRequestClose={handleCloseCreateMTCPolicy}
          >
            <h2 className="text-xl font-bold mt-2 mb-4">
              Are you sure you would like to{" "}
              {mtcPolicyIsActive ? "Remove" : "Add"} Motor Truck Cargo coverage?
            </h2>
            <div>
              <BlueButton
                text={`${mtcPolicyIsActive ? "Delete" : "Add"} MTC Policy`}
                onClickFunction={createMTCPolicySubmit}
                className="w-[100%]"
                icon="no-icon"
                disabled={createMTCPolicyLoading}
              />
            </div>
          </Popup>
          <Popup
            maxWidth="50%"
            isOpen={createAPDPolicyOpen}
            onRequestClose={handleCloseCreateAPDPolicy}
          >
            <h2 className="text-xl font-bold mt-2 mb-4">
              Are you sure you would like to{" "}
              {apdPolicyIsActive ? "Remove" : "Add"} Auto Physical Damage
              coverage?
            </h2>
            <div>
              <BlueButton
                text={`${apdPolicyIsActive ? "Delete" : "Add"} APD Policy`}
                onClickFunction={createAPDPolicySubmit}
                className="w-[100%]"
                icon="no-icon"
                disabled={createAPDPolicyLoading}
              />
            </div>
          </Popup>
        </>
      ) : (
        <>
          <FormPopup
            isOpen={createGLPolicyOpen}
            onRequestClose={handleCloseCreateGLPolicy}
            title={t("plm.coverage-side-panel.gl-coverage-confirmation")}
            glFormSettings={glFormSettings}
            loading={createGLPolicyLoading}
            zIndex={1001}
          />
          <FormPopup
            isOpen={createMTCPolicyOpen}
            onRequestClose={handleCloseCreateMTCPolicy}
            title={t("plm.coverage-side-panel.mtc-coverage-confirmation")}
            glFormSettings={mtcFormSettings}
            loading={createMTCPolicyLoading}
            zIndex={1001}
          />
          <FormPopup
            isOpen={createAPDPolicyOpen}
            onRequestClose={handleCloseCreateAPDPolicy}
            title={t("plm.coverage-side-panel.apd-coverage-confirmation")}
            glFormSettings={apdFormSettings}
            loading={createAPDPolicyLoading}
            zIndex={1001}
          />
        </>
      )}
      <FormPopup
        isOpen={openPortOfEntryModal}
        glFormSettings={glFormSettingsAddDriver}
        onRequestClose={handlePortOfEntryModal}
        title={t("plm.coverage-side-panel.add-port-of-entry.title")}
      />
      <Popup
        isOpen={openFilingsModal}
        onRequestClose={handleOpenFilingsModal}
        maxWidth="700px"
      >
        <div>
          <Breadcrumbs items={filingsBreadcrumbs} />
          <h1 className="text-3xl mt-2 mb-2  font-semibold">
            {insuredInfo.company}
          </h1>
          <DynamicTable
            skeletonLoader={skeletonTableLoader}
            columns={columnsForFilings}
            tableData={tableData}
            handleOpenEditMode={handleOpenEditMode}
            filterKeys={filterKeysForFilings}
            formData={formData}
            sortDefault={{ key: "creationDate", direction: "desc" }}
            // name="proofOfFilingsList"
          />
        </div>
      </Popup>
      {!insuredInfo.company ? (
        <TableSkeleton rows={20} columns={1} />
      ) : (
        <>
          <div className="card shadow-md">
            <div className="card-header bg-[#072a48]">
              <p className={`text-xl text-white`}>
                {language === "en" && (
                  <>
                    {t(`plm.coverage-side-panel.status.${formData.status}`)}{" "}
                    {t(
                      `plm.coverage-side-panel.documentType.${formData.documentType}`
                    )}
                  </>
                )}
                {language === "es" && (
                  <>
                    {t(
                      `plm.coverage-side-panel.documentType.${formData.documentType}`
                    )}{" "}
                    {t(`plm.coverage-side-panel.status.${formData.status}`)}
                  </>
                )}
              </p>
            </div>
            <section className="card-body">
              <InsuredInfo
                insuredInfo={insuredInfo}
                setDisplayCompanyDetails={setDisplayCompanyDetails}
              />
              {formData.documentType === "Policy" && (
                <BindInfo
                  setDisplayBindDetailsInfo={setDisplayBindDetailsInfo}
                />
              )}
              <fieldset>
                <AgentSelection formData={formData} policyID={policyID} />

                <div className="input-container">
                  <SelectDropdown
                    label={t("plm.coverage-side-panel.state-of-entry")}
                    policyID={policyID}
                    formData={formData}
                    options={dropdownOptions.stateOfEntry}
                    fieldName={"stateOfEntry"}
                    labelTitle={`This value is automatically assigned based on the insured's physical address.`}
                  />
                </div>
                {formData.stateOfEntry === "CA" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.port-of-entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryCA
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "AZ" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.port-of-entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryAZ
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "NM" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.port-of-entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryNM
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "TX" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.port-of-entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryTX
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {Boolean(formData.stateOfEntry) && isAdminOrUnderwriter ? (
                  <div>
                    <Typography
                      component="span"
                      onClick={(e) => {
                        e.preventDefault();
                        if (formData.documentType === "Application") {
                          handlePortOfEntryModal();
                        }
                      }}
                      sx={{
                        color: "#0c4a6e",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        cursor:
                          formData.documentType === "Application"
                            ? "pointer"
                            : "not-allowed",
                        textDecoration:
                          formData.documentType === "Application"
                            ? "underline"
                            : "none",
                        opacity:
                          formData.documentType === "Application" ? 1 : 0.5,
                        pointerEvents:
                          formData.documentType === "Application"
                            ? "auto"
                            : "none",
                        "&:hover": {
                          textDecoration:
                            formData.documentType === "Application"
                              ? "underline"
                              : "none",
                        },
                      }}
                    >
                      <AddLocationIcon sx={{ marginRight: "8px" }} />
                      {/* Add Port Of Entry */}
                      {t("plm.coverage-side-panel.add-port-of-entry.button")}
                    </Typography>
                  </div>
                ) : null}
              </fieldset>
            </section>
            <div
              onClick={(e) => setShowCoverages(!showCoverages)}
              className="card-header bg-gradient-to-r from-stone-200 to-neutral-300"
            >
              <p
                className={`text-lg text-[#072a48]`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Coverages & Filings */}
                {t("plm.coverage-side-panel.coverage-and-filings")}
                {/* TODO: add this to other collapsibles */}
                <ExpandCollapseArrow
                  show={showCoverages}
                  setShow={setShowCoverages}
                />
              </p>
            </div>
            {showCoverages && (
              <section className="card-body">
                <fieldset>
                  <div className="mb-2">
                    <CoverageCheckboxes
                      policyID={policyID}
                      formData={formData}
                      formDataGL={formDataGL}
                      setFormData={setFormData}
                      handleOpenMTCModalValue={handleOpenMTCModalValue}
                      handleOpenAPDModalValue={handleOpenAPDModalValue}
                      handleOpenGLModalValue={handleOpenGLModalValue}
                      handleUpdateGLData={handleUpdateGLData}
                      handleDate={handleDate}
                      handleClickDate={handleClickDate}
                      formDataAPD={formDataAPD}
                      formDataMTC={formDataMTC}
                      handleUpdateMTCData={handleUpdateMTCData}
                      handleUpdateAPDData={handleUpdateAPDData}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.coverage-single-limit")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.coverageSingleLimit}
                      fieldName={"coverageSingleLimit"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  {isAdminOrUnderwriter && (
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.product")}
                      fieldName={"product"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.product}
                      disabled={formData.documentType !== "Application"}
                    />
                  )}
                  <div className="my-3">
                    <label className="text-capitalize mt-1 mb-2">
                      {t("plm.coverage-side-panel.filings")}
                    </label>
                    <Typography
                      component="span"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenFilingsModal();
                      }}
                      sx={{
                        color: "#0c4a6e",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#072a48",
                        },
                      }}
                    >
                      <SummarizeIcon sx={{ marginRight: "8px" }} />
                      {t("plm.coverage-side-panel.view-historical-filings")}
                    </Typography>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          label="91X (Federal)"
                          field={"has91X"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {formData.stateOfEntry === "TX" && (
                          <div className="input-container">
                            <Checkbox
                              label="Form E (State)"
                              field={"hasFormE"}
                              policyID={policyID}
                              setFormData={setFormData}
                              formData={formData}
                              disabled={formData.documentType !== "Application"}
                            />
                          </div>
                        )}
                        {formData.stateOfEntry === "CA" && (
                          <div className="input-container">
                            <Checkbox
                              label="MCP-65 (State)"
                              field={"hasMCP65"}
                              policyID={policyID}
                              setFormData={setFormData}
                              formData={formData}
                              disabled={formData.documentType !== "Application"}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.new-renewal.button")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.newOrRenewal}
                      fieldName={"newOrRenewal"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.program.button")}
                      fieldName={"program"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.program}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.cargo-type.button")}
                      fieldName={"cargoType"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.cargoType}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("plm.coverage-side-panel.payment-plan.button")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.paymentPlan}
                      fieldName={"paymentPlan"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <Checkbox
                    label={t("plm.coverage-side-panel.payment-confirmed")}
                    field={"paymentConfirmed"}
                    policyID={policyID}
                    setFormData={setFormData}
                    formData={formData}
                  />
                </fieldset>
              </section>
            )}
          </div>
        </>
      )}
    </main>
  );
}
