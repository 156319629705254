import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import History from ".";
import useTranslations from "../../../Context/actions/useTranslations";

export default function MiniHistory(props) {
  const numberOfEntriesToShow =
    props.formData.documentType === "Policy" ? 8 : 5;
  const [history, setHistory] = useState([]);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);

  // Manejo del modal de historial completo
  const handleOpenHistoryModal = () => {
    setOpenHistoryModal(true);
  };
  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
  };

  // Traducciones
  const { t } = useTranslations();

  // Escucha en tiempo real los cambios en Firestore
  useEffect(() => {
    if (!props.showOverviewAndHistory) {
      return () => {};
    }

    const q = query(
      collection(db, "system-activity"),
      where("policyID", "==", props.policyID)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });

      // Sort entries by date (newest to oldest)
      values.sort((a, b) => b.activityDate.toDate() - a.activityDate.toDate());

      setHistory(values);
    });

    // Cleanup subscription when component unmounts
    return () => {
      unsubscribe();
    };
  }, [props.showOverviewAndHistory, props.policyID]);

  return (
    <section className="card h-100">
      <div className="card-header text-lg text-white bg-gradient-to-r from-[#0c4a6e] to-[#096384]">
        <button
          onClick={() =>
            props.setShowOverviewAndHistory(!props.showOverviewAndHistory)
          }
        >
          {t("plm.history.title")}
          <ExpandCollapseArrow
            show={props.showOverviewAndHistory}
            setShow={props.setShowOverviewAndHistory}
          />
        </button>
        <button
          onClick={handleOpenHistoryModal}
          className="float-right text-sky-900 hover:text-[#072a48] text-white"
        >
          {t("plm.history.view-all")}
        </button>
      </div>
      {props.showOverviewAndHistory && (
        <div className="card-body bg-gradient-to-r from-slate-50 to-stone-200">
          <div>
            <ul>
              {[...history].slice(0, numberOfEntriesToShow).map((entry) => {
                return (
                  <li key={entry.id} title={entry.description}>
                    {t(entry.shortDescription)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      <History
        open={openHistoryModal}
        onClose={handleCloseHistoryModal}
        policyID={props.policyID}
      />
    </section>
  );
}
