import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { t } from "i18next";
import Checkbox from "../Quotes/APDandMTC/APD/Checkbox";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import KPBrokersInfo from "../KPBrokersInfo";
import EditDropdownModal from "./EditDropdownModal"; // Import the modal
import { Button } from "@mui/material";
import { numberWithCommas } from "../../../utils/helpers";
import useTranslations from "../../../Context/actions/useTranslations";
import updatePolicyField from "../../utils/updatePolicyField";
import NonStandardClauses from "./NonStandardClauses";
import Deductible from "./Deductible";

export default function APD({
  userInfo,
  policyID,
  formData,
  setFormData,
  isAdminOrUnderwriter,
  loadedPolicies,
  policies,
}) {
  const { t } = useTranslations();

  const [dropdownOptions, setDropdownOptions] = useState({
    targetDeductibles: [],
    anyOneLossLimits: [],
    towingStorageAndCleanUpLimits: [],
    nonOwnedHiredAutoLimits: [],
    trailerInterchangeLimits: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  const [anyOneLossLimit, setAnyOneLossLimit] = useState(
    formData?.apdDetails?.anyOneLossLimit
      ? `$${numberWithCommas(formData.apdDetails.anyOneLossLimit)}`
      : ""
  );

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.apd.title") },
  ];

  const disabledRule = formData.documentType !== "Application";

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", "apdDropdowns"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(doc.data());
          console.log(doc.data());
        } else {
          console.log("No dropdown options found.");
        }
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDropdownChange = async (field, value) => {
    const apdDetails = {
      ...(formData.apdDetails || {}),
      [field]: value,
    };

    const policyRef = doc(db, "policy-applications", policyID);
    await updateDoc(policyRef, {
      apdDetails,
    });
  };

  const handleEditClick = (field) => {
    setSelectedField(field);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedField(null);
  };

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-3 md:mb-0">
          {t("plm.apd.title")}
        </span>
      </div>

      <KPBrokersInfo
        activePolicies={policies.activeAPDPolicies}
        userInfo={userInfo}
      />

      {loadedPolicies && !(policies.activeAPDPolicies.length > 0) && (
        <>
          {!formData?.hasAPDCoverage ? (
            <p className="mb-3">
              {t("plm.apd.description1")}{" "}
              {`plm.status.${formData.documentType}`}{" "}
              {t("plm.apd.description2")}
            </p>
          ) : (
            <fieldset disabled={disabledRule}>
              <h1 className="text-lg mt-3">{t("plm.apd.deductible")}</h1>

              <Deductible
                formData={formData}
                setFormData={setFormData}
                dropdownOptions={dropdownOptions}
                policyID={policyID}
                disabledRule={disabledRule}
                isAdminOrUnderwriter={isAdminOrUnderwriter}
                handleDropdownChange={handleDropdownChange}
                handleEditClick={handleEditClick}
                t={t}
              />

              <NonStandardClauses
                disabledRule={disabledRule}
                formData={formData}
                setFormData={setFormData}
                policyID={policyID}
                dropdownOptions={dropdownOptions}
                handleEditClick={handleEditClick}
                handleDropdownChange={handleDropdownChange}
                isAdminOrUnderwriter={isAdminOrUnderwriter}
                t={t}
              />
            </fieldset>
          )}
        </>
      )}

      {/* Include the EditDropdownModal component */}
      <EditDropdownModal
        open={isModalOpen}
        onClose={handleCloseModal}
        field={selectedField}
      />
    </div>
  );
}
