import React from "react";
import useTranslations from "../../../Context/actions/useTranslations";

export default function Header({ userInfo, formData, isNewQuote }) {
  const { t } = useTranslations();
  return (
    <section>
      <h1 className="text-3xl mt-2 font-semibold md:mb-0">
        {!isNewQuote
          ? t(`plm.quotes.titles.Quote Request`)
          : t(`plm.quotes.titles.Quote Request`)}
      </h1>
      {userInfo.role === "Agent" && !isNewQuote ? (
        <>
          <p className="mt-3 mb-3">{t("plm.quotes.description1")}</p>
        </>
      ) : (
        <>
          {!isNewQuote && (
            <p className="mt-3 mb-3">
              {t("plm.quotes.description2")}{" "}
              {formData.documentType !== "Policy" &&
                ` ${t("plm.quotes.description3")} `}
              {t("plm.quotes.description4")}
            </p>
          )}
        </>
      )}
      {formData.documentType === "Application" && !isNewQuote && (
        <p className="mt-2 mb-3">{t("plm.quotes.description5")}</p>
      )}
    </section>
  );
}
