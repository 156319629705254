import React from "react";
import { TEMPORARILY_CLIENT_ROUTE } from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import { 
  Description,
  People,
  LocalShipping,
  Train,
  Inventory,
  Assignment
} from '@mui/icons-material';
import useUsersState from "../../../Context/actions/useUsersState";
import useClientState from "../../../Context/actions/useClientState";

export default function ClientManageMenu() {
  const { user } = useUsersState();
  const email = user?.email;
  const { client } = useClientState()
  const selectedPolicy = client?.clientRoute

  return (
    <>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=print-policy&email=${email}`}>
        <Description className="mr-2" /> Print My Policy
      </NavDropdown.Item>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=drivers&email=${email}`}>
        <People className="mr-2" /> Drivers
      </NavDropdown.Item>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=power-units&email=${email}`}>
        <Train className="mr-2" /> Power Units
      </NavDropdown.Item>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=trailers&email=${email}`}>
        <LocalShipping className="mr-2" /> Trailers
      </NavDropdown.Item>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=commodities&email=${email}`}>
        <Inventory className="mr-2" /> Commodities
      </NavDropdown.Item>
      <NavDropdown.Item href={`${TEMPORARILY_CLIENT_ROUTE}/${selectedPolicy?.id}?module=questionnaire&email=${email}`}>
        <Assignment className="mr-2" /> Questionnaire
      </NavDropdown.Item>
    </>
  );
}