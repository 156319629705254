import { useContext } from "react";
import { AppContext, initialState } from "../AppContext";
import {
  SET_FORM_DATA,
  SET_FORM_DATA_GL,
  SET_FORM_DATA_MTC,
  SET_FORM_DATA_APD,
  SET_QUOTE_SELECTED,
} from "../types";

const useFormDataState = () => {
  const { state, dispatch } = useContext(AppContext);
  const {
    formData,
    formDataGL,
    formDataMTC,
    formDataAPD,
    quoteSelected
  } = state || initialState;

  const setFormDataState = (result) => {
    dispatch({ type: SET_FORM_DATA, payload: result });
  };

  const setFormDataGLState = (result) => {
    dispatch({ type: SET_FORM_DATA_GL, payload: result });
  };

  const setFormDataMTCState = (result) => {
    dispatch({ type: SET_FORM_DATA_MTC, payload: result });
  };

  const setFormDataAPDState = (result) => {
    dispatch({ type: SET_FORM_DATA_APD, payload: result });
  };

  const setQuoteSelectedState = (result) => {
    dispatch({ type: SET_QUOTE_SELECTED, payload: result });
  };

  return {
    formData,
    formDataGL,
    formDataMTC,
    formDataAPD,
    quoteSelected,
    setFormDataState,
    setFormDataGLState,
    setFormDataMTCState,
    setFormDataAPDState,
    setQuoteSelectedState
  };
};

export default useFormDataState;
