import React, { useState, useEffect } from "react";
import Popup from "../../../generals/Popup";
import {
  useMediaQuery,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { auth, db } from "../../../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import DatePicker from "react-datepicker";

export default function AddDriverTrainingModal({
  open,
  setOpen,
  onClose,
  driver,
  editMode,
  editData,
  userInfo,
  formData,
  isTemporalUrl,
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const initialFormData = {
    trainingProvider: "",
    courseComplete: false,
    completionDate: "",
    courseCategory: "",
    insuredPartner: "",
    courseTitles: [""], // Array for multiple course titles
    studentName: `${driver?.driverFirst || ""} ${driver?.driverLast || ""}`,
    driversLicenseNumber: driver?.licenseNumber || "",
    assignedByUser: auth.currentUser?.uid || "",
    assignedbyUserName: auth.currentUser?.displayName || "",
    assignedOnDate: new Date(),
  };

  const [trainingFormData, setTrainingFormData] = useState(initialFormData);

  useEffect(() => {
    if (editMode && editData) {
      setTrainingFormData((prevData) => ({
        ...prevData,
        ...editData,
        completionDate: editData.completionDate
          ? editData.completionDate.toDate()
          : null,
      }));
    } else {
      setTrainingFormData(initialFormData);
    }
  }, [editMode, editData]);

  const resetForm = () => {
    setTrainingFormData(initialFormData);
  };

  const handleChange = (field, value) => {
    let insuredsInfo;
    if (field === "insuredPartner") {
      const idToFind = value;
      insuredsInfo = insureds.find((insured) => insured.insuredID === idToFind);
      setTrainingFormData((prev) => ({
        ...prev,
        [field]: value,
        insuredsInfo,
      }));
    } else setTrainingFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const promises = trainingFormData.courseTitles.map(async (title) => {
      const data = {
        ...trainingFormData,
        courseTitle: title,
      };
      delete data.courseTitles; // Remove the array before saving
      if (!editMode) {
        const docRef = await addDoc(collection(db, "driver-trainings"), data);
      } else {
        await updateDoc(doc(db, "driver-trainings", editData.id), data);
      }
    });

    await Promise.all(promises);
    resetForm();
    onClose();
  };

  const [courseCategories, setCourseCategories] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "loss-control", "driverTraining", "courseCategories"),
      (snapshot) => {
        const categories = snapshot.docs.map((doc) => doc.data().name);
        setCourseCategories(categories);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "loss-control", "driverTraining", "courses"),
      (snapshot) => {
        const courses = snapshot.docs.map((doc) => doc.data().name);
        setCourses(courses.sort((a, b) => a.localeCompare(b))); // Sort courses alphabetically
      }
    );

    return () => unsubscribe();
  }, []);

  const addCourseField = () => {
    setTrainingFormData((prev) => ({
      ...prev,
      courseTitles: [...prev.courseTitles, ""],
    }));
  };

  const removeCourseField = (index) => {
    const newCourseTitles = [...trainingFormData.courseTitles];
    newCourseTitles.splice(index, 1); // Remove the specific course
    setTrainingFormData((prev) => ({
      ...prev,
      courseTitles: newCourseTitles,
    }));
  };

  const handleCourseTitleChange = (index, value) => {
    const newCourseTitles = [...trainingFormData.courseTitles];
    newCourseTitles[index] = value;
    setTrainingFormData((prev) => ({
      ...prev,
      courseTitles: newCourseTitles,
    }));
  };

  // get list of insureds
  const [insureds, setInsureds] = useState([]);
  const getInsureds = async () => {
    // if it's a temporal URL, only get the insured from the formData.insuredID
    let policyIDs = [];
    if (isTemporalUrl) {
      const policyRef = doc(db, "policy-applications", formData.id);
      const policyDoc = await getDoc(policyRef);
      const policy = policyDoc.data();
      policyIDs = [policy.id];
    } else {
      policyIDs = driver.policiesDetails.map((policy) => policy.id);
    }

    const values = [];
    policyIDs.forEach(async (id) => {
      const docRef = doc(db, "policy-applications", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const insuredID = data.insuredID;
        const insuredDocRef = doc(
          db,
          "agencies",
          userInfo.agencyID,
          "insureds",
          insuredID
        );
        const insuredDocSnap = await getDoc(insuredDocRef);
        let insured;
        let insuredData;
        if (insuredDocSnap.exists()) {
          insuredData = insuredDocSnap.data();
          insured = insuredData.company;
          const insuredID = insuredDocSnap.id;
          const address = insuredData.address;
          const city = insuredData.city;
          const state = insuredData.state;
          const zip = insuredData.zip;

          if (values.some((value) => (value.insured = insured))) {
            return;
          } else {
            values.push({ insured, insuredID, address, city, state, zip });
          }
        } else {
          console.log("no insured found");
        }
      } else {
        console.log("no doc found");
      }
      setInsureds(values);
    });
  };

  useEffect(() => {
    getInsureds();
  }, []);

  return (
    <div>
      <Popup
        isOpen={open}
        onRequestClose={onClose}
        maxWidth={isMobileScreen ? "100%" : "400px"}
        zIndex={1000}
        contentLabel={"Add Driver Training"}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Driver Training
          </Typography>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Insured Partner</InputLabel>
            <Select
              value={trainingFormData.insuredPartner}
              onChange={(e) => handleChange("insuredPartner", e.target.value)}
              label="Insured Partner"
            >
              {insureds.map((insured) => (
                <MenuItem key={insured.insured} value={insured.insuredID}>
                  {insured.insured}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Training Provider</InputLabel>
            <Select
              value={trainingFormData.trainingProvider}
              onChange={(e) => handleChange("trainingProvider", e.target.value)}
              label="Training Provider"
            >
              <MenuItem value="JJ Keller">JJ Keller</MenuItem>
              <MenuItem value="Safethor">Safethor</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Course Category</InputLabel>
            <Select
              value={trainingFormData.courseCategory}
              onChange={(e) => handleChange("courseCategory", e.target.value)}
              label="Course Category"
            >
              {courseCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {trainingFormData.courseTitles.map((courseTitle, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gap={1}
              flexDirection="row"
            >
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>Course Title</InputLabel>
                <Select
                  value={courseTitle}
                  onChange={(e) =>
                    handleCourseTitleChange(index, e.target.value)
                  }
                  label="Course Title"
                >
                  {courses.map((course) => (
                    <MenuItem key={course} value={course}>
                      {course}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeCourseField(index)}
              >
                Remove
              </Button>
            </Box>
          ))}

          <Button variant="outlined" color="primary" onClick={addCourseField}>
            Add Another Course
          </Button>

          <FormControlLabel
            control={
              <Switch
                checked={trainingFormData.courseComplete}
                onChange={(e) =>
                  handleChange("courseComplete", e.target.checked)
                }
                color="primary"
              />
            }
            label="Course Complete"
          />

          {trainingFormData.courseComplete && (
            <DatePicker
              wrapperClassName="datePicker"
              selected={trainingFormData.completionDate}
              onChange={(date) => handleChange("completionDate", date)}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
            />
          )}

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Popup>
    </div>
  );
}
