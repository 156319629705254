import { useNavigate, useParams } from "react-router-dom";
import DriverDetails from "../../Components/PolicyLifecycleManager/Drivers/DriverDetails";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  collectionGroup,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import useUsersState from "../../Context/actions/useUsersState";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import LoadingScreen from "../../Components/assets/LoadingScreen";

const DriverDetailSection = () => {
  const { paramDriverRefID } = useParams();

  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState(null);
  const [formData, setFormData] = useState(null);
  const [insuredInfo, setInsuredInfo] = useState(null);
  const { user: userInfo } = useUsersState();

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(
        collectionGroup(db, "drivers"),
        where("licenseNumber", "==", paramDriverRefID)
      ),
      (querySnapshot) => {
        const values = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });
        const driverResult = values?.[0];
        if (driverResult) {
          setDriver(driverResult);
        } else {
          setDriver(null);
          setLoading(false);
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const policyID = driver?.policiesDetails?.[0]?.id;

  useEffect(() => {
    if (!policyID) return () => {};
    const formDataRef = doc(db, "policy-applications", policyID);
    const unsubscribe = onSnapshot(formDataRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setFormData({ ...docSnapshot.data(), id: docSnapshot.id });
      } else {
        setFormData(null);
        setLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [policyID]);

  const agencyID = formData?.agencyID;
  const insuredID = formData?.insuredID;

  useEffect(() => {
    if (Boolean(!agencyID || !insuredID)) return () => {};
    const formDataRef = doc(db, "agencies", agencyID, "insureds", insuredID);
    const unsubscribe = onSnapshot(formDataRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setInsuredInfo({ ...docSnapshot.data(), id: docSnapshot.id });
        setLoading(false);
      } else {
        setInsuredInfo(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [agencyID, insuredID]);

  const thereIsData = Boolean(driver && formData && insuredInfo);

  return (
    <div className="bg-white shadow-md rounded-lg p-6 m-6 border border-gray-200">
      {!thereIsData && loading ? (
        <LoadingScreen text={"Loading driver details..."} />
      ) : !thereIsData && !loading ? (
        <h1 className="text-center text-lg font-semibold text-red-500">
          Driver not found
        </h1>
      ) : (
        <DriverDetails
          formData={formData}
          userInfo={userInfo}
          selectedDriver={driver?.id}
          setShowDriverDetails={() => navigate(ACCOUNT_ROUTE)}
          setShowTable={() => {}}
          policyID={policyID}
          insuredInfo={insuredInfo}
        />
      )}
    </div>
  );
};

export default DriverDetailSection;
