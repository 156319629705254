const findTotalAdjustments = (quoteData) => {
  const percentageAdjustment =
    parseFloat(quoteData.adjustmentFormData.percentage) *
    (quoteData.adjustmentFormData.debitOrCredit === "Credit" ? -1 : 1);

  const renewalCreditAdjustment =
    parseFloat(quoteData.adjustmentFormData.renewalCredit) *
    (quoteData.adjustmentFormData.renewalCreditDebitOrCredit === "Credit" ||
    !quoteData.adjustmentFormData.renewalCreditDebitOrCredit
      ? -1
      : 1);

  const lossRatioAdjustment =
    parseFloat(quoteData.adjustmentFormData.lossRatioAdjustmentPercentage) *
    (quoteData.adjustmentFormData.lossRatioAdjustmentDebitOrCredit ===
      "Credit" || !quoteData.adjustmentFormData.lossRatioAdjustmentDebitOrCredit
      ? -1
      : 1);

  const safetyAdjustment =
    parseFloat(quoteData.adjustmentFormData.safetyAdjustmentPercentage) *
    (quoteData.adjustmentFormData.safetyAdjustmentDebitOrCredit === "Credit" ||
    !quoteData.adjustmentFormData.safetyAdjustmentDebitOrCredit
      ? -1
      : 1);

  const returnAmount =
    (percentageAdjustment +
      renewalCreditAdjustment +
      lossRatioAdjustment +
      safetyAdjustment) /
    100;

  return returnAmount;
};

export default findTotalAdjustments;
