import React, { useState } from "react";
import useTranslations from "../../../Context/actions/useTranslations";
import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import UnderwritingDetails from "./UnderwritingDetails";
import CreateNewQuote from "./CreateNewQuote";
import { auth } from "../../../firebase";
import createNewQuote from "./utils/createNewQuote";

export default function NewQuoteDetails({
  formData,
  loading,
  setLoading,
  userInfo,
  powerUnitCount,
  isNewQuote,
  setIsNewQuote,
  formDataGL,
  insuredInfo,
  setFormData,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setTruckListData,
  setShowQuoteSuccess,
  showQuoteSuccess,
  formDataAPD,
  formDataMTC
}) {
  const { t } = useTranslations();
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const insuredID = formData.insuredID;
  const agencyID = formData.agencyID;
  const policyID = formData.id;
  const staging = formData?.stagingArea?.adjustmentFormData;

  const [showAdjustmentsPanel, setShowAdjustmentsPanel] = useState(false);
  const [showUnderwritingDetailsPanel, setShowUnderwritingDetailsPanel] =
    useState(false);

  const nextStep = () => {
    setShowUnderwritingDetailsPanel(false);
    setShowAdjustmentsPanel(true);

    // scroll up to very top of createNewQuote id element
    const createNewQuoteElement = document.getElementById(
      "underwritingDetails"
    );
    createNewQuoteElement.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section>
      {formData.documentType !== "Application" &&
        formData.documentType !== "Policy" && (
          <>
            <div id="title" className="mt-2">
              <Box
                sx={{
                  mb: 3,
                  textAlign: "center",
                  p: 2,
                  borderRadius: 1,
                  border: "1px solid",
                  borderColor: "grey.300",
                  bgcolor: "grey.100",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label={t("Enter a title for your quote")}
                  variant="outlined"
                  placeholder={t("e.g., 'New Business Quote'")}
                  value={adjustmentFormData.description}
                  onChange={(e) =>
                    setAdjustmentFormData({
                      ...adjustmentFormData,
                      description: e.target.value,
                    })
                  }
                  sx={{
                    bgcolor: "white",
                    borderRadius: 1,
                    boxShadow: 1,
                    input: { fontWeight: "medium" },
                  }}
                />
              </Box>
              {!showAdjustmentsPanel && !showUnderwritingDetailsPanel && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowUnderwritingDetailsPanel(true);
                    // scroll into view
                    const underwritingDetailsElement = document.getElementById(
                      "underwritingDetails"
                    );
                    underwritingDetailsElement.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  sx={{ width: isMobileScreen ? "100%" : "auto" }}
                >
                  Continue
                </Button>
              )}
            </div>
            <div id="underwritingDetails">
              <UnderwritingDetails
                agencyID={agencyID}
                insuredID={insuredID}
                policyID={policyID}
                formData={formData}
                setFormData={setFormData}
                userInfo={userInfo}
                powerUnitCount={powerUnitCount}
                isNewQuote={isNewQuote}
                show={showUnderwritingDetailsPanel}
                setShow={setShowUnderwritingDetailsPanel}
                nextStep={nextStep}
                insuredInfo={insuredInfo}
              />
            </div>
            {/* Adjustments */}
            <div id="createNewQuote">
              <CreateNewQuote
                createNewQuote={(e, isReprint, quoteAdjustmentFormData) =>
                  {
                    createNewQuote(
                    e,
                    isReprint,
                    setLoading,
                    quoteAdjustmentFormData,
                    setAdjustmentFormData,
                    truckListData,
                    setShowQuoteSuccess,
                    formData,
                    insuredInfo,
                    auth,
                    formDataGL,
                    setIsNewQuote,
                    isNewQuote,
                    null,
                    formDataAPD,
                    formDataMTC
                  )}
                }
                loading={loading}
                setLoading={setLoading}
                adjustmentFormData={adjustmentFormData}
                setAdjustmentFormData={setAdjustmentFormData}
                truckListData={truckListData}
                setTruckListData={setTruckListData}
                show={showAdjustmentsPanel}
                setShow={setShowAdjustmentsPanel}
                formData={formData}
                showQuoteSuccess={showQuoteSuccess}
              />
            </div>
          </>
        )}
    </section>
  );
}
