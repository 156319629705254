import React, { useState } from "react";
import { Typography, Box, useMediaQuery, Button } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import Popup from "../../generals/Popup";
import axios from "axios";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import ApiService from "../../../services/southern-api/apiService";
import { pdfjs } from "react-pdf";
import RedButton from "../../assets/forms/RedButton";

const RequestSignaturesModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo,
  selectedQuote,
  signatureStatus,
}) => {
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [signerName, setSignerName] = useState(null);
  const proposalData = formData?.signedProposalData;
  const apiService = new ApiService("southern");
  const publicUrl = proposalData?.publicUrl || "";
  const envelopeId = proposalData?.envelopeId;

  const requestSignature = async () => {
    try {
      // Ensure policyID and selectedQuote are defined
      const quoteRef = await getDoc(
        doc(db, "policy-applications", policyID, "quotes", selectedQuote.id)
      );
      const quoteData = quoteRef.data();

      if (!quoteData) {
        console.error("No quote data found for the given ID.");
        return;
      }

      const urlField = quoteData.URL; // Access the URL field from quote data

      // Prepare the data object for the request
      const data = {
        signerEmail: email, // Replace with actual signer email
        signerName: signerName, // Replace with actual signer name
        url: urlField, // Include the quote URL
        policyID,
      };
      const response = await apiService.sendEnvelope(data);
    } catch (error) {
      console.error(
        "Error requesting signature:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSubmit = async (e) => {
    // TODO: put a system log when a signature is requested, also put one when a signature is completed
    e.preventDefault();
    try {
      setLoading(true);
      await requestSignature(
        e,
        formData,
        policyID,
        userAuth,
        userState,
        insuredInfo,
        email,
        selectedQuote
      );
      setLoading(false);
      setEmail("");
      setSignerName("");
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  // Set the workerSrc property for pdf.js
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Function to handle the loading of the PDF
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  async function cancelEnvelopeRequest() {
    const voidReason = "Signature request cancelled by underwriter.";
    try {
      async function callBackCancelEnvelope(data) {
        const policyRef = doc(db, "policy-applications", policyID);
        await updateDoc(policyRef, {
          signedProposalData: {
            completeDate: null,
            dateSent: null,
            envelopeId: null,
            publicUrl: null,
            status: null,
            signerName: null,
            signerEmail: null,
          },
        });
        // Update the UI or show a notification to the user
      }

      // Make the Axios POST request
      const response = await axios.post(
        `http://portal.southernstarmga.com/docusign/cancelEnvelope`,
        { envelopeId, voidReason }, // Request body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Call the callback function with the response data
      await callBackCancelEnvelope(response.data);
    } catch (error) {
      // Handle errors and log them
      console.error(
        "Error canceling envelope:",
        error.response ? error.response.data : error.message
      );
    }
  }

  const breadcrumbs = [
    { label: "Policy" },
    { label: "Quote Request" },
    { label: "Signatures" },
  ];

  return (
    <Popup
      showExpandButton={true}
      expandByDefault={false}
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Send Quote"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Breadcrumbs items={breadcrumbs} />
          <Typography className="mt-2" variant="h6" gutterBottom>
            {/* TODO: Translate this */}
            {signatureStatus === "sent"
              ? "Awaiting Signature"
              : signatureStatus === "complete"
              ? "Signature Complete"
              : "Request Signatures"}
          </Typography>
          <p className="my-3">
            {signatureStatus === "sent"
              ? "The signature request has been sent. The status will be updated once the signature is completed."
              : signatureStatus === "complete"
              ? "The requested signature has been completed. View the signed document below."
              : "Enter the email address and full name of the signer below."}
          </p>
          {signatureStatus !== "complete" && signatureStatus !== "sent" && (
            <>
              <div className="mb-2">
                <label>Email</label>
                <input
                  required
                  name="extraEmailToSend"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none"
                />
              </div>
              <div className="mb-2">
                <label>Signer Name</label>
                <input
                  required
                  name="signerName"
                  type="text"
                  value={signerName}
                  onChange={(e) => setSignerName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none"
                />
              </div>
              <ApprovalButtons
                onApprove={null}
                onDecline={onClose}
                forceLoadingAccept={loading}
                titleAccept={"Submit"}
                titleDecline={"Cancel"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={true}
              />
            </>
          )}
          {signatureStatus === "sent" && (
            <RedButton
              onClickFunction={cancelEnvelopeRequest}
              className="mt-2"
              text="Cancel Request"
              disabled={loading}
            />
          )}
          {signatureStatus === "complete" && (
            <div>
              {/* button to view document which is at signedProposalData.publicUrl */}
              <Button
                className="mt-2"
                onClick={() => window.open(publicUrl, "_blank")}
              >
                View Signed Document
              </Button>
            </div>
          )}
        </Box>
      </form>
    </Popup>
  );
};

export default RequestSignaturesModal;
