import {
    addDoc,
    collection,
    doc,
    runTransaction
} from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import updateSystemLog from "../../Components/utils/updateSystemLog";

export const createWaiverEndorsementRequest = async (
    waiverData,
    props,
    approveImmediately = false
) => {

  const {
    policyID,
    insuredInfo,
    formData
  } = props;

  try {
    const endoRef = collection(
      db,
      "policy-applications",
      policyID,
      "endorsements"
    )
    const countersDocRef = doc(
        db,
        "unique-numbers",
        "endorsements"
    )
    const auth = getAuth();

    let counter = ''
    let addId = ''
    const result = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });
      counter = newCounter
      let payload = {
        endorsementNumber: String(newCounter),
        type: "Add Waiver Of Subrogation",
        data: waiverData,
        dateCreated: new Date(),
        policyID,
        status: "Submitted",
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        endoEffectiveDate: waiverData.effectiveDate,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
      }
      if (approveImmediately) {
        payload['issuedDate'] = new Date()
        payload['documentType'] = 'Endorsement'
        payload['status'] = 'Approved'
      }
      if (Boolean(formData?.alPolicyNo)) {
        payload['alPolicyNo'] = formData.alPolicyNo
      }
      if (Boolean(formData?.glPolicyNo)) {
        payload['glPolicyNo'] = formData.glPolicyNo
      }
      await addDoc(endoRef, payload);
      const subCollectionRef = collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        'waivers-of-subrogation'
      )
      const additionRef = await addDoc(subCollectionRef, waiverData);
      addId = additionRef.id
      await updateSystemLog(
        auth,
        policyID,
        "Add Waiver Of Subrogation Endorsement Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        `Add Waiver Of Subrogation Endorsement`,
        waiverData
      );
      return payload
    });
    return {
      counter,
      result,
      addId
    }
  } catch (error) {
    throw error;
  }
};
