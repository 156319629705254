import React from "react";
import { LOSS_CONTROL_ROUTE } from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import useTranslations from "../../../Context/actions/useTranslations";
import { BarChart } from "@mui/icons-material";

export default function LossControlManageMenu({ userInfo }) {
  const { t } = useTranslations();

  console.log("userInfo?.hasFinanceAuthority", userInfo?.hasFinanceAuthority);

  return (
    <>
      <NavDropdown.Item href={LOSS_CONTROL_ROUTE}>
        <BarChart className="mr-2" /> Loss Control
      </NavDropdown.Item>
    </>
  );
}
