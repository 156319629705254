import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const EditSelection = ({
  editSelection,
  setEditSelection,
  disabled,
  disabledCrashes,
  searchType,
  disabledInspections
}) => {
  const handleOptionChange = (event) => {
    setEditSelection(event.target.value);
  };

  return (
    <div className="my-4">
      <FormControl fullWidth>
        <InputLabel id="select-label">Select Option</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={editSelection}
          onChange={handleOptionChange}
          fullWidth
        >
          <MenuItem value={"Search"}>Driver Search</MenuItem>
          <MenuItem value={"viewAll"}>View All Eventualities</MenuItem>
          <MenuItem value={"scaling"}>Driver Profile Scaling</MenuItem>
          <MenuItem value={"tables"}>View/Edit Tables</MenuItem>
          <MenuItem value={"matrices"}>View/Edit Matrices</MenuItem>
          {/* <MenuItem value={"upload"}>Upload Violations</MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
};

export default EditSelection;
