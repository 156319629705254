import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";

export default function CommodityDetails({
  insuredInfo,
  policyID,
  field,
  conditional,
  label,
  editMode,
  formData,
  setInsuredInfo,
}) {

  const handleChangeStandardCommodityExplanation = async (e) => {
    const updatedValues = { ...insuredInfo.standardCommodityPercentages };
    updatedValues[e.target.name] = e.target.value;
    setInsuredInfo({
      ...insuredInfo,
      standardCommodityPercentages: updatedValues,
    })
  };

  return (
    <>
      {conditional && (
        <div className="flex flex-col space-y-2">
          <label className="text-xs font-semibold">{label}:</label>
          <input
            name={field}
            type="text"
            onChange={handleChangeStandardCommodityExplanation}
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            value={insuredInfo.standardCommodityPercentages[field] || ""}
            disabled={!editMode}
          />
        </div>
      )}
    </>
  );
}
