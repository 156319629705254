import { updatePolicyInsuredField } from "../../../utils/updatePolicyField";

const handleSubtractCommodity = async (
  e,
  i,
  formData,
  policyID,
  setFormData,
  insuredInfo,
  setInsuredInfo
) => {
  e.preventDefault();
  const values = [...formData.commodityFields];
  values.splice(i, 1);
  if (values.length === 0) {
    setInsuredInfo({
      ...insuredInfo,
      commodityFields: [
        {
          id: 1,
          commodityDescription: "",
          commodityLoad: "0",
        },
      ],
    })
  } else {
    setInsuredInfo({
      ...insuredInfo,
      commodityFields: values,
    })
  }
};

export default handleSubtractCommodity;
