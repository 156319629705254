import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import PowerUnitDetailsModal from "./PowerUnitDetailsModal";
import LoadingScreen from "../../assets/LoadingScreen";
import getPoliciesWithPowerUnits from "./utils/getPoliciesWithPowerUnits";
import getCustomerData from "./utils/getCustomerData";
import axios from "axios";
import config from "../../../config/env";

export default function TruckMasterList() {
  const { REACT_APP_SOUTHERN_BACKEND } = config;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [unitList, setUnitList] = useState([]);
  const [filteredUnitList, setFilteredUnitList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [filterColumn, setFilterColumn] = useState("policyNumber");
  const [filterValue, setFilterValue] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    setSelectedUnit(row);
    setShowUnitDetailsModal(true);
  };

  const [shouldQueryAll, setShouldQueryAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const customersData = await getCustomerData();
      setCustomers(customersData);
      await getPoliciesWithPowerUnits(
        customersData,
        setLoading,
        (data) => {
          setUnitList(data);
          setFilteredUnitList(data); // Initialize the filtered list
        },
        shouldQueryAll
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterResults = () => {
      if (!filterValue) {
        setFilteredUnitList(unitList);
      } else {
        const filtered = unitList.filter((unit) =>
          unit[filterColumn]
            ?.toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
        setFilteredUnitList(filtered);
      }
    };

    filterResults();
  }, [filterValue, filterColumn, unitList]);

  const [showUnitDetailsModal, setShowUnitDetailsModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleExport = async () => {
    console.log("filteredUnitList:", filteredUnitList);
    // map so that the list is just
    // {
    //   policyNumber: "POL123",
    //   dot: "123456",
    //   namedInsured: "ABC Trucking",
    //   truckVIN: "1HGBH41JXMN109186",
    //   truckRadius: "500",
    // }
    const formattedUnitList = filteredUnitList.map((unit) => ({
      policyNumber: unit.policyNumber || "Application",
      dot: unit.dot,
      namedInsured: unit.namedInsured,
      truckVIN: unit.truckVIN,
      truckRadius: unit.truckRadius,
    }));
    // try {
    //   const response = await axios.post(
    //     `http://localhost:9000/export/vin-master-list`,
    //     { truckList: filteredUnitList }, // Send truckList in the request body
    //     { responseType: "blob" } // Ensure the response is treated as a file
    //   );

    //   // Create a link to download the file
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "VIN_Master_List.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    //   link.remove();
    // } catch (error) {
    //   console.error("Error exporting VIN data:", error);
    // }
  };

  return (
    <>
      <p className="mb-4 text-3xl font-bold">VIN Master List</p>
      <button
        onClick={handleExport}
        style={{
          backgroundColor: "#072a48",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          marginBottom: "20px",
          cursor: "pointer",
        }}
      >
        Export to Excel
      </button>
      <div className="mb-4">
        {shouldQueryAll
          ? "Currently Showing All Units"
          : "Currently Showing Only Units From Active Policies"}{" "}
        (
        <button
          className="text-[#072a48]"
          onClick={() => {
            const currentValue = shouldQueryAll;
            getPoliciesWithPowerUnits(
              customers,
              setLoading,
              (data) => {
                setUnitList(data);
                setFilteredUnitList(data);
              },
              !currentValue
            );
            setShouldQueryAll(!currentValue);
          }}
        >
          {shouldQueryAll ? "Show Active Only" : "Show All"}
        </button>
        )
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <PowerUnitDetailsModal
          isOpen={showUnitDetailsModal}
          onClose={() => setShowUnitDetailsModal(false)}
          contentLabel="Power Unit Details"
          policyID={selectedUnit?.policyID}
          truck={selectedUnit}
        />
        {loading ? (
          <LoadingScreen text={"Loading Trucks..."} />
        ) : (
          <Box style={{ padding: "10px" }}>
            {/* Dropdown and Input Filter */}
            <Box display="flex" alignItems="center" gap="16px" mb={2}>
              <TextField
                select
                label="Filter By"
                value={filterColumn}
                onChange={(e) => setFilterColumn(e.target.value)}
                sx={{ minWidth: 200 }}
              >
                <MenuItem value="policyNumber">Policy Number</MenuItem>
                <MenuItem value="dot">DOT</MenuItem>
                <MenuItem value="namedInsured">Named Insured</MenuItem>
                <MenuItem value="truckVIN">VIN</MenuItem>
              </TextField>
              <TextField
                label="Search"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                sx={{ flex: 1 }}
              />
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Policy Number</TableCell>
                    <TableCell>DOT</TableCell>
                    <TableCell>Named Insured</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Radius</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUnitList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        hover
                        onClick={() => handleRowClick(row)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Tooltip
                            title={row.effectiveDate?.toDate()
                              .toString()
                              .slice(4, 15)}
                          >
                            {row.policyNumber || "Application"}
                          </Tooltip>
                        </TableCell>
                        <TableCell>{row.dot}</TableCell>
                        <TableCell>{row.namedInsured}</TableCell>
                        <TableCell>{row.truckVIN}</TableCell>
                        <TableCell>{row.truckRadius}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredUnitList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </>
  );
}
