import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";

const clearQuoteList = async (e, quoteHistory, setQuoteHistory, policyID) => {
  e.preventDefault();
  let verify = await confirmDeletePromptGlobal(
    "You are about to delete an entire list! If you are sure, confirm the action"
  );
  const policyDocRef = doc(db, "policy-applications", policyID);
  await updateDoc(policyDocRef, {
    selectedQuote: "#",
    alPremiums: {},
    glPremiums: {},
    apdPremiums: {},
    mtcPremiums: {},
  });
  if (verify === "DELETE") {
    quoteHistory.forEach(async (quote) => {
      await deleteDoc(
        doc(db, "policy-applications", policyID, "quotes", quote.id)
      );
    });
    setQuoteHistory([]);
  }
};

export default clearQuoteList;
