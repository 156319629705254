import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ProvideQuoteFigures from "../APDandMTC/ProvideQuoteFigures";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";
import BlueButton from "../../../assets/forms/BlueButton";
import { Card, CardContent, CardHeader, Typography, useMediaQuery } from "@mui/material";
import useTranslations from "../../../../Context/actions/useTranslations";

function UnderwritingDetails({
  formData,
  setFormData,
  policyID,
  isNewQuote,
  nextStep,
  show,
  setShow,
  userInfo,
  disabled,
  insuredInfo
}) {
  const { t } = useTranslations();

  const userIsUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isQuoteRequest = formData.documentType === "Quote Request";

  return (
    <>
      {userIsUnderwriter && (
        <>
          <Card sx={{ mt: 4 }}>
            <CardHeader
              sx={{ backgroundColor: "#072a48", color: "white" }}
              title={
                <Typography variant="h6">
                  {t("plm.quotes.underwriting-details.title")}
                </Typography>
              }
              action={<ExpandCollapseArrow show={show} setShow={setShow} />}
            />
            {show && (
              <CardContent>
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      nextStep();
                    }}
                  >
                    <ProvideQuoteFigures
                      disabled={disabled}
                      formData={formData}
                      policyID={policyID}
                      setFormData={setFormData}
                      userInfo={userInfo}
                      isNewQuote={isNewQuote}
                      insuredInfo={insuredInfo}
                    />
                    {isQuoteRequest && userIsUnderwriter && isNewQuote && (
                      <article>
                        <div className="flex items-center mt-2">
                          <BlueButton
                            text={t("Continue")}
                            disabled={false}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            icon={null}
                            className={isMobileScreen ? "w-[100%]" : ""}
                            handleAsSubmit={true}
                          />
                        </div>
                      </article>
                    )}
                  </form>
                </>
              </CardContent>
            )}
          </Card>
        </>
      )}
    </>
  );
}

export default UnderwritingDetails;
