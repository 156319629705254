import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import useGeneralState from "../../../Context/actions/useGeneralState";
import {
  setPolicyDetailUpdate,
  setPolicyInfo,
} from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import Popup from "../../generals/Popup";
import DriverDetails from "../../PolicyLifecycleManager/Drivers/DriverDetails";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import { getLastId } from "../../../utils/endorsementDetails";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import useManagingState from "../../../Context/actions/useManagingState";
import checkDriverForFlags from "../../utils/checkDriverForFlags";
import useTranslations from "../../../Context/actions/useTranslations";
import DriverDetailFlags from "../../PolicyLifecycleManager/RedFlags/DriverDetailFlags";
import DropdownMultiUpload from "../../assets/forms/DynamicPropertiesInputs/DropdownMultiUpload";
import { handleDropdown } from "../../assets/lists/DynamicTable/utils";
import updateSystemLog from "../../utils/updateSystemLog";

export default function AddDrivers(props) {
  const agencyID = props.agencyID;
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;
  const insuredInfo = props.insuredInfo;
  const [endorsementData, setEndorsementData] = useState([]);

  const handleGetDriversById = async () => {
    const drivers = [];
    for (const driverId of endorsement.data) {
      const docRef = doc(
        db,
        "agencies",
        endorsement.agencyID,
        "insureds",
        endorsement.insuredID,
        "drivers",
        getLastId(driverId.path)
      );
      const docSnap = await getDoc(docRef);
      const policyDetails = docSnap.data()?.policiesDetails.find(policy => {
        return policy.id === paramPolicyID
      })
      if (policyDetails?.id) {
        const docPolicyRef = doc(
          db,
          "policy-applications",
          policyDetails?.id,
        );
        const docSnapPolicy = await getDoc(docPolicyRef)
        if (docSnap.exists()) {
          drivers.push({
            id: docSnap.id,
            ...docSnap.data(),
            policyData: {
              ...docSnapPolicy.data(),
              id: docSnapPolicy.id,
            }
          });
        } else {
          console.log("No such document!");
          continue
        }
      } else {
        console.log("No such document policy id in policies details!");
        continue
      }
    }
    setEndorsementData(drivers);
  };

  useEffect(() => {
    handleGetDriversById();
  }, [endorsement]);

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);

  const { allowApprovalRedFlags } = useManagingState()
  const { t } = useTranslations()

  const approveAddDriversEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreateDriver = async (
      endorsementId,
      status,
      driverData,
      policyData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date());
          const payload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            payload["wasARequestException"] = true;
            payload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, payload);
          const promises = driverData.map(async (driver) => {
            const subcollectionRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              "drivers",
              driver.id
            );
            const payload = setPolicyInfo(
              {
                ...driver,
                dateCreated: new Date(),
              },
              {
                policyID: paramPolicyID,
                status: "Active",
              },
              "endo-addition"
            );

            await setDoc(subcollectionRef, payload, { merge: true });

            updateSystemLog(
              auth,
              paramPolicyID,
              "Add Driver Endorsement (" +
                payload.licenseNumber +
                ") Approved For " +
                insuredInfo.company +
                " by " +
                auth.currentUser.displayName,
              "Add Drivers Endo Approved",
              payload,
              true
            );

            const renewalApplicationID = policyData?.renewalApplicationID
            if (renewalApplicationID) {
              const mirroredSubcollectionRef = doc(
                db,
                "agencies",
                agencyID,
                "insureds",
                insuredID,
                "drivers",
                driver.id
              );
              const mirroredPayload = setPolicyInfo(
                {
                  ...payload,
                  dateCreated: new Date(),
                },
                {
                  policyID: renewalApplicationID,
                  status: "Active",
                },
                "endo-addition"
              );

              await setDoc(mirroredSubcollectionRef, mirroredPayload, {
                merge: true,
              });

              updateSystemLog(
                auth,
                renewalApplicationID,
                "Add Driver Endorsement (" +
                  payload.licenseNumber +
                  ") Approved For " +
                  insuredInfo.company +
                  " by " +
                  auth.currentUser.displayName,
                "Add Drivers Endo Approved",
                payload,
                true
              );
            }
            return
          });
          await Promise.all(promises)
          setSuccess(true);
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating driver:",
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status;
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true)
      return
    }
    
    if (!allowApprovalRedFlags) {
      let thereIsRedFlag = false
      endorsementData.forEach(driver => {
        const redFlags = checkDriverForFlags(driver, t)
        if (redFlags?.length > 0) {
          thereIsRedFlag = true
        }
      })
      if (thereIsRedFlag) {
        controlToast(
          true,
          'Endorsement can not be approved because the driver has at least one red flag',
          'error'
        )
        return
      }
    }
    
    if (!allowApprovalRedFlags) {
      let thereIsRedFlag = false
      endorsementData.forEach(driver => {
        const redFlags = checkDriverForFlags(driver, t)
        if (redFlags?.length > 0) {
          thereIsRedFlag = true
        }
      })
      if (thereIsRedFlag) {
        controlToast(
          true,
          'Endorsement can not be approved because the driver has at least one red flag',
          'error'
        )
        return
      }
    }

    updateEndorsementStatusAndCreateDriver(
      paramRefID,
      "Approved",
      endorsementData,
      policyData
    );

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      agencyID
    );
  };

  const { controlToast } = useGeneralState();

  const [blacklistValidationDone, setBlacklistValidationDone] = useState(false);

  const verifyBlackDriver = async () => {
    const licenseNumber = endorsementData?.licenseNumber;
    const licenseNumberList = endorsementData?.map(
      (data) => data.licenseNumber
    );
    if (
      licenseNumberList.length > 0 &&
      endorsement.status === "Submitted" &&
      !blacklistValidationDone
    ) {
      let q = query(collection(db, "drivers-blacklist"));
      q = query(q, where("licenseNumber", "in", licenseNumberList));
      const queryData = await getDocs(q);
      const blacklistedMatches = queryData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      for (const entity of blacklistedMatches) {
        if (licenseNumber === entity.licenseNumber) {
          await declineAddDriversEndorsement(null, null, ["Blacklisted"]);
          setBlacklistValidationDone(true);
          controlToast(
            true,
            "Endorsement was declined because there was a driver found in the blacklist",
            "error"
          );
          break;
        }
      }
    }
  };

  useEffect(() => {
    verifyBlackDriver();
  }, [endorsementData]);

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAddDriversEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }

    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = {
      ...policyDoc.data(),
      id: policyDoc.id,
    }

    await updateDoc(endoRef, payload);

    const promises = endorsementData?.map(async (subcollection) => {
      const { agencyID, insuredID } = endorsement;
      
      const subcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "drivers",
        subcollection?.id
      );

      const entities = await getDoc(subcollectionRef);
      const entityData = entities.data();
      const updatedPayload = setPolicyDetailUpdate(
        payload,
        entityData,
        payload?.["policiesDetails"]?.[0]
      );

      await setDoc(subcollectionRef, updatedPayload, { merge: true });

      updateSystemLog(
        auth,
        paramPolicyID,
        "Add Driver Endorsement (" +
          updatedPayload.licenseNumber +
          ") Rejected For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Drivers Endo Rejected",
        updatedPayload,
        true
      );

      const renewalApplicationID = policyData?.renewalApplicationID

      if (renewalApplicationID) {
        const mirroredSubcollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "drivers",
          subcollection?.id
        );

        const mirroredPayload = setPolicyInfo(
          {
            ...payload,
            dateCreated: new Date(),
          },
          {
            policyID: renewalApplicationID,
            status: "Declined",
          },
          "endo-addition"
        );

        const updatedPayload = setPolicyDetailUpdate(
          mirroredPayload,
          entityData,
          mirroredPayload?.["policiesDetails"]?.[0]
        );

        await setDoc(mirroredSubcollectionRef, updatedPayload, { merge: true });

        updateSystemLog(
          auth,
          renewalApplicationID,
          "Add Driver Endorsement (" +
            updatedPayload.licenseNumber +
            ") Rejected For " +
            insuredInfo.company +
            " by " +
            auth.currentUser.displayName,
          "Add Drivers Endo Rejected",
          updatedPayload,
          true
        );
      }
    });
    await Promise.all(promises);

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");

  const handleOpenDetailsModal = (id) => {
    setSelectedDriver(id);
    setOpenDetailsModal(!openDetailsModal);
  };

  return (
    <>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <Popup
        isOpen={openDetailsModal}
        onRequestClose={() => setOpenDetailsModal(false)}
        showExpandButton={true}
        maxWidth="inherit"
      >
        <div>
          <DriverDetails
            formData={endorsement}
            userInfo={user}
            selectedDriver={selectedDriver}
            setShowDriverDetails={setOpenDetailsModal}
            policyID={paramPolicyID}
          />
        </div>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineAddDriversEndorsement(e, null, reasons)
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div>
        {endorsementData.map((driver) => {
          
          return (
            <div className="mx-auto p-6 bg-white shadow-lg rounded-lg mb-2">
              <Container className="p-0">
                <DropdownMultiUpload
                  containerStyle="bg-transparent border-none p-0 position-absolute right-[40px]"
                  wrapperStyle="text-[#8B0000] hover:text-[#8B0000] d-inline mx-1"
                  handleDropdown={() => handleDropdown(
                    driver,
                    null,
                    "drivers",
                    null,
                    null,
                    null,
                    t
                  )}
                  formData={driver?.policyData}
                  item={driver}
                />
                <Row
                  className="cursor-pointer"
                  onClick={() => handleOpenDetailsModal(driver.id)}
                >
                  <Col sm={12}>
                    <span className="font-semibold">Name:</span>{" "}
                    {driver.driverFirst} {driver.driverLast}
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">DOB:</span>{" "}
                    {driver.driverDOB.toDate().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', })}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Age:</span>{" "}
                    {getAge(driver.driverDOB.toDate().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }))}
                  </Col>
                </Row> */}
                <Row>
                  {driver.licenseCountry === "USA" && (
                    <Col sm={12} md={6}>
                      <span className="font-semibold">State:</span>{" "}
                      {driver.licenseState}
                    </Col>
                  )}
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Country:</span>{" "}
                    {driver.licenseCountry === "USA"
                      ? "United States"
                      : driver.licenseCountry === "CA"
                      ? "Canada"
                      : "Mexico"}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">License No.:</span>{" "}
                    {driver.licenseNumber}
                  </Col>
                  <Col sm={12} md={6}>
                    {driver.licenseCountry === "MX" && (
                      <>
                        <span className="font-semibold">NMP:</span>{" "}
                        {driver.mxNMP}
                      </>
                    )}
                  </Col>
                </Row>
                {/* Alerts Row */}
                <Row>
                  <div className="mt-2">
                  <DriverDetailFlags
                    // singleIcon={true}
                    driver={driver}
                    floatRight={true}                  />
                    </div>
                </Row>
              </Container>
            </div>
          );
        })}
      </div>

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      (
        user.role === "Underwriter" ||
        user.role === "Admin"
      ) ? (
        <>
          <article className="mt-3">
            <p
              className={
                isRequestException
                  ? "text-red-700 text-center !max-w-[345px] mx-auto"
                  : ""
              }
            >
              {isRequestException
                ? `This endorsement has already been declined. 
                You are accepting/decling an exception request`
                : `Approving this endorsement will add the above driver(s) to the
                policy and notify the insured of the change.`}
            </p>
          </article>
          {isRequestException ? (
            <RequestExceptions
              endorsement={endorsement}
              color="red"
              center={true}
            />
          ) : null}
          <ApprovalButtons
            onApprove={approveAddDriversEndorsement}
            onDecline={() => setRejectionModal(true)}
          />
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <ApprovalButtons
          dissapearAccept={true}
          titleDecline="REQUEST EXCEPTION"
          onDecline={() => setRequestingExceptionMode(true)}
        />
      ) : null}
    </>
  );
}
