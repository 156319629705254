import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { setPolicyInfo } from "../../../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import endoStatusChangeNotification from "../../../../utils/notifications/endoStatusChangeNotification";
import updateEndorsementStatusAndCreatePowerUnit from "./updateEndorsementStatusAndCreatePowerUnit";
import generatePolicyInvoice from "../../../../utils/generateInvoice";

const approveAddPowerUnitsEndorsement = async (
  e,
  requestExceptionData,
  endorsement,
  paramPolicyID,
  paramRefID,
  setTaxSchedule,
  insuredInfo,
  setSuccess,
  setOpenInCancellationModal,
  endorsementData,
  vinArray
) => {
  e.preventDefault();
  const { agencyID, insuredID } = endorsement;

  const policyRef = doc(db, "policy-applications", paramPolicyID);
  const policyDoc = await getDoc(policyRef);
  const policyData = policyDoc.data();
  const policyStatus = policyData.status;
  if (policyStatus === "In-Cancellation") {
    setOpenInCancellationModal(true);
    return;
  }

  await updateEndorsementStatusAndCreatePowerUnit(
    paramRefID,
    "Approved",
    endorsementData,
    policyData,
    setSuccess,
    paramPolicyID,
    agencyID,
    insuredID,
    requestExceptionData,
    endorsement,
    setPolicyInfo,
    insuredInfo,
    setTaxSchedule,
    paramRefID
  );

  await endoStatusChangeNotification(
    endorsement.type,
    endorsement.endorsementNumber,
    "Approved",
    paramRefID,
    paramPolicyID,
    endorsement.agencyID
  );

  const state = (policyNumber) => {
    return policyNumber.split("-")[2];
  };
  
  await generatePolicyInvoice(
    insuredInfo.company,
    endorsement.endoEffectiveDate,
    endorsement.endoEffectiveDate,
    state(endorsement.alPolicyNo),
    endorsement,
    endorsement,
    endorsement.agencyID,
    "AL",
    true,
    vinArray,
    true
  );
};

export default approveAddPowerUnitsEndorsement;
